import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";

const NumberField = (props) => {
	const { fieldOnChange, ...others } = props;
	return (
		<TextField
			{...others}
			InputProps={{
				inputComponent: NumberFormatCustom,
			}}
			onChange={(v) => {
				fieldOnChange(v);
			}}
		/>
	);
};

const NumberFormatCustom = (props) => {
	const { inputRef, onChange, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			thousandSeparator=","
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
						id: props.id,
					},
				});
			}}
			allowNegative={false}
			decimalScale={2}
			fixedDecimalScale
		/>
	);
};

export default NumberField;
