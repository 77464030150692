import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Grid, Paper, withStyles, Avatar } from "@material-ui/core";
import { styled } from "@mui/material";
import textStyles from "./textStyles.module.css";
import changefundIcon from "../../assets/icons/ic-changefund.png";
import Dropdown from "./Input/Dropdown";
import SearchBar from "./Input/SearchBar";
import CashOut from "./CashOut/CashOutInformation";
import Verification from "../BayadId/Verification/Verification";
import Footer from "./Footer/Footer.js";
import FormattedTextField from "./Input/FormattedTextField.js";
import PartnersComponent from "./PartnersSection/PartnersSection";

import { useSelector, useDispatch } from "react-redux";
// import { setTransactionType } from "../../redux/modules/kycBayad";
import { setTransactionType } from "../../redux/modules/kycRemittance";

const ListContainer = withStyles((theme) => ({
	root: {
		marginTop: "20px",
		padding: "0px",
	},
}))(Paper);

const StyledPapper = styled(Paper)(({ theme }) => ({
	padding: "0px",
	margin: "0px",
	display: "flex",
	height: "100%",
	flexDirection: "column",
	justifyContent: "space-between",
}));

const StyledInnerPaper = styled(Paper)(({ theme }) => ({
	padding: "0px",
	margin: "0px",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
	margin: 0,
	padding: 0,
	height: "100%",
}));

const StyledInnerGrid = styled(Grid)(({ theme }) => ({
	margin: 0,
	padding: "20px",
	height: "100%",
}));

const StyledCenterContainer = styled(Container)(({ theme }) => ({
	margin: "250px auto",
	display: "block",
	"& p": {
		textAlign: "center",
	},
	"& img": {
		display: "block",
		margin: "0 auto",
		width: "109px",
		height: "auto",
	},
}));

const stylingSearchBar = {
	opacity: "0.5",
	borderRadius: "16px",
	height: "32px",
	width: "100%",
	padding: "9px 10px",
	backgroundColor: "#DCDCDC",
};

const Remittance = (props) => {
	let dispatch = useDispatch();

	return (
		<>
			<ListContainer container spacing={2}>
				<Grid item xs={12}>
					<Grid container>
						<Grid item sm={3}>
							<StyledContainer maxWidth="xl">
								<StyledPapper elevation={2}>
									<StyledInnerGrid>
										<FormattedTextField
											className={
												textStyles.textStylesMedium1113Poppins
											}
										>
											Remittance Transaction
										</FormattedTextField>

										<PartnersComponent />
									</StyledInnerGrid>

									<StyledInnerPaper elevation={2}>
										<Footer />
									</StyledInnerPaper>
								</StyledPapper>
							</StyledContainer>
						</Grid>
						<StyledInnerGrid item sm={9}>
							{props.transactionType == "" ? (
								<StyledCenterContainer>
									<img src={changefundIcon} />
									<p
										className={
											textStyles.textStyle1619WavehausSemi
										}
									>
										You don't have Remittance Transaction
										Yet
									</p>
								</StyledCenterContainer>
							) : (
								<></>
							)}
							{props.transactionType == "CASHOUT" ? (
								<>
									<CashOut />
								</>
							) : (
								<></>
							)}
						</StyledInnerGrid>
					</Grid>
				</Grid>
			</ListContainer>
		</>
	);
};

export default connect(
	(state) => ({
		transactionType: state.kycRemittance.transactionType,
	}),
	{
		setTransactionType,
	}
)(Remittance);
