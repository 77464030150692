import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { styled, Stack } from "@mui/material";
import textStyles from "../textStyles.module.css";
import DynamicAccordion from "../../Remittance/Accordion/DynamicAccordion.js";
import BayadCI from "./BayadCI.js";
import Address from "./Address.js";
import IdentificationDetails from "./IdentificationDetails.js";
import Buttons from "../../Remittance/Input/Buttons";
import { RadioButtonUncheckedSharp } from "@material-ui/icons";
import Box from "@mui/material/Box";

import { Container, Grid, Paper, withStyles, Avatar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
	getUpdateResult,
	setUserDetailsUpdate,
	getCustomerData,
} from "../../../redux/modules/kycUpdate";
import LoadingBar from "../Loading/LoadingBar.js";
import { setTransactionType } from "../../../redux/modules/kycBayad";

const StyledStack = styled(Stack)(({ theme }) => ({
	margin: "10px",
}));

const StyledButtonStack = styled(Stack)(({ theme }) => ({
	display: "flex",
	flexFlow: "row",
	width: "100%",
	justifyContent: "flex-end",
}));

const StyledMarginStack = styled(Stack)(({ theme }) => ({
	display: "flex",
	width: "15%",
	margin: "5px",
}));

const StyledStackPadding = styled(Stack)(({ theme }) => ({
	padding: "20px",
}));

const stylingCancelButton = {
	backgroundColor: "#8B9095",
	borderColor: "#8B9095",
	color: "#FFF",
	margin: "0 auto",
	padding: "10px 38px",
	marginTop: "5%",
	marginBottom: "12%",
};

const stylingSubmitButton = {
	margin: "0 auto",
	padding: "10px 38px",
	marginTop: "5%",
	marginBottom: "12%",
};

const stylingSubmitDisabledButton = {
	opacity: "0.5",
	color: "#fff",
	backgroundColor: "#0068D1",
	margin: "0 auto",
	padding: "10px 38px",
	marginTop: "5%",
	marginBottom: "12%",
};

const stylingLoadingBox = {
	justifyContent: "center",
	flexFlow: "row",
	alignItems: "center",
};

const Update = (props) => {
	const { selectedTransaction } = props;

	let dispatch = useDispatch();

	const [bayadCIFields, setBayadCIFields] = useState({});
	const [permanentAddressFields, setPermanentAddressFields] = useState({});
	const [presentAddressFields, setPresentAddressFields] = useState({});
	const [identificationFields, setIdentificationCIFields] = useState({});
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [submitHover, setSubmitHover] = useState(false);

	useEffect(() => {
		setBtnDisabled(false);
		btnDisabledChecker(bayadCIFields);
		btnDisabledChecker(permanentAddressFields);
		btnDisabledChecker(presentAddressFields);
		btnDisabledChecker(identificationFields);
	}, [
		bayadCIFields,
		permanentAddressFields,
		presentAddressFields,
		identificationFields,
	]);

	const btnDisabledChecker = (inputObject) => {
		Object.values(inputObject).map((vals) => {
			if (
				vals.required == true &&
				(vals.fieldValue == "" || vals.fieldValue == null)
			) {
				setBtnDisabled(true);
			}
			if (
				vals.name == "mobileNumber" &&
				vals.fieldValue != "" &&
				vals.fieldValue != null &&
				vals.fieldValue.replace(/[^0-9]/g, "").length < 10
			) {
				setBtnDisabled(true);
			}
		});
	};

	useEffect(() => {
		dispatch(getCustomerData());
	}, []);

	const dataMerge = () => {
		let tempData = {};
		let idFileNames = [];
		Object.values(bayadCIFields).map((name) => {
			if (name.apiName == "mobileNumber" || name.apiName == "lastName") {
				tempData = {
					...tempData,
					[name.apiName]:
						name.apiName == "mobileNumber"
							? name.fieldValue.replace(/[^0-9]/g, "")
							: name.fieldValue.toString(),
				};
			}
		});
		Object.values(presentAddressFields).map((name) => {
			tempData = { ...tempData, [name.apiName]: name.fieldValue };
		});
		Object.values(identificationFields).map((name) => {
			if (name.type == "Attachment") {
				idFileNames = [...idFileNames, name.fieldValue];
			} else {
				tempData = {
					...tempData,
					id: { ...tempData.id, [name.name]: name.fieldValue },
				};
			}
		});
		tempData = {
			...tempData,
			id: { ...tempData.id, idFileNames },
		};
		dispatch(setUserDetailsUpdate(tempData));
		dispatch(getUpdateResult());
	};

	return (
		<>
			<LoadingBar
				loading={props.kycBayadLoading || props.kycUpdateLoading}
				styleProps={stylingLoadingBox}
				thickness={3}
				size={100}
			/>
			<StyledStackPadding>
				<StyledStack>
					<p className={textStyles.textStylesBold1619WavehausBold}>
						Update Information: {props.customerData.bayadId}
					</p>
				</StyledStack>
				<StyledStack>
					<p className={textStyles.textStylesNormal1218Poppins}>
						Please ensure to input correct information from the
						customer.
					</p>
				</StyledStack>

				<form>
					<DynamicAccordion
						accordionSummary="Bayad Customer Information"
						defaultExpanded
					>
						<BayadCI
							setBayadCIFields={setBayadCIFields}
							submitHover={submitHover}
						/>
					</DynamicAccordion>

					<DynamicAccordion
						accordionSummary="Address"
						defaultExpanded
					>
						<Address
							setPermanentAddressFields={
								setPermanentAddressFields
							}
							setPresentAddressFields={setPresentAddressFields}
							submitHover={submitHover}
						/>
					</DynamicAccordion>

					<DynamicAccordion
						accordionSummary="Identification Details"
						defaultExpanded
					>
						<IdentificationDetails
							setIdentificationCIFields={
								setIdentificationCIFields
							}
							submitHover={submitHover}
						/>
					</DynamicAccordion>
					<StyledButtonStack>
						<StyledMarginStack>
							<Buttons
								variant="outlined"
								text="Cancel"
								styling={stylingCancelButton}
								handleOnClick={() => {
									dispatch(setTransactionType(""));
								}}
								radius={true}
								fullWidth
							/>
						</StyledMarginStack>
						<StyledMarginStack
							onMouseOver={() => {
								setSubmitHover(true);
							}}
							onMouseOut={() => setSubmitHover(false)}
						>
							<Buttons
								variant="contained"
								text="Submit"
								styling={
									btnDisabled
										? stylingSubmitDisabledButton
										: stylingSubmitButton
								}
								handleOnClick={() => dataMerge()}
								disabled={btnDisabled}
								radius={true}
								fullWidth
								// type="submit"
							/>
						</StyledMarginStack>
					</StyledButtonStack>
				</form>
			</StyledStackPadding>
		</>
	);
};

export default connect(
	(state) => ({
		customerData: state.kycUpdate.customerData,
		kycUpdateLoading: state.kycUpdate.loading,
		kycBayadLoading: state.kycBayad.loading,
	}),
	{
		getUpdateResult,
		setUserDetailsUpdate,
		setTransactionType,
		getCustomerData,
	}
)(Update);
