import {
	FormControl,
	MenuItem,
	TextField,
	TextFieldProps,
	withStyles,
} from "@material-ui/core";

const StyledMenuItem = withStyles({
	root: {
		fontFamily: "Poppins-Regular",
	},
	selected: {
		color: "#F26122",
	},
})(MenuItem);

const SelectFilter = (props: TextFieldProps & { options: any[] }) => {
	return (
		<FormControl variant="outlined" fullWidth>
			<TextField
				value=""
				onChange={(event) => {
					console.log(event);
				}}
				variant="outlined"
				select
				{...props}
			>
				{props.options.map(({ name, value }, i) => (
					<StyledMenuItem key={i} value={value}>
						{name}
					</StyledMenuItem>
				))}
			</TextField>
		</FormControl>
	);
};

export default SelectFilter;
