import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import styles from "./Select.module.css";

export default React.forwardRef(
  ({ onChange, selected, label, options, ...rest }, ref) => (
    <FormControl variant="outlined" fullWidth>
      <TextField
        select
        fullWidth
        label={label}
        variant="outlined"
        value={selected}
        onChange={onChange}
        classes={{ root: styles.select }}
        {...rest}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option.value}
            classes={{
              gutters: styles.menuItemList,
              selected: styles.menuListSelected,
            }}
            ListItemClasses={{
              focusVisible: styles.menuItemFocusVisible,
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
);
