import { connect } from "react-redux";
import { styled, Stack } from "@mui/material";
import textStyles from "../textStyles.module.css";

const StyledStack = styled(Stack)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-evenly",
	padding: "10px",
}));

const Footer = () => {
	return (
		<>
			<StyledStack>
				<p className={textStyles.textStyles6001012WavehausSemi}>
					@2021 Bayad FA v.10
				</p>

				<p className={textStyles.textStylesNormal812Poppins}>
					PRIVACY POLICY
				</p>
				<p className={textStyles.textStylesNormal812Poppins}>
					TERMS & CONDITION
				</p>
			</StyledStack>
		</>
	);
};

export default connect()(Footer);
