import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { styled, Stack, Box, Checkbox, FormControlLabel } from "@mui/material";
import InputField from "../Input/InputField.js";
import SelectField from "../Input/SelectField.js";
import FormattedTextField from "../Input/FormattedTextField.js";
import textStyles from "../textStyles.module.css";

import { useDispatch } from "react-redux";
import {
	setSelectedPermanentProvince,
	setSelectedPresentProvince,
	setSelectedPermanentCity,
	setSelectedPresentCity,
} from "../../../redux/modules/kycBayad";

const StyledStack = styled(Stack)(({ theme }) => ({
	display: "flex",
	flexFlow: "wrap",
	justifyContent: "space-between",
	alignItems: "center",
}));

const StyledHeaderStack = styled(Stack)(({ theme }) => ({
	display: "flex",
	flexFlow: "wrap",
	margin: "5px 0 5px 0",
	alignItems: "center",
}));

const StyledPresentAddress = styled(Stack)(({ theme }) => ({
	flexGrow: 1,
}));

const StyledCheckbox = styled(Stack)(({ theme }) => ({
	flexGrow: 3,
}));

const StyledInputBox = styled(Box)(({ theme }) => ({
	flex: "1 0 100%",
	margin: "5px 0 5px 0",
}));

const StyledBox = styled(Box)(({ theme }) => ({
	flex: "1 0 24%",
	width: "24%",
	maxWidth: "24%",
	margin: "5px 0 5px 0",
}));

const Address = (props) => {
	let dispatch = useDispatch();

	const { setPermanentAddressFields, setPresentAddressFields, submitHover } =
		props;
	const [markAsTheSame, setMarkAsTheSame] = useState(false);

	const [permanentAddress, setPermanentAddress] = useState({
		"Address-Province": {
			type: "Select",
			label: "Province",
			name: "Address-Province",
			dbname: "permanentProvinceId",
			id: "Permanent-Province",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PermanentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			fieldValue: "",
			values: [{}],
			required: true,
		},
		"Address-Municipality": {
			type: "Select",
			label: "Municipality",
			name: "Address-Municipality",
			dbname: "permanentCityId",
			id: "Permanent-Municipality",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PermanentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			fieldValue: "",
			values: [{}],
			required: true,
		},
		"Address-Zip": {
			type: "Select",
			label: "Zip Code",
			name: "Address-Zip",
			dbname: "permanentZipCodeId",
			id: "Permanent-Zip",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PermanentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			fieldValue: "",
			values: [{}],
			required: true,
		},
		"Address-Barangay": {
			type: "Input",
			label: "Barangay",
			name: "Address-Barangay",
			dbname: "permanentBarangay",
			id: "Permanent-Barangay",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PermanentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			fieldValue: "",
			required: false,
			maxLength: 50,
		},
		"Address-House": {
			type: "Input",
			label: "House No./ Bldg. No., Street, Subdivision",
			name: "Address-House",
			dbname: "permanentHouseNo",
			id: "Permanent-House",
			fieldValue: "",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PermanentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			required: true,
			fullWidth: true,
			maxLength: 200,
		},
	});
	const [presentAddress, setPresentAddress] = useState({
		"Address-Province": {
			type: "Select",
			label: "Province",
			name: "Address-Province",
			dbname: "provinceId",
			id: "Present-Province",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PresentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			fieldValue: "",
			values: [{}],
			required: true,
		},
		"Address-Municipality": {
			type: "Select",
			label: "Municipality",
			name: "Address-Municipality",
			dbname: "cityId",
			id: "Present-Municipality",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PresentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			fieldValue: "",
			values: [{}],
			required: true,
		},
		"Address-Zip": {
			type: "Select",
			label: "Zip Code",
			name: "Address-Zip",
			dbname: "zipCodeId",
			id: "Present-Zip",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PresentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			fieldValue: "",
			values: [{}],
			required: true,
		},
		"Address-Barangay": {
			type: "Input",
			label: "Barangay",
			name: "Address-Barangay",
			dbname: "barangay",
			id: "Present-Barangay",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PresentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			fieldValue: "",
			required: false,
			maxLength: 50,
		},
		"Address-House": {
			type: "Input",
			label: "House No./ Bldg. No., Street, Subdivision",
			name: "Address-House",
			dbname: "houseNo",
			id: "Present-House",
			fieldValue: "",
			onChange: (val, markedSame) =>
				addressOnChange(
					"PresentAddress",
					val.target.name,
					val.target.value,
					val.target.dbname,
					markedSame
				),
			required: true,
			fullWidth: true,
			maxLength: 200,
		},
	});

	const [presentAddressTemp, setPresentAddressTemp] =
		useState(presentAddress);

	useEffect(() => {
		setPermanentAddressFields(permanentAddress);
	}, [permanentAddress]);

	useEffect(() => {
		setPresentAddressFields(presentAddress);
	}, [presentAddress]);

	useEffect(() => {
		if (markAsTheSame === true) {
			Object.values(permanentAddress).map((fields) => {
				const tempPresData = {
					...presentAddress[fields.name],
					fieldValue: fields.fieldValue,
				};
				setPresentAddress((prevProps) => ({
					...prevProps,
					[fields.name]: tempPresData,
				}));
			});
		} else if (markAsTheSame === false) {
			Object.values(presentAddressTemp).map((fields) => {
				const tempPresData = {
					...presentAddress[fields.name],
					fieldValue: fields.fieldValue,
				};
				setPresentAddress((prevProps) => ({
					...prevProps,
					[fields.name]: tempPresData,
				}));
			});
		}
	}, [markAsTheSame]);

	const addressOnChange = (type, name, values, dbname, markedSame) => {
		if (markedSame && type === "PermanentAddress") {
			setPermanentAddress((prevProps) => ({
				...prevProps,
				[name]: { ...prevProps[name], fieldValue: values },
			}));
			setPresentAddress((prevProps) => ({
				...prevProps,
				[name]: { ...prevProps[name], fieldValue: values },
			}));
		}
		if (type === "PermanentAddress") {
			setPermanentAddress((prevProps) => ({
				...prevProps,
				[name]: { ...prevProps[name], fieldValue: values },
			}));
		}
		if (type === "PresentAddress") {
			setPresentAddress((prevProps) => ({
				...prevProps,
				[name]: { ...prevProps[name], fieldValue: values },
			}));
		}
	};

	useEffect(() => {
		let tempDataProvince = {};
		let tempPresDataProvince = {};

		Object.values(props.provincesSelectValues).map((val) => {
			tempDataProvince = dropdownValuesSupply(
				tempDataProvince,
				permanentAddress,
				"permanentAddress",
				"Address-Province",
				val
			);

			tempPresDataProvince = dropdownValuesSupply(
				tempPresDataProvince,
				presentAddress,
				"presentAddress",
				"Address-Province",
				val
			);
		});
	}, [props.provincesSelectValues]);

	useEffect(() => {
		let tempDataCity = {};
		Object.values(props.permanentCitiesSelectValues).map((val) => {
			tempDataCity = dropdownValuesSupply(
				tempDataCity,
				permanentAddress,
				"permanentAddress",
				"Address-Municipality",
				val
			);
		});
	}, [props.permanentCitiesSelectValues]);

	useEffect(() => {
		let tempPresDataCity = {};
		Object.values(props.presentCitiesSelectValues).map((val) => {
			tempPresDataCity = dropdownValuesSupply(
				tempPresDataCity,
				presentAddress,
				"presentAddress",
				"Address-Municipality",
				val
			);
		});
	}, [props.presentCitiesSelectValues]);

	useEffect(() => {
		let tempDataZip = {};
		Object.values(props.permanentZipSelectValues).map((val) => {
			tempDataZip = dropdownValuesSupply(
				tempDataZip,
				permanentAddress,
				"permanentAddress",
				"Address-Zip",
				val
			);
		});
	}, [props.permanentZipSelectValues]);

	useEffect(() => {
		let tempPresDataZip = {};
		Object.values(props.presentZipSelectValues).map((val) => {
			tempPresDataZip = dropdownValuesSupply(
				tempPresDataZip,
				presentAddress,
				"presentAddress",
				"Address-Zip",
				val
			);
		});
	}, [props.presentZipSelectValues]);

	useEffect(() => {
		if (permanentAddress["Address-Province"]?.fieldValue) {
			dispatch(
				setSelectedPermanentProvince(
					permanentAddress["Address-Province"]?.fieldValue
				)
			);
		}
	}, [permanentAddress["Address-Province"]?.fieldValue]);

	useEffect(() => {
		if (presentAddress["Address-Province"]?.fieldValue) {
			dispatch(
				setSelectedPresentProvince(
					presentAddress["Address-Province"]?.fieldValue
				)
			);
		}
	}, [presentAddress["Address-Province"]?.fieldValue]);

	useEffect(() => {
		if (permanentAddress["Address-Municipality"]?.fieldValue) {
			dispatch(
				setSelectedPermanentCity(
					permanentAddress["Address-Municipality"]?.fieldValue
				)
			);
		}
	}, [permanentAddress["Address-Municipality"]?.fieldValue]);

	useEffect(() => {
		if (presentAddress["Address-Municipality"]?.fieldValue) {
			dispatch(
				setSelectedPresentCity(
					presentAddress["Address-Municipality"]?.fieldValue
				)
			);
		}
	}, [presentAddress["Address-Municipality"]?.fieldValue]);

	const dropdownValuesSupply = (
		tempData,
		addressState,
		typeOfAddress,
		objectKey,
		val
	) => {
		let selectVals = tempData ? tempData.values : {};
		if (selectVals) {
			tempData = {
				...addressState[objectKey],
				values: [...selectVals, { id: val.id, name: val.name }],
			};
		} else {
			tempData = {
				...addressState[objectKey],
				values: [{ id: val.id, name: val.name }],
			};
		}
		if (typeOfAddress == "permanentAddress") {
			setPermanentAddress((prevProps) => ({
				...prevProps,
				[objectKey]: tempData,
			}));
		} else if (typeOfAddress == "presentAddress") {
			setPresentAddress((prevProps) => ({
				...prevProps,
				[objectKey]: tempData,
			}));
		}
		return tempData;
	};

	return (
		<>
			<StyledHeaderStack>
				<FormattedTextField
					className={textStyles.textStylesNormal1218Poppins}
					color="#000000"
				>
					Permanent Address
				</FormattedTextField>
			</StyledHeaderStack>

			<StyledStack>
				{Object.values(permanentAddress).map((fields, x) => {
					if (fields.type == "Input") {
						return (
							<Fragment key={x}>
								{fields.fullWidth ? (
									<StyledInputBox key={x}>
										<InputField
											className={
												textStyles.textStylesNormal1218Poppins
											}
											label={fields.label}
											name={fields.name}
											onChange={(val) =>
												fields.onChange(
													val,
													markAsTheSame
												)
											}
											inputProps={{
												style: {
													fontSize: 12,
													fontFamily: "Poppins",
												},
												maxLength: fields.maxLength,
											}}
											required={fields.required}
											key={x}
											error={
												submitHover
													? fields.required
														? fields.fieldValue
															? false
															: true
														: false
													: false
											}
										/>
									</StyledInputBox>
								) : (
									<StyledBox key={x}>
										<InputField
											className={
												textStyles.textStylesNormal1218Poppins
											}
											label={fields.label}
											name={fields.name}
											onChange={(val) =>
												fields.onChange(
													val,
													markAsTheSame
												)
											}
											inputProps={{
												style: {
													fontSize: 12,
													fontFamily: "Poppins",
												},
												maxLength: fields.maxLength,
											}}
											required={fields.required}
											key={x}
											error={
												submitHover
													? fields.required
														? fields.fieldValue
															? false
															: true
														: false
													: false
											}
										/>
									</StyledBox>
								)}
							</Fragment>
						);
					} else if (fields.type == "Select") {
						return (
							<StyledBox key={x}>
								<SelectField
									className={
										textStyles.textStylesNormal1218Poppins
									}
									label={fields.label}
									name={fields.name}
									value={fields.fieldValue}
									values={fields.values}
									onChange={(val) =>
										fields.onChange(val, markAsTheSame)
									}
									disabled={
										(fields.name == "Address-Municipality"
											? permanentAddress[
													"Address-Province"
											  ]?.fieldValue
												? props.permanentCitiesSelectValues
													? false
													: true
												: true
											: false) ||
										(fields.name == "Address-Zip"
											? permanentAddress[
													"Address-Municipality"
											  ]?.fieldValue
												? props.permanentZipSelectValues
													? false
													: true
												: true
											: false)
									}
									required={fields.required}
									key={x}
									error={
										submitHover
											? fields.required
												? fields.fieldValue
													? false
													: true
												: false
											: false
									}
								/>
							</StyledBox>
						);
					}
				})}
			</StyledStack>

			<StyledHeaderStack>
				<StyledPresentAddress>
					<FormattedTextField
						className={textStyles.textStylesNormal1218Poppins}
						color="#000000"
					>
						Present Address
					</FormattedTextField>
				</StyledPresentAddress>

				<StyledCheckbox>
					<FormControlLabel
						control={
							<Checkbox
								size="small"
								onClick={() => setMarkAsTheSame(!markAsTheSame)}
							/>
						}
						label={
							<FormattedTextField
								className={
									textStyles.textStylesNormal1218PoppinsLight
								}
							>
								Same as permanent address
							</FormattedTextField>
						}
					/>
				</StyledCheckbox>
			</StyledHeaderStack>

			<StyledStack>
				{Object.values(presentAddress).map((fields, x) => {
					if (fields.type == "Input") {
						return (
							<Fragment key={x}>
								{fields.fullWidth ? (
									<StyledInputBox key={x}>
										<InputField
											className={
												textStyles.textStylesNormal1218Poppins
											}
											label={fields.label}
											name={fields.name}
											onChange={(val) =>
												fields.onChange(val)
											}
											disabled={markAsTheSame}
											value={fields.fieldValue}
											inputProps={{
												style: {
													fontSize: 12,
													fontFamily: "Poppins",
												},
											}}
											required={fields.required}
											fullWidth={fields.fullWidth}
											key={x}
											error={
												submitHover
													? fields.required
														? fields.fieldValue
															? false
															: true
														: false
													: false
											}
										/>
									</StyledInputBox>
								) : (
									<StyledBox key={x}>
										<InputField
											className={
												textStyles.textStylesNormal1218Poppins
											}
											label={fields.label}
											name={fields.name}
											onChange={(val) =>
												fields.onChange(val)
											}
											disabled={markAsTheSame}
											value={fields.fieldValue}
											inputProps={{
												style: {
													fontSize: 12,
													fontFamily: "Poppins",
												},
											}}
											required={fields.required}
											key={x}
											error={
												submitHover
													? fields.required
														? fields.fieldValue
															? false
															: true
														: false
													: false
											}
										/>
									</StyledBox>
								)}
							</Fragment>
						);
					} else if (fields.type == "Select") {
						return (
							<StyledBox key={x}>
								<SelectField
									className={
										textStyles.textStylesNormal1218Poppins
									}
									label={fields.label}
									name={fields.name}
									values={fields.values}
									fieldValue={fields.fieldValue}
									disabled={
										markAsTheSame ||
										(fields.name == "Address-Municipality"
											? presentAddress["Address-Province"]
													?.fieldValue
												? props.permanentCitiesSelectValues
													? markAsTheSame
													: true
												: true
											: markAsTheSame) ||
										(fields.name == "Address-Zip"
											? presentAddress[
													"Address-Municipality"
											  ]?.fieldValue
												? props.presentZipSelectValues
													? markAsTheSame
													: true
												: true
											: markAsTheSame)
									}
									onChange={(val) => fields.onChange(val)}
									required
									key={x}
									error={
										submitHover
											? fields.required
												? fields.fieldValue
													? false
													: true
												: false
											: false
									}
								/>
							</StyledBox>
						);
					}
				})}
			</StyledStack>
		</>
	);
};

export default connect(
	(state) => ({
		provincesSelectValues: state.kycBayad.provincesSelectValues,
		permanentCitiesSelectValues: state.kycBayad.permanentCitiesSelectValues,

		presentCitiesSelectValues: state.kycBayad.presentCitiesSelectValues,
		permanentZipSelectValues: state.kycBayad.permanentZipSelectValues,
		presentZipSelectValues: state.kycBayad.presentZipSelectValues,
	}),
	{
		setSelectedPermanentProvince,
		setSelectedPresentProvince,
		setSelectedPermanentCity,
		setSelectedPresentCity,
	}
)(Address);
