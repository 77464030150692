import {Button, Dialog, DialogActions, Grid, Typography} from "@material-ui/core";
import React, {Component} from "react";
import styles from "./ViewReceiptModal.module.css";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import {connect} from "react-redux";
import {
    toggleTransactionSummary
} from "../../redux/modules/transaction"
import { withRouter } from "react-router-dom";

class ViewReceiptModal extends Component{
    componentDidMount() {
        this.props.history.push("/");
        this.props.history.push("/pay-bills");
    }

    render(){
        return(
            <div>
                <Dialog aria-labelledby="customized-dialog-title" open={this.props.showViewReceiptModal}
                        fullWidth={true} maxWidth={"xs"}>
                    <Grid container>
                        <Grid item xs={12}
                              style={{textAlign: "center"}}>
                            <WarningRoundedIcon
                                style={{fontSize: "80px", color: "#F26122", margin: "20px 10px 10px 10px"}}/>
                            <div style={{padding: "0 0 50px 0"}}>
                                <Typography className={styles.modalTitle}>
                                    Reminder!
                                </Typography>
                                <Typography className={styles.modalBody} style={{padding: "10px"}}>
                                    There is a receipt that needs to be printed
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <DialogActions style={{justifyContent: "center", borderTop: "1px solid #8B9095"}}>
                        <Button
                            onClick={() => {this.props.toggleTransactionSummary(true)}}
                            className={styles.enable}>
                            View Receipt
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect((state) => ({
        showViewReceiptModal: state.transaction.showViewReceiptModal
}), { toggleTransactionSummary }
) (withRouter(ViewReceiptModal));
