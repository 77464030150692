import SuccessInfoIcon from "../../../assets/icons/ic-customer-info.svg";
import { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import textStyle from "../textStyles.module.css";
import { Stack } from "@mui/material";
import Buttons from "../Input/Buttons";
import { FormControl, styled } from "@material-ui/core";

import { getCustomerData } from "../../../redux/modules/kycUpdate";
import { setTransactionType } from "../../../redux/modules/kycBayad";
import sadICON from "../../../assets/icons/ic-sad.png";
import  DynamicDialog  from "../../common/DynamicDialog/DynamicDialog";
import hourglass from "../../../assets/icons/ic-hourglass.svg";
import warning from "../../../assets/icons/ic-warning.svg";

const updateButtonStyling = {
	backgroundColor: "#0076BF",
	color: "#fff",
	margin: "20px 0",
	marginBottom: "20px",
};

const doneButtonStyling = {
	color: "#F26122",
	backgroundColor: "transparent",
};

const searchButtonStyling = {
	color: "#fff",
	backgroundColor: "#F26122",
	margin: "30px 80px",
};

const StyledStack = styled(Stack)(({ theme }) => ({
	margin: "60px 30%",
	display: "block",
	textAlign: "center",
	"& #remittance-kyc-verification-result": {
		"& .verification-result-header": {
			backgroundColor: "#EBEBEB",
			padding: "10px 20px",
			textAlign: "center",
		},
		"& p": {
			textAlign: "left",
		},
	},
	"& img": {
		width: "109px",
		height: "auto",
		marginBottom: "20px",
	},
	"& .error-message": {
		margin: "20px 0",
	},
}));

const KYCCustomerInformation = (props) => {
	let dispatch = useDispatch();
	const isHit = props.customerInformation.hitStatus === "HIT";
	const isExpired = props.customerInformation;
	const [open, setOpen] = useState(isHit || isExpired );

	useEffect(() => {
		if (props.bayadId) {
			dispatch(getCustomerData());
		}
	}, [props.bayadId]);

	const clearResult = () => {
		props.clearFunc(true);
	};

	const handleUpdate = (e) => {
		dispatch(setTransactionType("Update"));
	};

	const handleClose = () => {
		setOpen(false);
	};

	const verificationResultMessage = {
		// icon: props.customerInformation.hitStatus === "HIT"? hourglass : warning,
		icon: warning,
		resultMessage: props.message,
		primaryBtnLabel: "Done",
		primaryBtnFunction: () => {
			window.location.href ="/bayad-id";
			handleClose()
		},
		hasSecondaryButton: false,
		secondaryBtnLabel: "Update",
		onClose: true,
		secondaryBtnFunction: () => {
			window.location.href = "/";
		},
	};

	return (
		<StyledStack>
			{props.status == "0010" ? (
				<FormControl fullWidth>
					<img src={sadICON} />
					<p
						className={`${textStyle.textStylesBold1619WavehausBold} error-message`}
					>
						Sorry no information found.
					</p>
					<p
						className={`${textStyle.textStylesNormal1218Poppins} error-message`}
					>
						Please check carefully the information of the customer
						your looking for.
					</p>

					<Buttons
						text="Search Again"
						variant="outlined"
						radius={true}
						styling={searchButtonStyling}
						handleOnClick={clearResult}
					/>
				</FormControl>
			) : props.status == "0025" ? (
				<FormControl fullWidth>
					<img src={sadICON} />
					<p
						className={`${textStyle.textStylesBold1619WavehausBold} error-message`}
					>
						{props.error}
					</p>
					<Buttons
						text="Search Again"
						variant="outlined"
						radius={true}
						styling={searchButtonStyling}
						handleOnClick={clearResult}
					/>
				</FormControl>
			) : (
				<>
					<img src={SuccessInfoIcon} />
					<p className={textStyle.textStyle1619WavehausSemi}>
						Bayad Customer Information
					</p>
					<FormControl
						id="remittance-kyc-verification-result"
						fullWidth
					>
						<p className="verification-result-header">
							Bayad ID: {props.customerInformation.bayadId}
						</p>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<p>
								First Name:{" "}
								{props.customerInformation.firstName}
							</p>
							<p>
								Last Name: {props.customerInformation.lastName}
							</p>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<p>
								Date of Birth:{" "}
								{props.customerInformation.dateOfBirth}
							</p>
							<p>
								Mobile: {props.customerInformation.mobileNumber}
							</p>
						</Stack>
						<Stack>
							<Buttons
								text="UPDATE INFO"
								variant="outlined"
								radius={true}
								styling={updateButtonStyling}
								handleOnClick={handleUpdate}
							/>
							<Buttons
								text="DONE"
								variant=""
								styling={doneButtonStyling}
								handleOnClick={clearResult}
							/>
						</Stack>
					</FormControl>

					<DynamicDialog
					onCloseModal={handleClose}
					aria-labelledby="customized-dialog-title"
					isOpen={open}
					dialogEntry = {verificationResultMessage}
				>	
				</DynamicDialog>
				</>
			)}
		</StyledStack>
	);
};

export default connect(
	(state) => ({
		bayadId: state.kycInquiry.customerInformation.bayadId,
		error: state.kycInquiry.error,
	}),
	{
		setTransactionType,
		getCustomerData,
	}
)(KYCCustomerInformation);
