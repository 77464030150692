import {
	Button,
	FormControl,
	Grid,
	makeStyles,
	MenuItem,
	Select,
	withStyles,
} from "@material-ui/core";
import { Pagination as MuiPagination, PaginationItem } from "@material-ui/lab";
import PropTypes from "prop-types";

const useStyles = makeStyles({
	prevBtn: {
		borderRadius: "4px 0px 0px 4px !important",
	},
	nextBtn: {
		borderRadius: "0px 4px 4px 0px !important",
	},
	select: {
		paddingTop: "0.9em",
		paddingBottom: "0.9em",
	},
});

const PageItem = withStyles({
	root: {
		marginLeft: -1.55,
		marginTop: 10
	},
	page: {
		color: "#F26122 !important",
		backgroundColor: "white !important",
		borderRadius: 0,
		padding: "1em",
		height: "3.5em",
		border: "1px solid #DEE2E6",
	},
	selected: {
		color: "white !important",
		backgroundColor: "#F26122 !important",
	},
})(PaginationItem);

const RowsPerPageSelect = ({ value, options, onChange }) => {
	const classes = useStyles();
	return (
		<FormControl variant="outlined">
			<Select
				value={value}
				classes={{ select: classes.select }}
				onChange={(e) => onChange(e.target.value)}
			>
				{options.map((option, i) => (
					<MenuItem key={i} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const Pagination = ({
	page,
	rowsPerPage,
	totalCount,
	onPageChange,
	rowsPerPageOptions,
	showRowsPerPage,
	...props
}) => {
	const classes = useStyles();
	return (
		<Grid container>
			<Grid item xs={8}>
				{totalCount > 0 && (
					<Grid item xs>
						Showing {(page - 1) * rowsPerPage + 1} -{" "}
						{Math.min(page * rowsPerPage, totalCount)} of {totalCount}
					</Grid>
				)}
			</Grid>

			<Grid item xs={4}>
				<Grid container>
					{showRowsPerPage && (
						<Grid item xs={2}>
							<RowsPerPageSelect
								options={rowsPerPageOptions}
								value={rowsPerPage}
								onChange={(value) => onPageChange(1, value)}
							/>
						</Grid>
					)}
					<Grid item xs={10}>
						<MuiPagination
							{...props}
							count={Math.ceil(totalCount / rowsPerPage)}
							variant="text"
							shape="rounded"
							color="standard"
							page={page}
							onChange={(_, p) =>
								p !== page && onPageChange(p, rowsPerPage)
							}
							renderItem={(item) => {
								const { type } = item;
								if (type === "previous")
									return (
										<PageItem
											component={(props) => (
												<Button
													classes={{
														root: classes.prevBtn,
													}}
													{...props}
												>
													Previous
												</Button>
											)}
											{...item}
										/>
									);
								if (type === "next")
									return (
										<PageItem
											component={(props) => (
												<Button
													classes={{
														root: classes.prevBtn,
													}}
													{...props}
												>
													Next
												</Button>
											)}
											{...item}
										/>
									);
								return <PageItem {...item} />;
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

Pagination.propTypes = {
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
	totalCount: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	showRowsPerPage: PropTypes.bool,
};

Pagination.defaultProps = {
	rowsPerPageOptions: [25, 50, 100],
	showRowsPerPage: true,
};

export default Pagination;