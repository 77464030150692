import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";

const PrimaryTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#D5D5D5",
    color: "#3B3B3B",
    font: "normal normal 12px/18px Poppins-Medium",
  },
  body: {
    font: "normal normal 12px/18px Poppins-Regular",
    color: "#3B3B3B",
    wordBreak: "break-word",
    maxWidth: "100px",
  },
}))(TableCell);

export { PrimaryTableCell };
