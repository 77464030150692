import {
	Dialog,
	withStyles,
	createMuiTheme,
	ThemeProvider,
	Grid,
	Typography,
	IconButton,
	TextField,
	Button,
	makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { useState } from "react";
import styles from "../../TransactionSummary/TransactionSummary.module.css";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import cx from "classnames";
import PrintReceiptSoa from "../../PrintReceipt/PrintReceiptSoa";
import PrintReceipt from "../../PrintReceipt/PrintReceipt";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateReprintRequestBody } from "../../../redux/modules/reprint";
import { togglePrintReceiptModal } from "../../../redux/modules/receipt";
import { updateSelectedForPrint } from "../../../redux/modules/transaction";
import PrintRecieptCheck from "../../PrintReceipt/PrintRecieptCheck";

const useStyles = makeStyles({
	title: {
		fontFamily: "Poppins-Medium",
		backgroundColor: "#0076C0",
		padding: "1em",
		color: "white",
	},
	body: {
		overflowY: "auto",
		height: "80vh",
	},
	bodyList: {
		padding: "2em",
	},
	span: {
		fontFamily: "Poppins-Regular",
	},
	bottomBorder: {
		borderBottom: "1px gray solid",
	},
});

const TransactionDialog = withStyles({
	paper: {
		width: "55vw",
		padding: "1em",
	},
})(Dialog);

const datePickerTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#0076C0",
		},
	},

	overrides: {
		MuiButton: {
			label: {
				textTransform: "none",
			},
		},
	},
});

const ReprintModal = ({ open, data, onClose }) => {
	const [approver, setApprover] = useState("");
	const [remarks, setRemarks] = useState("");
	const dispatch = useDispatch();

	const classes = useStyles();
	var componentRef = React.createRef();

	// commented for now
	// const selectedForPrint = useSelector((state) =>
	// 	state.transaction.selectedForPrint != null
	// 		? state.transaction.selectedForPrint.values
	// 		: null
	// );

	const bayadStyle = `
	@page {
		size: 10cm 21cm !important;
		page-break-after: avoid !important;
	}
	`;

	const soaStyle = `
	@page {
		size: 20.01cm 25cm !important;
		page-break-after: avoid !important;
	}
	`;

	const onBillReceiptClick = () => {
		try {
			onClose();
		} catch (e) {
			console.log(e);
		}
	};

	const handleReprint = () => {
		var requestBody = {
			// status: "reprint",
			client_reference_number: data.crn,
			approver: approver,
			remarks: remarks,
			receiptNo:
				data?.printType === "CHECK"
					? data.checkReceiptNo
					: data.receiptNo,
			username: data.username,
		};

		onBillReceiptClick(requestBody);
		dispatch(updateReprintRequestBody({ data: requestBody }));
		dispatch(
			updateSelectedForPrint({
				values: { ...data, approver, remarks },
			})
		);
	};
	const clearState = () => {
		setApprover("");
		setRemarks("");
	};

	const handleClose = () => {
		clearState();
		onClose();
	};

	const printBillsClose = () => {
		dispatch(togglePrintReceiptModal(true));
		onClose();
	};

	const formatInput = (e) => {
		return e.trim();
	};

	const handleInputChange = (event) => {
		const { value } = event.target;
		// Restricting :, /, ;, <, >, =
		event.target.value = value.replace(/[:/;<>=]/g, "");
	};

	return (
		<>
			{data && (
				<ThemeProvider theme={datePickerTheme}>
					<TransactionDialog
						onClose={handleClose}
						open={open}
						maxWidth="sm"
						fullWidth
					>
						<Grid spacing={2}>
							<Grid
								container
								justify="space-between"
								spacing={24}
								alignItems="center"
								style={{ padding: "0px 20px" }}
								className={classes.bottomBorder}
							>
								<Grid item>
									<Typography
										variant="h6"
										style={{
											fontFamily: "Poppins-Regular",
										}}
									>
										Reprint{" "}
										{data?.printType === "CHECK"
											? "Check"
											: "Receipt"}
									</Typography>
								</Grid>
								<Grid>
									<IconButton
										aria-label="close"
										className={classes.closeButton}
										onClick={handleClose}
									>
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>
							<Grid
								container
								item
								xs={12}
								style={{ padding: "40px 20px" }}
								className={classes.bottomBorder}
								direction="column"
							>
								<Grid item>
									<TextField
										required
										onBlur={(event) =>
											setApprover(
												formatInput(event.target.value)
											)
										}
										onChange={handleInputChange}
										fullWidth
										id="outlined-approver"
										label="Approver"
										type="text"
										variant="outlined"
										style={{ marginBottom: "20px" }}
										inputProps={{ maxLength: 255 }}
									/>
								</Grid>
								<Grid item>
									<TextField
										required
										onBlur={(event) =>
											setRemarks(
												formatInput(event.target.value)
											)
										}
										onChange={handleInputChange}
										fullWidth
										id="outlined-remarks"
										label="Remarks"
										type="text"
										variant="outlined"
										multiline
										rows={4}
										style={{ marginBottom: "20px" }}
										inputProps={{ maxLength: 1000 }}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								justify="flex-end"
								style={{ padding: "10px 20px" }}
							>
								<Button
									onClick={handleClose}
									autoFocus
									style={{
										marginRight: "10px",
										backgroundColor: "white",
										border: "1px #8080804a solid",
									}}
								>
									Cancel
								</Button>
								<ReactToPrint
									onAfterPrint={printBillsClose}
									content={() => componentRef}
									bodyClass={cx({
										[styles.bodyPrintWindow]:
											data.printType !== "SOA",
									})}
									pageStyle={
										data.printType === "SOA"
											? soaStyle
											: bayadStyle
									}
								>
									<PrintContextConsumer>
										{({ handlePrint }) => (
											<Button
												onClick={() => {
													handleReprint();
													handlePrint(
														() => componentRef
													);
												}}
												autoFocus
												style={{
													backgroundColor: "#32AA4D",
													color: "#FFFFFF",
												}}
												disabled={
													remarks === "" ||
													approver === ""
												}
											>
												Submit
											</Button>
										)}
									</PrintContextConsumer>
								</ReactToPrint>
							</Grid>
						</Grid>
						<div style={{ display: "none" }}>
							{data.printType === "SOA" ? (
								<PrintReceiptSoa
									ref={(el) => (componentRef = el)}
								/>
							) : data.printType === "CHECK" ? (
								<PrintRecieptCheck
									ref={(el) => (componentRef = el)}
								/>
							) : (
								<PrintReceipt
									ref={(el) => (componentRef = el)}
								/>
							)}
						</div>
					</TransactionDialog>
				</ThemeProvider>
			)}
		</>
	);
};

ReprintModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	data: PropTypes.object,
};

ReprintModal.defaultProps = {
	open: false,
	onClose: () => {},
	data: {
		printType: "SOA",
	},
};

export default ReprintModal;
