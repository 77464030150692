import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	userDetailsUpdate: {},
	userDetailsUpdateResult: {},
	customerData: {},
	isSuccess: false,
	loading: false,
	isError: false,
	error: "",
};

const kycUpdate = createSlice({
	name: "kycUpdate",
	initialState,
	reducers: {
		setUserDetailsUpdate: (state, payload) => {
			state.userDetailsUpdate = { ...payload };
		},
		loading: (state, payload) => {
			state.loading = true;
		},
		error: (state, payload) => {
			state.loading = false;
			state.isError = true;
			state.error = payload;
		},
		fulfilledUpdate: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.userDetailsUpdateResult = { ...payload };
			console.log("UPDATE SUCCESSFUL ", payload);
		},
		fulfilledCustomerData: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.customerData = payload.result?.data;
		},
	},
	extraReducers: {},
});

const getUpdateResultAPI = async (client, state) => {
	return await client.put("/v2/remittance/kyc/customers", {
		...state.kycUpdate.userDetailsUpdate.payload,
		bayadId: state.kycInquiry.bayadID,
		kycUploadForm: "kyc.pdf",
	});
};

const getCustomerDataAPI = async (client, state) => {
	return await client.get("/v2/remittance/kyc/customers", {
		params: { bayad_id: state.kycInquiry.bayadID },
	});
};

function getCustomerData() {
	return {
		types: [loading.type, fulfilledCustomerData.type, error.type],
		promise: getCustomerDataAPI,
	};
}

function getUpdateResult() {
	return {
		types: [loading.type, fulfilledUpdate.type, error.type],
		promise: getUpdateResultAPI,
	};
}

const {
	loading,
	fulfilledUpdate,
	error,
	setUserDetailsUpdate,
	fulfilledCustomerData,
} = kycUpdate.actions;

export default kycUpdate.reducer;

export { getUpdateResult, getCustomerData, setUserDetailsUpdate };
