import React, { useEffect, useState } from "react";
import cx from "classnames";
import styles from "./Tab.module.css";

export const Tabs = ({ tabs, onChange, children }) => {
	const [activeTab, setActiveTab] = useState(tabs[0]?.value);

	useEffect(() => {
		setActiveTab(tabs[0]?.value);
	}, [tabs]);

	useEffect(() => {
		onChange(activeTab);
	}, [activeTab]);

	const changeActiveTab = (tab) => {
		setActiveTab(tab);
	};

	const tabClass = (tab) => {
		return tab === activeTab ? cx(styles.cTab, styles.active) : styles.cTab;
	};

	const displayPanel = () => {
		const activePanel = Array.isArray(children)
			? children.find((element) => element.props?.value === activeTab)
			: children;

		return activePanel;
	};

	return (
		<>
			<div className={tabs.length && styles.tabContainer}>
				{tabs.map((e) => (
					<div
						key={e.value}
						className={tabClass(e.value)}
						onClick={() => changeActiveTab(e.value)}
					>
						<span>{e.label}</span>
					</div>
				))}
			</div>
			{displayPanel()}
		</>
	);
};

export const TabPanel = ({ children }) => {
	return <div className={styles.tabPanel}>{children}</div>;
};
