import { createSlice } from "@reduxjs/toolkit";

const initialState = { showModal: false };

const dialog = createSlice({
	name: "dialog",
	initialState,
	reducers: {
		toggleDialog: (state) => {
			state.showModal = !state.showModal;
		},
		showDialog: (state) => {
			state.showModal = true;
		},
		hideDialog: (state) => {
			state.showModal = false;
		},
	},
});

export const { toggleDialog, showDialog, hideDialog } = dialog.actions;
export default dialog.reducer;
