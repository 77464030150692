import {
	createMuiTheme,
	TextField,
	ThemeProvider,
	FormControl,
} from "@material-ui/core";
import PropTypes from "prop-types";

const textFieldTheme = createMuiTheme({
	overrides: {
		MuiInputLabel: {
			root: {
				fontFamily: "Poppins",
				fontSize: "12px",
			},
		},
	},
});

const InputField = ({ ...props }) => {
	return (
		<ThemeProvider theme={textFieldTheme}>
			<TextField fullWidth {...props} variant="outlined" />
		</ThemeProvider>
	);
};

export default InputField;
