import React from "react";
import { Grid } from "@material-ui/core";
import dateformat from "dateformat";
import { BranchUtil } from "../../utils/checkSession";
import { formatNumber } from "./PrintReceiptSoa";
import _ from "lodash";

const defaultFont = {
	fontSize: "8.5px",
	fontFamily: "Monaco",
	fontWeight: 700,
};
const spacing = {
	marginLeft: "10px",
};
const RecieptCheck = (props) => {
	const { selectedForPrint, transactionList, tpaId } = props;
	if (!{ ...selectedForPrint }.otherInfo) return <></>;
	const transList = transactionList? transactionList : [selectedForPrint]
	let totalAmount = _.sumBy(transList, data => parseFloat(data.amount));
	return (
		<Grid container className="modal-body">
			<div></div>
			{selectedForPrint && (
				<div style={{ width: "6cm", wordBreak: "break-word", whiteSpace: "pre-wrap"}}>
					<div style={defaultFont}>
						For Deposit To: {selectedForPrint.billerName}{" "}
						<span style={spacing}>
							{(selectedForPrint.reprintCheckCount &&
								selectedForPrint.paymentMethod === "CHECK") ||
							selectedForPrint.reprintCheckCount === 0
								? `(${selectedForPrint.reprintCheckCount + 1})`
								: ""}
						</span>
					</div>
					{transList.length > 1 ? 
						<span style={defaultFont}>
							Order No.:{" "}{selectedForPrint.orderNumber}
						</span> : <span style={defaultFont}>
							Account No.:{" "}{selectedForPrint.accountNo}
						</span>
					}
					<br />
					<span style={defaultFont}>
						<b style={{ fontWeight: "bolder" }}>Collector:</b>{" "}
						{tpaId}{" "}
						<b style={{ fontWeight: "bolder" }}>Reciept No.:</b>{" "}
						{transList.length > 1 ? selectedForPrint.checkReceiptNo :
							selectedForPrint.receiptNo}
					</span>
					<br />
					<span style={defaultFont}>
						{dateformat(
							selectedForPrint.createdAt,
							"mm/dd/yyyy h:MM TT"
						)}
					</span>
					<br />
					<span style={defaultFont}>
						<b style={{ fontWeight: "bolder" }}>Amount:</b>{" "}
						{`P${formatNumber(
							parseFloat(totalAmount)
						)}`}{" "}
						<b style={{ fontWeight: "bolder" }}>No. of Bills:</b> {transList.length}
					</span>

					<br />
					<br />

					<span style={defaultFont}>
						<b>Check No.:</b>{" "}
						{selectedForPrint.otherInfo.CheckDetails.CheckNo}{" "}
					</span>
					<br />
					<span style={defaultFont}>
						<b>Bank Acct No.:</b>{" "}
						{
							selectedForPrint.otherInfo.CheckDetails
								.BankAccountNumber
						}
					</span>
					<br />
					<span style={defaultFont}>
						<b>Biller:</b> {selectedForPrint.billerName}
					</span>
					<br />
					<span style={defaultFont}>
						<b>Contact No.:</b>{" "}
						{selectedForPrint.otherInfo.CheckDetails.ContactNumber}
					</span>
				</div>
			)}
		</Grid>
	);
};

export default RecieptCheck;
