import {
	Box,
	CircularProgress,
	Dialog,
	DialogContent,
	Grid,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { chunk } from "lodash";
import Pagination from "../../common/Pagination/Pagination";
import { usePagination } from "../../../utils/hooks";
import { useEffect } from "react";

const BillerListDialogContent = withStyles({
	root: {
		border: "1px solid #0076C0",
		borderRadius: "10px",
		margin: "1em",
		padding: 0,
		overflowY: "hidden",
		"&:first-child": {
			padding: 0,
		},
	},
})(DialogContent);

const BillerListDialog = withStyles({
	paper: {
		width: "40vw",
	},
})(Dialog);

const useStyles = makeStyles({
	title: {
		fontFamily: "Poppins-Medium",
		backgroundColor: "#0076C0",
		padding: "1em",
		color: "white",
	},
	body: {
		overflowY: "auto",
		height: "38vh",
	},
	bodyList: {
		padding: "2em",
		height: "100%",
	},
});

const BillerListModal = ({
	open,
	data,
	onClose,
	onSelect,
	page,
	rowsPerPage,
	loading,
	totalCount,
	onPageChange,
}) => {
	const handleListItemClick = (biller) => {
		onSelect(biller);
		onClose();
	};

	const classes = useStyles();
	return (
		<BillerListDialog onClose={onClose} open={open} maxWidth="md">
			<BillerListDialogContent>
				<div className={classes.title}>Biller Name</div>
				<div className={classes.body}>
					<List classes={{ root: classes.bodyList }}>
						{loading ? (
							<Grid
								container
								justify="center"
								alignItems="center"
								style={{ height: "100%" }}
							>
								<Grid item>
									<CircularProgress color="inherit" />
								</Grid>
							</Grid>
						) : (
							data.data.map((biller) => (
								<ListItem
									button
									onClick={() => handleListItemClick(biller)}
									key={biller.code}
								>
									<ListItemText primary={biller.name} />
								</ListItem>
							))
						)}
					</List>
				</div>
			</BillerListDialogContent>
			<Box p={2}>
				<Pagination
					page={page}
					onPageChange={onPageChange}
					totalCount={data.count || 0}
					rowsPerPage={rowsPerPage}
					showRowsPerPage={false}
					siblingCount={0}
					boundaryCount={1}
				/>
			</Box>
		</BillerListDialog>
	);
};

BillerListModal.propTypes = {
	open: PropTypes.bool,
	loading: PropTypes.bool,
	onClose: PropTypes.func,
	onSelect: PropTypes.func,
	onPageChange: PropTypes.func,
	totalCount: PropTypes.number,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	data: PropTypes.arrayOf(PropTypes.object),
};

BillerListModal.defaultProps = {
	open: false,
	onClose: () => {},
	onSelect: () => {},
	data: {
		data: [],
		count: 0,
	},
	page: 1,
	rowsPerPage: 25,
	loading: false,
};

export default BillerListModal;
