import HTTP from "../helpers/ApiClient";
import qs from "qs";
import { get as _get } from "lodash";
import { getSessionStorage, setSessionStorage } from "./storage";
import { RefreshTokenUtil, TokenUtil, UserInfoUtil } from "./checkSession";

export async function callback(code) {
	try {
		const req = qs.stringify({
			client_id: process.env.REACT_APP_APP_KEY,
			redirect_uri: "",
			code_verifier: getSessionStorage("codeVerifier"),
			grant_type: "authorization_code",
			code,
		});

		// get token
		const tokenResponse = await HTTP.post(
			process.env.REACT_APP_ACCESS_TOKEN_URL,
			req,
			{
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			}
		);

		const { access_token: token, refresh_token: refreshToken } = _get(
			tokenResponse,
			["data"],
			{}
		);

		const headers = {
			Authorization: `Bearer ${token}`,
		};

		// get user
		const authResponse = await HTTP.get(
			process.env.REACT_APP_ACCOUNTS_API + "/v1/oauth/user",
			{
				headers,
			}
		);

		const {
			uuid,
			username,
			emailAddress,
			lastname,
			firstname,
			middlename,
			mobileNumber,
			emailVerifiedAt,
			mobileNumberVerifiedAt,
			roles = [],
		} = _get(authResponse, ["data", "data"], {});

		const uamRoleData = roles.find(
			(role) => role.platform.id === process.env.REACT_APP_APP_KEY
		);

		const uamRole = uamRoleData || null;
		TokenUtil.set(token);
		RefreshTokenUtil.set(refreshToken);
		const userInfo = {
			uuid,
			username,
			emailAddress,
			lastname,
			firstname,
			middlename,
			mobileNumber,
			emailVerifiedAt,
			mobileNumberVerifiedAt,
			roles,
			uamRole,
		};

		UserInfoUtil.set(userInfo);

		setSessionStorage("previousSuccessfulPayments", []);
		window.location.replace(process.env.REACT_APP_URL);
	} catch (error) {
		console.error(error);
		return "CALLBACK ERROR"
	}
}
