import { useFormikContext } from "formik";
import { useEffect } from "react";
import PropTypes from "prop-types";

const FilterUpdateWatcher = ({ onChange, onSubmit, ignoreChange }) => {
	const {
		values,
		isValid,
		isSubmitting,
		setSubmitting,
		dirty,
		isValidating,
	} = useFormikContext();

	useEffect(() => {
		onChange(values, isValid);
		setSubmitting(true);
	}, [isValid, values]);

	useEffect(() => {
		if (!isValidating) {
			setSubmitting(false);
		}
		if (!isSubmitting && !isValidating && dirty && isValid) {
			onSubmit(values);
		}
	}, [isValidating, dirty, isSubmitting, isValid]);

	return null;
};

FilterUpdateWatcher.propTypes = {
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default FilterUpdateWatcher;
