import {
	createMuiTheme,
	FormControl,
	ThemeProvider,
	makeStyles,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useField } from "formik";
import { BsFillCalendarFill } from "react-icons/bs";
import { useEffect } from "react";

const DATE_FORMAT = "DD/MM/yyyy";

const datePickerTheme = createMuiTheme({
	overrides: {
		MuiInputLabel: {
			root: {
				fontFamily: "Poppins-Regular",
				fontSize: "12px",
			},
		},
		MuiIconButton: {
			root: {
				fontSize: "1.2rem",
				color: "#8B9095",
			},
		},
		MuiInputBase: {
			input: {
				cursor: "default",
				fontFamily: "Poppins-Regular",
				fontSize: "12px",
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: "#0076C0",
			},
		},
		MuiPickersDay: {
			daySelected: {
				backgroundColor: "#F26122",
				"&:hover": {
					backgroundColor: "#FF5722",
				},
			},
		},
	},
});

const FormattedDatePicker = ({ label, minDate, maxDate, ...props }) => {
	return (
		<ThemeProvider theme={datePickerTheme}>
			<FormControl variant="outlined" fullWidth>
				<KeyboardDatePicker
					maxDate={maxDate}
					minDate={minDate}
					inputVariant="outlined"
					format={DATE_FORMAT}
					variant="inline"
					autoOk="true"
					keyboardIcon={<BsFillCalendarFill />}
					inputProps={{
						readOnly: true,
					}}
					label={label}
					placeholder={label}
					{...props}
				/>
			</FormControl>
		</ThemeProvider>
	);
};

const useStyles = makeStyles({
	label: {
		fontFamily: "Poppins-Medium",
	},
});

export const DatePickerForm = ({
	handleChange = null,
	fieldValue = null,
	...props
}) => {
	const [field, meta, helpers] = useField(props);
	const currentError = meta.error;

	useEffect(() => {
		helpers.setValue(fieldValue);
	}, [fieldValue]);

	return (
		<FormattedDatePicker
			name={field.name}
			helperText={currentError}
			value={field.value}
			error={Boolean(currentError)}
			onChange={(value) => {
				helpers.setValue(value);
				handleChange ? handleChange(value) : "";
			}}
			{...props}
		/>
	);
};

export default FormattedDatePicker;
