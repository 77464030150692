import {configureStore} from "@reduxjs/toolkit";
import clientMiddleware from "./middleware/clientMiddleware";
import client from "../helpers/ApiClient";
import reducers from "./modules/reducers";

export default function createStore(initialState = null) {
  let config = {
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(clientMiddleware(client)),
  };

  if (initialState) {
    config.preloadedState = initialState;
  }

  return configureStore(config);
}
