import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	Typography,
} from "@material-ui/core";
import { ReactComponent as FailedLogo } from "../../assets/icons/ic-failed.svg";
import styles from "./CashFlow.module.css";
import { FC } from "react";

type TCashFlowTimeoutProps = {
	open: boolean;
	onClose?: () => void;
	retryFn?: () => void;
};

const CashFlowTimeout: FC<TCashFlowTimeoutProps> = ({
	open = false,
	onClose,
	retryFn,
}) => {
	return (
		<Dialog
			PaperProps={{ style: { width: "400px", zIndex: 9999 } }}
			onClose={onClose}
			open={open}
		>
			<DialogContent>
				<DialogContentText
					component={"div"}
					className={styles.retryDialog}
				>
					<FailedLogo />
					<Typography className={styles.retryTitle}>
						Timeout Error!
					</Typography>
					<Typography className={styles.retryText}>
						{" "}
						A problem occurred while loading the data <br /> Please
						try again.
					</Typography>
				</DialogContentText>
			</DialogContent>
			<Button
				onClick={retryFn}
				variant="contained"
				disableElevation
				className={styles.retryButton}
			>
				Retry
			</Button>
		</Dialog>
	);
};

export default CashFlowTimeout;
