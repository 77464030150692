import React, { useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import {
	createMuiTheme,
	ThemeProvider,
	FormControl,
	Grid,
	MenuItem,
	OutlinedInput,
	TextField,
	Paper,
	withStyles,
	makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik, useField } from "formik";
import FilterContainer from "../../common/Filter/FilterContainer";
import ClearFilterButton from "./ClearFilterButton";
import {
	DatePickerFormik,
	makeDateRangeValidator,
} from "../../Inputs/DatePicker";
import FilterUpdateWatcher from "../../common/Filter/FilterUpdateWatcher";
import styles from "./UserFilter.module.css";

const FilterField = ({ label, value, ...inputProps }) => {
	return (
		<FormControl variant="outlined">
			<OutlinedInput label={label} {...inputProps} />
		</FormControl>
	);
};
FilterField.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
};

const StyledMenuItem = withStyles({
	root: {
		fontFamily: "Poppins-Regular",
		fontSize: "14px",
	},
	selected: {
		color: "#F26122",
	},
})(MenuItem);

const StyledMenuEmpty = withStyles({
	root: {
		fontFamily: "Poppins-Regular",
		fontSize: "14px",
		pointerEvents: "none",
	},
})(MenuItem);
const FilterSelect = ({ label, value, options, onChange, ...props }) => {
	const [field, _, helpers] = useField(props);
	return (
		<FormControl variant="outlined" fullWidth>
			<TextField
				name={field.name}
				value={field.value || field.name}
				onChange={(event) =>
					helpers.setValue(event.target.value || event.target.name)
				}
				label={label}
				variant="outlined"
				select
				{...props}
			>
				{!isEmpty(options) ? (
					options.map(({ name, value }, i) => (
						<StyledMenuItem key={i} value={value || name}>
							{name}
						</StyledMenuItem>
					))
				) : (
					<StyledMenuEmpty
						key={label + "loading"}
						value={"Loading ..."}
					>
						Loading...
					</StyledMenuEmpty>
				)}
			</TextField>
		</FormControl>
	);
};

const validate = (values) => {
	const { dateFrom, dateTo } = values;
	return makeDateRangeValidator({ dateFrom, dateTo })();
};

const textFieldTheme = createMuiTheme({
	overrides: {
		MuiInputLabel: {
			root: {
				fontFamily: "Poppins-Regular",
				fontSize: "14px",
			},
		},
	},
});

const UserFilter = ({
	statusOptions,
	uamStatusOptions,
	title,
	values,
	initialValues,
	onChange,
	onSubmit,
	data,
	tellerNames,
	userNames,
	branches,
	resetFormOnTab = {},
	isFilterValueEmpty,
	isUamStatusFiltered,
}) => {
	const formEl = useRef();
	const handleClear = (resetForm) => (e) => {
		resetForm({});
		onChange(initialValues);
		onSubmit(initialValues);
	};

	useEffect(() => {
		if (resetFormOnTab.currentTab == resetFormOnTab.tab) {
			formEl.current?.resetForm();
		}
	}, [resetFormOnTab.currentTab]);

	return (
		<ThemeProvider theme={textFieldTheme}>
			<Formik
				innerRef={formEl}
				initialValues={initialValues}
				validate={validate}
			>
				{({ resetForm }) => (
					<FilterContainer>
						<Grid container justify="flex-end" direction="column">
							<Grid item xs container justify="flex-end">
								<ClearFilterButton
									onClear={handleClear(resetForm)}
								/>
							</Grid>
							<Grid
								item
								container
								spacing={3}
								direction="row"
								alignItems="center"
								xs
							>
								<Grid item>
									<span className={styles.filterLabel}>
										Filters:
									</span>
								</Grid>
								<Grid item container xs>
									<Grid
										item
										container
										spacing={2}
										justify="space-evenly"
									>
										{title !== "Branch Access Control" && (
											<>
												<Grid item xs>
													<DatePickerFormik
														label="Date From"
														name="dateFrom"
														size="small"
														disabled={
															!isFilterValueEmpty &&
															isUamStatusFiltered
														}
													/>
												</Grid>
												<Grid item xs>
													<DatePickerFormik
														label="Date To"
														name="dateTo"
														size="small"
														disabled={
															!isFilterValueEmpty &&
															isUamStatusFiltered
														}
													/>
												</Grid>
												<Grid item xs>
													<FilterSelect
														label="Teller Name"
														name="tellerName"
														size="small"
														options={tellerNames}
														disabled={
															!isFilterValueEmpty &&
															isUamStatusFiltered
														}
													/>
												</Grid>
												<Grid item xs>
													<FilterSelect
														label="Username"
														name="username"
														size="small"
														options={userNames}
														disabled={
															!isFilterValueEmpty &&
															isUamStatusFiltered
														}
													/>
												</Grid>
											</>
										)}
										{![
											"Pending Request for Approval",
											"History of Teller Requests",
										].includes(title) && (
											<Grid item xs>
												<FilterSelect
													label="Branch"
													name="branch"
													size="small"
													options={branches}
													disabled={
														!isFilterValueEmpty &&
														isUamStatusFiltered
													}
												/>
											</Grid>
										)}
										{title !==
											"Pending Request for Approval" && (
											<Grid item xs>
												<FilterSelect
													label="User Activity Status"
													name="status"
													size="small"
													options={statusOptions}
													disabled={
														!isFilterValueEmpty &&
														isUamStatusFiltered
													}
												/>
											</Grid>
										)}
										{title === "User List" && (
											<Grid item xs>
												<FilterSelect
													label="UAM Status"
													name="uamStatus"
													size="small"
													options={uamStatusOptions}
													disabled={
														!isFilterValueEmpty &&
														!isUamStatusFiltered
													}
												/>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
							{title === "User List" && (
								<Grid item xs container justify="flex-end">
									<span className={styles.note}>
										*Note: Multiple data filter is disabled
										when the "UAM Status" data is filtered.
									</span>
								</Grid>
							)}
						</Grid>
						<FilterUpdateWatcher
							onChange={onChange}
							onSubmit={onSubmit}
						/>
					</FilterContainer>
				)}
			</Formik>
		</ThemeProvider>
	);
};

UserFilter.propTypes = {
	values: PropTypes.object,
	initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
};

UserFilter.defaultProps = {
	values: {
		dateFrom: null,
		dateTo: null,
	},
	initialValues: {
		dateFrom: null,
		dateTo: null,
	},
	onSubmit: () => {},
	onChange: () => {},
};

export default UserFilter;
