import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Grid, Paper, withStyles, Avatar } from "@material-ui/core";
import { styled } from "@mui/material";
import textStyles from "./textStyles.module.css";
import changefundIcon from "../../assets/icons/ic-changefund.png";
import Dropdown from "../Remittance/Input/Dropdown";
import SearchBar from "../Remittance/Input/SearchBar";
import CashOut from "../Remittance/CashOut/CashOutInformation";
import Verification from "./Verification/Verification";
import Footer from "../Remittance/Footer/Footer.js";
import FormattedTextField from "../Remittance/Input/FormattedTextField.js";
import Sidebar from "./Sidebar/Sidebar.js";
import Register from "./Register/Register.js";
import Update from "../BayadId/Update/Update.js";
import PartnersComponent from "../Remittance/PartnersSection/PartnersSection";

import { useSelector, useDispatch } from "react-redux";
import {
	setTransactionType,
	getNationalitiesValues,
	getIdTypesValues,
	getProvincesValues,
	getCitiesValues,
	getZipValues,
	getSofValues,
	getNofValues,
	setSelectedPermanentProvince,
	setSelectedPresentProvince,
	setSelectedPermanentCity,
	setSelectedPresentCity,
} from "../../redux/modules/kycBayad";
import KycRegistration from "../../redux/modules/kycRegistration";

const ListContainer = withStyles((theme) => ({
	root: {
		marginTop: "20px",
		padding: "0px",
	},
}))(Paper);

const StyledPapper = styled(Paper)(({ theme }) => ({
	padding: "0px",
	margin: "0px",
	display: "flex",
	height: "100%",
	flexDirection: "column",
	justifyContent: "space-between",
}));

const StyledInnerPaper = styled(Paper)(({ theme }) => ({
	padding: "0px",
	margin: "0px",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
	margin: 0,
	padding: 0,
	height: "100%",
}));

const StyledInnerGrid = styled(Grid)(({ theme }) => ({
	margin: 0,
	// padding: "20px",
	height: "100%",
	position: "relative",
}));

const StyledCenterContainer = styled(Container)(({ theme }) => ({
	margin: "250px auto",
	display: "block",
	"& p": {
		textAlign: "center",
	},
	"& img": {
		display: "block",
		margin: "0 auto",
		width: "109px",
		height: "auto",
	},
}));

const stylingSearchBar = {
	opacity: "0.5",
	borderRadius: "16px",
	height: "32px",
	width: "100%",
	padding: "9px 10px",
	backgroundColor: "#DCDCDC",
};

const BayadId = (props) => {
	let dispatch = useDispatch();

	useEffect(() => {
		dispatch(getNationalitiesValues());
		dispatch(getIdTypesValues());
		dispatch(getProvincesValues());
		dispatch(getSofValues());
		dispatch(getNofValues());
	}, []);

	useEffect(() => {
		return () => {
			dispatch(setTransactionType(""));
		};
	}, []);

	useEffect(() => {
		if (props.selectedPermanentCity && props.selectedPermanentProvince) {
			dispatch(getZipValues("permanentAddress"));
		}
	}, [props.selectedPermanentCity, props.selectedPermanentProvince]);

	useEffect(() => {
		if (props.selectedPresentCity && props.selectedPresentProvince) {
			dispatch(getZipValues("presentAddress"));
		}
	}, [props.selectedPresentCity, props.selectedPresentProvince]);

	useEffect(() => {
		if (props.selectedPermanentProvince) {
			dispatch(getCitiesValues("permanentAddress"));
		}
	}, [props.selectedPermanentProvince]);

	useEffect(() => {
		if (props.selectedPresentProvince) {
			dispatch(getCitiesValues("presentAddress"));
		}
	}, [props.selectedPresentProvince]);

	useEffect(() => {
		dispatch(setSelectedPermanentProvince(""));
		dispatch(setSelectedPresentProvince(""));
		dispatch(setSelectedPermanentCity(""));
		dispatch(setSelectedPresentCity(""));
	}, [props.transactionType]);

	return (
		<>
			<ListContainer container spacing={2}>
				<Grid item xs={12}>
					<Grid container>
						<Grid item sm={2}>
							<StyledContainer maxWidth="xl">
								<StyledPapper elevation={2}>
									<Sidebar />
								</StyledPapper>
							</StyledContainer>
						</Grid>
						<StyledInnerGrid item sm={10}>
							{props.transactionType == "" ||
							props.transactionType == "Verification" ? (
								<Verification />
							) : (
								<></>
							)}

							{props.transactionType == "Register" ? (
								<Register />
							) : (
								<></>
							)}

							{props.transactionType == "Update" ? (
								<Update />
							) : (
								<></>
							)}
						</StyledInnerGrid>
					</Grid>
				</Grid>
			</ListContainer>
		</>
	);
};

export default connect(
	(state) => ({
		transactionType: state.kycBayad.transactionType,
		selectedPermanentProvince: state.kycBayad.selectedPermanentProvince,
		selectedPresentProvince: state.kycBayad.selectedPresentProvince,
		selectedPermanentCity: state.kycBayad.selectedPermanentCity,
		selectedPresentCity: state.kycBayad.selectedPresentCity,
	}),
	{
		setTransactionType,
		getNationalitiesValues,
		getIdTypesValues,
		getProvincesValues,
		getCitiesValues,
		getZipValues,
		getSofValues,
		getNofValues,
		setSelectedPermanentProvince,
		setSelectedPresentProvince,
		setSelectedPermanentCity,
		setSelectedPresentCity,
	}
)(BayadId);
