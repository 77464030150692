import { withStyles, Modal, Paper, Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { FC, useState, useMemo, useCallback, useEffect } from "react";
import cx from "classnames";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../../common/Pagination/Pagination";
import styles from "../CashFlow.module.css";
import HTTP from "../../../helpers/ApiClient";

type TBillerOption = {
	label: string;
	value: string;
	type: "link" | "option";
};

const StyledAutoComplete = withStyles({
	popupIndicatorOpen: {
		transform: `rotate(0deg) !important`,
	},
})(Autocomplete);

async function loadBillers() {
	const res = await HTTP.get(`/v2/billers`);
	return res.data;
}

type BillerModalProps = {
	open: boolean;
	onClose: () => void;
	options: TBillerOption[];
	onSelect: Function;
};

const BILLER_MODAL_PAGE_SIZE = 25;
const BillerModal: FC<BillerModalProps> = ({
	open = false,
	onClose,
	options = [],
	onSelect,
}) => {
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (!open) setPage(1);
	}, [open]);

	const optionsShown: any[] = useMemo(() => {
		return options.slice(
			BILLER_MODAL_PAGE_SIZE * (page - 1),
			BILLER_MODAL_PAGE_SIZE * page
		);
	}, [options, page]);

	const paddingOptions = useMemo(() => {
		const diff = BILLER_MODAL_PAGE_SIZE - optionsShown.length;
		if (diff < 0) return [];

		return Array(diff)
			.fill(null)
			.map((_, index) => <div className={styles.padding} key={index} />);
	}, [optionsShown]);

	const handleSelect = useCallback(
		(value: any) => {
			onSelect(value);
			onClose();
		},
		[onSelect, onClose]
	);

	const handleClose = useCallback(
		(event) => {
			if (event.target.className.includes("modalContainer")) {
				onClose();
			}
		},
		[onClose]
	);

	return (
		<Modal open={open} onClose={onClose}>
			<div className={cx(styles.modalContainer)} onClick={handleClose}>
				<Paper style={{ minWidth: "45vw" }}>
					<div className={cx(styles.billerModalContainer)}>
						<div className={cx(styles.billerModalTitle)}>
							Biller Name
						</div>
						<div className={cx(styles.billerModalOptions)}>
							{optionsShown.map((option) => (
								<div
									onClick={() => handleSelect(option)}
									key={option.value}
								>
									{option.label}
								</div>
							))}
							{paddingOptions}
						</div>
					</div>
					<Box p={2}>
						<Pagination
							page={page}
							onPageChange={(page: number) => setPage(page)}
							totalCount={options.length}
							rowsPerPage={25}
							showRowsPerPage={false}
							siblingCount={0}
							boundaryCount={1}
							rowsPerPageOptions={[25]}
							isHidePageNumber={false}
						/>
					</Box>
				</Paper>
			</div>
		</Modal>
	);
};

type TBillerField = {
	value?: string | null;
	onSelect: Function;
};

const BillerField: FC<TBillerField> = ({ value, onSelect }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [billers, setBillers] = useState<TBillerOption[]>([]);
	const [inputValue, setInputValue] = useState("");

	const handleLoadBillers = useCallback(async () => {
		const { data } = await loadBillers();
		const options = data.map((biller: any) => ({
			label: biller.name,
			value: biller.code,
			type: "option",
		}));
		setBillers(options);
	}, []);

	useEffect(() => {
		handleLoadBillers();
	}, [handleLoadBillers]);

	const filterOptions = useCallback((options, { inputValue }) => {
		let opts = [...options];
		if (inputValue) {
			opts = options.filter(
				(opt) =>
					opt.label
						.toLowerCase()
						.includes(inputValue.toLowerCase()) &&
					opt.type === "option"
			);
		}

		if (opts.length > 10) {
			opts = opts.slice(0, 10);
			opts.push({
				label: "See all results",
				value: "see-all",
				type: "link",
			});
		}
		return opts;
	}, []);

	const selected = useMemo(
		() => billers.find((biller) => biller.value === value),
		[value, billers]
	);

	useEffect(() => {
		if (value == null) {
			setInputValue("");
		}
	}, [value]);

	const openModal = useCallback(() => {
		setModalOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		if (inputValue === "See all results") {
			setInputValue("");
		}
		setModalOpen(false);
	}, [inputValue]);

	return (
		<>
			<StyledAutoComplete
				key={JSON.stringify(selected)}
				options={billers}
				filterOptions={filterOptions}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Biller"
						variant="outlined"
						onChange={(e: any) => setInputValue(e.target.value)}
					/>
				)}
				sx={{
					"& .MuiInputBase-root": {
						paddingBottom: "13px",
					},
				}}
				onOpen={handleLoadBillers}
				onChange={(event, value: any, reason) => {
					switch (reason) {
						case "selectOption":
							if (value) {
								if (value.value === "see-all") {
									openModal();
									return;
								}
								setInputValue(value.label);
								onSelect(value.value);
							}
							break;
						case "clear":
							setInputValue("");
							onSelect(null);
							break;
					}
				}}
				ListboxProps={{
					style: {
						minHeight: 405,
					},
				}}
				noOptionsText="No match"
				value={selected}
				getOptionLabel={(option: any) => option.label}
				renderOption={(props, option: any) => {
					if (option.type === "link") {
						return (
							<li
								{...props}
								key={option.id}
								className={cx(styles.seeMoreLabel)}
							>
								{option.label}
							</li>
						);
					}

					return (
						<li {...props} key={option.id}>
							{option.label}
						</li>
					);
				}}
				popupIcon={<SearchIcon />}
				inputValue={inputValue}
			/>
			<BillerModal
				options={billers.filter(
					(option: any) => option.type !== "link"
				)}
				onClose={closeModal}
				open={modalOpen}
				onSelect={(value: TBillerOption) => {
					onSelect(value.value);
					setInputValue(value.label);
				}}
			/>
		</>
	);
};

export default BillerField;
