import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateBillerTxn } from "../../../redux/modules/billerList";
import { resetValidated } from "../../../redux/modules/validation";
import tStyles from "../TransactionList.module.css";

function GenericField(props: any) {
	const {
		field,
		classes,
		data,
		transactionKey,
		selectedBillers,
		updateBillerTxn,
		error,
		onChange,
		value,
		...other
	} = props;
	const [amountInput, setAmountInput] = useState(null);
	const _field = getParameters(data, field);
	const menuItems = getMenuItems(data, field, classes);

	const onVerifyInputChange = (
		code: any,
		txnKey: any,
		event: any,
		dateField: any = null,
		dateRule: any = null
	) => {
		if (event !== null) {
			const amount_names = ["amount"];
			if (
				event.target !== undefined &&
				amount_names.includes(event.target.name)
			) {
				const number = /^[0-9]*[.]{0,1}[0-9]*$/;

				if (
					number.test(event.target.value) ||
					event.target.value === ""
				) {
					setAmountInput(event.target.value);
				} else {
					event.target.value = amountInput;
				}
			}
		}

		const id = dateField
			? dateField
			: event.target.id !== undefined
			? event.target.id
			: event.target.name;

		let momentDate = null;

		const dateFormat = dateRule
			? dateRule[0].includes(":")
				? dateRule[0].split(":")[1]
				: dateRule[1]["format"]
			: "MM/dd/yyyy";
		if (event) {
			momentDate =
				event._f === "YYYY-YYYY" || event._f === "YYYY - YYYY"
					? event._i
					: moment(event).format(dateFormat);
		}

		const value = dateRule ? momentDate : event.target.value;

		if (onChange) {
			onChange(event);
		}

		updateBillerTxn({
			code: code,
			txnKey: txnKey,
			key: id,
			value: value,
		});
	};

	return (
		<Grid item md={3}>
			<TextField
				size="small"
				fullWidth
				variant="outlined"
				select={menuItems != undefined}
				name={field}
				id={field}
				value={value}
				InputProps={{
					classes: {
						input: tStyles.textInput,
					},
				}}
				label={
					<Typography
						className={`${
							_field.label.length < 21
								? classes.inputLabel
								: classes.inputLabelSmall
						}`}
					>
						{_field.label}
					</Typography>
				}
				onChange={(event) =>
					onVerifyInputChange(data.code, transactionKey, event)
				}
				helperText={error}
				error={error != undefined}
			>
				{menuItems}
			</TextField>
		</Grid>
	);
}

/**
 * Function that gets the properties of a field in the verify array from API 3.0
 * @author   Val
 * @param    {any} data      data returned from inquire biller API
 * @param    {String} key  fieldName in biiller.config.json
 */
function getParameters(data: any, key: string) {
	data.parameters.verify.forEach((v: any) => {});
	return data.parameters.verify.find((v: any) => {
		return Object?.prototype.hasOwnProperty.call(v, key);
	})[key];
}

/**
 * Function that gets the menu items from an in: field in the verify array from API 3.0
 * @author   Val
 * @param    {any} data      data returned from inquire biller API
 * @param    {String} key  fieldName in biiller.config.json
 */
function getMenuItems(data: any, key: string, classes: any) {
	const _verify = data.parameters.verify;
	const index = findIndexJson(_verify, key);
	const _rules = _verify[index][key].rules;

	const _items = Object.keys(_rules).map((key) => {
		if (key.startsWith("in:")) {
			return _rules[key].options;
		}
	});
	if (_items[1]) {
		return Object.keys(_items[1]).map((k) => {
			return (
				<MenuItem key={k} value={k}>
					<Typography className={classes.inputLabel}>
						{_items[1][k]}
					</Typography>
				</MenuItem>
			);
		});
	}
}

function findIndexJson(arr: any[], key: string) {
	let index = 0;
	arr.forEach((v, i) => {
		if (Object?.prototype.hasOwnProperty.call(v, key)) index = i;
	});
	return index;
}

export default connect(
	(state: any) => ({
		selectedBillers: state.billerList.selectedBillers,
	}),
	{
		updateBillerTxn,
	}
)(GenericField);
