import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import BillListPanel from "../BillerList/BillerListPanel.js";
import TransactionList from "../TransactionList/TransactionList";
import BillSummary from "../BillSummary/BillSummary";
import TransactionSummary from "../TransactionSummary/TransactionSummary";
import ViewReceiptModal from "../ViewReceipt/ViewReceiptModal";
import UnablePrintReceiptModal from "../UnablePrintReceipt/UnablePrintReceiptModal";
import PrintReceiptFlow from "../PrintReceiptFlow/PrintReceiptFlow";
import { updateWallet } from "../../redux/modules/walletBalance.ts";
import HTTP from "../../helpers/ApiClient";
import SubscribeWalletBalance from "./SubscribeWalletBalance";
import { Box } from "@mui/material";

const PayBills = ({ showTransactionList, updateWallet }) => {
	const [walletId, setWalletId] = useState("");

	useEffect(() => {
		(async () => {
			const { data } = await HTTP.get(`v2/wallet/balance`);
			updateWallet(data.data.data);
			setWalletId(data.data.data.wallet_identifier);
		})();
	}, []);

	return (
		<>
			{walletId && (
				<SubscribeWalletBalance
					walletId={walletId}
					updateWallet={updateWallet}
				/>
			)}
			<PrintReceiptFlow />
			<ViewReceiptModal />
			<UnablePrintReceiptModal />
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "1fr min(120rem, 100%) 1fr",
				}}
			>
				<Box sx={{ gridColumn: "2" }}>
					<Box
						display="grid"
						gridTemplateColumns="minmax(320px,400px) minmax(300px,1fr) minmax(320px,400px)"
					>
						<Paper
							style={{
								gridColumn: "1 / 4",
								gridRow: "1 / 3 ",
							}}
						/>
						{showTransactionList && (
							<Box
								style={{
									gridColumn: "1 / 4",
									gridRow: "1 / 3 ",
								}}
							>
								<TransactionSummary />
							</Box>
						)}
						{!showTransactionList && (
							<Box
								style={{
									gridColumn: "1 / 2",
									gridRow: "1 / 3 ",
									margin: "20px 0px 20px 20px",
								}}
							>
								{!showTransactionList && <BillListPanel />}
							</Box>
						)}

						{!showTransactionList && (
							<Box
								span={2}
								style={{
									gridColumn: "2 / 4",
									gridRow: "1 / 3 ",
									margin: "30px 20px 20px 20px",
								}}
							>
								<TransactionList />
							</Box>
						)}

						<Box
							style={{
								gridColumn: "4 / 5",
								gridRow: "1 / 3 ",
								margin: "20px 20px 20px 0px",
							}}
						>
							<BillSummary />
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default connect(
	(state) => ({
		showTransactionList: state.transaction.showTransactionList,
	}),
	{ updateWallet }
)(PayBills);
