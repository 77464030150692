import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import HTTP from "../../helpers/ApiClient";
import { Moment } from "moment";

async function loadCashflow(tpaId: string, params: string) {
	try {
		const res = await HTTP.get(
			`/v3/wallet/cash-flow/${tpaId}/list?${params}`
		);
		return res.data;
	} catch (e) {
		return { data: [], meta: null, error: true };
	}
}

export const useFilteredData = (searchDelay = 500) => {
	const tpaId: string = useSelector(
		(state: any) => state.userInfo.terminal.name
	);
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(25);
	const [meta, setMeta] = useState<any>(null);
	const [dateRange, setDateRange] = useState<{
		[key: string]: Moment | null;
	}>({
		dateFrom: null,
		dateTo: null,
	});
	const [biller, setBiller] = useState<string | null>(null);
	const [transactionType, setTransactionType] = useState<string>("");
	const [sorter, setSorter] = useState<[string | null, "asc" | "desc"]>([
		"date_created",
		"desc",
	]);
	const [search, setSearch] = useState("");
	const [searchKey, setSearchKey] = useState("");
	const [loading, setLoading] = useState(false);
	const [loadError, setLoadError] = useState(false);

	const withPageReset = useMemo(() => {
		return (target: Function) => {
			return (arg: any) => {
				target(arg);
				setPage(1);
			};
		};
	}, []);

	const filters = useMemo(() => {
		return {
			dateFrom: dateRange.dateFrom,
			dateTo: dateRange.dateTo,
			biller,
			transactionType,
		};
	}, [dateRange, biller, transactionType]);

	const clearFilters = useCallback(() => {
		setDateRange({ dateFrom: null, dateTo: null });
		setBiller(null);
		setTransactionType("");
		setSorter(["date_created", "desc"]);
		setPage(1);
	}, []);

	useEffect(() => {
		const t = setTimeout(() => {
			if (search.length === 0 || search.length >= 3) {
				setSearchKey(search);
			}
		}, searchDelay);

		return () => clearTimeout(t);
	}, [search, searchDelay]);

	const queryString = useMemo<string | null>(() => {
		const [sortField, sortOrder] = sorter;
		const { dateTo, dateFrom, biller, transactionType } = filters;
		const params: { [key: string]: any } = {
			sortBy: sortField,
			sort: sortOrder,
			page,
			limit,
		};
		if (transactionType) {
			params.transactionType = transactionType;
		}
		if ((searchKey?.length ?? 0) > 0) {
			params.trn = searchKey;
		}
		if (biller) {
			params.billerCode = biller;
		}
		if (dateFrom && !dateTo && page === 1) {
			return null;
		}
		if (dateFrom && dateTo) {
			if (!dateTo.isSameOrAfter(dateFrom)) {
				return null;
			}
			params.dateCreatedFrom = dateFrom.format("YYYY-MM-DD 00:00:00");
			params.dateCreatedTo = dateTo.format("YYYY-MM-DD 23:59:59");
		}
		const queryString = Object.keys(params)
			.map((key: string) => `${key}=${params[key]}`)
			.join("&");

		return queryString;
	}, [filters, limit, page, searchKey, sorter]);

	const request = useCallback(async () => {
		setLoadError(false);
		if (queryString) {
			setLoading(true);
			const {
				data: list,
				meta,
				error,
			} = await loadCashflow(tpaId, queryString);
			setLoading(false);
			setLoadError(!!error);
			setData(list);
			setMeta(meta);
		}
	}, [tpaId, queryString]);

	useEffect(() => {
		request();
	}, [request]);

	return {
		filters,
		data,
		clearFilters,
		page,
		limit,
		sorter,
		search,
		loading,
		meta,
		request,
		loadError,
		handlers: {
			biller: withPageReset(setBiller),
			transactionType: withPageReset(setTransactionType),
			dateRange: withPageReset(setDateRange),
			page: setPage,
			limit: setLimit,
			sorter: setSorter,
			search: withPageReset(setSearch),
			setLoadError,
		},
	};
};
