import { Box, Grid, makeStyles, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import FilterContainer from "../../common/Filter/FilterContainer";
import {
	DatePickerFormik,
	makeDateRangeValidator,
} from "../../Inputs/DatePicker";
import SearchField from "../../Inputs/SearchField";
import ClearFilterButton from "../../Transaction/TransactionFilter/ClearFilterButton";
import moment from "moment";
import CommonTable from "../../common/CommonTable/CommonTable";
import { get } from "lodash";
import NumberFormatter from "../../common/Format/NumberFormatter";
import { debounce } from "lodash";
import FilterUpdateWatcher from "../../common/Filter/FilterUpdateWatcher";

const useStyles = makeStyles({
	title: {
		fontFamily: "Poppins-Medium",
	},
});

const RedText = withStyles({
	root: {
		color: "red",
	},
})(Box);

const renderNumber = (value) => <NumberFormatter value={value} />;

const validate = (values) => {
	const { dateFrom, dateTo } = values;
	return makeDateRangeValidator({ dateFrom, dateTo })();
};

const headers = [
	{
		label: () => (
			<>
				<div>Date and Time</div>
				<div>generated</div>
			</>
		),
		identifier: "dateTimeGenerated",
		render: (value) => moment(value).format("MM/DD/YYYY hh:mm:ss A"),
		sortable: true,
	},
	{ label: "Username", identifier: "username", sortable: true },
	{
		label: () => (
			<>
				<div>Partial</div>
				<div>Deposit</div>
			</>
		),
		identifier: "partialDeposit",
		render: renderNumber,
		sortable: true,
	},
	{
		label: () => (
			<>
				<div>Total Cash</div>
				<div>on Hand</div>
			</>
		),
		identifier: "totalCash",
		render: renderNumber,
		sortable: true,
	},
	{
		label: () => (
			<>
				<div>Total Check</div>
				<div>on Hand</div>
			</>
		),
		identifier: "totalCheck",
		render: renderNumber,
		sortable: true,
	},
	{
		label: "Cash End",
		identifier: "cashEnd",
		render: renderNumber,
		sortable: true,
	},
	{
		label: () => (
			<>
				<div>Total Cash on</div>
				<div>Hand vs Cash End</div>
			</>
		),
		identifier: "cashVsCashEnd",
		sortable: true,
		render: (value) => {
			let sign = "";
			if (value !== 0) {
				sign = value > 0 ? "+" : "";
				return (
					<RedText>
						{sign}
						{renderNumber(value)}
					</RedText>
				);
			}

			return renderNumber(value);
		},
	},
	{ label: "Filename", identifier: "fileName", sortable: true },
];

const EndSessionLogs = ({
	data,
	filters,
	onFilterChange,
	onFilterSubmit,
	onPageChange,
	onSort,
	initialFilters,
}) => {
	const classes = useStyles();
	const { dateFrom, dateTo } = filters;

	const handleClear = (resetForm) => (e) => {
		resetForm();
		onFilterChange(initialFilters);
		onFilterSubmit(initialFilters);
	};
	const handleSearchChange = debounce((event) => {
		const {
			target: { value: search },
		} = event;
		onFilterChange({ ...filters, search });
		if (search.length >= 3 || (search === "" && filters.search !== "")) {
			onFilterSubmit({ search });
		}
	}, 500);

	return (
		<Grid container spacing={2} direction="column">
			<Grid
				classes={{ root: classes.title }}
				container
				item
				alignItems="center"
				justify="space-between"
			>
				<Grid item>End Session Logs</Grid>
				<Grid item xs={3}>
					<SearchField
						label="Username"
						onChange={handleSearchChange}
					/>
				</Grid>
			</Grid>
			<Grid item xs>
				<Formik
					initialValues={{ dateFrom, dateTo }}
					validate={validate}
				>
					{({ resetForm }) => (
						<FilterContainer>
							<Grid container direction="column" spacing={1}>
								<Grid item container justify="flex-end">
									<ClearFilterButton
										onClear={handleClear(resetForm)}
									/>
								</Grid>
								<Grid
									item
									container
									spacing={3}
									direction="row"
									alignItems="center"
									xs
									classes={{ root: classes.title }}
								>
									<Grid item>Filter: </Grid>
									<Grid item container spacing={1} xs>
										<Grid item xs>
											<DatePickerFormik
												label="Date From"
												name="dateFrom"
											/>
										</Grid>
										<Grid item xs>
											<DatePickerFormik
												label="Date To"
												name="dateTo"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<FilterUpdateWatcher
								onChange={onFilterChange}
								onSubmit={onFilterSubmit}
							/>
						</FilterContainer>
					)}
				</Formik>
			</Grid>
			<Grid item xs>
				<CommonTable
					headers={headers}
					entries={get(data, "items", [])}
					page={filters.page}
					totalEntries={get(data, "count", 0)}
					rowsPerPage={filters.rowsPerPage}
					sort={filters.sort}
					sortBy={filters.sortBy}
					onPageChange={onPageChange}
					onSort={onSort}
					size="medium"
				/>
			</Grid>
		</Grid>
	);
};

export default EndSessionLogs;
