import { getMonth } from "date-fns";

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
function isToday(inputDate) {
	const today = new Date();
	return (
		inputDate.getDate() == today.getDate() &&
		inputDate.getMonth() == today.getMonth() &&
		inputDate.getFullYear() == today.getFullYear()
	);
}

function getMonthName(inputDate) {
	return months[getMonth(new Date(inputDate))];
}

function addFirstDaytoMonthYearFormat(date) {
	if (date.length === 7) {
		const formatDate = date.replace("/", "/01/");
		return formatDate;
	}
	return date;
}

export { isToday, getMonthName, addFirstDaytoMonthYearFormat };
