import PropTypes from "prop-types";

const ZERO = 0;

function placeCurrency(value, currency = "P", location = "start") {
	if (location.toLowerCase() === "end") return `${value}${currency}`;

	return `${currency}${location}`;
}

function toDecimalPlaces(value, decimalPlaces = 2) {
	if (isNaN(value)) return ZERO.toFixed(decimalPlaces);
	return value.toFixed(decimalPlaces);
}

function addThousandsSeparator(value, decimalPlaces, separator = ",") {
	if (isNaN(value)) return ZERO.toFixed(decimalPlaces);
	return value
		.toLocaleString("en-US", {
			minimumFractionDigits: decimalPlaces,
		})
		.replace(",", separator);
}

const NumberFormatter = ({
	value,
	decimalPlaces,
	hasThousandsSeparator,
	thousandsSeparator,
	currency,
	currencyLocation,
}) => {
	let result = value;
	if (hasThousandsSeparator) {
		result = addThousandsSeparator(
			result,
			decimalPlaces,
			thousandsSeparator
		);
	} else {
		result = toDecimalPlaces(value, decimalPlaces);
	}

	if (currency) {
		result = placeCurrency(result, currency, currencyLocation);
	}

	return <>{result}</>;
};

NumberFormatter.propTypes = {
	value: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
	decimalPlaces: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
	hasThousandsSeparator: PropTypes.bool,
	thousandsSeparator: PropTypes.string,
	currency: PropTypes.string,
	currencyLocation: PropTypes.string,
};

NumberFormatter.defaultProps = {
	decimalPlaces: 2,
	hasThousandsSeparator: true,
	thousandsSeparator: ",",
	currency: null,
	currencyLocation: "start",
};

export default NumberFormatter;
