import {
	FormControlLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
	ThemeProvider,
	Typography,
	Checkbox,
	Divider,
	InputAdornment,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import AmountField from "../Components/AmountField";
import NumberField from "../Components/NumberField";
import styles from "../TransactionList.module.css";
import CheckReminder from "./CheckReminder";
import { useEffect, useState } from "react";
import {
	selectBiller,
	getBillerDetailsAction,
} from "../../../redux/modules/banks";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { formatter } from "../util/formatter";
import { TerminalUtil } from "../../../utils/checkSession";
import { isEmpty } from "underscore";
import { Box } from "@mui/material";

const checkTypes = [
	{
		key: "Local",
		value: "Local",
	},
	{
		key: "Regional",
		value: "Regional",
	},
	{
		key: "Manager's Check",
		value: "Manager's Check",
	},
];

const topRow = ["Amount", "CheckNo", "CheckType", "CheckDate"];
const botRow = ["BankCode", "BankBranch", "BankAccountNumber", "ContactNumber"];

function PaymentForm(props: any) {
	const {
		classes,
		onPaymentModeChange,
		selectedPaymentMethod,
		selectedBillers,
		theme,
		onPaymentDetailsChange,
		paymentDetails,
		paymentFieldErrors,
		failedTransactions,
		selectedDate,
		customerDetailsCheck,
		handleCustomerDetailsCheck,
		handleDateChange,
		banks,
		selectedBillerDetails,
		selectBiller,
		selectedBiller,
		getBillerDetailsAction,
		isValidated,
		privacyInfoCheck,
		...other
	} = props;

	const [errorCheckMarks, setErrorCheckMarks] = useState({
		top: false,
		bot: false,
	});

	useEffect(() => {
		selectBiller(selectedBillers[0].code);
	}, []);

	useEffect(() => {
		getBillerDetailsAction();
	}, [selectedBiller]);

	useEffect(() => {
		//for icon when error/success
		let _top = false;
		let _bot = false;

		topRow.forEach((key: string) => {
			_top = _top ? _top : getErrorMessage(key) != "";
		});

		botRow.forEach((key: string) => {
			_bot = _bot ? _bot : getErrorMessage(key) != "";
		});

		setErrorCheckMarks({
			top: _top,
			bot: _bot,
		});
	}, [failedTransactions, paymentFieldErrors]);

	const getErrorMessage = (field: string) => {
		let paymentFieldError = "";
		let failedTransactionError = "";
		try {
			paymentFieldError =
				paymentFieldErrors[`otherInfo.CheckDetails.${field}`];

			failedTransactionError =
				failedTransactions[selectedBillers[0].code][0].errors[
					`otherInfo.CheckDetails.${field}`
				][0].message;

			paymentFieldError = paymentFieldError || "";
			failedTransactionError = failedTransactionError || "";

			return paymentFieldError != ""
				? paymentFieldError
				: failedTransactionError;
		} catch (e) {
			paymentFieldError = paymentFieldError || "";
			failedTransactionError = failedTransactionError || "";
		}
		return paymentFieldError != ""
			? paymentFieldError
			: failedTransactionError;
	};

	const [customerDetails, setCustomerDetails] = useState({
		lastName: "",
		firstName: "",
		middleIntial: "",
		emailAddress: "",
		mobileNumber: "",
		address: "",
	});
	const clearCustomerDetails = () => {
		setCustomerDetails({
			lastName: "",
			firstName: "",
			middleIntial: "",
			emailAddress: "",
			mobileNumber: "",
			address: "",
		});
	};

	const [initialCash, setInitialCash] = useState("");
	const onNumericPaymentDetailsChange = (event: any) => {
		const number = /^[0-9]*[.]{0,1}[0-9]*$/;
		if (number.test(event.target.value) || event.target.value === "") {
			setInitialCash(event.target.value);
			onPaymentDetailsChange(event);
		} else if (
			!number.test(event.target.value) &&
			event.target.value.length === 1
		) {
			setInitialCash("");
		}
	};

	const onPaymentMethodChange = (event: any) => {
		setInitialCash("");
		onPaymentModeChange(event);
	};

	const initializePaymentModes = () => {
		let concatinated: string[] = [];
		selectedBillers
			.map((billers: any) => {
				if (!isEmpty(billers)) {
					let paymentMethodParam = billers.parameters.verify.filter(
						(parameter: any) =>
							Object.keys(parameter)[0] === "paymentMethod"
					)[0].paymentMethod;
					return Object.keys(paymentMethodParam.rules)
						.filter((methods: any) => methods.includes("in:"))[0]
						.replace("in:", "")
						.split(",");
				}
			})
			.forEach((mode: any) => (concatinated = concatinated.concat(mode)));
		return Array.from(new Set(concatinated));
	};

	return (
		<div id="paymentDetails">
			<div id="paymentForm">
				<Grid container direction="row">
					<Grid
						container
						item
						md={6}
						justify="flex-start"
						alignItems="center"
						className="paymentHeader"
					>
						<label className={styles.modePaymentHeader}>
							Mode of Payment
						</label>
						<label className={styles.paymentKeyHeader}>
							{" "}
							| SHFT + F1
						</label>
					</Grid>
					<Grid container item md={6} justify="flex-end">
						<RadioGroup
							row
							onChange={onPaymentMethodChange}
							value={selectedPaymentMethod}
						>
							<FormControlLabel
								value="CASH"
								control={<Radio size="small" />}
								label={
									<Typography
										className={classes.formControlLabel}
									>
										Cash
									</Typography>
								}
								labelPlacement="end"
								disabled={
									!initializePaymentModes().find(
										(mode) => mode === "CASH"
									)
								}
							/>
							<FormControlLabel
								value="CHECK"
								control={<Radio size="small" />}
								label={
									<Typography
										className={classes.formControlLabel}
									>
										Check
									</Typography>
								}
								labelPlacement="end"
								disabled={
									!initializePaymentModes().find(
										(mode) => mode === "CHECK"
									)
								}
							/>
							<FormControlLabel
								value="CASH+CHECK"
								control={<Radio size="small" />}
								label={
									<Typography
										className={classes.formControlLabel}
									>
										Cash + Check
									</Typography>
								}
								labelPlacement="end"
								disabled={
									!initializePaymentModes().find(
										(mode) => mode === "CASH+CHECK"
									)
								}
							/>
						</RadioGroup>
					</Grid>
					{selectedPaymentMethod === "CHECK" ||
					selectedPaymentMethod === "CASH+CHECK" ? (
						<Grid item md={12}>
							<CheckReminder
								selectedBillers={selectedBillers}
								billerDetails={selectedBillerDetails}
							/>
						</Grid>
					) : null}
				</Grid>
				{/* Note: ids are hardcoded but aligned with otherInfo.CheckDetails since payment fields are not dynamic */}
				<div id="paymentForm">
					<ThemeProvider theme={theme}>
						<form
							id="billsPaymentModeForm"
							name="billsPaymentModeForm"
						>
							<Grid
								container
								direction="row"
								className={styles.paymentFormGroup}
								spacing={1}
							>
								{selectedPaymentMethod === "CASH" ||
								selectedPaymentMethod === "CASH+CHECK" ? (
									<Box >
										<NumberField
                                            size="small"
											style={{width:"200px"}}
											id="otherInfo.initialCash"
											value={initialCash}
											InputProps={{
												classes: {
													root: `${
														paymentDetails
															?.otherInfo
															?.initialCash
															? classes.inputWithValue
															: ""
													}`,
												},
											}}
											error={
												paymentFieldErrors[
													"otherInfo.initialCash"
												]
											}
											helperText={
												paymentFieldErrors[
													"otherInfo.initialCash"
												]
											}
											label={
												<Typography
													className={
														classes.inputLabel
													}
												>
													Initial Cash Received
												</Typography>
											}
											variant="outlined"
											fieldOnChange={(event: any) =>
												onNumericPaymentDetailsChange(
													event
												)
											}
										/>
									</Box>
								) : null}
							</Grid>
							<Grid
								container
								direction="row"
								className={styles.paymentFormGroup}
								spacing={1}
							>
								{selectedPaymentMethod === "CHECK" ||
								selectedPaymentMethod === "CASH+CHECK" ? (
									<Grid item md={3}>
										<NumberField
											size="small"
											id="otherInfo.CheckDetails.Amount"
											InputProps={{
												classes: {
													root: `${
														paymentDetails
															?.otherInfo
															?.CheckDetails
															?.Amount
															? classes.inputWithValue
															: ""
													}`,
												},
											}}
											error={
												getErrorMessage("Amount") != ""
											}
											helperText={getErrorMessage(
												"Amount"
											)}
											label={
												<Typography
													className={
														classes.inputLabel
													}
												>
													Amount
												</Typography>
											}
											variant="outlined"
											fieldOnChange={
												onPaymentDetailsChange
											}
										/>
									</Grid>
								) : null}
								{selectedPaymentMethod === "CHECK" ||
								selectedPaymentMethod === "CASH+CHECK" ? (
									<Grid item md={3}>
										<TextField
											size="small"
											id="otherInfo.CheckDetails.CheckNo"
											inputProps={{ maxLength: 255 }}
											InputProps={{
												classes: {
													root: `${
														paymentDetails
															?.otherInfo
															?.CheckDetails
															?.CheckNo
															? classes.inputWithValue
															: ""
													}`,
												},
											}}
											error={
												getErrorMessage("CheckNo") != ""
											}
											helperText={getErrorMessage(
												"CheckNo"
											)}
											label={
												<Typography
													className={
														classes.inputLabel
													}
												>
													Check Number
												</Typography>
											}
											variant="outlined"
											onChange={onPaymentDetailsChange}
										/>
									</Grid>
								) : null}
								{selectedPaymentMethod === "CHECK" ||
								selectedPaymentMethod === "CASH+CHECK" ? (
									<Grid item md={2}>
										<TextField
											size="small"
											select
											fullWidth
											id="otherInfo.CheckDetails.CheckType"
											name="otherInfo.CheckDetails.CheckType"
											InputProps={{
												classes: {
													root: `${
														paymentDetails
															?.otherInfo
															?.CheckDetails
															?.CheckType
															? classes.inputWithValue
															: ""
													}`,
												},
											}}
											label={
												<Typography
													className={
														classes.inputLabel
													}
												>
													Check Type
												</Typography>
											}
											variant="outlined"
											defaultValue="Local"
											onChange={onPaymentDetailsChange}
										>
											{checkTypes.map((item) => {
												return (
													<MenuItem
														key={item.value}
														value={item.value}
													>
														<Typography
															className={
																classes.inputLabel
															}
														>
															{item.key}
														</Typography>
													</MenuItem>
												);
											})}
										</TextField>
									</Grid>
								) : null}
								{selectedPaymentMethod === "CHECK" ||
								selectedPaymentMethod === "CASH+CHECK" ? (
									<Grid item md={3}>
										<KeyboardDatePicker
											size="small"
											id="otherInfo.CheckDetails.CheckDate"
											autoOk
											variant="inline"
											inputVariant="outlined"
											InputProps={{
												classes: {
													root: `${
														paymentDetails
															?.otherInfo
															?.CheckDetails
															?.CheckDate
															? classes.inputWithValue
															: ""
													}`,
												},
											}}
											error={
												getErrorMessage("CheckDate") !=
												""
											}
											helperText={getErrorMessage(
												"CheckDate"
											)}
											label={
												<Typography
													className={
														classes.inputLabel
													}
												>
													Check Date
												</Typography>
											}
											format="MM/DD/yyyy"
											value={selectedDate}
											onChange={(date) =>
												handleDateChange(date)
											}
										/>
									</Grid>
								) : null}
								{(selectedPaymentMethod === "CHECK" ||
									selectedPaymentMethod === "CASH+CHECK") &&
								(isValidated || errorCheckMarks.top) ? (
									<Grid item md={1}>
										{!errorCheckMarks.top ? (
											<CheckCircleIcon
												className={
													classes.successValidateIcon
												}
											/>
										) : (
											<ErrorIcon
												className={
													classes.errorValidateIcon
												}
											/>
										)}
									</Grid>
								) : null}
							</Grid>

							<Grid
								container
								direction="row"
								className={styles.paymentFormGroup}
								spacing={1}
							>
								{selectedPaymentMethod === "CHECK" ||
								selectedPaymentMethod === "CASH+CHECK" ? (
									<Grid item md={3}>
										<TextField
											select
											size="small"
											name="otherInfo.CheckDetails.BankCode"
											id="otherInfo.CheckDetails.BankCode"
											InputProps={{
												classes: {
													root: `${
														paymentDetails
															?.otherInfo
															?.CheckDetails
															?.BankCode
															? classes.inputWithValue
															: ""
													}`,
												},
											}}
											error={
												getErrorMessage("BankCode") !=
												""
											}
											helperText={getErrorMessage(
												"BankCode"
											)}
											fullWidth
											className={styles.textInput}
											label={
												<Typography
													className={
														classes.inputLabel
													}
												>
													Bank Name
												</Typography>
											}
											variant="outlined"
											defaultValue=""
											onChange={onPaymentDetailsChange}
										>
											{banks.map((bank: any) => {
												return (
													<MenuItem
														key={bank.bank_code}
														value={bank.bank_code}
													>
														<Typography
															className={
																classes.inputLabel
															}
														>
															{bank.bank_name}
														</Typography>
													</MenuItem>
												);
											})}
										</TextField>
									</Grid>
								) : null}
								{selectedPaymentMethod === "CHECK" ||
								selectedPaymentMethod === "CASH+CHECK" ? (
									<Grid item md={3}>
										<TextField
											size="small"
											id="otherInfo.CheckDetails.BankBranch"
											inputProps={{ maxLength: 255 }}
											InputProps={{
												classes: {
													root: `${
														paymentDetails
															?.otherInfo
															?.CheckDetails
															?.BankBranch
															? classes.inputWithValue
															: ""
													}`,
												},
											}}
											error={
												getErrorMessage("BankBranch") !=
												""
											}
											helperText={getErrorMessage(
												"BankBranch"
											)}
											label={
												<Typography
													className={
														classes.inputLabel
													}
												>
													Bank Branch
												</Typography>
											}
											variant="outlined"
											onChange={onPaymentDetailsChange}
										/>
									</Grid>
								) : null}
								{selectedPaymentMethod === "CHECK" ||
								selectedPaymentMethod === "CASH+CHECK" ? (
									<Grid item md={2}>
										<TextField
											size="small"
											id="otherInfo.CheckDetails.BankAccountNumber"
											InputProps={{
												classes: {
													root: `${
														paymentDetails
															?.otherInfo
															?.CheckDetails
															?.BankAccountNumber
															? classes.inputWithValue
															: ""
													}`,
												},
											}}
											error={
												getErrorMessage(
													"BankAccountNumber"
												) != ""
											}
											helperText={getErrorMessage(
												"BankAccountNumber"
											)}
											label={
												<Typography
													className={
														classes.inputLabel
													}
												>
													Bank Account No.
												</Typography>
											}
											variant="outlined"
											onChange={onPaymentDetailsChange}
										/>
									</Grid>
								) : null}

								{selectedPaymentMethod === "CHECK" ||
								selectedPaymentMethod === "CASH+CHECK" ? (
									<Grid item md={3}>
										<TextField
											size="small"
											id="otherInfo.CheckDetails.ContactNumber"
											InputProps={{
												classes: {
													root: `${
														paymentDetails
															?.otherInfo
															?.CheckDetails
															?.ContactNumber
															? classes.inputWithValue
															: ""
													}`,
												},
											}}
											inputProps={{
												maxLength: 15,
											}}
											error={
												getErrorMessage(
													"ContactNumber"
												) != ""
											}
											helperText={getErrorMessage(
												"ContactNumber"
											)}
											label={
												<Typography
													className={
														classes.inputLabel
													}
												>
													Contact Number
												</Typography>
											}
											variant="outlined"
											onChange={onPaymentDetailsChange}
											onKeyDown={formatter.contactNumber}
										/>
									</Grid>
								) : null}
								{(selectedPaymentMethod === "CHECK" ||
									selectedPaymentMethod === "CASH+CHECK") &&
								(isValidated || errorCheckMarks.bot) ? (
									<Grid item md={1}>
										{!errorCheckMarks.bot ? (
											<CheckCircleIcon
												className={
													classes.successValidateIcon
												}
											/>
										) : (
											<ErrorIcon
												className={
													classes.errorValidateIcon
												}
											/>
										)}
									</Grid>
								) : null}
							</Grid>
						</form>
					</ThemeProvider>
				</div>
				<br />
				<Divider />
				<br />
				{/* START > Customer Information */}
				<div id="paymentForm">
					<Grid container direction="row">
						<Grid
							container
							item
							md={6}
							justify="flex-start"
							alignItems="center"
							className="paymentHeader"
						>
							<label className={styles.privacyInfoConsentHeader}>
								Privacy Information and Consent
							</label>
						</Grid>
						<Grid
							container
							item
							md={10}
							justify="flex-start"
							alignItems="center"
							className="paymentHeader"
						>
							<label className={styles.privacyInfoReminder}>
								Please record what was written in the Privacy Information and Consent and Customer details. Kindly ensure that details inputted in Bayad FA is the same with what is written in the Bayad form provided by the customer.
							</label>
						</Grid>
						<Grid container item md={12}>
							<FormControlLabel
								control={
									<Checkbox
										className={styles.formControlCB}
										size="small"
										onChange={(event) => {
											handleCustomerDetailsCheck(
												event.target.checked,
												"transparency"
											);
										}}
									/>
								}
								label={
									<Typography
										className={classes.formControlLabel}
									>
										<span className={styles.privacyInfoCheckboxLabel}>Transparency.</span> I have read and
										understood the Terms and Conditions for
										this transaction and the Privacy Notice
										posted at this location.
									</Typography>
								}
								labelPlacement="end"
							/>
						</Grid>
						<Grid container item md={12}>
							{privacyInfoCheck.transparencyErrMsg !== "" && <Typography className={classes.formControlErrorLabel}>{privacyInfoCheck.transparencyErrMsg}</Typography>}
						</Grid>
						<Grid container item md={12}>
							<FormControlLabel
								control={
									<Checkbox
										className={styles.formControlCB}
										size="small"
										onChange={(event) => {
											handleCustomerDetailsCheck(
												event.target.checked,
												"dataIntegrity"
											);
										}}
									/>
								}
								label={
									<Typography
										className={classes.formControlLabel}
									>
										<span className={styles.privacyInfoCheckboxLabel}>Data Integrity.</span> The information
										and documents provided are true and
										correct.
									</Typography>
								}
								labelPlacement="end"
							/>
						</Grid>
						<Grid container item md={12}>
							{privacyInfoCheck.dataIntegrityErrMsg !== "" && <Typography className={classes.formControlErrorLabel}>{privacyInfoCheck.dataIntegrityErrMsg}</Typography>}
						</Grid>
						<Grid container item md={12}>
							<FormControlLabel
								control={
									<Checkbox
										className={styles.formControlCB}
										size="small"
										onChange={(event) => {
											handleCustomerDetailsCheck(
												event.target.checked,
												"consent"
											);
											clearCustomerDetails();
										}}
									/>
								}
								label={
									<Typography
										className={classes.formControlLabel}
									>
										<span className={styles.privacyInfoCheckboxLabel}>Consent.</span> I allow Bayad to use
										this personal data to provide improved
										service, to inform me of rewards and
										promotions, and to offer other related
										and suited services.
									</Typography>
								}
								labelPlacement="end"
							/>
						</Grid>
					</Grid>
					<ThemeProvider theme={theme}>
						<form
							id="customerInformationModeForm"
							name="customerInformationModeForm"
							autoComplete="off"
						>
							<Box
								display={"grid"}
								gridColumn={"1 / 2"}
								gridRow={"1 / -1"}
								className={styles.customerDetailsFormGroup}
							>
								<Box
									display={"flex"}
									flexDirection={"row"}
									flexWrap={"wrap"}
									style={{
										gap: "8px",
									}}
								>
									<TextField
										size="small"
										id="otherInfo.CustomerDetails.firstName"
										autoComplete="off"
										style={{
											width: "200px",
										}}
										InputProps={{
											classes: {
												root: `${
													paymentDetails?.otherInfo
														?.CustomerDetails
														?.firstName
														? classes.inputWithValue
														: ""
												}`,
												disabled: `${classes.inputDisabled}`,
											},
										}}
										disabled={!customerDetailsCheck}
										label={
											<Typography
												className={classes.inputLabel}
											>
												First Name
											</Typography>
										}
										error={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.firstName"
											]
										}
										helperText={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.firstName"
											]
										}
										variant="outlined"
										value={customerDetails.firstName}
										onChange={(event) => {
											onPaymentDetailsChange(event);
											setCustomerDetails({
												...customerDetails,
												firstName: event.target.value,
											});
										}}
										onKeyDown={formatter.alphaNumericSpace}
									/>
									<TextField
										size="small"
										id="otherInfo.CustomerDetails.middleInitial"
										autoComplete="off"
										style={{
											width: "200px",
										}}
										InputProps={{
											classes: {
												root: `${
													paymentDetails?.otherInfo
														?.CustomerDetails
														?.middleInitial
														? classes.inputWithValue
														: ""
												}`,
												disabled: `${classes.inputDisabled}`,
											},
										}}
										disabled={!customerDetailsCheck}
										label={
											<Typography
												className={classes.inputLabel}
											>
												M.I.
											</Typography>
										}
										error={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.middleInitial"
											]
										}
										helperText={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.middleInitial"
											]
										}
										variant="outlined"
										value={customerDetails.middleIntial}
										onChange={(event) => {
											onPaymentDetailsChange(event);
											setCustomerDetails({
												...customerDetails,
												middleIntial:
													event.target.value,
											});
										}}
										onKeyDown={formatter.alphaNumericSpace}
									/>
									<TextField
										size="small"
										id="otherInfo.CustomerDetails.lastName"
										autoComplete="off"
										style={{
											width: "200px",
										}}
										InputProps={{
											classes: {
												root: `${
													paymentDetails?.otherInfo
														?.CustomerDetails
														?.lastName
														? classes.inputWithValue
														: ""
												}`,
												disabled: `${classes.inputDisabled}`,
											},
										}}
										disabled={!customerDetailsCheck}
										label={
											<Typography
												className={classes.inputLabel}
											>
												Last Name
											</Typography>
										}
										error={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.lastName"
											]
										}
										helperText={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.lastName"
											]
										}
										variant="outlined"
										value={customerDetails.lastName}
										onChange={(event) => {
											onPaymentDetailsChange(event);
											setCustomerDetails({
												...customerDetails,
												lastName: event.target.value,
											});
										}}
										onKeyDown={formatter.alphaNumericSpace}
									/>
									<TextField
										size="small"
										id="otherInfo.CustomerDetails.mobileNumber"
										autoComplete="off"
										style={{
											width: "200px",
										}}
										InputProps={{
											classes: {
												root: `${
													paymentDetails?.otherInfo
														?.CustomerDetails
														?.mobileNumber
														? classes.inputWithValue
														: ""
												}`,
												disabled: `${classes.inputDisabled}`,
											},
											startAdornment:
												customerDetailsCheck ? (
													<InputAdornment
														className={
															classes.mobileNoAdorment
														}
														position="start"
													>
														+63
													</InputAdornment>
												) : (
													""
												),
										}}
										disabled={!customerDetailsCheck}
										label={
											<Typography
												className={classes.inputLabel}
											>
												Mobile Number
											</Typography>
										}
										variant="outlined"
										value={customerDetails.mobileNumber}
										error={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.mobileNumber"
											]
										}
										helperText={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.mobileNumber"
											]
										}
										onChange={(event) => {
											onPaymentDetailsChange(event);
											setCustomerDetails({
												...customerDetails,
												mobileNumber:
													event.target.value,
											});
										}}
										onKeyDown={formatter.mobileNumber}
									/>
									<TextField
										size="small"
										id="otherInfo.CustomerDetails.emailAddress"
										autoComplete="off"
										style={{
											width: "408px",
										}}
										InputProps={{
											classes: {
												root: `${
													paymentDetails?.otherInfo
														?.CustomerDetails
														?.emailAddress
														? classes.inputWithValue
														: ""
												}`,
												disabled: `${classes.inputDisabled}`,
											},
										}}
										disabled={!customerDetailsCheck}
										label={
											<Typography
												className={classes.inputLabel}
											>
												Email Address
											</Typography>
										}
										error={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.emailAddress"
											]
										}
										helperText={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.emailAddress"
											]
										}
										variant="outlined"
										value={customerDetails.emailAddress}
										onChange={(event) => {
											onPaymentDetailsChange(event);
											setCustomerDetails({
												...customerDetails,
												emailAddress:
													event.target.value,
											});
										}}
										onKeyDown={formatter.omitSpace}
									/>
									<TextField
										size="small"
										id="otherInfo.CustomerDetails.address"
										autoComplete="off"
										style={{
											width: "408px",
										}}
										InputProps={{
											classes: {
												root: `${
													paymentDetails?.otherInfo
														?.CustomerDetails
														?.address
														? classes.inputWithValue
														: ""
												}`,
												disabled: `${classes.inputDisabled}`,
											},
										}}
										disabled={!customerDetailsCheck}
										label={
											<Typography
												className={classes.inputLabel}
											>
												Address (City/Province)
											</Typography>
										}
										error={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.address"
											]
										}
										helperText={
											paymentFieldErrors[
												"otherInfo.CustomerDetails.address"
											]
										}
										variant="outlined"
										value={customerDetails.address}
										onChange={(event) => {
											onPaymentDetailsChange(event);
											setCustomerDetails({
												...customerDetails,
												address: event.target.value,
											});
										}}
										onKeyDown={
											formatter.alphaNumericSpaceDotComma
										}
									/>
								</Box>
							</Box>
						</form>
					</ThemeProvider>
				</div>
				{/* END > Customer Information */}
			</div>
		</div>
	);
}

export default connect(
	(state: any) => ({
		banks: state.banks.banks,
		selectedBillerDetails: state.banks.selectedBillerDetails,
		selectedBiller: state.banks.selectedBiller,
	}),
	{
		selectBiller,
		getBillerDetailsAction,
	}
)(PaymentForm);