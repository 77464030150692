import PropTypes from "prop-types";
import styles from "./ModalScrollBar.module.css";

const ModalScrollBar = (props) => {
	return (
		<div
			style={
				props.isOverflowXhidden
					? {
							overflowX: "hidden",
					  }
					: {}
			}
			className={styles.scrollbar}
		>
			<div className={styles.children}>{props.children}</div>
		</div>
	);
};

ModalScrollBar.propTypes = {
	isOverflowXhidden: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
export default ModalScrollBar;
