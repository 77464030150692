// https://github.com/diegohaz/arc/wiki/Atomic-Design
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const TextStyle = styled("span")(({ theme }) => ({}));

const FormattedTextField = ({ children, className, color, ...props }) => {
	return (
		<TextStyle className={className} style={{ color: color }} {...props}>
			{children}
		</TextStyle>
	);
};

FormattedTextField.propTypes = {
	// children: PropTypes.any.isRequired,
};

export default FormattedTextField;
