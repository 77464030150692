import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularStatic from './CicrcularStatic';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    MuiTypographyRoot: {

    },
    MuiTypographyH6: {
        fontSize: '15px'
    },

});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className={classes.MuiTypographyH6} variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function CustomizedDialogs({open, handleClose, isRequestSuccess}) {

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    color: 'white',
                    fontSize: '12px',
                },
            }}>
            <DialogTitle id="customized-dialog-title" style={{ backgroundColor: 'none', fontSize: '12px' }}>
                Please wait while reports are being generated
            </DialogTitle>
            <DialogContent>
                <CircularStatic onClose={handleClose} isRequestSuccess={isRequestSuccess} />
            </DialogContent>
        </Dialog>
    );
}
