import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Divider, Grid } from "@material-ui/core";
import icchangepassword from "../../assets/icons/ic-changepassword-hover.svg";
import icid from "../../assets/icons/ic-id.svg";
import icarrowdown from "../../assets/icons/ic-arrowdown.svg";
import {
	BranchUtil,
	TerminalUtil,
	UserInfoUtil,
} from "../../utils/checkSession";

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		padding: "0px 41px 0px 18px",
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	section1: {
		margin: "0px 16px 15px",
		fontSize: "14px",
		color: "#0076c0",
	},
	section2: {
		margin: theme.spacing(2),
	},
	section3: {
		margin: "21px 18px",
	},
	light: {
		fontFamily: "Poppins-Light",
	},
	regular: {
		fontFamily: "Poppins-Regular",
	},
	medium: {
		fontFamily: "Poppins-Medium",
	},
}));

function AttributeList() {
	//retrieve details from local storage

	const userDetails = UserInfoUtil.get();
	const branch = BranchUtil.get([{ name: "", id: "" }]);

	const classes = useStyles();

	const attribs = [
		"Username",
		"Branch",
		"Branch Code",
		"User Role",
		"Email",
		"Mobile Number",
	];

	const values = [
		userDetails.username,
		branch[0].name,
		branch[0].id,
		userDetails.roles[0].name,
		maskEmail(userDetails.emailAddress),
		maskMobile(`+${userDetails.mobileNumber}`),
	];

	return attribs.map((attrib, i) => {
		return (
			<Grid
				key={i}
				className={classes.section2}
				item
				xs
				style={{
					marginTop: "10px",
					display: "flex",
					alignItems: "center",
					flexWrap: "wrap",
				}}
			>
				<Typography>
					<span
						className={classes.medium}
						style={{ fontSize: "12px" }}
					>
						{attrib}:{" "}
					</span>
					<span
						className={classes.regular}
						style={{ fontSize: "12px" }}
					>
						{values[i]}
					</span>
				</Typography>
			</Grid>
		);
	});
}

function maskEmail(email) {
	if (email.length > 3) {
		const prefix = email.substr(0, 3);
		const suffix = email.match(/@.*/)[0];
		const mid = email.substr(3, email.length - suffix.length - 3);

		return prefix + "*".repeat(mid.length) + suffix;
	} else {
		//base case
		return "*******";
	}
}

function maskMobile(mobile) {
	return mobile.replaceAll(mobile.substr(3, 6), "*".repeat(6));
}

export default function ProfilePopover() {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	var isSupervisor = false;

	const roles = UserInfoUtil.get().roles;
	var mappedRole = roles.filter((e) => {
		return e.name === "Super Admin" || e.name === "Supervisor";
	});
	if (mappedRole.length > 0) {
		isSupervisor = true;
	}
	return (
		<div>
			<IconButton onClick={handleClick}>
				<img src={icarrowdown} alt="Open Profile" />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<div className={classes.root}>
					<div className={classes.section1}>
						<Grid container alignItems="center">
							<Grid item xs>
								<span className={`${classes.medium} `}>
									Profile Information
								</span>
							</Grid>
						</Grid>
					</div>

					<Divider variant="middle" />

					<AttributeList />

					<Divider variant="middle" />
					<div className={`${classes.section3}`}>
						<Grid item xs style={{ marginBottom: "12px" }}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									flexWrap: "wrap",
								}}
							>
								<img
									src={icchangepassword}
									alt="Change Password"
									style={{ marginRight: "14px" }}
								/>
								<Typography
									style={{ fontSize: "12px" }}
									className={classes.regular}
								>
									Change Password
								</Typography>
							</div>
						</Grid>
						{isSupervisor && (
							<Grid item xs style={{ fontSize: "12px" }}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										flexWrap: "wrap",
									}}
								>
									<img
										src={icid}
										alt="Change ID"
										style={{ marginRight: "14px" }}
									/>
									<Typography
										style={{ fontSize: "12px" }}
										className={classes.regular}
									>
										Change Branch/TPA ID
									</Typography>
								</div>
							</Grid>
						)}
					</div>
				</div>
			</Popover>
		</div>
	);
}
