import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showPrintReceiptModal: false,
	receiptNo: "",
};

const receipt = createSlice({
	name: "receipt",
	initialState,
	reducers: {
		togglePrintReceiptModal: (state, { payload }) => {
			state.showPrintReceiptModal = payload;
		},
		setReceiptNo: (state, { payload }) => {
			state.receiptNo = payload;
		},
	},
});

const { togglePrintReceiptModal, setReceiptNo } = receipt.actions;

export default receipt.reducer;

export { togglePrintReceiptModal, setReceiptNo };
