import {
	Button,
	Dialog,
	DialogActions,
	Grid,
	Typography,
	Divider,
} from "@material-ui/core";
import { sanitize } from "dompurify";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { GreenColorButton } from "../App/App";
import styles from "./ConfirmationModal.module.css";
import cx from "classnames";

type ConfirmationModalProps = {
	isOpen: boolean;
	title?: string;
	content?: string;
	contentHint?: string;
	isBreakWordContent?: boolean;
};

interface IConfirmationModal {
	onClickCancel: () => void;
	onClickConfirm: () => void;
}
export default function ConfirmationModal({
	isOpen = false,
	title = "Delete Schedule",
	content = "Are you sure you want to delete schedule?",
	contentHint,
	onClickCancel,
	onClickConfirm,
	isBreakWordContent = false,
}: ConfirmationModalProps & IConfirmationModal) {
	return (
		<Dialog
			disableBackdropClick
			open={isOpen}
			fullWidth={true}
			maxWidth={"xs"}
			classes={{ paper: styles.dialog }}
		>
			<div className={styles.dialogTitle}>
				<Typography className={styles.dialogTitleText}>
					{title}
				</Typography>

				<IconButton onClick={onClickCancel} aria-label="close">
					<CloseIcon />
				</IconButton>
			</div>
			<Divider />
			<Grid>
				<div className={styles.dialogContent}>
					<Typography
						className={cx(styles.dialogContentText, {
							[styles.breakWord]: isBreakWordContent,
						})}
						dangerouslySetInnerHTML={{
							__html: sanitize(content),
						}}
					/>
					{contentHint && (
						<span className={styles.dialogContentHint}>
							{contentHint}
						</span>
					)}
				</div>
			</Grid>
			<Divider />
			<DialogActions>
				<Button
					onClick={onClickCancel}
					className={styles.dialogCancelButton}
				>
					Cancel
				</Button>
				{/* <Button
					onClick={confirmOnClick}
					className={styles.dialogConfirmButton}
				>
					Confirm
				</Button> */}
				<GreenColorButton
					onClick={onClickConfirm}
					autoFocus
					color="primary"
				>
					Confirm
				</GreenColorButton>
			</DialogActions>
		</Dialog>
	);
}
