import React from "react";
import { useSelector } from "react-redux";
import styles from "./PrintReceipt.module.css";
import RecieptCheck from "./RecieptCheck";
import { PropaneSharp } from "@mui/icons-material";
import { getSessionStorage } from "../../utils/storage";
import _ from "lodash";

const PrintReceipt = (_, ref, props) => {
	const selectedForPrint = useSelector((state) =>
		state.transaction.selectedForPrint != null
			? state.transaction.selectedForPrint.values
			: null
	);

	let transactionList = null
	const sessionTransactionDetails = getSessionStorage("transactionDetails") || []
	if(selectedForPrint && sessionTransactionDetails && sessionTransactionDetails.length > 0){
		transactionList = sessionTransactionDetails.filter((t) => t.checkReceiptNo === selectedForPrint.checkReceiptNo);
	}

	const tpaId = useSelector((state) => state.userInfo.terminal.name);
	return (
		<div className="printme" style={{ padding: "0" }} ref={ref}>
			<div style={{ marginLeft: "0.35cm", marginTop: "0.7cm" }}>
				<RecieptCheck
					selectedForPrint={selectedForPrint}
					transactionList={transactionList}
					tpaId={tpaId}
				/>
			</div>
		</div>
	);
};

export default React.forwardRef(PrintReceipt);
