import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment, { Moment } from "moment";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { TextField, TextFieldProps } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import WarningIcon from "@material-ui/icons/Warning";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePickerFormik } from "../../Inputs/DatePicker";
import { Formik, useField, FormikHelpers } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "./BranchAccessControlModal.module.css";
import HTTP from "../../../helpers/ApiClient";
import Switch from "../../Switch/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import CalendarSVG from "../../../assets/icons/img-emptycalendar.svg";
import { requestWrapper } from "../../../utils/requestWrapper";
import EditScheduleForm from "./EditScheduleForm";
import ConfirmationModal from "../ConfirmationModal";
import AddScheduleOptionModal from "./AddScheduleOptionModal";
import { Schedule, FormValues } from "./types/BrachAccessControlModal";
import { useDispatch } from "react-redux";
import {
	setBranchStatus,
	setBranchTerminalStatus,
} from "../../../redux/modules/branchAccessControl";

type BranchAccessControlModalProps = {
	isOpen: boolean;

	selectedBranch: {
		branchId: number;
		tpaid: number;
		tpaidName: string;
		isOperating: boolean;
		branchName: string;
	};
};

interface IBranchAccessControlModal {
	handleClose: () => void;
	setSuccessModalInfo: (info: {
		title: string;
		isOpen: boolean;
		message: string;
		fullWidth: boolean;
	}) => void;
	showLoader: () => void;
	hideLoader: () => void;
	fetchBranchTerminals: (x: number) => Promise<void>;
}

type ConfirmationModalInfo = {
	isOpen: boolean;
	title: string;
	content: string;
	type: string;
};
const getSchedules = async (branch_id: number, terminal_id: number) => {
	const url = terminal_id
		? `/v3/branch-access/${branch_id}/terminals/${terminal_id}/schedules`
		: `/v3/branch-access/${branch_id}/schedules`;
	const data = await HTTP.get(url);

	return data;
};

const MenuProps = {
	getContentAnchorEl: null,
	PaperProps: {
		style: {
			padding: 0,
		},
	},
};

const _styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	});

const DialogWithStyle = withStyles({
	root: {
		//@ts-ignore
		//!important doesn't get accepted here for now ignoring this with ts
		zIndex: "1100 !important",
	},
})(Dialog);

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		paddingTop: 0,
	},
}))(MuiDialogContent);

const DialogTitle = withStyles(_styles)(
	(
		props: { id: string; onClose?: () => void } & WithStyles<typeof _styles>
	) => {
		const { classes, onClose, ...other } = props;
		return (
			<MuiDialogTitle
				disableTypography
				className={classes.root}
				{...other}
			>
				{onClose ? (
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={onClose}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		);
	}
);

const Alert = ({
	branchName,
	tpaidName,
}: {
	branchName: string;
	tpaidName: string;
}) => {
	return (
		<div className={styles.alertContainer}>
			<WarningIcon style={{ fontSize: "20px" }} />
			<span>
				To enable{" "}
				<b>
					{branchName}
					{tpaidName}
				</b>
				, please create first an operating schedule.
			</span>
		</div>
	);
};

const ModalTitle = ({
	branchName,
	tpaidName,
	hasSchedule,
	isOperating,
}: {
	branchName: string;
	tpaidName: string;
	hasSchedule: boolean;
	isOperating: boolean;
}) => {
	const color = {
		green: "#40A434",
		gray: "#8b9095",
	};
	return (
		<>
			{!hasSchedule && (
				<Alert
					branchName={branchName}
					tpaidName={tpaidName ? " " + tpaidName : ""}
				/>
			)}
			<div className={styles.modalTitleContainer}>
				<span className={styles.title} style={{ fontWeight: 700 }}>
					{branchName}
				</span>
				<span className={styles.title} style={{ fontWeight: 700 }}>
					{tpaidName}
				</span>
				<div
					className={styles.statusIcon}
					style={{
						backgroundColor: isOperating ? color.green : color.gray,
					}}
				></div>
			</div>
		</>
	);
};

//FieldHookConfig throws a type error with typescript + material ui + useField, using this type for work around
//reference: https://stackoverflow.com/questions/61089182/how-to-properly-use-usefield-hook-from-formik-in-typescript
type FieldTimePickerProps = {
	label: string;
	name: string;
	validate?: (value: any) => undefined | string | Promise<any>;
	type?: string;
	multiple?: boolean;
	value?: any;
	isValidateError?: string | null;
};

export const FieldTimePicker = ({
	label,
	isValidateError,
	...props
}: FieldTimePickerProps) => {
	const [field, meta, helper] = useField(props);
	const currentError = meta.error;

	return (
		<TimePicker
			label={label}
			value={field.value}
			onChange={(newValue) => helper.setValue(newValue)}
			{...props}
			renderInput={(params: any) => (
				<TextField
					{...params}
					helperText={isValidateError ? "" : currentError}
					error={Boolean(currentError)}
					variant="outlined"
					size="small"
					style={{ width: "130px" }}
				/>
			)}
		/>
	);
};

//FieldHookConfig throws a type error with typescript + material ui + useField, using this type for work around
//reference: https://stackoverflow.com/questions/61089182/how-to-properly-use-usefield-hook-from-formik-in-typescript
type SelectDaysFieldProps = {
	label: string;
	name: string;
	validate?: (value: any) => undefined | string | Promise<any>;
	type?: string;
	multiple?: boolean;
	value?: string[];
};

export const SelectDaysField = ({ label, ...props }: SelectDaysFieldProps) => {
	const [field, meta, helper] = useField({ ...props });
	const currentError = meta.error;
	const options = [
		{ name: "Monday", value: "mon" },
		{ name: "Tuesday", value: "tue" },
		{ name: "Wednesday", value: "wed" },
		{ name: "Thursday", value: "thu" },
		{ name: "Friday", value: "fri" },
		{ name: "Saturday", value: "sat" },
		{ name: "Sunday", value: "sun" },
	];

	return (
		<FormControl
			variant="outlined"
			size="small"
			error={Boolean(currentError)}
		>
			<InputLabel>{label}</InputLabel>
			<Select
				label={label}
				name={field.name}
				value={field.value}
				onChange={(event) => helper.setValue(event.target.value)}
				multiple
				renderValue={(selected: any) =>
					selected
						.map(
							(e: any) => options.find((s) => s.value === e)?.name
						)
						.join(", ")
				}
				MenuProps={MenuProps}
				style={{ width: "230px" }}
				IconComponent={() => (
					<DateRangeIcon style={{ marginRight: "6px" }} />
				)}
			>
				{options.map((e) => (
					<MenuItem key={e.name} value={e.value}>
						<Checkbox
							checked={field.value?.indexOf(e.value) > -1}
						/>
						<ListItemText primary={e.name} />
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{currentError}</FormHelperText>
		</FormControl>
	);
};

//FieldHookConfig throws a type error with typescript + material ui + useField, using this type for work around
//reference: https://stackoverflow.com/questions/61089182/how-to-properly-use-usefield-hook-from-formik-in-typescript
type CheckBoxFieldProps = {
	label: string;
	name: string;
	validate?: (value: any) => undefined | string | Promise<any>;
	type?: string;
	multiple?: boolean;
	value?: any;
};
export const CheckboxField = ({ label, ...props }: CheckBoxFieldProps) => {
	const [field, _, helper] = useField({ ...props });

	return (
		<FormControlLabel
			name={field.name}
			checked={field.value}
			onChange={(event: any) => {
				helper.setValue(event.target.checked);
			}}
			control={<Checkbox />}
			label={<span style={{ fontSize: "14px" }}>{label}</span>}
			style={{ marginBottom: "19px", width: "fit-content" }}
		/>
	);
};

const ScheduleForm = ({
	handleAdd,
	errorMessage,
	setErrorMessage,
}: {
	handleAdd: () => void;
	errorMessage?: string | null;
	setErrorMessage: (e: string | null) => void;
}) => {
	const initialValues = {
		startDate: null,
		endDate: null,
		startTime: null,
		endTime: null,
		isRecurring: false,
		recurringDays: [],
	};

	const handleValidation = (values: {
		startDate: Moment | null;
		endDate: Moment | null;
		startTime: string | null;
		endTime: string | null;
		recurringDays: string[];
		isRecurring: boolean;
	}): { [key: string]: string } => {
		setErrorMessage(null);
		const { startDate, endDate, startTime, endTime, recurringDays } =
			values;
		let errors: { [key: string]: string } = {};

		const validateDateTime = (
			start: { value: Moment | null; name: string },
			end: { value: Moment | null; name: string },
			errorMessage: string
		): { [key: string]: string } => {
			const _e: { [key: string]: string } = {};

			if (start.value && end.value) {
				if (
					start.value.isAfter(end.value) ||
					end.value.isBefore(start.value)
				) {
					_e[end.name] = errorMessage;
				}
			} else {
				if (start.value && !end.value) {
					_e[end.name] = errorMessage;
				}

				if (!start.value && end.value) {
					_e[start.name] = errorMessage;
				}
			}

			if (!start.value) {
				_e[start.name] = "This field is required.";
			}

			if (!end.value) {
				_e[end.name] = "This field is required.";
			}

			return _e;
		};

		const validateRecurringDays = (): { [key: string]: string } => {
			return recurringDays.length
				? {}
				: { recurringDays: "This field is required." };
		};

		const v1: { [key: string]: string } = values.isRecurring
			? validateRecurringDays()
			: validateDateTime(
					{ value: startDate, name: "startDate" },
					{ value: endDate, name: "endDate" },
					"Invalid date input"
			  );

		const v2: { [key: string]: string } = validateDateTime(
			{
				value: startTime ? moment(startTime, "h:mma") : null,
				name: "startTime",
			},
			{
				value: endTime ? moment(endTime, "h:mma") : null,
				name: "endTime",
			},
			"Invalid time input"
		);

		errors = { ...v1, ...v2 };
		return errors;
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Formik
				initialValues={initialValues}
				validate={handleValidation}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={handleAdd}
			>
				{({ submitForm, values }) => (
					<>
						<CheckboxField
							label="Recurring Schedule"
							name="isRecurring"
							value={values.isRecurring}
						/>
						<Grid
							container
							direction="row"
							alignItems="flex-start"
							style={{ justifyContent: "space-between" }}
						>
							{!values.isRecurring ? (
								<>
									<Grid item>
										<DatePickerFormik
											label="Start Date"
											name="startDate"
											size="small"
											style={{ width: "170px" }}
										/>
									</Grid>
									<Grid item>
										<DatePickerFormik
											label="End Date"
											name="endDate"
											size="small"
											style={{ width: "170px" }}
										/>
									</Grid>
								</>
							) : (
								<Grid item>
									<SelectDaysField
										label="Repeat"
										name="recurringDays"
										value={values.recurringDays}
									/>
								</Grid>
							)}
							<Grid item></Grid>
							<Grid item>
								<Grid
									container
									direction="row"
									alignItems="flex-start"
									style={{
										width: "280px",
										justifyContent: "space-between",
									}}
								>
									<Grid item>
										<FieldTimePicker
											label="Start Time"
											name="startTime"
											isValidateError={errorMessage}
											value={values.startTime}
										/>
									</Grid>
									<Grid item>
										<FieldTimePicker
											label="End Time"
											name="endTime"
											isValidateError={errorMessage}
											value={values.endTime}
										/>
									</Grid>
									{errorMessage && (
										<span style={{ color: "red" }}>
											{errorMessage}
										</span>
									)}
								</Grid>
							</Grid>
							<Grid item>
								<IconButton
									aria-label="add"
									size="small"
									style={{ marginTop: "8px" }}
									onClick={submitForm}
								>
									<AddCircleIcon
										fontSize="inherit"
										style={{ color: "#4ea2d3" }}
									/>
								</IconButton>
							</Grid>
						</Grid>
					</>
				)}
			</Formik>
		</LocalizationProvider>
	);
};

const ScheduleFormContainer = ({
	handleAdd,
	isTpaid,
	errorMessage,
	setErrorMessage,
}: {
	handleAdd: () => void;
	isTpaid: boolean;
	errorMessage?: string | null;
	setErrorMessage: (e: string | null) => void;
}) => {
	return (
		<div className={styles.scheduleFormContainer}>
			<span className={styles.title}>Add Schedule</span>
			{!isTpaid && (
				<span className={styles.hint}>
					* This schedule will also be applied to all TPAIDs under
					this branch.
				</span>
			)}
			<ScheduleForm
				handleAdd={handleAdd}
				errorMessage={errorMessage}
				setErrorMessage={setErrorMessage}
			/>
		</div>
	);
};

const EmptyList = ({ isTpaid }: { isTpaid: boolean }) => (
	<div className={styles.emptyList}>
		<img
			src={CalendarSVG}
			alt="Empty Calendar"
			style={{ marginBottom: "14px" }}
		/>
		<i style={{ color: "#8B9095" }}>
			You haven’t created yet an operating schedule for this{" "}
			{isTpaid ? "TPAID" : "branch"}. Please select a date range and click
			the “+” icon to create.
		</i>
	</div>
);

const days: { [key: string]: string } = {
	mon: "Monday",
	tue: "Tuesday",
	wed: "Wednesday",
	thu: "Thursday",
	fri: "Friday",
	sat: "Saturday",
	sun: "Sunday",
};
export const formatDateSchedule = ({
	is_recurring = false,
	recurring_days = "",
	start_date = null,
	end_date = null,
}: {
	is_recurring: boolean;
	recurring_days?: string;
	start_date?: Moment | null;
	end_date?: Moment | null;
}) => {
	return is_recurring
		? `Every ${recurring_days
				.split("|")
				.map((e) => days[e.toLowerCase()])
				.join(", ")}`
		: `${moment(start_date).format("MMMM D, YYYY")} - ${moment(
				end_date
		  ).format("MMMM D, YYYY")}`;
};

export const formatTimeSchedule = ({
	start_time,
	end_time,
}: {
	start_time: Moment | string;
	end_time: Moment | string;
}) => {
	return (
		moment(start_time, "hh:mm").format("LT") +
		" - " +
		moment(end_time, "hh:mm").format("LT")
	);
};

const ScheduleItem = ({
	schedule,
	onClickType,
	handleEditSchedule,
}: {
	schedule: Schedule;
	onClickType: (e: string) => void;
	handleEditSchedule: () => void;
}) => {
	const [isEditEnabled, setIsEditEnabled] = useState(false);
	const scheduleStyle = {
		recurring: {
			backgroundColor: "#F0EFC3",
			border: "1px solid #CCCF19",
		},
		specific: {
			backgroundColor: "#D5EADA",
			border: "1px solid #4BB482",
		},
		edit: {
			backgroundColor: "#FFFFFF",
			border: "1px solid #dadce0",
		},
	};

	const dateSchedule = formatDateSchedule(schedule);
	const timeSchedule = formatTimeSchedule(schedule);

	return (
		<>
			<div
				className={styles.scheduleItem}
				style={
					isEditEnabled
						? scheduleStyle.edit
						: schedule.is_recurring
						? scheduleStyle.recurring
						: scheduleStyle.specific
				}
			>
				<div className={styles.scheduleItemHeader}>
					<div className={styles.scheduleLabel}>
						<span className={styles.scheduleLabelTitle}>
							{dateSchedule}
						</span>
						<span className={styles.scheduleLabelSubTitle}>
							Operating Hours: <b>{timeSchedule}</b>
						</span>
					</div>
					<div className={styles.scheduleActions}>
						<Switch
							onChange={() =>
								onClickType(
									confirmationModalType.TOGGLE_SCHEDULE
								)
							}
							checked={schedule?.status === "ACTIVE"}
							size="small"
						/>
						<IconButton
							aria-label="edit"
							onClick={() => setIsEditEnabled((prev) => !prev)}
							size="small"
						>
							<EditIcon
								fontSize="small"
								style={{ color: "#0076c0" }}
							/>
						</IconButton>
						<IconButton
							onClick={() =>
								onClickType(
									confirmationModalType.DELETE_SCHEDULE
								)
							}
							aria-label="delete"
							size="small"
						>
							<DeleteIcon fontSize="small" />
						</IconButton>
					</div>
				</div>
				{isEditEnabled && (
					<EditScheduleForm
						schedule={schedule}
						handleEditSchedule={handleEditSchedule}
					/>
				)}
			</div>
		</>
	);
};

const OperatingSchedule = ({
	schedules,
	isTpaid,
	handleEditSchedule,
	setSelectedScheduleInfo,
	setConfirmationModalInfo,
}: {
	schedules: Schedule[];
	isTpaid: boolean;
	handleEditSchedule: () => void;
	setSelectedScheduleInfo: (e: Schedule) => void;
	setConfirmationModalInfo: (e: ConfirmationModalInfo) => void;
}) => {
	const handleClickType = (type: string, schedule: Schedule) => {
		if (type === confirmationModalType.DELETE_SCHEDULE) {
			setConfirmationModalInfo({
				isOpen: true,
				title: "Delete Schedule",
				content: "Are you sure you want to delete this schedule?",
				type,
			});
		} else if (type === confirmationModalType.TOGGLE_SCHEDULE) {
			const status = schedule?.status === "ACTIVE" ? "Disable" : "Enable";
			setConfirmationModalInfo({
				isOpen: true,
				title: `${status} Schedule`,
				content: `Are you sure you want to ${status.toLocaleLowerCase()} this schedule?`,
				type,
			});
		}
	};
	return (
		<>
			<span className={styles.title}>Operating Schedule</span>
			<div className={styles.scheduleContainer}>
				{schedules.length ? (
					schedules.map((e) => (
						<ScheduleItem
							key={e.id}
							schedule={e}
							handleEditSchedule={handleEditSchedule}
							onClickType={(type) => {
								setSelectedScheduleInfo({ ...e });
								handleClickType(type, e);
							}}
						/>
					))
				) : (
					<EmptyList isTpaid={isTpaid} />
				)}
			</div>
		</>
	);
};

export const confirmationModalType = {
	DELETE_SCHEDULE: "deleteSchedule",
	TOGGLE_SCHEDULE: "toggleSchedule",
};

const conflictScheduleErrorType = "conflicting_terminal_schedule";

const BranchAccessControlModal = ({
	selectedBranch,
	isOpen,
	handleClose,
	setSuccessModalInfo,
	showLoader,
	hideLoader,
	fetchBranchTerminals,
}: BranchAccessControlModalProps & IBranchAccessControlModal) => {
	const [schedules, setSchedules] = useState<Array<Schedule>>([]);
	const [isOperating, setIsOperating] = useState<boolean | null>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const dispatch = useDispatch();
	const [confirmationModalInfo, setConfirmationModalInfo] =
		useState<ConfirmationModalInfo>({
			isOpen: false,
			title: "",
			content: "",
			type: "",
		});
	const [selectedScheduleInfo, setSelectedScheduleInfo] =
		useState<Schedule | null>();
	const [isApplyOptionModalOpen, setIsApplyOptionModalOpen] = useState(false);
	const httpCallback = useRef<((e: string) => Promise<any>) | null>(null);
	const isTpaid = !!selectedBranch.tpaidName;

	const fetchSchedules = async ({
		initialLoad = false,
	}: {
		initialLoad?: boolean;
	} = {}) => {
		try {
			const { data } = await getSchedules(
				selectedBranch.branchId,
				selectedBranch.tpaid
			);
			setSchedules(data.schedules ?? []);
			if (!initialLoad) {
				setIsOperating(data.is_operating ?? null);
			}
			return data;
		} catch (e) {
			if (axios.isAxiosError(e)) {
				if (e?.response?.status === 403) {
					return;
				}
			}
		}
	};

	const updateBranchAccessControlState = async () => {
		try {
			const data = await fetchSchedules();
			if (selectedBranch.tpaid) {
				dispatch(
					setBranchTerminalStatus({
						branchID: selectedBranch.branchId,
						tpaID: selectedBranch.tpaid,
						status: data.status,
						is_operating: data.is_operating,
					})
				);

				return;
			}

			await fetchBranchTerminals(selectedBranch.branchId);
			dispatch(
				setBranchStatus({
					branchID: selectedBranch.branchId,
					status: data.status,
					enabledTerminals: data?.enabled_terminals ?? 0,
					totalTerminals: data?.total_terminals ?? 0,
					is_operating: data.is_operating,
				})
			);
		} catch (e) {
			console.error(e);
		}
	};

	const handleAddSchedule = requestWrapper(
		showLoader,
		hideLoader,
		async (
			values: FormValues,
			{ resetForm, setErrors }: FormikHelpers<FormValues>,
			applyOption?: string
		) => {
			setErrorMessage(null);

			const url = selectedBranch.tpaid
				? `/v2/branch-access/${selectedBranch.branchId}/terminals/${selectedBranch.tpaid}`
				: `/v3/branch-access/${selectedBranch.branchId}/schedules`;
			const _payload = values.isRecurring
				? {
						recurringDays: values?.recurringDays
							?.map((e) => e.toUpperCase())
							?.join("|"),
				  }
				: {
						startDate: values.startDate?.format("MM/DD/YYYY"),
						endDate: values.endDate?.format("MM/DD/YYYY"),
				  };
			const payload = {
				..._payload,
				startTime: moment(values.startTime).format("HH:mm:ss"),
				endTime: moment(values.endTime).format("HH:mm:ss"),
				isRecurring: values.isRecurring,

				...(applyOption ? { applyOption } : {}),
			};
			try {
				await HTTP.post(url, payload);
				await updateBranchAccessControlState();
				resetForm();
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error?.response?.status === 400) {
						const message = selectedBranch.tpaid
							? error.response.data.errors
							: error.response.data.message;

						if (
							error.response?.data?.errors?.type ==
							conflictScheduleErrorType
						) {
							setIsApplyOptionModalOpen(true);
							httpCallback.current = (applyOption: string) =>
								handleAddSchedule(
									values,
									{
										resetForm,
										setErrors,
									},
									applyOption
								);
							return;
						}
						setErrors({ startTime: message, endTime: message });
						setErrorMessage(message);
					} else if (error?.response?.status === 403) {
						//to stop GET call if no add permission
						return;
					}
				}
			}
		}
	);

	const handleEditSchedule = requestWrapper(
		showLoader,
		hideLoader,
		async (
			values: FormValues,
			{ resetForm, setErrors }: FormikHelpers<FormValues>,
			setErrorMessage: any,
			applyOption?: string
		) => {
			const url = selectedBranch.tpaid
				? `/v3/branch-access/${selectedBranch.branchId}/terminals/${selectedBranch.tpaid}/schedules/${values.id}`
				: `/v3/branch-access/${selectedBranch.branchId}/schedules/${values.id}`;

			const _payload = values.isRecurring
				? {
						recurringDays: values?.recurringDays
							?.map((e) => e.toUpperCase())
							?.join("|"),
				  }
				: {
						startDate: moment(values.startDate).format(
							"MM/DD/YYYY"
						),
						endDate: moment(values.endDate).format("MM/DD/YYYY"),
				  };
			const payload = {
				..._payload,
				startTime: moment(values.startTime).format("HH:mm:ss"),
				endTime: moment(values.endTime).format("HH:mm:ss"),
				isRecurring: values.isRecurring,

				...(applyOption ? { applyOption } : {}),
			};
			try {
				await HTTP.put(url, payload);
				await updateBranchAccessControlState();
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error?.response?.status === 400) {
						const message = error.response.data.message;

						if (
							error.response?.data?.errors?.type ==
							conflictScheduleErrorType
						) {
							setIsApplyOptionModalOpen(true);
							httpCallback.current = (applyOption: string) =>
								handleEditSchedule(
									values,
									{
										resetForm,
										setErrors,
									},
									setErrorMessage,
									applyOption
								);
							return;
						}
						setErrors({ startTime: message, endTime: message });
						setErrorMessage(message);
					} else if (error?.response?.status === 403) {
						//to stop GET call if no add permission
						return;
					}
				}
			}
		}
	);

	const handleDeleteSchedule = requestWrapper(
		showLoader,
		hideLoader,
		async (scheduleId: number) => {
			try {
				const url = selectedBranch.tpaid
					? `/v3/branch-access/${selectedBranch.branchId}/terminals/${selectedBranch.tpaid}/schedules/${scheduleId}`
					: `/v3/branch-access/${selectedBranch.branchId}/schedules/${scheduleId}`;
				await HTTP.delete(url);

				await updateBranchAccessControlState();
			} catch (error) {
				if (axios.isAxiosError(error))
					if (error?.response?.status === 403) {
						//to stop GET call if no add permission
						return;
					}
			}
		}
	);

	const handleToggleSchedule = requestWrapper(
		showLoader,
		hideLoader,
		async (scheduleId: number, scheduleStatus: string) => {
			const url = selectedBranch.tpaid
				? `/v3/branch-access/${selectedBranch.branchId}/terminals/${selectedBranch.tpaid}/schedules/${scheduleId}/set-status`
				: `/v3/branch-access/${selectedBranch.branchId}/schedules/${scheduleId}/set-status`;
			try {
				const payload = {
					status: scheduleStatus === "ACTIVE" ? 0 : 1,
				};

				await HTTP.put(url, payload);
				await updateBranchAccessControlState();

				const status =
					scheduleStatus === "ACTIVE" ? "Disabled" : "Enabled";
				const dateSchedule = formatDateSchedule({
					is_recurring: selectedScheduleInfo?.is_recurring ?? false,
					recurring_days: selectedScheduleInfo?.recurring_days ?? "",
					start_date: selectedScheduleInfo?.start_date ?? null,
					end_date: selectedScheduleInfo?.end_date ?? null,
				});
				const timeSchedule = formatTimeSchedule({
					start_time: selectedScheduleInfo?.start_time ?? "",
					end_time: selectedScheduleInfo?.end_time ?? "",
				});

				setSuccessModalInfo({
					title: `Schedule ${status}!`,
					message: `<b>${timeSchedule} (${dateSchedule})</b> schedule successfully ${status.toLowerCase()}!`,
					isOpen: true,
					fullWidth: false,
				});
			} catch (error) {
				if (axios.isAxiosError(error))
					if (error?.response?.status === 403) {
						//to stop GET call if no add permission
						return;
					}
			}
		}
	);

	useEffect(() => {
		if (!isOpen) {
			setErrorMessage(null);
			setIsOperating(null);
			setSchedules([]);
		}
	}, [isOpen]);

	useEffect(() => {
		if (selectedBranch.branchId && isOpen) {
			(async () => {
				showLoader();
				try {
					await fetchSchedules({ initialLoad: true });
				} catch (error) {
					console.log(error);
				} finally {
					hideLoader();
				}
			})();
		}
	}, [selectedBranch, isOpen]);

	return (
		<>
			<div>
				<DialogWithStyle
					disableBackdropClick
					open={isOpen}
					maxWidth="lg"
				>
					<DialogTitle
						id="customized-dialog-title"
						onClose={handleClose}
					/>
					<DialogContent className={styles.modalContent}>
						<ModalTitle
							branchName={selectedBranch.branchName}
							tpaidName={selectedBranch.tpaidName}
							isOperating={
								isOperating === null
									? selectedBranch.isOperating
									: isOperating
							}
							hasSchedule={schedules.length > 0}
						/>
						<ScheduleFormContainer
							isTpaid={isTpaid}
							handleAdd={handleAddSchedule}
							errorMessage={errorMessage}
							setErrorMessage={setErrorMessage}
						/>
						<OperatingSchedule
							schedules={schedules}
							isTpaid={isTpaid}
							handleEditSchedule={handleEditSchedule}
							setConfirmationModalInfo={setConfirmationModalInfo}
							setSelectedScheduleInfo={setSelectedScheduleInfo}
						/>
					</DialogContent>
				</DialogWithStyle>
			</div>
			<ConfirmationModal
				isOpen={confirmationModalInfo.isOpen}
				title={confirmationModalInfo.title}
				content={confirmationModalInfo.content}
				onClickCancel={() =>
					setConfirmationModalInfo((val) => ({
						...val,
						isOpen: false,
					}))
				}
				onClickConfirm={() => {
					setConfirmationModalInfo((val) => ({
						...val,
						isOpen: false,
					}));
					if (
						confirmationModalInfo.type ===
						confirmationModalType.DELETE_SCHEDULE
					) {
						handleDeleteSchedule(selectedScheduleInfo?.id ?? 0);
					} else if (
						confirmationModalInfo.type ===
						confirmationModalType.TOGGLE_SCHEDULE
					) {
						handleToggleSchedule(
							selectedScheduleInfo?.id ?? 0,
							selectedScheduleInfo?.status
						);
					}
				}}
			/>
			<AddScheduleOptionModal
				isOpen={isApplyOptionModalOpen}
				branchName={selectedBranch?.branchName}
				onClickConfirm={(value) => {
					setIsApplyOptionModalOpen(false);
					if (httpCallback?.current) {
						httpCallback.current(value);
					}
				}}
				onClickCancel={() => {
					setIsApplyOptionModalOpen(false);
				}}
			/>
		</>
	);
};

export default BranchAccessControlModal;
