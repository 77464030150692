import { FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";
import { styled } from "@material-ui/core";
import textStyles from "../../Remittance/textStyles.module.css";

const StyledSelect = styled(Select)(({ theme }) => ({
	height: "32px",
	padding: "9px 5px",
}));

export const Dropdown = ({
	selectedValue,
	variant,
	text,
	items,
	handleChange,
}) => {
	let objItems = Object.keys(items);
	return (
		<>
			<FormControl fullWidth>
				<StyledSelect
					displayEmpty
					labelId="remittance-select-label"
					id="remittance-select"
					value={selectedValue}
					label={text}
					variant={variant}
					onChange={handleChange}
				>
					<MenuItem disabled value="">
						<p className={textStyles.textStylesNormal1315Poppins}>
							{text}
						</p>
					</MenuItem>
					{items && Array.isArray(items)
						? items?.map((item, i) => {
								return (
									<MenuItem value={item} key={i}>
										<p
											className={
												textStyles.textStylesNormal1315Poppins
											}
										>
											{item}
										</p>
									</MenuItem>
								);
						  })
						: objItems.map((item, i) => {
								return (
									<MenuItem value={item} key={i}>
										<p
											className={
												textStyles.textStylesNormal1315Poppins
											}
										>
											{item}
										</p>
									</MenuItem>
								);
						  })}
				</StyledSelect>
			</FormControl>
		</>
	);
};

export default Dropdown;
