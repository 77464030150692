import IconButton from "@material-ui/core/IconButton";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { isEqual } from "lodash";
import { ReactComponent as ApproveIcon } from "../../../assets/icons/ic-approve.svg";
import { DatePickerFormik } from "../../Inputs/DatePicker";
import { SelectDaysField, FieldTimePicker } from "./BranchAccessControlModal";
import styles from "./EditScheduleForm.module.css";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import schema from "./EditScheduleForm.schema";

import { Schedule, FormValues } from "./types/BrachAccessControlModal";

type EditScheduleProps = {
	schedule: Schedule;
};

interface IEditSchedule {
	handleEditSchedule: (values: any, z: any, x: any) => void;
}

export default function EditSchedule({
	schedule,
	handleEditSchedule,
}: EditScheduleProps & IEditSchedule) {
	const [errorMessage, setErrorMessage] = useState("");
	const startTime = useMemo(
		() => formatTimeToDate(schedule.start_time),
		[schedule.start_time]
	);
	const endTime = useMemo(
		() => formatTimeToDate(schedule.end_time),
		[schedule.end_time]
	);
	const recurringDays = useMemo(
		() =>
			schedule.recurring_days
				?.split("|")
				.map((val) => val.toLowerCase()) || [],
		[schedule.recurring_days]
	);
	const initialValues: FormValues = {
		id: schedule.id,
		startDate: schedule.start_date,
		endDate: schedule.end_date,
		startTime: startTime,
		endTime: endTime,
		isRecurring: schedule.is_recurring,
		recurringDays: recurringDays,
	};

	//ADDED CUSTOM DIRTY CHECKER
	//FORMIK Dirty flag doesn't work well with dates
	const isInitialValuesAndNewValuesEqual = (
		initialValues: FormValues,
		newValues: FormValues
	) => {
		return (
			isEqual(initialValues.recurringDays, newValues.recurringDays) &&
			areDatesEqual(initialValues.startDate, newValues.startDate) &&
			areDatesEqual(initialValues.endDate, newValues.endDate) &&
			areTimesEqual(initialValues.startTime, newValues.startTime) &&
			areTimesEqual(initialValues.endTime, newValues.endTime)
		);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={schema}
				validateOnChange={true}
				validateOnBlur={false}
				onSubmit={(z, x) => handleEditSchedule(z, x, setErrorMessage)}
			>
				{({
					submitForm,
					values,
					isValid,
					errors,
					initialValues,
					setFieldError,
				}) => (
					<>
						{/* DATE RELATED */}
						<div className={styles.scheduleDateContainer}>
							<div className={styles.scheduleDatePicker}>
								{!values.isRecurring ? (
									<>
										<DatePickerFormik
											label="Start Date"
											name="startDate"
											size="small"
											style={{
												width: "172px",
											}}
										/>
										<DatePickerFormik
											label="End Date"
											name="endDate"
											size="small"
											style={{ width: "172px" }}
										/>
									</>
								) : (
									<SelectDaysField
										label="Repeat"
										name="recurringDays"
										value={values.recurringDays ?? []}
									/>
								)}
							</div>
							<div className={styles.scheduleTimePicker}>
								<div>
									<FieldTimePicker
										label="Start Time"
										name="startTime"
										value={values.startTime}
										isValidateError={errorMessage}
									/>
									<FieldTimePicker
										label="End Time"
										name="endTime"
										value={values.endTime}
										isValidateError={errorMessage}
									/>
								</div>

								{errorMessage && (
									<span style={{ color: "red" }}>
										{errorMessage}
									</span>
								)}
							</div>

							<IconButton
								aria-label="save"
								size="small"
								style={{ marginTop: "8px" }}
								onClick={submitForm}
								disabled={
									isInitialValuesAndNewValuesEqual(
										initialValues,
										values
									) || !isValid
								}
							>
								<ApproveIcon
									className={
										isInitialValuesAndNewValuesEqual(
											initialValues,
											values
										) || !isValid
											? styles.approveIconDisable
											: ""
									}
								/>
							</IconButton>
						</div>

						<FormikListener
							values={values}
							callback={() => {
								if (
									errors?.startTime &&
									errors?.endTime &&
									errorMessage
								) {
									setFieldError("startTime", "");
									setFieldError("endTime", "");
									setErrorMessage("");
								}
							}}
						/>
					</>
				)}
			</Formik>
		</LocalizationProvider>
	);
}

//FORM LISTENER TO CHANGE custom error message `setErrorMessage` to empty string(remove error from api)
function FormikListener({
	values,
	callback,
}: {
	values: FormValues;
	callback: () => void;
}) {
	useEffect(() => {
		callback();
	}, [
		values.startDate,
		values.endDate,
		values.startTime,
		values.endTime,
		values?.recurringDays?.length,
	]);

	return null;
}

//CANT MOVE THIS TO DATE UTIL SINCE IT'S A JS FILE
//CONVERTING IT MAY CAUSE AN ISSUE TO OTHER COMPONENTS
function areTimesEqual(time1: moment.Moment, time2: moment.Moment): boolean {
	const momentTime1 = moment(time1, "HH:mm");
	const momentTime2 = moment(time2, "HH:mm");

	return momentTime1.isSame(momentTime2);
}

function areDatesEqual(
	date1: moment.Moment | null | undefined,
	date2: moment.Moment | null | undefined
): boolean {
	if (!date1 && !date2) {
		return true;
	}

	const momentDate1 = moment(date1, "MM-DD-YYYY");
	const momentDate2 = moment(date2, "MM-DD-YYYY");

	return (
		momentDate1.isSame(momentDate2, "year") &&
		momentDate1.isSame(momentDate2, "month") &&
		momentDate1.isSame(momentDate2, "day")
	);
}

//TIME PICKER COMPONENT DOESNT ACCEPT TIME ONLY IT NEEDS TO HAVE DATE TO DISPLAY VALUE PROPERLY
function formatTimeToDate(time: moment.Moment) {
	const momentTime = moment(time, "HH:mm:ss");
	const currentDate = moment();
	currentDate.set({
		hour: momentTime.hour(),
		minute: momentTime.minute(),
		second: momentTime.second(),
	});

	return currentDate;
}
