import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
	container: {
		border: "2px solid #f26122",
		borderRadius: 10,
		padding: "1em",
	},
});

const FilterContainer = ({ children }) => {
	const classes = useStyles();

	return <div className={classes.container}>{children}</div>;
};

FilterContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default FilterContainer;
