import React, { useState, useEffect } from "react";
import CommonTable from "../common/CommonTable/CommonTable";
import HTTP from "../../helpers/ApiClient";
import {
	BranchActions,
	UserListStatus,
	STATUS_BRANCH_ACCESS_CONTROL,
} from "./UserManagement";
import { get } from "lodash";
import styles from "./UserManagement.module.css";
import { useDispatch } from "react-redux";
import { setBranchTerminals } from "../../redux/modules/branchAccessControl";

const headers = (branch_name) => [
	{
		label: "",
		identifier: "",
		className: styles.freeColumn,
	},
	{
		label: "",
		identifier: "",
		width: "10%",
	},
	{
		label: "TPAID",
		identifier: "tpa",
		width: "40%",
	},
	{
		label: "",
		identifier: "status",
		width: "15%",
		render: (value) => (
			<UserListStatus
				status={value}
				list={STATUS_BRANCH_ACCESS_CONTROL}
			/>
		),
	},
	{
		label: "",
		identifier: "status",
		render: (
			value,
			row,
			{ handleOpenBranchModal, showConfirmationModal }
		) => (
			<BranchActions
				row={{
					branchId: row.branch_id,
					branchName: branch_name,
					tpaid: row.id,
					tpaidName: row.tpa,
					status: row.status,
					isOperating: row.is_operating,
				}}
				handleOpen={handleOpenBranchModal}
				showBranchDisableModal={showConfirmationModal}
				status={value}
				isOperating={row.is_operating}
				hasSchedule={row.has_schedule}
				terminals={{
					enabled: row.enabled_terminals,
					total: row.total_terminals,
				}}
				isTerminal
			/>
		),
	},
];

export async function getTerminals(filters, branchId) {
	const resolveParam = (p) => p || undefined;

	try {
		const { search, status } = filters;
		const params = {
			status: resolveParam(status),
			tpa: resolveParam(search),
		};
		const result = await HTTP.get(
			`/v3/branch-access/${branchId}/terminals`,
			{ params }
		);
		const {
			data: { data: items },
		} = result;

		return {
			items,
		};
	} catch (error) {
		console.log(error);
		return {
			items: [],
		};
	}
}

const TPAIDTable = ({
	data: { id, name, tpaList },
	filters,
	rowsPerPage,
	showConfirmationModal,
	handleOpenBranchModal,
	setIsTerminalExpanded,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			const result = await getTerminals(filters, id);
			dispatch(
				setBranchTerminals({
					tpaList: result.items ?? [],
					branchID: id,
				})
			);
		})();

		return () =>
			dispatch(setBranchTerminals({ tpaList: [], branchID: id }));
	}, [filters, rowsPerPage]);

	useEffect(() => {
		setIsTerminalExpanded(id, true);

		return () => setIsTerminalExpanded(id, false);
	}, []);

	return (
		<CommonTable
			headers={headers(name)}
			entries={get(tpaList, "items", [])}
			totalEntries={get(tpaList, "totalCount", 0)}
			sortBy={"tpa"}
			sort={"asc"}
			noPagination={true}
			handleOpenBranchModal={handleOpenBranchModal}
			showConfirmationModal={showConfirmationModal}
			spacing={0}
			isWhiteHeader
			noStripes
			borderless
		/>
	);
};

export default TPAIDTable;
