import { styled } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

export default styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: "#f26122",
		"&:hover": {
			backgroundColor: "rgba(242, 97, 34, 0.2)",
		},
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: "#F6A27E",
	},
}));

export const BranchAccessControlSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		color: "#6F7378",
		"&:hover": {
			backgroundColor: "rgba(183, 183, 183, 0.8)",
		},
	},
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: "#f26122",
		"&:hover": {
			backgroundColor: "rgba(242, 97, 34, 0.2)",
		},
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: "#F6A27E",
	},
}));
