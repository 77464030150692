import { React } from "react";
import { Dialog, Box, DialogActions, Typography } from "@material-ui/core";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import styles from "./SuccessModal.module.css";
import { GreenColorButton } from "../App/App";

const SuccessModal = ({
	title,
	message = "Success!",
	toggleDialog,
	open,
	buttonTitle = "Okay",
	disableScrollLock = false
}) => {
	return (
		<Dialog disableBackdropClick disableScrollLock={disableScrollLock} open={open} className={styles.container}>
			<Box className={styles.iconContainer}>
				<CheckCircleOutlineRoundedIcon className={styles.sucessIcon} />
			</Box>
			<Box>
				<Typography className={styles.modalTitle}>{title}</Typography>
			</Box>
			<Box>
				<Typography className={styles.modalMessage}>
					{message}
				</Typography>
			</Box>
			<DialogActions className={styles.modalActions}>
				<GreenColorButton
					autoFocus
					onClick={toggleDialog}
					color="primary"
					className={styles.button}
				>
					{buttonTitle}
				</GreenColorButton>
			</DialogActions>
		</Dialog>
	);
};

export { SuccessModal };
