import React from "react";
import {
	Link,
	InputLabel,
	Select,
	Tooltip,
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	Typography,
	Divider,
	Grid,
	MenuItem,
	FormControl,
	IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import SystemUpdateIcon from "@material-ui/icons/SystemUpdate";
import downloadLogo from "../../assets/icons/ic-download.svg";
import HTTP from "../../helpers/ApiClient";
import styles from "./CustomRow.module.css";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	primary: {
		color: "#000000",
	},
	typeButton: {
		width: "110px",
		height: "45px",
		marginTop: "10px",
	},
	bottomBorder: {
		borderBottom: "1px #80808082 solid",
	},
}));

export default function NativeSelects({ row, icon }) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [password, setPassword] = React.useState(false);
	const [status, setStatus] = React.useState(false);
	const [openTypeSelector, setTypeSelectorState] = React.useState(false);
	const [type, setType] = React.useState("");

	let selectedRow = row;

	const handleOnClick = () => {
		setPassword("");
		if (type == "pdf") {
			HTTP.get("/v2/reports/download", {
				params: { reportName: selectedRow.filename },
			}).then((resp) => {
				setPassword(resp.data.password);
				window.open(resp.data.url);
			});
		} else if (type == "xls") {
			var newName = selectedRow.filename.slice(0, -4) + "_u.xlsx";
			HTTP.get("/v2/cash-management/session-report-xlsx", {
				params: { reportName: newName },
			}).then((resp) => {
				setPassword(resp.data.password);
				let link = document.createElement("a");
				link.setAttribute("download", resp.data.url);
				link.setAttribute("href", resp.data.url);
				link.click();
			});
		}
		setOpen(true);
		setStatus(true);
		setTimeout(() => {
			setStatus(false);
		}, 10000);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOnFileSelect = (data) => {
		selectedRow = data;
		setTypeSelectorState(true);
	};

	const handleFileSelectClose = (action) => {
		setTypeSelectorState(false);
		setType("");
		if (action == "print") {
			handleOnClick();
		}
	};

	const handleTypeChange = (event) => {
		setType(event.target.value);
	};

	return (
		<>
			{icon === "Print" ? (
				<Tooltip title="Print" aria-label="print">
					<Link className={classes.primary}>
						<PrintIcon />
					</Link>
				</Tooltip>
			) : (
				<Tooltip title="Download" aria-label="download">
					<Link
						className={classes.primary}
						onClick={() => handleOnFileSelect(row)}
					>
						<SystemUpdateIcon />
					</Link>
				</Tooltip>
			)}

			<Dialog
				PaperProps={{ style: { width: "400px" } }}
				onClose={handleClose}
				open={open}
				disableBackdropClick
				disableEscapeKeyDown
			>
				<DialogContent>
					<DialogContentText id="download">
						<img
							src={downloadLogo}
							className={styles.downloadImage}
						/>
						<Typography
							style={{
								font: "normal normal 600 21px Wavehaus-SemiBold",
								textAlign: "center",
								marginTop: "15px",
							}}
						>
							File is being downloaded
						</Typography>
						<Typography
							style={{
								font: "normal normal 300 14px Poppins-Regular",
								textAlign: "center",
								marginTop: "15px",
								color: "#3B3B3B",
							}}
						>
							To open the file, take note of this password:
						</Typography>
						<Typography
							style={{
								font: "normal normal 300 14px Poppins-Regular",
								textAlign: "center",
								color: "#3B3B3B",
							}}
						>
							{password}
						</Typography>
					</DialogContentText>
				</DialogContent>
				<Divider />
				<Button
					onClick={handleClose}
					className={styles.doneButton}
					autoFocus
					disabled={status}
					style={{
						backgroundColor: "#32AA4D",
						color: "#FFFFFF",
						width: "50%",
						left: "25%",
						height: "45px",
						marginTop: "10px",
					}}
				>
					Done
				</Button>
			</Dialog>

			<Dialog
				PaperProps={{ style: { width: "400px", padding: "20px 0px" } }}
				onClose={handleClose}
				open={openTypeSelector}
				disableBackdropClick
				disableEscapeKeyDown
			>
				<Grid spacing={2}>
					<Grid
						container
						justify="space-between"
						spacing={24}
						alignItems="center"
						style={{ padding: "0px 20px" }}
						className={classes.bottomBorder}
					>
						<Grid item>
							<Typography
								variant="h6"
								style={{ fontFamily: "Poppins-Regular" }}
							>
								Download Report
							</Typography>
						</Grid>
						<Grid>
							<IconButton
								aria-label="close"
								className={classes.closeButton}
								onClick={() => handleFileSelectClose("cancel")}
							>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						style={{ padding: "40px 20px" }}
						className={classes.bottomBorder}
					>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="typeLabel">File Format</InputLabel>
							<Select
								labelId="typeLabel"
								id="typeLabel-select"
								label="File Format"
								value={type}
								onChange={handleTypeChange}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									getContentAnchorEl: null,
								}}
							>
								<MenuItem value={"xls"}>XLS</MenuItem>
								<MenuItem value={"pdf"}>PDF</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid
						container
						direction="row"
						justify="flex-end"
						style={{ padding: "0px 20px" }}
					>
						<Button
							onClick={() => handleFileSelectClose("cancel")}
							className={classes.typeButton}
							autoFocus
							disabled={status}
							style={{
								backgroundColor: "white",
								border: "1px #8080804a solid",
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={() => handleFileSelectClose("print")}
							className={classes.typeButton}
							autoFocus
							disabled={status}
							style={{
								backgroundColor: "#32AA4D",
								color: "#FFFFFF",
							}}
						>
							Confirm
						</Button>
					</Grid>
				</Grid>
			</Dialog>
		</>
	);
}
