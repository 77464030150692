import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	transactionType: "",
};

const kycRemittance = createSlice({
	name: "kycRemittance",
	initialState,
	reducers: {
		setTransactionType: (state, { payload }) => {
			state.transactionType = payload;
		},
		loading: (state, payload) => {
			state.loading = true;
		},
		error: (state, payload) => {
			state.isError = true;
			state.error = payload;
		},
	},
	extraReducers: {},
});

const { setTransactionType, loading, error } = kycRemittance.actions;

export default kycRemittance.reducer;

export { setTransactionType };
