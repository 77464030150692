import {
	Dialog,
	DialogContent,
	DialogTitle as MuiDialogTitle,
	Divider,
	Grid,
	IconButton,
	List,
	ListItemText,
	withStyles,
	Button,
	createMuiTheme,
	ThemeProvider,
	Menu,
	MenuItem,
	Tooltip,
} from "@material-ui/core";
import moment from "moment";
import {
	Close as CloseIcon,
	ArrowDropDown,
	Error,
	CheckCircle,
	QueryBuilder,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "./TransactionTable.module.css";
import React, { useEffect } from "react";
import ReprintCodeModal from "./ReprintCodeModal";
import { useToggle } from "../../../utils/hooks";
import { getScopes } from "../../../utils/checkSession";
import { connect } from "react-redux";
import { resetCurrentReprintNotification } from "../../../redux/modules/reprint";
import VoidBadge from "./components/VoidBadge";
import { VoidRequestStatus } from "./VoidRequestModal";

const modalStyles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
		fontFamily: "Poppins-Medium !important",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const TransactionDialog = withStyles({
	paper: {
		width: "55vw",
		padding: "1em",
	},
})(Dialog);

const datePickerTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#0076C0",
		},
	},

	overrides: {
		MuiButton: {
			label: {
				textTransform: "none",
			},
		},
	},
});

const STATUS = [
	{
		code: 0,
		label: "Success",
		className: styles.statusSuccess,
	},
	{
		code: 1,
		label: "Failed",
		className: styles.statusFailed,
	},
	{
		code: 2,
		label: "Processing",
		className: styles.statusProcessing,
	},
	{
		code: 3,
		label: "Re-Processing",
		className: styles.statusReprocessing,
	},
	{
		code: 4,
		label: "Voided",
		className: styles.statusFailed,
	},
];

const MAX_REPRINT_CHECK = 3;

const DialogTitle = withStyles(modalStyles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const TransactionListModal = ({
	open,
	data = {},
	closeThis,
	onClose,
	onReprint,
	reprintNotifList,
	fetchReprintStatus,
	resetCurrentReprintNotification,
	selectedTransactionInfo,
	openVoidRequestModal,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [printType, setPrintType] = React.useState(null);
	const [reprintPermission, setReprintPermission] = React.useState(null);
	const [reprintCheckPermission, setReprintCheckPermission] =
		React.useState(null);
	const [reprintStatus, setReprintStatus] = React.useState(
		data.reprintStatus
	);
	const [reprintCheckStatus, setReprintCheckStatus] = React.useState(
		data.reprintCheckStatus
	);

	// isApprovedFromBE - for upon opening the trans modal / reprintStatus from Appsync
	const isApproved = data.isApprovedFromBE || reprintStatus === "APPROVED";
	const isApprovedCheck = reprintCheckStatus === "APPROVED";

	const [
		isOpenReprintModal,
		openRequestReprintModal,
		closeRequestReprintModal,
	] = useToggle();

	useEffect(() => {
		getScopes().then(({ data }) => {
			if (
				data &&
				data.find((v) => v.scope === "bfa.branch.reprint.check.request")
			) {
				setReprintCheckPermission("reprint_request");
			} else if (
				data &&
				data.find((v) => v.scope === "bfa.branch.reprint.check")
			) {
				setReprintCheckPermission("reprint");
			}

			if (
				data &&
				data.find((v) => v.scope === "bfa.transaction.reprint.request")
			) {
				setReprintPermission("reprint_request");
			} else if (
				data &&
				data.find((v) => v.scope === "bfa.transaction.reprint")
			) {
				setReprintPermission("reprint");
			}
		});
		return () => {
			resetCurrentReprintNotification();
		};
	}, []);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const resolveField = (field, defaultValue = "-") =>
		get(data, field, defaultValue) || defaultValue;

	const reprintCount = resolveField("reprintCount");
	const reprintCheckCount = resolveField("reprintCheckCount");
	const paymentMethod = resolveField("paymentMethod");

	const maxReprintCheckReached = reprintCheckCount >= MAX_REPRINT_CHECK;
	const cannotReprintCheck = paymentMethod === "CASH";

	useEffect(() => {
		setReprintStatus(resolveField("reprintStatus"));
		setReprintCheckStatus(resolveField("reprintCheckStatus"));
	}, [data]);

	useEffect(() => {
		const foundReprintNotif =
			reprintNotifList &&
			reprintNotifList.find((r) => r.txn_no === data.crn);

		if (foundReprintNotif) setReprintStatus(foundReprintNotif.notif_state);
	}, [reprintNotifList]);

	const values = [
		{
			name: "Transaction Date and Time",
			value: moment(resolveField("createdAt")).format(
				"MM/DD/YYYY hh:mm:ss A"
			),
		},
		{
			name: "Transaction Reference No.",
			value: resolveField("trn"),
		},
		{
			name: "Client Reference No.",
			value: resolveField("crn"),
		},
		{
			name: "Channel Name",
			value: resolveField("channel_name"),
		},
		{
			name: "Branch Name",
			value: resolveField("branch_name"),
		},
		{
			name: "TPA ID",
			value: resolveField("tpaid"),
		},
		{
			name: "Biller Name",
			value: resolveField("billerName"),
		},
		{
			name: "Account Number",
			value: resolveField("arn"),
		},
		{
			name: "Mode of Payment",
			value: paymentMethod,
		},
		{
			name: "Cash Amount",
			value: paymentMethod == "CASH" ? resolveField("amount") : "-",
		},
		{
			name: "Check Amount",
			value: paymentMethod == "CHECK" ? resolveField("amount") : "-",
		},
		{
			name: "Convenience Fee",
			value: resolveField("fee"),
		},
		{
			name: "Status",
			className: (status) => {
				const match = STATUS.find(({ label }) => label === status);
				if (!match) return "";
				return match.className;
			},
			value: resolveField("status"),
		},
		{
			name: "Teller",
			value: resolveField("username"),
		},
	];

	const printBayad = (reprintDetails = {}, getFromBE = false) => {
		onReprint("BAYAD", reprintDetails, getFromBE);
		handleClose();
	};

	const printSOA = (reprintDetails = {}, getFromBE = false) => {
		onReprint("SOA", reprintDetails, getFromBE);
		handleClose();
	};

	const printCheck = (reprintDetails = {}, getFromBE = false) => {
		onReprint("CHECK", reprintDetails, getFromBE);
		handleClose();
	};

	const PrintStatusIcons = ({ key, type, permission }) => {
		if (permission === "reprint") return <></>;

		let status;
		if (type === "CHECK") {
			status = reprintCheckStatus;
		} else if (type === "RECEIPT") {
			status = reprintStatus;
		} else {
			return <></>;
		}

		if (
			(isApproved && type === "RECEIPT") ||
			(isApprovedCheck && type === "CHECK")
		)
			return (
				<Tooltip title="Your request to reprint was approved.">
					<CheckCircle
						style={{
							marginLeft: 2,
							fontSize: 14,
							verticalAlign: "middle",
							color: "#28A745",
						}}
						key={key}
					/>
				</Tooltip>
			);
		if (status === "DECLINED" && type === "RECEIPT")
			return (
				<Tooltip title="Your request to reprint the receipt was declined by your supervisor.">
					<Error
						style={{
							marginLeft: 2,
							fontSize: 14,
							verticalAlign: "middle",
							color: "#D72E22",
						}}
						key={key}
					/>
				</Tooltip>
			);
		if (status === "DECLINED" && type === "CHECK")
			return (
				<Tooltip title="Your request to reprint the check was declined by your supervisor.">
					<Error
						style={{
							marginLeft: 2,
							fontSize: 14,
							verticalAlign: "middle",
							color: "#D72E22",
						}}
						key={key}
					/>
				</Tooltip>
			);
		if (status === "PENDING" && type === "RECEIPT")
			return (
				<Tooltip title="Your request to reprint receipt is still for pending approval of your supervisor.">
					<QueryBuilder
						style={{
							marginLeft: 2,
							fontSize: 15,
							verticalAlign: "middle",
							color: "#FFA500",
						}}
						key={key}
					/>
				</Tooltip>
			);
		if (status === "PENDING" && type === "CHECK")
			return (
				<Tooltip title="Your request to reprint check is still for pending approval of your supervisor.">
					<QueryBuilder
						style={{
							marginLeft: 2,
							fontSize: 15,
							verticalAlign: "middle",
							color: "#FFA500",
						}}
						key={key}
					/>
				</Tooltip>
			);
		return <></>;
	};

	return (
		<ThemeProvider theme={datePickerTheme}>
			{" "}
			<TransactionDialog
				onClose={onClose}
				open={open}
				maxWidth="md"
				classes={{ root: styles.modal }}
				fullWidth
			>
				<DialogTitle onClose={onClose}>
					<div className={styles.modalDialogTitle}>
						Transaction Details{" "}
						{selectedTransactionInfo?.void_item?.reference_id && (
							<VoidRequestStatus>
								<li>
									Void Reference ID:{" "}
									<a
										className={styles.voidReference}
										onClick={() => {
											closeThis();
											openVoidRequestModal();
										}}
									>
										{
											selectedTransactionInfo?.void_item
												?.reference_id
										}
									</a>
								</li>
								<li>
									Void Request Status:{" "}
									<VoidBadge
										status={
											selectedTransactionInfo?.void_item
												?.status
										}
									/>
								</li>
							</VoidRequestStatus>
						)}
					</div>
				</DialogTitle>
				<DialogContent>
					<ReprintCodeModal
						currentTransaction={data}
						isOpen={isOpenReprintModal}
						close={closeRequestReprintModal}
						printBayad={printBayad}
						printType={printType}
						printCheck={printCheck}
						fetchReprintStatus={fetchReprintStatus}
					/>
					{data && (
						<List>
							{values.map(({ name, value, className }, i) => (
								<ListItemText key={i}>
									<Grid
										container
										justify="space-between"
										alignContent="flex-start"
									>
										<Grid
											item
											xs
											classes={{
												root: styles.modalLabel,
											}}
										>
											{name}
										</Grid>
										<Grid
											item
											xs
											classes={{
												root: styles.modalValue,
											}}
										>
											<div
												className={
													className
														? className(value)
														: ""
												}
											>
												{value}
											</div>
										</Grid>
									</Grid>
									<Divider />
								</ListItemText>
							))}
						</List>
					)}
					<Grid
						container
						justify="flex-end"
						style={{ marginTop: "2em" }}
						direction="row"
						spacing={2}
					>
						<Grid item>
							<Button variant="outlined" color="primary">
								Preview Check
							</Button>
						</Grid>
						<Grid item direction="column">
							<Grid item xs>
								<Button
									variant="contained"
									color="primary"
									disabled={
										reprintCheckCount >= 3 ||
										cannotReprintCheck ||
										maxReprintCheckReached ||
										!reprintCheckPermission ||
										(reprintCheckPermission ===
											"reprint_request" &&
											reprintCheckStatus === "APPROVED" &&
											!isApprovedCheck)
									}
									onClick={() => {
										if (
											reprintCheckStatus === "APPROVED" ||
											reprintCheckPermission === "reprint"
										) {
											{
												handleClick;
											}
											closeThis();
											onReprint("CHECK");
											return;
										}
										setPrintType("CHECK");
										openRequestReprintModal();
										handleClose();
									}}
								>
									Reprint Check
								</Button>
								<PrintStatusIcons
									key={data.id}
									type={"CHECK"}
									permission={reprintCheckPermission}
								/>
							</Grid>

							<Grid
								item
								style={{
									fontSize: 10,
									color: maxReprintCheckReached
										? "#f26122"
										: "#0076C0",
									fontStyle: "italic",
									marginTop: "1em",
									maxWidth: "120px",
								}}
							>
								{maxReprintCheckReached
									? `*You have reached the maximum number(${MAX_REPRINT_CHECK}) of reprint.`
									: `*${
											reprintCheckCount == "-"
												? "0"
												: reprintCheckCount
									  } of 3 copies printed`}
							</Grid>
						</Grid>
						<Grid item>
							<Button variant="outlined" color="primary">
								Preview Receipt
							</Button>
						</Grid>
						<Grid item direction="column">
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={handleClick}
									aria-controls="simple-menu"
									aria-haspopup="true"
									disabled={
										reprintCount >= 3 ||
										!reprintPermission ||
										data.status === "Failed" ||
										(reprintPermission ===
											"reprint_request" &&
											reprintStatus === "APPROVED" &&
											!isApproved)

										// isApproved used to override disable if not approve
									}
								>
									Reprint Receipt <ArrowDropDown />
								</Button>
								<PrintStatusIcons
									key={data.id}
									type={"RECEIPT"}
									permission={reprintPermission}
								/>
								<Menu
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={handleClose}
									id="simple-menu"
									keepMounted
								>
									<MenuItem
										onClick={() => {
											if (
												reprintStatus === "APPROVED" ||
												reprintPermission === "reprint"
											) {
												closeThis();
												onReprint("BAYAD");
												return;
											}
											setPrintType("BAYAD");
											openRequestReprintModal();
											handleClose();
										}}
									>
										Bayad Form
									</MenuItem>
									{data.billerName &&
										data.billerName.toUpperCase() ===
											"MERALCO" && (
											<MenuItem
												onClick={() => {
													if (
														reprintStatus ===
															"APPROVED" ||
														reprintPermission ===
															"reprint"
													) {
														closeThis();
														onReprint("SOA");
														return;
													}
													setPrintType("SOA");
													openRequestReprintModal();
													handleClose();
												}}
											>
												SOA
											</MenuItem>
										)}
								</Menu>
							</Grid>
							<Grid
								item
								style={{
									fontSize: 10,
									color: "#0076C0",
									fontStyle: "italic",
									marginTop: "1em",
								}}
							>
								{" "}
								*{reprintCount == "-" ? "0" : reprintCount} of 3
								copies printed{" "}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</TransactionDialog>{" "}
		</ThemeProvider>
	);
};

TransactionListModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onReprint: PropTypes.func,
	data: PropTypes.object,
	selectedTransactionInfo: PropTypes.object,
	openVoidRequestModal: PropTypes.func,
};

TransactionListModal.defaultProps = {
	open: false,
	onClose: () => {},
	onReprint: () => {},
	data: [],
	selectedTransactionInfo: {},
	openVoidRequestModal: () => {},
};

export default connect(
	(state) => ({ reprintNotifList: state.reprint.currentReprintNotification }),
	{ resetCurrentReprintNotification }
)(TransactionListModal);
