import { useEffect, useState } from "react";
import { InputAdornment, styled } from "@mui/material";
import { Container, Grid, Paper, withStyles, Avatar } from "@material-ui/core";

import FormattedTextField from "../Input/FormattedTextField.js";
import Button from "../Input/Buttons";

import { useSelector, useDispatch, connect } from "react-redux";
import { setTransactionType } from "../../../redux/modules/kycBayad";

import imgBayad from "../../../assets/images/bayadId/img-bayad.png";

import sidebarStyles from "./Sidebar.module.css";
import textStyles from "../textStyles.module.css";

const StyledImageGrid = styled(Grid)(({ theme }) => ({
	padding: 0,
	margin: 0,
	width: "100%",
}));

const StyledTextGrid = styled(Grid)(({ theme }) => ({
	margin: "7px 30px 0px 30px",
	textAlign: "justify",
}));

const StyledAvatarGrid = styled(Grid)(({ theme }) => ({
	padding: "0 8px",
}));

const stylingRegisterButton = {
	backgroundColor: "#32AA4D",
	borderColor: "#32AA4D",
	color: "#fff",
	margin: "0 auto",
	borderRadius: "21px",
	padding: "10px 38px",
	marginTop: "5%",
	marginBottom: "12%",
};

const Sidebar = (props) => {
	let dispatch = useDispatch();

	const handleChangeView = (navigate) => {
		dispatch(setTransactionType(navigate));
	};

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			<StyledImageGrid>
				<img
					src={imgBayad}
					className={sidebarStyles.imgSidebar}
					alt="logo"
				/>
			</StyledImageGrid>
			<StyledTextGrid>
				<FormattedTextField
					className={textStyles.textStylesBold1619WavehausBold}
				>
					Welcome to Bayad ID
				</FormattedTextField>
			</StyledTextGrid>
			<StyledTextGrid>
				<FormattedTextField
					className={textStyles.textStylesNormal1117Poppins}
				>
					Welcome to Bayad ID where you can register new customer to
					engage on their transactions. This ID serve as a proof of
					identification and serve as basic KYC for all ends.
				</FormattedTextField>
			</StyledTextGrid>
			<StyledTextGrid>
				<Button
					variant="outlined"
					text="Register"
					styling={stylingRegisterButton}
					handleOnClick={() => {
						handleChangeView("Register");
					}}
				/>
			</StyledTextGrid>
		</Grid>
	);
};

export default connect(
	(state) => ({ transactionType: state.kycRemittance.transactionType }),
	{ setTransactionType }
)(Sidebar);
