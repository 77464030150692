import React, { useState, useEffect } from "react";
import {
	FormControl,
	styled,
	TextField,
	Checkbox,
	Container,
} from "@material-ui/core";
import { Stack, Box } from "@mui/material";
import { useSelector, useDispatch, connect } from "react-redux";

import textStyle from "../textStyles.module.css";

import cashoutIcon from "../../../assets/icons/ic-cashout.png";
import sadIcon from "../../../assets/icons/ic-sad.png";
import Button from "../Input/Buttons";
import InputField from "../Input/InputField";
import CustomerInformation from "./CustomerInformation";
import Dropdown from "../Input/Dropdown";

import {
	submitCashoutInformation,
	onCheck,
	cashoutInformationActionCreator,
	setPartnerCode,
} from "../../../redux/modules/cashoutInformation";

import { setDocumentOptions } from "../../../redux/modules/kycPartnerList";

const StyledStack = styled(Stack)(({ theme }) => ({
	margin: "auto 15%",
	marginBottom: "10%",
	display: "block",
	// textAlign: "center",
	"& .cashout-header": {
		marginBottom: "20px",
		textAlign: "center",
	},
	"& img": {
		width: "53.95px",
		height: "auto",
		display: "block",
		margin: "auto",
	},
	"& .divider": {
		margin: "10px 15%",
	},
	"& .remittance-cashout-textfield": {
		marginTop: "7.5px",
		marginBottom: "7.5px",
		font: "normal normal normal 14px/21px Poppins-Regular",
	},
	"& #remittance-cashout-noinfo": {
		margin: "10% 0",
		"& img": {
			width: "109px",
			height: "auto",
			marginBottom: "15px",
		},
	},
	"& #remittance-cashout-info": {
		margin: "2% 0",
		"& p": {
			textAlign: "left",
			marginLeft: "0",
			width: "33.33%",
			font: "normal normal normal 13px/20px Poppins-Regular",
		},
		"& .remittance-cashout-input": {
			marginTop: "20px",
			width: "33.33%",
			height: "42px",
		},
		"& .remittance-cashout-accordion": {
			margin: "0px",
		},
	},
	"& .cashout-error-text": {
		color: "#D93025",
		textAlign: "left",
	},
}));

const stylingSearchBar = {
	borderRadius: "21px",
	border: "0.5px solid #3B3B3B",
	letterSpacing: "0px",
	color: "#3B3B3B",
	margin: "0 20%",
};

const stylingCancelButton = {
	backgroundColor: "#959595",
	textTransform: "capitalize",
	fontFamily: "normal normal normal 12px/18px Poppins-Regular",
};

const stylingValidateButton = {
	backgroundColor: "#32AA4D",
	textTransform: "capitalize",
	fontFamily: "normal normal normal 12px/18px Poppins-Regular",
};

const stylingCheckButton = {
	backgroundColor: "#32AA4D",
	textTransform: "capitalize",
	color: "#fff",
	width: "100%",
	fontFamily: "normal normal normal 12px/18px Poppins-Regular",
};

const disabledBtn = {
	backgroundColor: "#32AA4D",
	textTransform: "capitalize",
	opacity: "0.5",
	color: "#fff",
	width: "100%",
	fontFamily: "normal normal normal 12px/18px Poppins-Regular",
};

const Divider = ({ color, opacity }) => (
	<hr
		style={{
			backgroundColor: color,
			height: ".5px",
			width: "100%",
			opacity: opacity ? opacity : 1,
		}}
	/>
);

const CustomContainer = styled(Container)(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-start",
	"& p": {
		marginTop: "6px",
	},
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CashOutInformation = (props) => {
	let dispatch = useDispatch();

	const [formPayload, setFormPayload] = useState("");
	const [dropdownVal, setDropDownVal] = useState("");

	const handleChange = (e) => {
		const value = e.target.value;

		setFormPayload({
			...formPayload,
			[e.target.name]: value,
		});
	};

	const handleDropdownChange = (e) => {
		const value = e.target.value;
		setDropDownVal(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let payload = {
			referenceNumber: formPayload.referenceNumber,
			bayadId: formPayload.bayadId,
			amount: formPayload.amount,
			otherInfo: {
				pin: formPayload["otherInfo.pin"],
				documentType: dropdownVal,
				documentNumber: formPayload["otherInfo.documentNumber"],
			},
		};
		dispatch(submitCashoutInformation(payload));
		dispatch(cashoutInformationActionCreator());
		// check dispatch here
	};

	const handleCheckBox = (e) => {
		dispatch(onCheck(e.target.checked));
	};

	const ruleFilter = (obj) => {
		const keys = Object.keys(obj).filter((x) => x.includes("in:"));
		const docu = obj[keys] != undefined ? obj[keys].options : null;
		if (obj[keys] != undefined) {
			dispatch(setDocumentOptions(docu));
		}

		return keys.length;
	};

	useEffect(() => {
		dispatch(setPartnerCode(props.partnerCode));
	}, [props.transactionDetails]);

	return (
		<>
			<StyledStack spacing={2}>
				<FormControl id="remittance-cashout-form" fullWidth>
					<Stack>
						<img src={cashoutIcon} />
						<p
							className={`${textStyle.textStyle1619WavehausSemi} cashout-header`}
						>
							Cash Out - {props.selectedPartner.name}
						</p>

						<Stack
							direction="column"
							alignItems="center"
							flexDirection="center"
							spacing={2}
						>
							{props.partnerFields?.map((input, i) => {
								return (
									<React.Fragment key={i}>
										{ruleFilter(input.rules) > 0 ? (
											<Dropdown
												text="Select ID"
												items={
													props.selectedPartnerDocument
												}
												handleChange={
													handleDropdownChange
												}
												selectedValue={dropdownVal}
												variant="outlined"
											/>
										) : (
											<InputField
												className={
													textStyle.textStylesNormal1421Poppins
												}
												id={input.name}
												label={input.label}
												type="text"
												name={input.name}
												onChange={handleChange}
											/>
										)}
										{/* 
										<p
											className={`${textStyle.textStylesNormal1016Poppins} cashout-error-text`}
										>
											Enter 10 Digit Contract Number
										</p> */}
									</React.Fragment>
								);
							})}
							<Button
								type="submit"
								variant="outlined"
								text="SUBMIT"
								styling={
									props.isDisabled
										? disabledBtn
										: stylingCheckButton
								}
								handleOnClick={handleSubmit}
								radius={true}
								isDisabled={props.isDisabled}
								isLoading={props.isLoading}
							/>
						</Stack>

						{/* {Object.keys(props.transactionDetails).length === 0 ? (
							<Stack spacing={2} id="remittance-cashout-noinfo">
								<img src={sadIcon} />
								<p
									className={
										textStyle.textStyle1619WavehausSemi
									}
								>
									We don't find your transaction.
								</p>
								<p
									className={
										textStyle.textStylesNormal1218Poppins
									}
								>
									Check your details. Please try again.
								</p>
							</Stack>
						) : (
							<DynamicAccordion
								accordionSummary="Transaction Details"
								className="remittance-cashout-accordion"
							>
								<CustomerInformation />
							</DynamicAccordion>
						)}
						 */}
					</Stack>
				</FormControl>
			</StyledStack>
			<Divider color="black" opacity=".3" />
			<CustomContainer>
				<Checkbox {...label} onChange={handleCheckBox} />
				<p className={textStyle.textStylesMedium1014Poppins}>
					I guarantee that the customer has provided a written consent
					to share their data for the sole purpose of sending a
					receipt and/or payment confirmation via email and SMS and
					that I have duly informed the customer of the purpose for
					collecting their data. I agree that the data collected
					should be treated with confidentiality and will not
					use/distribute the data beyond its stated purpose.
				</p>
			</CustomContainer>
		</>
	);
};

export default connect(
	(state) => ({
		isDisabled: state.cashoutInformation.isDisabled,
		transactionDetails: state.cashoutInformation.transactionDetails,
		isLoading: state.cashoutInformation.isLoading,
		partnerFields: state.kycPartnerList.selectedPartnerParams,
		selectedPartner: state.kycPartnerList.selectedPartner,
		selectedPartnerDocument: state.kycPartnerList.selectedPartnerDocument,
		partnerCode: state.kycPartnerList.partnerCode,
	}),
	{
		submitCashoutInformation,
		onCheck,
		setDocumentOptions,
		cashoutInformationActionCreator,
		setPartnerCode,
	}
)(CashOutInformation);
