import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
// A custom theme for this app
const theme = createMuiTheme({
	palette: {
		type: "light",
		primary: {
			main: "#F16124",
			light: "#F6A07B",
			dark: "#F16124",
		},
		secondary: {
			main: "#F6A07B",
			light: "#F6A07B",
			dark: "#F16124",
		},
		error: {
			main: red.A400,
		},
		background: {
			default: "#F1F4F8",
		},
	},
	overrides: {
		MuiPaper: {
			root: {
				padding: "20px 10px",
				margin: "10px",
				backgroundColor: "#fff", // 5d737e
			},
		},
		MuiButton: {
			root: {
				margin: "5px",
				textTransform: "none",
				padding: "12px 16px",
			},
			label: {
				font: "normal normal normal 14px/21px Poppins-Light",
			},
		},
		MuiAutocomplete: { listbox: { maxHeight: "auto", height: "23.5em" } },
	},
});
export default theme;
