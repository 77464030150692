import styles from "./LoginErrorRedirect.module.css";
import loginErrorLogo from "../../assets/icons/img-login-error.png"
import { Container, Grid, Typography } from "@material-ui/core";

const LoginErrorRedirect = () => {
	return (
		<>
      <Container maxWidth="xl" disableGutters={true} className={styles.divContainer} >
        <Grid container component="main" className={styles.root} direction="column" justify="center" alignItems="center" >
          <Grid item xs={12}>
		  	<img src={loginErrorLogo} alt="icon" className={styles.img} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={styles.title}>Session not found.</Typography>
          </Grid>
          <Grid item className={styles.textLeft}>
            <Grid container className={styles.cardContainer} direction="column" spacing={2}>
              <Grid item xs={12}>
                <Typography className={styles.subtitle}>
                  Here are the things you can do:
                </Typography>
                <Typography className={styles.contentText}>
                  1. Close this tab then open another one.
                </Typography>
                <Typography className={styles.contentText}>
                  2. Clear cache/cookies.
                </Typography>
                <Typography className={styles.contentText}>
                  3. Access the page via incognito.
                </Typography>
                <Typography className={styles.contentText}>
                  4. Change browser.
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} className={styles.textLeft}>
                <Typography className={styles.subtitle}>
                  If the problem still persists, you may reach
                </Typography>
                <Typography className={styles.subtitle}>
                  out to <a className={styles.mail} href='mailto:helpdesk@bayad.com'>helpdesk@bayad.com</a>. Thank you!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
	);
};

export default LoginErrorRedirect;
