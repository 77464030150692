import HTTP from "../../helpers/ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

type TSummaryPayload = {
	tpaId: string;
};

type TSummary = {
	tpa_id: string;
	current_date_start_time: string;
	current_date_end_time: string;
	count_transaction: number;
	count_by_payment_method: {
		[key: string]: number;
	};
	total_amount: number | null;
};

const fmt = (num: number, decimal = 0) =>
	num.toLocaleString("en-US", { minimumFractionDigits: decimal });

const getTransactionsSummaryRequest = async ({ tpaId }: TSummaryPayload) => {
	const res = await HTTP.get(
		`/v3/transaction/transaction-summary-header/${tpaId}`
	);
	const data: TSummary = res.data;
	return [
		{
			name: "No. of Transactions",
			value: fmt(data.count_transaction ?? 0),
		},
		{
			name: "Total no. of CHECK",
			value: fmt(data.count_by_payment_method.CHECK ?? 0),
		},
		{
			name: "Total no. of CASH",
			value: fmt(data.count_by_payment_method.CASH ?? 0),
		},
		{
			name: "Total no. of CASH + CHECK",
			value: fmt(data.count_by_payment_method["CASH+CHECK"] ?? 0),
		},
		{
			name: "Total Amount",
			value: fmt(data.total_amount ?? 0, 2),
		},
	];
};

export const getTransactionsSummary = createAsyncThunk(
	"transaction/getTransactionsSummary",
	async (params: TSummaryPayload) => {
		const data = await getTransactionsSummaryRequest(params);
		return data;
	}
);
