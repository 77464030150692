import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
	/* circle progress bar position */
	MuiCircularProgressRoot: {
		position: "relative",
		height: "50px !important",
		width: "50px !important",
		left: "8.2rem",
		top: "-0.7rem",
	},
}));

function CircularProgressWithLabel(props) {
	const classes = useStyles();
	return (
		<div>
			<Box
				display="inline-flex"
				alignContent="center"
				alignItems="center"
			>
				<CircularProgress
					className={classes.MuiCircularProgressRoot}
					variant="determinate"
					{...props}
				/>
				<Box
					top={30}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography
						variant="caption"
						component="div"
						color="white"
					>{`${Math.round(props.value)}%`}</Typography>
				</Box>
			</Box>
		</div>
	);
}

CircularProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 */
	value: PropTypes.number.isRequired,
};

export default function CircularStatic(props) {
	const classes = useStyles();
	const [progress, setProgress] = React.useState(0);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) =>
				props.isRequestSuccess == true ? 100 : prevProgress + 1
			);
		}, 650);
		return () => {
			clearInterval(timer);
		};
	}, []);

	return <CircularProgressWithLabel value={progress} />;
}
