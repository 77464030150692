import { Component } from "react";
import Typography from "@material-ui/core/Typography";
import {
	Box,
	Button,
	Menu,
	Dialog,
	Grid,
	TextField,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import logoutIcon from "../../assets/icons/ic-logout.svg";
import styles from "./Logout.module.css";
import HTTP from "../../helpers/ApiClient";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { clearSessionStorage, setSessionStorage } from "../../utils/storage";
import {
	RefreshTokenUtil,
	TerminalUtil,
	TokenUtil,
	UserInfoUtil,
} from "../../utils/checkSession";
import {
	hideEndSession,
	toggleUserLoggingOut,
} from "../../redux/modules/timeout";

import { getCurrentDate } from "../../components/App/App";

class Logout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null,
			isDialogOpen: false,
			oneThousand: null,
			fiveHundred: null,
			twoHundred: null,
			oneHundred: null,
			fifty: null,
			twenty: null,
			ten: null,
			five: null,
			one: null,
			twentyFiveCents: null,
			tenCents: null,
			fiveCents: null,
			oneCent: null,
			isSubmitDisabled: true,
			totalAmount: null,
			path: null,
			isButtonsDisabled: false,
			isTemporaryDialogOpen: false,
			oneThousandValue: "",
			fiveHundredValue: "",
			twoHundredValue: "",
			oneHundredValue: "",
			fiftyValue: "",
			twentyValue: "",
			tenValue: "",
			fiveValue: "",
			oneValue: "",
			twentyFiveCentsValue: "",
			tenCentsValue: "",
			fiveCentsValue: "",
			oneCentValue: "",
			action: "",
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
		this.submitButtonState = this.submitButtonState.bind(this);
		this.handleTemporaryLogout = this.handleTemporaryLogout.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleRefreshToken = this.handleRefreshToken.bind(this);
		this.denomName = [
			"1000",
			"500",
			"200",
			"100",
			"50",
			"20",
			"10",
			"5",
			"1",
			".25",
			".10",
			".05",
			".01",
		];
		this.stateKey = [
			"oneThousand",
			"fiveHundred",
			"twoHundred",
			"oneHundred",
			"fifty",
			"twenty",
			"ten",
			"five",
			"one",
			"twentyFiveCents",
			"tenCents",
			"fiveCents",
			"oneCent",
		];
		this.denomValue = [
			{ oneThousand: 1000 },
			{ fiveHundred: 500 },
			{ twoHundred: 200 },
			{ oneHundred: 100 },
			{ fifty: 50 },
			{ twenty: 20 },
			{ ten: 10 },
			{ five: 5 },
			{ one: 1 },
			{ twentyFiveCents: 0.25 },
			{ tenCents: 0.1 },
			{ fiveCents: 0.05 },
			{ oneCent: 0.01 },
		];
	}

	componentDidUpdate(prevProps) {
		if (this.props.endSession !== prevProps.endSession) {
			if (this.props.endSession) {
				this.handleOpenModal("endSession");
				this.props.hideEndSession();
			}
		}
	}

	handleOpenModal(type) {
		let actionType = "End Session Logout";
		if (type === "turnOverSession") {
			actionType = "Turn Over Session Logout";
		}

		this.setState({
			isDialogOpen: true,
			action: actionType,
		});

		//TODO Modify once actual component exist
		if (type === "endSession") {
			this.setState({
				path: "/cash-management",
			});
		} else if (type === "turnOverSession") {
			this.setState({
				path: "/turnOverSession",
			});
		}
	}
	async handleRefreshToken() {
		const refreshToken = RefreshTokenUtil.get();
		const req = JSON.stringify({
			grant_type: "refresh_token",
			refresh_token: refreshToken,
			client_id: process.env.REACT_APP_APP_KEY,
			scope: "",
		});
		const getToken = await HTTP.post(
			process.env.REACT_APP_ACCESS_TOKEN_URL,
			req
		);
		const data = getToken.data;

		TokenUtil.set(data["access_token"]);
		RefreshTokenUtil.set(data["refresh_token"]);

		let userInfo = UserInfoUtil.get();

		HTTP.put(
			"/v2/users/" + userInfo["uuid"] + "/sessions/",
			JSON.stringify({ token: TokenUtil.get() })
		).then(() => {
			this.setState({
				isTemporaryDialogOpen: false,
			});
		});
	}
	handleTemporaryLogout() {
		this.setState({
			isTemporaryDialogOpen: true,
		});
	}

	handleLogout() {
		const userDetails = UserInfoUtil.get();
		const terminal = TerminalUtil.get();

		let req = {
			token: TokenUtil.get(),
			fullname: userDetails.firstname + " " + userDetails.lastname,
			username: userDetails.username,
			tpaid: terminal.id,
			action: "Temporary Logout",
		};

		const body = {
			user_id: userDetails.uuid,
			action: "Temporary Logout",
			owner_id: userDetails.roles[0].platform.id,
			owner_type: userDetails.uamRole,
			created_at: getCurrentDate(),
			updated_at: getCurrentDate(),
			new_values: "{}",
			old_values: "{}",
			source_ip: "",
		};

		HTTP.post("/v2/user/logout", body)
			.then(() => {
				clearSessionStorage();
				this.props.history.push("/");
				window.location.reload();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleClick(event) {
		this.setState({
			anchorEl: event.currentTarget,
		});

		let paramsVal = {
			tpaId: this.props.terminalName,
			status: "PENDING",
		};
		HTTP.get("/v2/receipts", { params: paramsVal })
			.then((resp) => {
				if (
					resp.data.data.txn_receipts.length === 0 &&
					resp.data.data.check_receipts.length === 0
				) {
					this.setState({
						isButtonsDisabled: false,
					});
				} else {
					this.setState({
						isButtonsDisabled: true,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleClose() {
		this.setState({
			anchorEl: null,
		});
	}

	handleBack() {
		this.setState({
			isDialogOpen: false,
		});
		setSessionStorage("logout", 0);
	}

	handleSubmit() {
		const userDetails = UserInfoUtil.get();
		const terminal = TerminalUtil.get();
		let body = {
			oneThousand:
				parseFloat(this.state.oneThousand.replace(/,/g, "")) / 1000,
			fiveHundred:
				parseFloat(this.state.fiveHundred.replace(/,/g, "")) / 500,
			twoHundred:
				parseFloat(this.state.twoHundred.replace(/,/g, "")) / 200,
			oneHundred:
				parseFloat(this.state.oneHundred.replace(/,/g, "")) / 100,
			fifty: parseFloat(this.state.fifty.replace(/,/g, "")) / 50,
			twenty: parseFloat(this.state.twenty.replace(/,/g, "")) / 20,
			ten: parseFloat(this.state.ten.replace(/,/g, "")) / 10,
			five: parseFloat(this.state.five.replace(/,/g, "")) / 5,
			one: parseFloat(this.state.one.replace(/,/g, "")) / 1,
			twentyFiveCents:
				parseFloat(this.state.twentyFiveCents.replace(/,/g, "")) / 0.25,
			tenCents: parseFloat(this.state.tenCents.replace(/,/g, "")) / 0.1,
			fiveCents:
				parseFloat(this.state.fiveCents.replace(/,/g, "")) / 0.05,
			oneCent: parseFloat(this.state.oneCent.replace(/,/g, "")) / 0.01,
			fullname: userDetails.firstname + " " + userDetails.lastname,
			username: userDetails.username,
			tpaid: terminal.id,
			action: this.state.action,
		};

		let denomValues = {
			oneThousand:
				parseFloat(this.state.oneThousand.replace(/,/g, "")) / 1000,
			fiveHundred:
				parseFloat(this.state.fiveHundred.replace(/,/g, "")) / 500,
			twoHundred:
				parseFloat(this.state.twoHundred.replace(/,/g, "")) / 200,
			oneHundred:
				parseFloat(this.state.oneHundred.replace(/,/g, "")) / 100,
			fifty: parseFloat(this.state.fifty.replace(/,/g, "")) / 50,
			twenty: parseFloat(this.state.twenty.replace(/,/g, "")) / 20,
			ten: parseFloat(this.state.ten.replace(/,/g, "")) / 10,
			five: parseFloat(this.state.five.replace(/,/g, "")) / 5,
			one: parseFloat(this.state.one.replace(/,/g, "")) / 1,
			twentyFiveCents: parseFloat(
				this.state.twentyFiveCents / 0.25
			).toFixed(0),
			tenCents: parseFloat(this.state.tenCents / 0.1).toFixed(0),
			fiveCents: parseFloat(this.state.fiveCents / 0.05).toFixed(0),
			oneCent: parseFloat(this.state.oneCent / 0.01).toFixed(0),
		};
		Promise.all([HTTP.post("/v2/cash_denomination", body)])
			.then(() => {
				setSessionStorage("logout", "endSession");
				setSessionStorage("cashEnd", this.state.totalAmount);
				setSessionStorage("encode", denomValues);
				this.props.history.push(this.state.path);
				this.props.toggleUserLoggingOut();
				window.location.reload();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleOnChange(event) {
		const number = /^[0-9\b]+$/;
		var value = event.target.id + "Value";
		if (number.test(event.target.value)) {
			this.setState({
				[value]: event.target.value,
			});
		} else {
			this.setState({
				[value]: "",
			});
		}

		if (event.target.value >= 0 && event.target.value !== "") {
			this.denomValue.forEach((value) => {
				if (event.target.id === Object.keys(value).toString()) {
					this.setState(
						{
							[Object.keys(value).toString()]: (
								event.target.value *
								[parseFloat(Object.values(value))]
							).toLocaleString(undefined, {
								minimumFractionDigits: 2,
							}),
						},
						() => this.submitButtonState()
					);
				}
			});
		} else {
			this.stateKey.forEach((key) => {
				if (event.target.id === key) {
					this.setState(
						{
							[key]: null,
						},
						() => this.submitButtonState()
					);
				}
			});
		}
	}

	submitButtonState() {
		var fieldCounter = 0;
		var totalAmountComputed = 0;
		this.stateKey.forEach((key) => {
			if (this.state[key] !== null) {
				totalAmountComputed += parseFloat(
					this.state[key].replace(/,/g, "")
				);
				fieldCounter++;
			}
		});
		if (fieldCounter === 13) {
			this.setState({
				isSubmitDisabled: false,
				totalAmount: parseFloat(
					totalAmountComputed.toFixed(2)
				).toLocaleString(undefined, { minimumFractionDigits: 2 }),
			});
		} else {
			this.setState({
				isSubmitDisabled: true,
				totalAmount: 0,
			});
		}
	}

	render() {
		return (
			<>
				<Button onClick={this.handleClick}>
					<Box display="flex" flexDirection="row" alignItems="center">
						<img src={logoutIcon} alt="logout" />
						<Typography className={styles.textTitle}>
							&nbsp;Logout
						</Typography>
					</Box>
				</Button>

				<Menu
					id="logout-option"
					anchorEl={this.state.anchorEl}
					keepMounted
					open={Boolean(this.state.anchorEl)}
					onClose={this.handleClose}
					style={{ width: "1000px" }}
					PaperProps={{
						style: {
							width: 330,
							height: 350,
							marginTop: 55,
						},
					}}
				>
					<Typography align="center" className={styles.question}>
						Are you sure you want to logout?{" "}
					</Typography>
					<Typography align="center" className={styles.reminder}>
						You have a running session.
					</Typography>

					<Button
						onClick={this.handleTemporaryLogout}
						className={styles.logoutButtons}
						disabled={this.state.isButtonsDisabled}
					>
						Temporary Logout
					</Button>
					<Button
						onClick={() => this.handleOpenModal("turnOverSession")}
						className={styles.logoutButtons}
						disabled={this.state.isButtonsDisabled}
					>
						Turn Over Session
					</Button>
					<Button
						onClick={() => this.handleOpenModal("endSession")}
						className={styles.logoutButtons}
						disabled={this.state.isButtonsDisabled}
					>
						End Session
					</Button>
				</Menu>

				<Dialog
					open={this.state.isDialogOpen}
					className={styles.modalContainer}
					maxWidth="xs"
					hideBackdrop
					PaperProps={{
						style: {
							width: 350,
						},
					}}
				>
					<Grid container spacing={2}>
						<Grid item sm={5}>
							<Typography className={styles.headerTextQuantity}>
								QUANTITY
							</Typography>
							{this.stateKey.map((stateKey, index) => {
								var value = stateKey + "Value";
								return (
									<TextField
										id={stateKey}
										variant="outlined"
										onChange={this.handleOnChange}
										size="small"
										inputProps={{
											min: 0,
											style: {
												textAlign: "center",
												width: 70,
											},
										}}
										className={styles.inputBox}
										value={this.state[value]}
										onKeyDown={this.handleOnKeyDown}
										key={index}
									/>
								);
							})}
						</Grid>

						<Grid item sm={3}>
							<Typography className={styles.headerText}>
								DENOM.
							</Typography>

							{this.denomName.map((denom) => {
								return (
									<Typography
										key={denom}
										className={styles.numberFormat}
									>
										{denom}
									</Typography>
								);
							})}
						</Grid>

						<Grid item sm={3}>
							<Typography className={styles.headerText}>
								AMOUNT
							</Typography>
							{this.stateKey.map((key, index) => {
								return (
									<Typography
										className={styles.numberFormat}
										key={index}
									>
										{this.state[key] !== null
											? this.state[key]
											: 0}
									</Typography>
								);
							})}
						</Grid>

						<Grid item sm={12}>
							<Typography
								align="center"
								className={styles.totalAmount}
							>
								{" "}
								Total Amount:{" "}
								{this.state.totalAmount !== null
									? this.state.totalAmount
									: 0}{" "}
							</Typography>
						</Grid>

						<Grid item sm={12} className={styles.cashButtons}>
							<Button
								disabled={this.state.isSubmitDisabled}
								className={styles.logoutButtons}
								type="submit"
								onClick={this.handleSubmit}
							>
								Submit
							</Button>
						</Grid>

						<Grid item sm={12} className={styles.cashButtons}>
							<Button
								onClick={this.handleBack}
								className={styles.backButton}
							>
								Back
							</Button>
						</Grid>
					</Grid>
				</Dialog>

				<Dialog
					open={this.state.isTemporaryDialogOpen}
					className={styles.tempContainer}
					maxWidth="sm"
				>
					<DialogTitle>
						<Typography className={styles.tempHeader}>
							Temporary Logout
						</Typography>
					</DialogTitle>

					<DialogContent dividers>
						<Typography gutterBottom className={styles.tempBody}>
							You are about to logout temporarily without ending
							your Pay Bills session in{" "}
							<b>TPA-{this.props.terminalName}</b>.
						</Typography>

						<Typography gutterBottom className={styles.tempBody}>
							Do you want to continue?
						</Typography>
					</DialogContent>

					<Grid container style={{ marginLeft: -25 }}>
						<Grid item sm={6}>
							<DialogActions>
								<Button
									className={styles.tempKeep}
									variant="outlined"
									onClick={this.handleRefreshToken}
								>
									<Typography
										style={{
											fontSize: 15,
											fontFamily: "Poppins-Regular",
										}}
									>
										No, Keep Me Logged In
									</Typography>
								</Button>
							</DialogActions>
						</Grid>
						<Grid item sm={6}>
							<DialogActions>
								<Button
									className={styles.tempLogout}
									onClick={this.handleLogout}
								>
									<Typography
										style={{
											fontSize: 15,
											fontFamily: "Poppins-Regular",
										}}
									>
										Yes, Continue Temporary Logout
									</Typography>
								</Button>
							</DialogActions>
						</Grid>
					</Grid>
				</Dialog>
			</>
		);
	}
}

export default connect(
	(state) => ({
		terminalId: state.userInfo.terminal.id,
		terminalName: state.userInfo.terminal.name,
		endSession: state.timeout.endSession,
	}),
	{
		toggleUserLoggingOut,
		hideEndSession,
	}
)(withRouter(Logout));
