import { React } from "react";
import {
	Dialog,
	Box,
	DialogActions,
	Typography,
	Button
} from "@material-ui/core";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import Button from '@mui/material/Button';
import styles from "./DuplicateErrorModal.module.css";

const DuplicateErrorModal = ({ title, message, toggleDialog, open }) => {
	return (
		<Dialog
			disableBackdropClick
			open={open}
			className={styles.container}
		>
			<Box className={styles.iconContainer}>
				<WarningAmberIcon className={styles.duplicateErrorIcon}/>
			</Box>
			<Box>
				<Typography 
					className={styles.modalTitle}
				>
					{title}
				</Typography>
			</Box>
			<Box>
				<Typography 
					className={styles.modalMessage}
				>
					{message}
				</Typography>
			</Box>
			<DialogActions className={styles.modalActions}>
				<Button
					onClick={toggleDialog}
					variant="outlined"
					size={"small"}
					className={styles.button}
				>
					Continue
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export {DuplicateErrorModal};
