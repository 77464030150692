import React from 'react';
import CustomRow from "../../components/Table/CustomRow"
import {createSlice} from "@reduxjs/toolkit";
import "../../components/Table/CustomTable.module.css"


const initialState = {
    search : "",
    sessionReports : [],
    showLoading : false,
    showErrorLoading: false,
    filter: {
        date_time_generated: "",
        type: "",
        filename: "",
      },
}

const sessionReports = createSlice({
    name: "sessionReports",
    initialState,
    reducers: {
        prep: (state, payload) => {
            state.showLoading = true
            state.filter = { ...payload.sessionReportsFields };
        },
        load: (state, payload) => {
          state.showLoading = false
          state.sessionReports = payload.result.result
          state.sessionReports.map(report => {
            report.icon1 = <CustomRow icon={"Print"} downloadData={downloadData} row={report}/>
            report.icon2 = <CustomRow icon={"Download"} downloadData={downloadData} row={report}/>
          })
        },
        error: (state, payload) => {
            state.showErrorLoading = true
        },
    }
})

const getSessionReport = async (client, state) => {
    return await client.get("/v2/cash-management/session-report", {
      params: { ...prepareParams(state.sessionReports.filter)},});
};

const {prep, load , error, filterPrep} = sessionReports.actions;

function getSessionReportsActionCreator(sessionReportsFields) {
    return {
        types: [prep.type, load.type, error.type],
        promise: getSessionReport,
        sessionReportsFields,
    }
}

function downloadData(report) {
  //Insert code to download Row
  console.log(report)
}

function prepareParams(state) {
    let params={}
    if(state.date_time_generated) {
      params={
        ...params,
        "date_time_generated" : state.date_time_generated,
      }
    }
    if(state.type) {
      params={
        ...params,
        "type" : state.type,
      }
    }
    if(state.filename) {
      params={
        ...params,
        "filename" : state.filename,
      }
    }
    return params;
}


  

export default sessionReports.reducer;

export {getSessionReportsActionCreator}