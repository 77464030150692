import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	userInfo: null,
	showInvalidIpModal: false,
	allowSimultaneousLogin: false,
};

const userInfo = createSlice({
	name: "userInfo",
	initialState,
	reducers: {
		showInvalidIpModal: (state) => {
			state.showInvalidIpModal = true;
		},
		hideInvalidIpModal: (state) => {
			state.showInvalidIpModal = false;
		},
		setSimultaneousLogin: (state, { payload }) => {
			state.allowSimultaneousLogin = payload;
		},
	},
});

export const { showInvalidIpModal, hideInvalidIpModal, setSimultaneousLogin } =
	userInfo.actions;
export default userInfo.reducer;
