import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	partnerParams: {},
	partnerCode: "",
	error: "",
	isLoading: false,
	isDisabled: true,
};

const cashoutInformation = createSlice({
	name: "cashoutInformation",
	initialState,
	reducers: {
		prep: (state, payload) => {
			state.isLoading = true;
		},
		load: (state, payload) => {
			state.isLoading = false;
			console.log("_____load", payload);
		},
		fulfilled: (state, payload) => {
			state.isLoading = false;
			console.log("___fulfilled", payload);
		},
		error: (state, payload) => {
			console.log("error", payload);
			state.error = payload;
			state.isLoading = false;
		},
		submitCashoutInformation: (state, action) => {
			state.partnerParams = action.payload;
		},
		onCheck: (state, action) => {
			state.isDisabled = !action.payload;
		},
		setPartnerCode: (state, action) => {
			state.partnerCode = action.payload;
		},
	},
});

const {
	prep,
	error,
	load,
	fulfilled,
	onCheck,
	submitCashoutInformation,
	setPartnerCode,
} = cashoutInformation.actions;

const verifyInfo = async (client, state) => {
	// api here
	return await client.post(
		`/v2/remittance/verify?code=${state.cashoutInformation.partnerCode}`,
		state.cashoutInformation.partnerParams
	);
};

function cashoutInformationActionCreator() {
	return {
		types: [prep.type, load.type, error.type],
		promise: verifyInfo,
	};
}

export default cashoutInformation.reducer;
export {
	cashoutInformationActionCreator,
	onCheck,
	submitCashoutInformation,
	setPartnerCode,
};
