import { Component } from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import styles from "./CashManagement.module.css";

import EndSession from "../Logout/EndSession";
import SessionReport from "../SessionReports/SessionReports";
import BranchReport from "./BranchReport/BranchReport";
import { getSessionStorage } from "../../utils/storage";

class CashManagement extends Component {
	constructor(props) {
		super(props);

		var path = "encodeDeposit";
		let withSession = getSessionStorage("withSession");

		if (getSessionStorage("logout") || withSession == 0) {
			path = "endSession";
		}

		this.state = {
			buttonCliked: path,
		};

		this.handleClick = this.handleClick.bind(this);

		this.stateKey = [
			{ encodeDeposit: "Encode Deposit" },
			{ turnOverSession: "Turn Over Sessions" },
			{ endSession: "End Session" },
			{ sessionReport: "Session Report" },
			{ branchReport: "Branch Report" },
		];

		// Remove encodeDeposit and turnOverSession if no session
		if (withSession === null || withSession == 0) {
			this.stateKey = [
				{ endSession: "End Session" },
				{ sessionReport: "Session Report" },
				{ branchReport: "Branch Report" },
			];
		}
	}

	handleClick(url) {
		this.setState({
			buttonCliked: url,
		});
	}

	render() {
		return (
			<Grid container>
				<Grid item xs={2}>
					<Paper>
						{this.stateKey.map((value) => (
							<Button
								key={Object.keys(value).toString()}
								style={{
									width: "100%",
									justifyContent: "left",
									height: "50px",
								}}
								onClick={() =>
									this.handleClick(
										Object.keys(value).toString()
									)
								}
								className={
									this.state.buttonCliked ===
									Object.keys(value).toString()
										? styles.cashButtons
										: ""
								}
							>
								{Object.values(value).toString()}
							</Button>
						))}
					</Paper>
				</Grid>
				<Grid item xs={10}>
					<Paper>
						<LinkButton type={this.state.buttonCliked} />
					</Paper>
				</Grid>
				<p>Bayad FA version 1.0</p>
			</Grid>
		);
	}
}
function LinkButton({ type }) {
	if (type === "endSession") {
		return <EndSession />;
	} else if (type === "sessionReport") {
		return <SessionReport />;
	} else if (type === "branchReport") {
		return <BranchReport />;
	}
	return "";
}
export default CashManagement;
