import { Button, withStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";

const StyledButton = withStyles({
	label: {
		color: "#0076c0",
	},
})(Button);

const ClearFilterButton = ({ onClear, ...props }) => (
	<StyledButton startIcon={<CancelIcon />} onClick={onClear} {...props}>
		Clear All Filters
	</StyledButton>
);

ClearFilterButton.propTypes = {
	onClear: PropTypes.func,
};

ClearFilterButton.defaultProps = {
	onClear: () => {},
};

export default ClearFilterButton;
