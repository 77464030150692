import { createSlice } from "@reduxjs/toolkit";
import { getSessionStorage } from "../../utils/storage.js";

const initialState = {
	userDetails: {},
	userDetailsResult: {},
	file: null,
	isSuccess: false,
	loading: false,
	isError: false,
	error: "",
};

const kycRegistration = createSlice({
	name: "kycRegistration",
	initialState,
	reducers: {
		setUserDetails: (state, { payload }) => {
			state.userDetails = payload;
		},
		setFile: (state, { payload }) => {
			state.file = payload;
		},
		loading: (state, payload) => {
			state.loading = true;
		},
		fulfilled: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.userDetailsResult = {
				result: payload.result.data,
				ResultMessage: payload.result.message
			};

			console.log("REGISTRATION SUCCESS ", payload);
			// uploadDataToS3(
			// 	payload.result.data.customerId_1.s3_data.view_url,
			// 	state.file
			// );
		},
		error: (state, payload) => {
			state.isError = true;
			state.loading = false;
			state.error = payload;
		},
	},
	extraReducers: {},
});

const uploadDataToS3 = async (state, file) => {
	const myHeaders = new Headers({
		"Content-Type": "image/*",
		// Authorization: "Bearer " + getSessionStorage("token"),
		"x-bayad-platform-id": process.env.REACT_APP_APP_KEY,
	});
	const response = await fetch(state, {
		method: "PUT",
		headers: myHeaders,
		body: file,
	});
	// const myHeaders = new Headers({ "Content-Type": "image/*" });
	// const response = await fetch(presignedUploadUrl, {
	// 	method: "PUT",
	// 	headers: myHeaders,
	// 	body: file,
	// });
};

const getRegistrationResultAPI = async (client, state) => {
	return await client.post("/v2/remittance/kyc/customers", {
		...state.kycRegistration.userDetails,
		kycUploadForm: "sample.pdf",
	});
};

function getRegistrationResult() {
	return {
		types: [loading.type, fulfilled.type, error.type],
		promise: getRegistrationResultAPI,
	};
}

const { loading, fulfilled, error, setUserDetails, setFile } =
	kycRegistration.actions;

export default kycRegistration.reducer;

export { getRegistrationResult, setUserDetails, uploadDataToS3, setFile };
