import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	partnersList: [],
	partnerCode: "",
	searchParams: "",
	selectedPartner: {},
	selectedPartnerParams: [],
	selectedPartnerDocument: {},
	isLoading: false,
	error: "",
};

const kycPartnerList = createSlice({
	name: "kycPartnerList",
	initialState,
	reducers: {
		prep: (state, payload) => {
			// state.partnersList = payload;
			state.isLoading = true;
		},
		load: (state, payload) => {
			// load state here
			state.partnersList = payload.result.data.remittancePartners;
			state.isLoading = false;
		},
		fulfilled: (state, payload) => {
			state.isLoading = false;
			console.log("___", payload);
		},
		error: (state, payload) => {
			console.log("error", state);
			state.error = payload;
			state.isLoading = false;
		},
		selectPartner: (state, action) => {
			state.partnerCode = action.payload;
			state.isLoading = false;
		},
		setSearchParams: (state, action) => {
			state.searchParams = action.payload;
		},
		prepSearch: (state) => {
			state.isLoading = true;
		},
		fulfilledSearch: (state, payload) => {
			state.partnersList = payload.result.data.remittancePartners;
			state.isLoading = false;
		},
		fulfilledPartnerDetails: (state, payload) => {
			state.selectedPartner = payload.result.data.remittancePartner;
			state.selectedPartnerParams =
				payload.result.data.remittancePartnerParams;
		},
		setDocumentOptions: (state, action) => {
			state.selectedPartnerDocument = action.payload;
		},
	},
});

const {
	prep,
	load,
	error,
	selectPartner,
	fulfilled,
	setSearchParams,
	fulfilledSearch,
	fulfilledPartnerDetails,
	prepSearch,
	setDocumentOptions,
} = kycPartnerList.actions;

const getPartnerList = async (client) => {
	// api here
	return await client.get("/v2/remittance/remittance-partners");
};

function partnerActionCreator() {
	return {
		types: [prep.type, load.type, error.type, fulfilled.type],
		promise: getPartnerList,
	};
}

const getPartnerBySeach = async (client, state) => {
	return await client.get(
		`/v2/remittance/remittance-partners?search=${state.kycPartnerList.searchParams}`
	);
};

function searchPartnerActionCreator() {
	return {
		types: [prepSearch.type, fulfilledSearch.type],
		promise: getPartnerBySeach,
	};
}

const getPartnerDetails = async (client, state) => {
	return await client.get(
		`/v2/remittance/remittance-partner?code=${state.kycPartnerList.partnerCode}`
	);
};

function getPartnerDetailsActionCreator() {
	return {
		types: [error.type, fulfilledPartnerDetails.type],
		promise: getPartnerDetails,
	};
}

export default kycPartnerList.reducer;

export {
	partnerActionCreator,
	getPartnerDetailsActionCreator,
	searchPartnerActionCreator,
	getPartnerBySeach,
	selectPartner,
	setSearchParams,
	setDocumentOptions,
};
