import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { styled, Stack, Box, Button } from "@mui/material";
import textStyles from "../textStyles.module.css";
import InputField from "../../Remittance/Input/InputField.js";
import SelectField from "../../Remittance/Input/SelectField.js";
import { Formik } from "formik";
import { DatePickerForm } from "../../Remittance/DatePicker/FormattedDatePicker";
import FormattedTextField from "../../Remittance/Input/FormattedTextField.js";
import moment from "moment";
import { format } from "date-fns";
import { values } from "lodash";
const StyledStack = styled(Stack)(({ theme }) => ({
	display: "flex",
	flexFlow: "wrap",
	justifyContent: "space-between",
	padding: "5px 0 5px 0",
}));

const StyledBox = styled(Box)(({ theme }) => ({
	flex: "1 0 19%",
	width: "19%",
	maxWidth: "19%",
	margin: "5px 0 5px 0",
}));

const BayadCI = ({ setBayadCIFields, submitHover, ...props }) => {
	const formEl = useRef();
	const [loading, setLoading] = useState(true);
	const [expiredId, setExpiredId] = useState(false);

	const [inputFields, setInputFields] = useState({
		firstName: {
			type: "Input",
			name: "firstName",
			apiName: "firstName",
			placeholder: "First Name",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			required: true,
			disabled: true,
		},
		middleInitial: {
			type: "Input",
			name: "middleInitial",
			apiName: "middleInitial",
			placeholder: "Middle Initial (Optional)",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			required: false,
			disabled: true,
		},
		lastName: {
			type: "Input",
			name: "lastName",
			apiName: "lastName",
			placeholder: "Last Name",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			required: true,
		},
		mobileNumber: {
			type: "Input",
			name: "mobileNumber",
			apiName: "mobileNumber",
			placeholder: "Mobile Number",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			// pattern: "number",
			required: true,
		},
		emailAddress: {
			type: "Input",
			name: "emailAddress",
			apiName: "emailAddress",
			placeholder: "Email Address (Optional)",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			required: false,
			disabled: true,
		},
		gender: {
			type: "Select",
			name: "gender",
			apiName: "gender",
			placeholder: "Gender",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			values: [
				{ id: "Male", name: "Male" },
				{ id: "Female", name: "Female" },
			],
			required: true,
			disabled: true,
		},
		dateOfBirth: {
			type: "Date",
			name: "dateOfBirth",
			apiName: "dateOfBirth",
			placeholder: "Date of Birth",
			fieldValue: "",
			onChange: (val) =>
				inputOnChange("dateOfBirth", moment(val).format("MM/DD/YYYY")),
			initialValues: { dateOfBirth: null },
			required: true,
			disabled: true,
		},
		placeOfBirth: {
			type: "Input",
			name: "placeOfBirth",
			apiName: "placeOfBirth",
			placeholder: "Birth Place",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			required: true,
			disabled: true,
		},
		nationality: {
			type: "Select",
			name: "nationality",
			apiName: "nationalityId",
			placeholder: "Nationality",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			values: [{}],
			required: true,
			disabled: true,
		},
		sourceOfFunds: {
			type: "Select",
			name: "sourceOfFunds",
			apiName: "sourceOfFundsId",
			placeholder: "Source of Funds",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			values: [{}],
			required: true,
			disabled: true,
		},
		natureOfWork: {
			type: "Select",
			name: "natureOfWork",
			apiName: "natureOfWorkId",
			placeholder: "Nature of Work",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			values: [{}],
			required: true,
			disabled: true,
		},
	});

	const inputOnChange = (name, values) => {
		if (name == "mobileNumber") {
			setInputFields((prevProps) => ({
				...prevProps,
				[name]: {
					...prevProps[name],
					fieldValue: mobileNumberFormatter(
						values,
						prevProps[name].fieldValue
					),
				},
			}));
		} else {
			setInputFields((prevProps) => ({
				...prevProps,
				[name]: { ...prevProps[name], fieldValue: values },
			}));
		}
	};

	const mobileNumberFormatter = (value, previousValue) => {
		if (!value) return value;

		const currentValue = value.replace(/[^\d]/g, "");
		const cvLength = currentValue.length;

		if (!previousValue || value.length > previousValue.length) {
			if (cvLength < 4) return currentValue;

			if (cvLength < 7)
				return ` (${currentValue.slice(0, 3)}) ${currentValue.slice(
					3
				)}`;

			return ` (${currentValue.slice(0, 3)}) ${currentValue.slice(
				3,
				6
			)}-${currentValue.slice(6, 10)}`;
		}
	};
	useEffect(() => {
		setBayadCIFields(inputFields);
	}, [inputFields]);

	useEffect(() => {
		if (props.customerData) {
			setExpiredId(props.customerData.expiredId);
			Object.keys(props.customerData).find((key) => {
				if (
					key ==
					Object.keys(inputFields).find(
						(inputFieldsKey) => inputFieldsKey == key
					)
				) {
					if (key == "dateOfBirth") {
						const [day, month, year] =
							props.customerData[key].split("/");
						const date = month + "-" + day + "-" + year;

						const tempData = {
							...inputFields[key],
							fieldValue: moment(date).format("MM-DD-YYYY"),
							initialValues: {
								dateOfBirth: moment(date).format("MM-DD-YYYY"),
							},
						};
						setInputFields((prevProps) => ({
							...prevProps,
							[key]: tempData,
						}));
					} else if (key == "mobileNumber") {
						const tempData = {
							...inputFields[key],
							fieldValue: mobileNumberFormatter(
								props.customerData[key].substring(3),
								0
							),
						};
						setInputFields((prevProps) => ({
							...prevProps,
							[key]: tempData,
						}));
					} else {
						const tempData = {
							...inputFields[key],
							fieldValue: props.customerData[key]?.id
								? props.customerData[key]?.id
								: props.customerData[key],
						};

						setInputFields((prevProps) => ({
							...prevProps,
							[key]: tempData,
						}));
					}
				}
			});
		}
	}, [props.customerData]);

	useEffect(() => {
		let tempDataNationality = {};
		let tempDataSof = {};
		let tempDataNof = {};
		Object.values(props.nationalitiesSelectValues).map((val) => {
			tempDataNationality = dropdownValuesSupply(
				tempDataNationality,
				"nationality",
				val
			);
		});
		Object.values(props.sofSelectValues).map((val) => {
			tempDataSof = dropdownValuesSupply(
				tempDataSof,
				"sourceOfFunds",
				val
			);
		});
		Object.values(props.nofSelectValues).map((val) => {
			tempDataNof = dropdownValuesSupply(
				tempDataNof,
				"natureOfWork",
				val
			);
		});
	}, [
		props.nationalitiesSelectValues,
		props.sofSelectValues,
		props.nofSelectValues,
	]);

	const dropdownValuesSupply = (tempData, objectKey, val) => {
		let selectVals = tempData ? tempData.values : {};
		if (selectVals) {
			tempData = {
				...inputFields[objectKey],
				values: [...selectVals, { id: val.id, name: val.name }],
			};
		} else {
			tempData = {
				...inputFields[objectKey],
				values: [{ id: val.id, name: val.name }],
			};
		}
		setInputFields((prevProps) => ({
			...prevProps,
			[objectKey]: tempData,
		}));
		return tempData;
	};

	return (
		<>
			<StyledStack>
				{Object.values(inputFields).map((fields, x) => {
					if (fields.type == "Input") {
						return (
							<StyledBox key={x}>
								<InputField
									type={
										fields.pattern == "number"
											? "number"
											: ""
									}
									InputProps={
										fields.name == "mobileNumber"
											? {
													startAdornment: (
														<FormattedTextField
															className={
																textStyles.textStylesNormal1218Poppins
															}
														>
															+63
														</FormattedTextField>
													),
											  }
											: {}
									}
									className={
										textStyles.textStylesNormal1218Poppins
									}
									label={fields.placeholder}
									inputProps={{
										style: {
											fontSize: 12,
											fontFamily: "Poppins",
										},
									}}
									name={fields.name}
									onChange={(val) => fields.onChange(val)}
									key={x}
									required={fields.required}
									disabled={fields.disabled || expiredId}
									value={
										fields.fieldValue
											? fields.fieldValue
											: ""
									}
									error={
										submitHover
											? fields.required
												? fields.fieldValue
													? false
													: true
												: false
											: false
									}
								/>
							</StyledBox>
						);
					} else if (fields.type == "Select") {
						return (
							<StyledBox key={x}>
								<SelectField
									className={
										textStyles.textStylesNormal1218Poppins
									}
									label={fields.placeholder}
									value={fields.fieldValue}
									values={fields.values}
									name={fields.name}
									onChange={(val) => fields.onChange(val)}
									required={fields.required}
									key={x}
									disabled={fields.disabled || expiredId}
									error={
										submitHover
											? fields.required
												? fields.fieldValue
													? false
													: true
												: false
											: false
									}
								/>
							</StyledBox>
						);
					} else if (fields.type == "Date") {
						return (
							<StyledBox key={x}>
								<Formik initialValues={fields.initialValues}>
									<DatePickerForm
										label={fields.placeholder}
										name={fields.name}
										required={fields.required}
										handleChange={(val) =>
											fields.onChange(val._d)
										}
										disabled={fields.disabled}
										fieldValue={fields.fieldValue}
										error={
											submitHover
												? fields.required
													? fields.fieldValue
														? false
														: true
													: false
												: false
										}
									/>
								</Formik>
							</StyledBox>
						);
					}
				})}
			</StyledStack>
		</>
	);
};

export default connect(
	(state) => ({
		customerData: state.kycUpdate.customerData,
		nationalitiesSelectValues: state.kycBayad.nationalitiesSelectValues,
		sofSelectValues: state.kycBayad.sofSelectValues,
		nofSelectValues: state.kycBayad.nofSelectValues,
	}),
	{}
)(BayadCI);
