import styled from "@emotion/styled";
import { Button } from "@material-ui/core";
import {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import AttachmentCard from "./AttachmentCard";
import { ReactComponent as DragDropIcon } from "../../../../assets/icons/img-dragdrop.svg";
import { ACCEPTABLE_FILE_TYPES, MAX_SIZE, VoidAttachmentErrorType } from "../VoidRequestModal";

interface IVoidAttachmentFieldProps {
	onDrop: (
		acceptedFiles: File[],
		fileRejections: FileRejection[],
		event: DropEvent
	) => void;
	isEdit: boolean;
	acceptedFiles: Array<{ key: string; file: File }>;
	onUploadSuccess: (res: any, file: File, key: string) => Promise<void | null>;
	onUploadTimeout: (file: File, key: string) => void;
	onRemove: Dispatch<SetStateAction<any>>;
	onView: (index: number) => void;
}

type TFileErrors = {
	[key: string]: string;
};

const UploadContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	border: 1px dashed #b8b8b8;
	padding: 36px 15px;
	padding-bottom: 0;
	border-radius: 10px;
	text-align: center;
	align-items: center;
`;

const AttachmentContainer = styled.div`
	width: 100%;
	overflow: hidden;
	overflow-x: auto;
	flex-flow: nowrap;
	display: flex;
	flex-direction: row;
	gap: 8px;
	padding: 8px;
	padding-bottom: 36px;
`;

const ChooseFileButton = styled(Button)`
	color: #f26122 !important;
	border-color: #f26122 !important;
	height: 45px;
	width: 140px;
`;

const PlaceholderText = styled.span`
	color: #8b9095;
	font-style: italic;
	font-size: 12px;
	font-family: Poppins-Regular;
`;

const HintText = styled.div`
	color: #8b9095;
	font-style: italic;
	font-family: Poppins-Regular;
	font-size: 10px;
	margin-top: 3px;
	margin-bottom: 3px;

	& span {
		font-style: normal;
		font-family: Poppins-Medium;
		color: #3b3b3b;
	}
`;

/**
 * Main modal to display attachment in the void modal 
 * @component 
 */
const VoidAttachmentField: FC<IVoidAttachmentFieldProps> = ({
	onDrop,
	isEdit = false,
	acceptedFiles = [],
	onRemove,
	onUploadSuccess,
	onUploadTimeout,
	onView
}) => {
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		open: openFileBrowser,
	} = useDropzone({
		onDrop,
		noClick: true,
		disabled: isEdit,
	});

	const [errorFileKeys, setErrorFileKeys] = useState<TFileErrors>({});

	const addErrorFile = useCallback((fileKey: string, error: string) => {
		setErrorFileKeys((errors: TFileErrors) => {
			return {
				...errors,
				[fileKey]: error,
			};
		});
	}, []);

	const removeErrorFile = useCallback((fileKey: string) => {
		setErrorFileKeys((errors: TFileErrors) => {
			const _errors = { ...errors };
			delete _errors[fileKey];

			return _errors;
		});
	}, []);

	const handleSuccessFile = useCallback(
		(res: any, file: File | any, key: string) => {
			removeErrorFile(key);
			onUploadSuccess(res, file, key);
		},
		[onUploadSuccess, removeErrorFile]
	);

	const handleRemove = useCallback(
		(index: number, filename: string, key: string) => {
			onRemove({
				fileIndex: index,
				fileName: filename,
				isOpen: true,
				key,
			});
		},
		[onRemove]
	);

	useEffect(() => {
		if (Object.keys(errorFileKeys).length > 0) {
			const errors: { [key: string]: {} } = {};
			for (const [key] of Object.entries(errorFileKeys)) {
				errors[key] = "Timeout error: Failed to upload attachment.";
			}
		}
	}, [errorFileKeys]);

	/** Checks if all files are acceptable */
	const attachmentStatus = (file: File) => {
		// TODO: Unusable but can be used
		let error = {
			hasFileTypeError: false,
			hasFileSizeError: false,
		};

		if (!ACCEPTABLE_FILE_TYPES.includes(file?.type)) {
			error.hasFileTypeError = true;
		}

		if (file?.size > MAX_SIZE) {
			error.hasFileSizeError = true;
		}

		return error;
	};

	return (
		<>
			<UploadContainer
				{...getRootProps()}
				// @ts-ignore
				onFocus={null}
				// @ts-ignore
				onBlur={null}
			>
				<input {...getInputProps()} hidden />
				<DragDropIcon />
				<PlaceholderText>
					{isDragActive
						? "Drop your files here"
						: 'Drag and drop files here or click "Choose File" button'}
				</PlaceholderText>
				<div>
					<ChooseFileButton
						variant="outlined"
						onClick={openFileBrowser}
						disabled={isEdit}
					>
						Choose File
					</ChooseFileButton>
				</div>

				{/* {voidRequestData?.attachments?.length === 0 &&
					"No attachments added for this request"} */}

				<AttachmentContainer>
					{acceptedFiles &&
						acceptedFiles?.map(
							(
								{
									file,
									key,
								}: {
									file: any;
									key: string;
								},
								index: number
							) => {
								const status = attachmentStatus(file);
								const hasError =
									!isEdit &&
									(status.hasFileSizeError ||
										status.hasFileTypeError);
								const errorMessage = status.hasFileSizeError
									? "Maximum file size exceeded"
									: status.hasFileTypeError
									? "Invalid file type."
									: "Failed to upload";
								return (
									//@ts-ignore
									<AttachmentCard
										fileKey={key}
										clearError={() => removeErrorFile(key)}
										key={key || file?.key}
										hasError={hasError}
										onError={() => {
											addErrorFile(key, errorMessage);
										}}
										errorMessage={errorMessage}
										file={file}
										onView={() => onView(index)}
										onRemove={() => {
											handleRemove(
												index,
												file?.name,
												key ?? file?.key
											);
										}}
										onUploadSuccess={async (res, file) => {
											handleSuccessFile(
												res,
												file,
												`${key ?? file?.key}${VoidAttachmentErrorType.Timeout}`
											);
										}}
										onUploadTimeout={(file) => onUploadTimeout(file, key)}
										isEdit={isEdit}
										isViewOnly={isEdit}
									/>
								);
							}
						)}
				</AttachmentContainer>
			</UploadContainer>
			<HintText>
				*Maximum size per attachment: <span>5MB</span>
			</HintText>
			<HintText>
				*Maximum number of attachment: <span>10</span>
			</HintText>
			<HintText>
				*Allow file types:{" "}
				<span>.doc, .docx, .pdf, .jpg, .jpeg, or .png</span>
			</HintText>
		</>
	);
};

export default VoidAttachmentField;
