import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { FormControl, styled, Container } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import { Stack, Box } from "@mui/material";
import textStyle from "../textStyles.module.css";
import verificationIcon from "../../../assets/icons/ic-verification.svg";
import Button from "../Input/Buttons";
import InputField from "../../Remittance/Input/InputField";
import { Formik } from "formik";
import { DatePickerForm } from "../../Remittance/DatePicker/FormattedDatePicker";
import moment from "moment";

import {
	validateInfo,
	validateCustomerInfoActionCreator,
	verifyInfo,
	clearCustomerInfo,
	setKYCInformation,
} from "../../../redux/modules/kycInquiry";

import KYCCustomerInformation from "./KYCCustomerInformation";
import { setTransactionType } from "../../../redux/modules/kycBayad";
import FormattedTextField from "../Input/FormattedTextField";

const StyledStack = styled(Stack)(({ theme }) => ({
	margin: "60px auto",
	display: "block",
	textAlign: "center",
	"& .kyc-verification-header": {
		marginBottom: "20px",
	},
	"& img": {
		width: "53.95px",
		height: "auto",
		display: "block",
		margin: "auto",
	},
	"& .divider": {
		margin: "10px 15%",
	},
	"& p": {
		textAlign: "center",
	},
	"& #remittance-kyc-verification-form": {
		margin: "0 auto",
		"& .kyc-text-helper": {
			color: "#D93025",
			textAlign: "left",
		},
	},
	"& .remittance-kyc-textfield": {
		marginTop: "7.5px",
		marginBottom: "7.5px",
	},
}));

const StyledBox = styled(Box)(({ theme }) => ({
	margin: "5px 0 5px 0",
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Divider = ({ color, opacity }) => (
	<hr
		style={{
			backgroundColor: color,
			height: ".5px",
			width: "100%",
			opacity: opacity ? opacity : 1,
		}}
	/>
);

const stylingCheckButton = (isLoading, isDisabled) => {
	let styling;
	return (styling = {
		backgroundColor:
			isLoading || isDisabled != true ? "#F26122" : "rgb(214 102 54)",
		borderColor: "#F26122",
		color: "#fff",
		margin: "0 auto",
		borderRadius: "21px",
		padding: "10px 38px",
		marginTop: "5%",
		marginBottom: "12%",
	});
};

const stylingRegisterButton = {
	backgroundColor: "#0076BF",
	borderColor: "#0076BF",
	color: "#fff",
	borderRadius: "21px",
	padding: "10px 38px",
};

const Verification = (props) => {
	const formEl = useRef();
	const dispatch = useDispatch();

	const [bayadID, setBayadID] = useState("");
	const [formPayload, setFormPayload] = useState({});
	const [requiredHelper, setRequiredHelper] = useState(false);
	const [lengthValidator, setLengthValidator] = useState(false);
	const [alphaNumericValidator, setAlphaNumericValidator] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	// const inquiryResult = props.kycInquiry.customerInformation;

	const [fnameHelper, setFnameHelper] = useState(false);
	const [lnameHelper, setLnameHelper] = useState(false);
	const [dobHelper, setDobHelper] = useState(false);
	const [mobileNumHelper, setMobileNumHelper] = useState(false);
	const [mobileFormatHelper, setMobileFormatHelper] = useState(false);

	const handleChange = (e) => {
		let value = e.target.value;
		setBayadID(value);
		setRequiredHelper(false);
		setIsDisabled(false);
		if (value.length == 15 || value.length == 0) {
			setLengthValidator(false);
		}
	};

	const handleFormInput = (e) => {
		setFormPayload({
			...formPayload,
			[e.target.name]: e.target.value,
		});
		setLengthValidator(false);
		setAlphaNumericValidator(false);
		setIsDisabled(false);
	};

	const bayadIDValidator = () => {
		if (bayadID.length == 0) {
			setRequiredHelper(true);
		} else if (bayadID.length != 15) {
			setLengthValidator(true);
		} else {
			var regex = /^[A-Za-z0-9 ]+$/;
			var isValid = regex.test(bayadID);
			if (!isValid) {
				setAlphaNumericValidator(true);
			} else {
				dispatch(verifyInfo(bayadID));
				dispatch(validateInfo());
			}
		}
	};
	const verificationResultMessage = {
		// icon: inquiryResult.data.hitStatus == "HIT"? "ic-hourglass.svg": "/ic-success.svg",
		// resultMessage: inquiryResult.message,
		primaryBtnLabel: "Continue",
		primaryBtnFunction: () => {
			window.location.href = "/";
		},
		hasSecondaryButton: false,
		secondaryBtnLabel: "Update",
		onClose: true,
		secondaryBtnFunction: () => {
			window.location.href = "/";
		},
	};

	const formDetailsValidator = () => {
		if (Object.keys(formPayload).length > 0) {
			if (!formPayload["firstName"]) {
				setFnameHelper(true);
			} else if (!formPayload["lastName"]) {
				setLnameHelper(true);
			} else if (!formPayload["dateOfBirth"]) {
				setDobHelper(true);
			} else if (!formPayload["mobileNumber"]) {
				setMobileNumHelper(true);
			} else {
				var regex = /^\d{10}$/;
				var isValid = regex.test(formPayload["mobileNumber"]);
				if (!isValid) {
					setMobileFormatHelper(true);
				} else {
					setFnameHelper(false);
					setLnameHelper(false);
					setMobileNumHelper(false);
					setDobHelper(false);
					setMobileFormatHelper(false);
					dispatch(setKYCInformation(formPayload));
					dispatch(validateCustomerInfoActionCreator());
				}
			}
		}
	};

	const dateChange = (date) => {
		setFormPayload({
			...formPayload,
			dateOfBirth: moment(date).format("DD/MM/YYYY"),
		});
	};

	const handleCheck = () => {
		// check if object is made
		if (Object.keys(formPayload).length > 0) {
			if (Object.values(formPayload).every((x) => x !== "")) {
				// false if object is made and not empty
				// validation for form should perform
				// dispatch verification
				formDetailsValidator();
				dispatch(verifyInfo(bayadID));
			} else {
				// forms are empty check button should disabled
				setLengthValidator(false);
				setIsDisabled(true);
				bayadIDValidator();
			}
		} else {
			bayadIDValidator();
		}
		

	};

	const clearResult = (data) => {
		if (data === true) {
			dispatch(clearCustomerInfo());
			setFormPayload({});
			setBayadID("");
			setRequiredHelper(false);
			setLengthValidator(false);
		}
	};

	const dateConfig = () => {
		// 12 years from current date
		const d = new Date();
		d.setFullYear(d.getFullYear() - 12);

		return d.toDateString();
	};

	return (
		<>
			<StyledStack spacing={2}>
				{Object.keys(props.customerInfo).length <= 0 &&
				props.errorStatus !== "0010" ? (
					<>
						<img src={verificationIcon} />
						<p
							className={`${textStyle.textStyle1619WavehausSemi} kyc-verification-header`}
						>
							KYC Verification
						</p>
						<FormControl id="remittance-kyc-verification-form">
							<InputField
								error={lengthValidator || alphaNumericValidator}
								className={
									textStyle.textStylesNormal1421Poppins
								}
								label="Bayad ID"
								placeholder="Enter 15 Digits Bayad ID"
								name="bayadID"
								onChange={handleChange}
								inputProps={{
									maxLength: 15,
									style: {
										fontSize: 12,
										fontFamily: "Poppins",
										borderColor: "#D93025",
									},
								}}
								key="bayadID"
								disabled={
									Object.keys(formPayload).length > 0
										? Object.values(formPayload).every(
												(x) => x !== ""
										  )
										: false
								}
							/>
							{requiredHelper ? (
								<p
									className={`${textStyle.textStylesNormal1016Poppins} kyc-text-helper`}
								>
									Bayad ID is required.
								</p>
							) : lengthValidator ? (
								<p
									className={`${textStyle.textStylesNormal1016Poppins} kyc-text-helper`}
								>
									Bayad ID must be 15 digits.
								</p>
							) : alphaNumericValidator ? (
								<p
									className={`${textStyle.textStylesNormal1016Poppins} kyc-text-helper`}
								>
									Bayad ID must be alphanumeric.
								</p>
							) : null}

							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
								spacing={2}
								className="divider"
							>
								<Divider color="black" />
								<p
									className={
										textStyle.textStyle1619WavehausSemi
									}
								>
									or
								</p>
								<Divider color="black" />
							</Stack>

							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
								spacing={2}
							>
								<StyledBox>
									<InputField
										className={
											textStyle.textStylesNormal1421Poppins
										}
										label="First Name"
										name="firstName"
										onChange={handleFormInput}
										inputProps={{
											style: {
												fontSize: 12,
												fontFamily: "Poppins",
											},
										}}
										key="firstName"
										disabled={bayadID != ""}
										error={fnameHelper}
									/>
									{fnameHelper ? (
										<p
											className={`${textStyle.textStylesNormal1016Poppins} kyc-text-helper`}
										>
											First Name is required
										</p>
									) : null}
								</StyledBox>
								<StyledBox>
									<InputField
										className={
											textStyle.textStylesNormal1421Poppins
										}
										label="Last Name"
										name="lastName"
										onChange={handleFormInput}
										inputProps={{
											style: {
												fontSize: 12,
												fontFamily: "Poppins",
											},
										}}
										key="lastName"
										disabled={bayadID != ""}
										error={lnameHelper}
									/>
									{lnameHelper ? (
										<p
											className={`${textStyle.textStylesNormal1016Poppins} kyc-text-helper`}
										>
											Last Name is required
										</p>
									) : null}
								</StyledBox>
							</Stack>
							<StyledBox>
								<Formik
									innerRef={formEl}
									initialValues={{ dateOfBirth: null }}
								>
									<DatePickerForm
										label="Date of Birth"
										maxDate={dateConfig()}
										name="dateOfBirth"
										disabled={bayadID != ""}
										handleChange={(e) => dateChange(e._d)}
									/>
								</Formik>
								{dobHelper ? (
									<p
										className={`${textStyle.textStylesNormal1016Poppins} kyc-text-helper`}
									>
										Date of Birth is required
									</p>
								) : null}
							</StyledBox>
							<StyledBox>
								<InputField
									className={
										textStyle.textStylesNormal1421Poppins
									}
									label="Mobile Number"
									name="mobileNumber"
									onChange={handleFormInput}
									InputProps={{
										startAdornment: (
											<FormattedTextField
												className={
													textStyle.textStylesNormal1421Poppins
												}
											>
												+63
											</FormattedTextField>
										),
									}}
									inputProps={{
										maxLength: "10",
										style: {
											fontSize: 12,
											fontFamily: "Poppins",
										},
									}}
									key="mobileNumber"
									type="tel"
									disabled={bayadID != ""}
									error={mobileNumHelper}
								/>
								{mobileNumHelper ? (
									<p
										className={`${textStyle.textStylesNormal1016Poppins} kyc-text-helper`}
									>
										Mobile number is required
									</p>
								) : mobileFormatHelper ? (
									<p
										className={`${textStyle.textStylesNormal1016Poppins} kyc-text-helper`}
									>
										Enter valid mobile number is required
									</p>
								) : null}
							</StyledBox>
							<Button
								variant="outlined"
								text={`${
									props.isLoading ? "CHECKING..." : "CHECK"
								}`}
								disabled={
									props.isLoading ||
									(bayadID.length == 0 &&
										Object.keys(formPayload).length == 0) ||
									isDisabled
								}
								styling={stylingCheckButton(
									props.isLoading,
									bayadID.length == 0 &&
										Object.keys(formPayload).length == 0
								)}
								handleOnClick={handleCheck}
								isLoading={props.isLoading}
							/>
						</FormControl>
					</>
				) : (
					<KYCCustomerInformation
						customerInformation={props.customerInfo}
						clearFunc={clearResult}
						status={props.errorStatus}
						message= {props.message}
					/>
				)}
			</StyledStack>
		</>
	);
};

export default connect(
	(state) => ({
		customerInfo: state.kycInquiry.customerInformation,
		isLoading: state.kycInquiry.isLoading,
		errorStatus: state.kycInquiry.errorStatus,
		message: state.kycInquiry.message,
	}),
	{ validateInfo, validateCustomerInfoActionCreator }
)(Verification);
