import * as yup from "yup";

import moment from "moment";
const schema = yup.object({
	startDate: yup.date().nullable(),
	endDate: yup
		.date()
		.nullable()
		.min(yup.ref("startDate"), "Invalid date input."),
	isRecurring: yup.boolean(),
	recurringDays: yup.array().when("isRecurring", {
		is: true,
		then: (schema) => schema.min(1, "This field is required."),
		otherwise: (schema) => schema.min(0),
	}),
	startTime: yup
		.string()
		.required("This field is required.")
		.test("is-valid", "Invalid time input.", function (value) {
			return moment(value).isValid();
		}),
	endTime: yup
		.string()
		.required("This field is required.")
		.test("is-greater", "Invalid time input.", function (value: any) {
			const { startTime }: { startTime: string } = this.parent;

			if (!moment(value).isValid()) {
				return false;
			}

			if (!moment(startTime).isValid()) {
				return true;
			}

			return (
				moment(value).format("HH:mm:ss") >
				moment(startTime).format("HH:mm:ss")
			);
		}),
});

export default schema;
