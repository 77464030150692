import React, { useEffect, useState } from "react";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@material-ui/core";
import styles from "./ReprintCodeModal.module.css";
import CloseIcon from "@material-ui/icons/Close";
import HTTP from "../../../helpers/ApiClient";
import { connect } from "react-redux";
import {
	AccountCircle,
	Error,
	ErrorOutlineOutlined,
	Visibility,
	VisibilityOff,
} from "@material-ui/icons";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import cx from "classnames";
import {
	GrayColorButton,
	GreenColorButton,
	WhiteColorButton,
} from "../../App/App";
import { DangerousOutlined } from "@mui/icons-material";

const ReprintCodeModal = ({
	isOpen,
	close,
	currentTransaction,
	printType,
	printBayad,
	printSOA,
	printCheck,
	fetchReprintStatus,
}) => {
	const [code, setCode] = useState("");
	const [showPasscode, setShowPasscode] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [errorSendRequest, setErrorSendRequest] = useState(null);
	const [showSucccess, setShowSuccess] = useState(false);
	const [showSuccessRequest, setShowSuccessRequest] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [loadingSendRequest, setLoadingSendRequest] = useState(false);
	const [reprintDetails, setReprintDetails] = useState(null);

	const errorMessages = {
		alreadyApproved:
			"Your supervisor already approved to reprint this transaction.",
		alreadyRequested:
			"You've already requested to reprint this transaction.",
		limitReached:
			"You've reached the maximum reprint for this transaction.",
		invalidCode: "You've entered an invalid code.",
		maxRetries:
			"You reached the max. no. tries, please generate a new code.",
	};

	const closeAndReset = () => {
		close();
		setLoadingSendRequest(false);
		setLoadingSubmit(false);
		setShowPasscode(false);
		setShowSuccessRequest(false);
		setShowSuccess(false);
		setCode("");
		setErrorMessage(null);
		setErrorSendRequest(false);
	};

	useEffect(() => {
		if (!isOpen) closeAndReset();
	}, [isOpen]);

	const isPrintTypeCheck = printType === "CHECK";
	const validateCode = async () => {
		setLoadingSubmit(true);

		const endpoint = isPrintTypeCheck
			? `v3/receipts/check/reprint/${currentTransaction.crn}/validate`
			: `v2/receipts/reprint/${currentTransaction.crn}/validate`;
		await HTTP.post(endpoint, { reprintCode: code })
			.then(async ({ data }) => {
				if (data.message.includes("already approved"))
					return setErrorMessage(errorMessages.alreadyApproved);

				if (data.message.includes("Successful")) {
					const responseSuccess = await fetchReprintStatus(
						currentTransaction,
						true,
						isPrintTypeCheck ? false : true
					);
					if (
						(isPrintTypeCheck &&
							responseSuccess?.reprintCheckStatus ===
								"APPROVED") ||
						(!isPrintTypeCheck &&
							responseSuccess?.reprintStatus === "APPROVED")
					) {
						setReprintDetails(responseSuccess);
						close();
						setShowSuccess(true);
					}
				}
			})
			.catch((error) => {
				console.log("Error validate: ", { ...error });
				if (
					error?.response?.data?.message?.includes(
						"Reprint Code not found or already expired"
					) ||
					error?.response?.data?.message?.includes("invalid code")
				) {
					setErrorMessage(errorMessages.invalidCode);
					return;
				} else if (
					error?.response?.data?.message?.includes(
						"You reached the maximum number of tries"
					)
				) {
					setErrorMessage(errorMessages.maxRetries);
					return;
				}
				setErrorMessage("An error has occured. Please try again.");
			});
		setLoadingSubmit(false);
	};

	const requestReprint = async () => {
		setLoadingSendRequest(true);
		setErrorSendRequest(false);

		const endpoint = isPrintTypeCheck
			? `v3/receipts/check/reprint/${currentTransaction.crn}/request`
			: `v2/receipts/reprint/${currentTransaction.crn}/request`;

		await HTTP.post(endpoint)
			.then(async ({ data }) => {
				if (data.message && data.message.includes("PENDING")) {
					setErrorMessage(errorMessages.alreadyRequested);
					return;
				}
				if (data.message && data.message.includes("APPROVED")) {
					setErrorMessage(errorMessages.alreadyApproved);
					return;
				}
				if (data.message && data.message.includes("maximum limit")) {
					setErrorMessage(errorMessages.limitReached);
					return;
				}
				if (data.message && data.message.includes("Successful")) {
					await fetchReprintStatus(currentTransaction, true);
					close();
					setShowSuccessRequest(true);
				}
			})
			.catch((error) => {
				console.log("Error request: ", error);
				setErrorSendRequest(true);
				setCode("");
				setErrorMessage(null);
			});
		setLoadingSendRequest(false);
	};

	const SuccessReprintCode = () => (
		<Dialog
			open={showSucccess}
			onClose={() => {
				setShowSuccess(false);
			}}
		>
			<Grid
				container
				direction="column"
				alignItems="center"
				className={styles.successRequestDialog}
			>
				<Grid item>
					<CheckCircleOutlineIcon
						style={{
							fontSize: 85,
							color: "#28A745",
							margin: "18px",
						}}
					/>
				</Grid>
				<Grid item>
					<Typography className={styles.successRequestText}>
						Success!
					</Typography>
				</Grid>
				<Grid item>
					<Typography className={styles.successRequestBodyText}>
						You may now reprint the{" "}
						{isPrintTypeCheck ? "check" : "receipt"}.
					</Typography>
				</Grid>
				<Grid item>
					<GreenColorButton
						autoFocus
						onClick={() => {
							setShowSuccess(false);
							if (printType === "BAYAD") {
								printBayad(reprintDetails, true);
							}
							if (printType === "SOA") {
								printSOA(reprintDetails, true);
							}
							if (isPrintTypeCheck) {
								printCheck(reprintDetails);
							}
						}}
						style={{ marginBottom: "20px" }}
						color="primary"
					>
						Reprint {isPrintTypeCheck ? "Check" : "Receipt"}
					</GreenColorButton>
				</Grid>
			</Grid>
		</Dialog>
	);
	const SuccessRequestApprove = () => (
		<Dialog open={showSuccessRequest} onClose={closeAndReset}>
			<Grid
				container
				direction="column"
				alignItems="center"
				className={styles.successRequestDialog}
			>
				<Grid item>
					<CheckCircleOutlineIcon
						style={{
							fontSize: 85,
							color: "#28A745",
							margin: "18px",
						}}
					/>
				</Grid>
				<Grid item>
					<Typography className={styles.successRequestText}>
						Approval Request Sent!
					</Typography>
				</Grid>
				<Grid item>
					<Typography className={styles.successRequestBodyText}>
						{!isPrintTypeCheck
							? "You successfully sent an approval request to your supervisor for reprint receipt."
							: "You successfully sent an approval request to your supervisor for reprint check."}
					</Typography>
				</Grid>
				<Grid item>
					<GreenColorButton
						autoFocus
						onClick={() => {
							closeAndReset();
						}}
						style={{ marginBottom: "20px" }}
						color="primary"
					>
						Done
					</GreenColorButton>
				</Grid>
			</Grid>
		</Dialog>
	);

	const ErrorTimeoutRequestApprove = () => (
		<Dialog open={errorSendRequest}>
			<Grid
				container
				direction="column"
				alignItems="center"
				className={styles.successRequestDialog}
			>
				<Grid item>
					<DangerousOutlined
						style={{
							fontSize: 85,
							color: "#d93025",
							margin: "18px",
						}}
					/>
				</Grid>
				<Grid item>
					<Typography className={styles.successRequestText}>
						Timeout Error!
					</Typography>
				</Grid>
				<Grid item>
					<Typography className={styles.successRequestBodyText}>
						Sorry! We encountered a problem while trying to send the
						approval request to your supervisor for
						{!isPrintTypeCheck
							? "reprint receipt"
							: "reprint check"}
						. Please try again.
					</Typography>
				</Grid>
				<Grid item style={{ marginBottom: "20px" }}>
					<WhiteColorButton
						style={{ marginRight: "14px" }}
						onClick={() => {
							setErrorSendRequest(false);
						}}
					>
						Cancel
					</WhiteColorButton>
					<GrayColorButton onClick={requestReprint}>
						Retry
					</GrayColorButton>
				</Grid>
			</Grid>
		</Dialog>
	);

	return (
		<>
			<SuccessReprintCode />
			<SuccessRequestApprove />
			<ErrorTimeoutRequestApprove />
			<Dialog
				aria-labelledby="print-receipt-confirmation"
				open={isOpen}
				fullWidth={true}
				maxWidth={"xs"}
			>
				<Grid container style={{ paddingBottom: "23px" }}>
					<Grid item xs={12}>
						<DialogTitle id="print-receipt-confirmation">
							<Typography
								variant="h6"
								className={styles.modalTitle}
							>
								Reprint {isPrintTypeCheck ? "Check" : "Receipt"}{" "}
								Approval Required
								<IconButton
									aria-label="close"
									style={{
										padding: 0,
										float: "right",
										fontSize: "100px",
									}}
									onClick={close}
								>
									<CloseIcon />
								</IconButton>
							</Typography>
						</DialogTitle>
						<hr className={styles.topDivider} />
						<DialogContent style={{ paddingTop: "20px" }}>
							<Typography className={styles.notice}>
								* Code will be provided by your supervisor.
							</Typography>
							<Grid
								container
								style={{ padding: "25px 0 10px 0" }}
							>
								<Grid item xs={7}>
									<OutlinedInput
										error={!!errorMessage}
										id="outlined-error"
										type={
											showPasscode ? "text" : "password"
										}
										value={code && code.replace(" ", "")}
										label="Code"
										onChange={(e) => {
											setCode(
												e.target.value &&
													e.target.value.replace(
														" ",
														""
													)
											);
											errorMessage &&
												setErrorMessage(null);
										}}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() =>
														setShowPasscode(
															!showPasscode
														)
													}
													onMouseDown={(e) =>
														e.preventDefault()
													}
													edge="end"
												>
													{showPasscode ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										}
										fullWidth={true}
										InputProps={{
											classes: {
												input: cx(
													styles.modalBody,
													styles.passcode
												),
											},
										}}
										InputLabelProps={{
											shrink: true,
											classes: {
												root: styles.modalBody,
											},
										}}
									/>
									{errorMessage && (
										<small
											style={{ color: "#FF0000" }}
											className={styles.modalBody}
										>
											{errorMessage}
										</small>
									)}
								</Grid>
								<Grid item xs={5}>
									<Button
										onClick={() =>
											!loadingSubmit && validateCode()
										}
										disabled={
											!code.trim() ||
											(code.trim() &&
												code.trim().length < 8)
										}
										className={cx(
											styles.enable,
											styles.enableMargin
										)}
									>
										{loadingSubmit ? (
											<CircularProgress
												color="white"
												size={22}
											/>
										) : (
											"Submit"
										)}
									</Button>
								</Grid>
							</Grid>
							<Grid
								container
								style={{
									marginTop: "16px",
									marginBottom: "50px",
								}}
							>
								<Grid item xs={5}>
									<hr className={styles.divider} />
								</Grid>
								<Grid
									item
									xs={2}
									style={{
										display: "grid",
										placeContent: "center",
									}}
								>
									<small className={styles.or}>or</small>
								</Grid>
								<Grid item xs={5}>
									<hr className={styles.divider} />
								</Grid>
							</Grid>
							<Button
								onClick={() =>
									!loadingSendRequest && requestReprint()
								}
								className={cx(styles.request)}
							>
								{loadingSendRequest ? (
									<CircularProgress color="white" size={22} />
								) : (
									"Send Request"
								)}
							</Button>
						</DialogContent>
					</Grid>
				</Grid>
			</Dialog>
		</>
	);
};

export default ReprintCodeModal;
