import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CommonTable from "../common/CommonTable/CommonTable";
import FilterContainer from '../common/Filter/FilterContainer';
import ClearFilterButton from '../Transaction/TransactionFilter/ClearFilterButton';
import Table from '@material-ui/core/Table';
import Dialog from '@material-ui/core/Dialog';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { format } from 'date-fns';
import { Paper } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography, Grid, Button, Link } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';
import styles1 from './CustomTable.module.css';
import styles from "./CustomRow.module.css"
import failedLogo from "../../assets/icons/ic-failed.svg"
import {
  KeyboardDatePicker
} from '@material-ui/pickers';

import Pagination from "../common/Pagination/NewPagination";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'gray !important',
    fontFamily: "Poppins-Regular",
    color: 'black',
  },
  body: {
    fontSize: 14,
    fontFamily: "Poppins-Regular",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: "#D5D5D5",
    fontFamily: "Poppins-Medium",
    textTransform: "uppercase",
  },
  red: {
    color: 'FF0000'
  },
  fs: {
    fontSize: '500%'
  },
  mr10: {
    marginLeft: '10%',
    marginRight: '10%'
  },
  primary: {
    color: '#0000ff',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  formControl: {
    // margin: theme.spacing(1),
    fontFamily: "Poppins-Medium"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),

  },
  table: {
    minWidth: 700,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    borderStyle: 'groove',
    width: 300,
    fontFamily: "Poppins-Medium",
    borderRadius: '5px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    fontFamily: "Poppins-Regular",
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  padding: {
    fontFamily: "Poppins-Regular",
    paddingTop: 20,
    paddingLeft: 20,
  },
  marginTop: {
    marginTop: 20,
  },
  defaultFont: {
    fontFamily: "Poppins",
  }
}));


function EnhancedTableHead(props) {
  const { headCells, classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property, transform) => (event) => {
    onRequestSort(event, property, transform);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          headCell.id === "date_and_time_generated" ?
            <StyledTableCell
              width={"25%"}
              className={styles.head}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id, headCell.transform)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell> : <StyledTableCell
              className={styles.head}
              key={headCell.id}
            >
              {headCell.label}
            </StyledTableCell>

        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};


export default function NativeSelects({ sort, sortBy, totalCount,headCells, tableSort, getComparator, handleRequestSort, order, setOrder, orderBy, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, filter, setFilter, fetchSessionReports, sessionReports, showError, hideError, showLoading, getSessionReportsActionCreator, sessionReport }) {
  const classes = useStyles();


  const handleChange = (event) => {
    setFilter({
      ...filter,
      type: event.target.value
    })
    fetchSessionReports({
      ...filter,
      type: event.target.value
    })
  };

  const handleFileNameChange = (event) => {
    setFilter({
      ...filter,
      filename: event.target.value
    })
  };

  const handleFileNameEnter = (event) => {
    if (event.keyCode === 13) {
      setFilter({
        ...filter,
        filename: event.target.value
      })
      fetchSessionReports({
        ...filter,
        filename: event.target.value,
        page: 1
      })
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(new Date(date))
    setFilter({
      ...filter,
      date_time_generated: format(new Date(date), 'yyyy/MM/dd kk:mm:ss')
    })
    fetchSessionReports({
      ...filter,
      date_time_generated: format(new Date(date), 'yyyy/MM/dd kk:mm:ss')
    })
  }

  const handleClearChange = () => {
    setFilter({
      type: '',
      date_time_generated: '',
      filename: ''
    })
    setSelectedDate(null) 
    fetchSessionReports({
      type: '',
      date_time_generated: '',
      filename: ''
    })
  } 

  const [selectedDate, setSelectedDate] = React.useState(
    null
  )

  let lodash = require("lodash");
  const pages = lodash.chunk(sessionReport, rowsPerPage)
  // const pages = lodash.chunk(tableSort(sessionReport, getComparator(order.orderBy)), rowsPerPage)
  return (
    <>
      <Backdrop className={classes.backdrop} open={showLoading}> <CircularProgress color="inherit" /> </Backdrop>
      <Dialog PaperProps={{ style: { width: "400px" } }} onClose={hideError} open={showError} disableBackdropClick disableEscapeKeyDown >
        <DialogContent>
          <DialogContentText id="download">
            <img src={failedLogo} className={styles.failedImage} />
            <Typography style={{ font: "normal normal 600 21px Wavehaus-SemiBold", textAlign: "center", marginTop: "15px" }}>Timeout Error!</Typography>
            <Typography style={{ font: "normal normal 300 14px Poppins-Regular", textAlign: "center", marginTop: "15px", color: "#3B3B3B" }}> A problem accurred while generating <br />the report. Please try again.</Typography>
          </DialogContentText>
        </DialogContent>
        <Button onClick={hideError} autoFocus variant="outlined" disabled={status} style={{ width: "50%", left: "25%", height: "45px", marginTop: "10px" }}>
          Retry
        </Button>
      </Dialog>
      <Grid container>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography className={classes.padding}>
            <b>Session Report</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              native
              value={filter.filename}
              placeholder="Filename"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e) => handleFileNameChange(e)}
              onKeyDown={(e) => handleFileNameEnter(e)}
              inputProps={{ 'aria-label': 'search' }}
            />
          </FormControl>
        </Grid>
        <Grid item xs>
        <FilterContainer>
							<Grid container direction="column" spacing={1}>
								<Grid item container justify="flex-end">
									<ClearFilterButton
										onClear={() => handleClearChange()}
									/>
								</Grid>
								<Grid
									item
									container
									spacing={3}
									direction="row"
									alignItems="center"
									xs
									classes={{ root: classes.title }}
								>
									<Grid item>Filter: </Grid>
									<Grid item container spacing={1} xs>
										<Grid item xs>
                    <KeyboardDatePicker
              fullWidth
              disableToolBar
              variant='inline'
              inputVariant="outlined"
              format='MM/DD/YYYY'
              id='date-picker'
              label='Date'
              value={selectedDate}
              onChange={(e) => handleDateChange(e)}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
										</Grid>
										<Grid item xs>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-session-native-simple">Type</InputLabel>
            <Select
              native
              value={filter.type}
              onChange={(e) => handleChange(e)}
              label="Type"
              inputProps={{
                name: 'session',
                id: 'outlined-session-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <option value={"END_SESSION_REPORT"}>End session report</option>
              <option value={"Turn over session report"}>Turn over session report</option>
            </Select>
          </FormControl>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</FilterContainer>
        </Grid>
       
            </Grid>
      </Grid><br />
        {/* <Grid container xs> */}
        {/* <FilterContainer>
        <Grid item xs={1}>
          <Typography>
            &nbsp;&nbsp;&nbsp; Filters:
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <FormControl variant="outlined" className={classes.formControl} >
            <KeyboardDatePicker
              disableToolBar
              variant='inline'
              inputVariant="outlined"
              format='MM/DD/YYYY'
              id='date-picker'
              label='Date'
              value={selectedDate}
              onChange={(e) => handleDateChange(e)}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-session-native-simple">Type</InputLabel>
            <Select
              native
              value={filter.type}
              onChange={(e) => handleChange(e)}
              label="Type"
              inputProps={{
                name: 'session',
                id: 'outlined-session-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <option value={"End session report"}>End session report</option>
              <option value={"Turn over session report"}>Turn over session report</option>
            </Select>
          </FormControl>
        </Grid>
        </FilterContainer> */}
        {/* </Grid> */}
       
       

      <CommonTable
					headers={headCells}
					entries={sessionReport || []}
					page={page}
					totalEntries={totalCount}
					rowsPerPage={rowsPerPage}
					sort={sort}
					sortBy={sortBy}
					onPageChange={handleChangePage}
					onSort={handleRequestSort}
					size="medium"
				/>
      {/* <Table  className={styles1.table} aria-label="customized table">
        <EnhancedTableHead
          classes={classes}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {sessionReport.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.date_time_generated}
              </StyledTableCell>
              <StyledTableCell>{row.tpa_session_start_to_end_time}</StyledTableCell>
              <StyledTableCell >{row.type}</StyledTableCell>
              <StyledTableCell>{row.filename}</StyledTableCell>
              <StyledTableCell>{row.icon1} {row.icon2}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
                page={page}
                totalCount={totalCount}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
      /> */}

    </>
  );
}