export default function clientMiddleware(client) {
	return ({ dispatch, getState }) => {
		return (next) => (action) => {
			if (typeof action === "function") {
				return action(dispatch, getState);
			}

			const { promise, types, ...rest } = action;
			if (!promise) {
				return next(action);
			}

			window.resetTimeOut();

			const [REQUEST, SUCCESS, FAILURE] = types;
			next({ ...rest, type: REQUEST });

			const actionPromise = promise(client, getState(), dispatch);
			actionPromise
				.then((result) => {
					let data = result ? result.data : {};
					next({ ...rest, result: data, type: SUCCESS });
				})
				.catch((error) => {
					console.log("ERROR: ", error, { ...error });
					console.error("MIDDLEWARE ERROR:", error?.response);
					return next({
						...rest,
						statusCode: error?.response && error?.response?.status,
						result:
							(error?.response && error?.response.data) ||
							"An error has occured",
						type: FAILURE,
					});
				});

			return actionPromise;
		};
	};
}
