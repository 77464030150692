import React, { ChangeEvent } from "react";
import { useState } from "react";
import {
	Dialog,
	Box,
	DialogActions,
	DialogTitle,
	Button,
	Typography,
	TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./ReasonDetailModal.module.css";
import { makeStyles } from "@material-ui/core/styles";

type Props = {
	title: string;
	message: string;
	open: boolean;
	cancelBtnLabel?: string;
	confirmBtnLabel?: string;
	placeholder?: string;
	onClose: () => void;
	reason: (data: string) => void;
};

const ReasonDetailModal: React.FC<Props> = ({
	title,
	message,
	open = false,
	cancelBtnLabel,
	confirmBtnLabel,
	placeholder,
	onClose = () => {},
	reason,
}) => {
	const [inputText, setInputText] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [isConfirmButtonDisable, setIsConfirmButtonDisable] = useState(true);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		setInputText(inputValue);
	};

	const closeModal = () => {
		setInputText("");
		setErrorMessage("");
		setIsConfirmButtonDisable(true);
		onClose && onClose();
	};

	const validateInput = () => {
		if (inputText.length > 1000) {
			setIsConfirmButtonDisable(true);
			setErrorMessage("You can only input a max of 1000 characters.");
		} else if (/\/|;|<|>|=/.test(inputText)) {
			setIsConfirmButtonDisable(true);
			setErrorMessage("Invalid Character(s)");
		} else if (inputText.trim().length === 0) {
			setIsConfirmButtonDisable(true);
		} else {
			setErrorMessage("");
			setIsConfirmButtonDisable(false);
		}
	};

	const onSubmit = () => {
		validateInput();
		if (errorMessage.length === 0 && inputText.trim().length !== 0) {
			reason(inputText);
			setInputText("");
			setErrorMessage("");
			setIsConfirmButtonDisable(true);
			onClose && onClose();
		}
	};

	const borderColor = errorMessage.length > 0 ? "#FF0000" : "#b4bbc2";

	const useStyles = makeStyles({
		textField: {
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: borderColor,
				},
			},
		},
	});

	const classes = useStyles();

	return (
		<Dialog
			disableBackdropClick
			PaperProps={{
				style: {
					width: "600px",
					height: "fit-content",
					padding: 0,
				},
			}}
			open={open}
			onClose={onClose}
		>
			<DialogTitle style={{ padding: 0, margin: 0 }}>
				<Box className={styles.header}>
					<Typography className={styles.title}>
						{title}
						<b
							style={{
								paddingLeft: 10,
								color: "#139c04",
								fontStyle: "italic",
								fontSize: "small",
							}}
						>
							Verified User
						</b>
					</Typography>
					<DialogActions>
						<CloseIcon
							onClick={closeModal}
							className={styles.close}
						/>
					</DialogActions>
				</Box>
			</DialogTitle>

			{message && (
				<Box>
					<Typography className={styles.message}>
						{message}
					</Typography>
				</Box>
			)}

			<Box className={styles.reasonText}>Reason:</Box>
			<Box>
				<TextField
					id="outlined-full-width"
					label=""
					className={classes.textField}
					style={{ paddingLeft: 15, paddingRight: 15 }}
					value={inputText}
					onChange={handleChange}
					onBlur={validateInput}
					placeholder={placeholder}
					fullWidth
					multiline
					margin="normal"
					rows={4}
					InputLabelProps={{
						shrink: true,
					}}
					inputProps={{
						color: "black",
					}}
					variant="outlined"
				/>
			</Box>
			<Box>
				<Typography gutterBottom>
					{errorMessage.length === 0 ? (
						<p className={styles.maxMessage}>
							*Maximum of 1000 characters only.
						</p>
					) : (
						<p className={styles.errorMessage}>{errorMessage}</p>
					)}
				</Typography>
			</Box>

			<DialogActions
				className={styles.reasonFooter}
				style={{ borderTop: "1px solid #a8abad" }}
			>
				<Button
					onClick={closeModal}
					variant="outlined"
					className={styles.disabled}
				>
					{cancelBtnLabel || "Cancel"}
				</Button>
				<Button
					onClick={onSubmit}
					variant="contained"
					className={styles.enable}
					disabled={isConfirmButtonDisable}
				>
					{confirmBtnLabel || "Confirm"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReasonDetailModal;
