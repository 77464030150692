import {Component} from "react";
import styles from "./Help.module.css"


class Help extends Component {
    render() {
        const REACT_APP_HELP_URL = process.env.REACT_APP_HELP_URL;
        return (
            <iframe className={styles.helpIFrame} frameBorder="0" scrolling="no"  title="Help" src={REACT_APP_HELP_URL}/>
        )
    }
}

export default Help;
