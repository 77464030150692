import { createSlice } from "@reduxjs/toolkit";

type TPA = {
	id: number;
	branch_id: number;
	tpa: string;
	status: string;
	is_operating: boolean;
	has_schedule: boolean;
};

type Branch = {
	id: number;
	name: string;
	status: string;
	is_operating: boolean;
	has_schedule: boolean;
	enabled_terminals: number;
	total_terminals: number;
	tpaList: {
		totalCount: number;
		items: TPA[];
	};
};

type InitialState = {
	totalCount: number;
	items: Branch[];
};
const initialState: InitialState = {
	totalCount: 0,
	items: [],
};

export const STATUS = {
	ACTIVE_NO_SCHED: "ACTIVE_NO_SCHED",
	ACTIVE: "ACTIVE",
	CLOSED: "CLOSED",
};

const branchAccessControl = createSlice({
	name: "branchAccessControl",
	initialState,
	reducers: {
		setInitialStateBranchAccessControl: (
			state,
			action: { payload: { totalCount: number; items: Branch[] } }
		) => {
			const list = action.payload.items.map((branchItem) => {
				const branch = state.items.find(
					(value) => value.id === branchItem.id
				);

				return {
					...branchItem,
					tpaList: {
						totalCount: 0,
						items: branch?.tpaList?.items ?? [],
					},
				};
			});
			return { totalCount: action.payload.totalCount, items: list };
		},
		setBranchToDisable: (
			state,
			action: { payload: { branchID: number } }
		) => {
			const branch = state.items.find(
				(branch) => branch.id === action.payload.branchID
			);

			if (!branch) {
				return;
			}

			if (branch.status === STATUS.ACTIVE_NO_SCHED) {
				return;
			}

			branch.status = STATUS.ACTIVE_NO_SCHED;
			branch.enabled_terminals = 0;
			branch.is_operating = true;

			//set tpaList under branch to ACTIVE_NO_SCHED
			const tpaList = branch.tpaList.items.map((tpa) => ({
				...tpa,
				status: STATUS.ACTIVE_NO_SCHED,
				is_operating: true,
			}));

			branch.tpaList.items = tpaList;
		},
		setBranchStatus: (
			state,
			action: {
				payload: {
					branchID: number;
					enabledTerminals: number;
					totalTerminals: number;
					status: string;
					is_operating: boolean;
				};
			}
		) => {
			const branch = state.items.find(
				(branch) => branch.id === action.payload.branchID
			);

			if (!branch) {
				return;
			}

			const currentStatus = branch.status;
			const newStatus = action.payload.status;

			if (currentStatus === newStatus) {
				return;
			}

			branch.status = newStatus;
			branch.enabled_terminals = action.payload.enabledTerminals;
			branch.total_terminals = action.payload.totalTerminals;
			branch.is_operating = action.payload.is_operating;
		},
		setBranchTerminals: (
			state,
			action: { payload: { branchID: number; tpaList: TPA[] } }
		) => {
			const branch = state.items.find(
				(branch) => branch.id === action.payload.branchID
			);

			if (!branch) {
				return;
			}

			branch.tpaList.items = action.payload.tpaList;
		},
		setBranchTerminalStatus: (
			state,
			action: {
				payload: {
					branchID: number;
					tpaID: number;
					status: string;
					is_operating: boolean;
					disableUpdateEnabledTerminalCount?: boolean;
				};
			}
		) => {
			const { disableUpdateEnabledTerminalCount = false } =
				action.payload;
			const branch = state.items.find(
				(branch) => branch.id === action.payload.branchID
			);

			if (!branch) {
				return;
			}

			const terminal = branch.tpaList.items.find(
				(terminal) => terminal.id === action.payload.tpaID
			);

			if (!terminal) {
				return;
			}

			const currentStatus = terminal.status;
			const newStatus = action.payload.status;
			const enabledTerminals = branch.enabled_terminals;
			const totalTerminals = branch.total_terminals;

			if (currentStatus === newStatus) {
				return;
			}

			if (!disableUpdateEnabledTerminalCount) {
				//IF PREVIOUS STATUS IS 'CLOSED' OR 'ACTIVE' THEN NEW STATUS IS 'ACTIVE_NO_SCHED'
				//DECREMENT ENABLED TERMINALS
				if (
					currentStatus !== STATUS.ACTIVE_NO_SCHED &&
					newStatus === STATUS.ACTIVE_NO_SCHED &&
					enabledTerminals > 0
				) {
					branch.enabled_terminals -= 1;
				}
				//IF PREVIOUS STATUS IS EQUAL TO ACTIVE NO SCHED THEN NEW STATUS IS 'CLOSED' OR 'ACTIVE'
				//INCREMENT ENABLED TERMINALS
				else if (
					currentStatus === STATUS.ACTIVE_NO_SCHED &&
					[STATUS.CLOSED, STATUS.ACTIVE].includes(newStatus) &&
					enabledTerminals < totalTerminals
				) {
					branch.enabled_terminals += 1;
				}
			}

			terminal.status = newStatus;
			terminal.is_operating = action.payload.is_operating;
		},
	},
});

export const {
	setInitialStateBranchAccessControl,
	setBranchTerminals,
	setBranchToDisable,
	setBranchStatus,
	setBranchTerminalStatus,
} = branchAccessControl.actions;
export default branchAccessControl.reducer;
