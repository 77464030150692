import { Divider, Grid, Paper } from "@material-ui/core";
import classNames from "classnames";
import styles from "./TransactionSummary.module.css";
import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect } from "react";
import { getTransactionsSummary } from "../../../redux/thunks/transaction";

type SummaryStatProps = {
	name: string;
	value: string;
	className?: string;
	[key: string]: any;
};

const SummaryStat: FC<SummaryStatProps> = ({
	name,
	value,
	className,
	...props
}) => (
	<Grid
		container
		direction="column"
		alignItems="flex-start"
		spacing={1}
		classes={{ container: className }}
		{...props}
	>
		<Grid item classes={{ root: styles.stat }}>
			{name}
		</Grid>
		<Grid
			item
			classes={{ root: classNames(styles.stat, styles.statValue) }}
		>
			{value}
		</Grid>
	</Grid>
);

const TransactionSummary = () => {
	const stats: { name: string; value: string }[] = useSelector(
		(state: any) => state.transaction.summary ?? []
	);
	const tpaId = useSelector((state: any) => state.userInfo.terminal.name);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getTransactionsSummary({ tpaId }));
	}, [dispatch, tpaId]);

	return (
		<Paper>
			<Grid
				container
				direction="row"
				classes={{ container: styles.container }}
			>
				<Grid item>
					<div className={styles.header}>
						Today&apos;s Transaction Summary
					</div>
				</Grid>
				<Grid item container justify="space-around" spacing={3}>
					{stats.map(({ name, value }, i) => (
						<Grid key={i} item xs container direction="column">
							<SummaryStat
								name={name}
								value={value}
								alignContent={i === 0 ? "flex-start" : "center"}
							/>
							{i !== stats.length - 1 && (
								<Divider orientation="vertical" />
							)}
						</Grid>
					))}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default TransactionSummary;
