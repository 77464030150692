import {
	Dialog,
	DialogContent,
	DialogTitle as MuiDialogTitle,
	IconButton,
	withStyles,
	createMuiTheme,
	ThemeProvider,
} from "@material-ui/core";
import moment from "moment";
import { Close as CloseIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "./UserTable.module.css";
import React from "react";
import CommonTable from "../../common/CommonTable/CommonTable";

const modalStyles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
		fontFamily: "Poppins-Medium !important",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const UserDialog = withStyles({
	paper: {
		width: "75vw",
		padding: "1em",
	},
})(Dialog);

const datePickerTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#0076C0",
		},
	},

	overrides: {
		MuiButton: {
			label: {
				textTransform: "none",
			},
		},
	},
});

const DialogTitle = withStyles(modalStyles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const headers = [
	{
		label: () => (
			<>
				<div>Date and Time</div>
			</>
		),
		identifier: "date_created",
		render: (value) => moment(value).format("MM/DD/YYYY hh:mm:ss A"),
		style: { minWidth: "210px" },
	},
	{
		label: "Username",
		identifier: "username",
	},
	{
		label: "Action",
		identifier: "action",
		style: { minWidth: "180px" },
	},
	{
		label: "Reason",
		identifier: "reason",
	},
	{
		label: "Remarks",
		identifier: "remarks",
	},
	{
		label: "Source IP",
		identifier: "ip_address",
	},
];

const UserActivityLogsModal = ({
	open,
	name,
	username,
	data,
	onClose,
	page,
	rowsPerPage,
	onPageChange,
}) => {
	return (
		<ThemeProvider theme={datePickerTheme}>
			{" "}
			<UserDialog
				onClose={onClose}
				open={open}
				maxWidth="md"
				classes={{ root: styles.modal }}
				fullWidth
			>
				<DialogTitle onClose={onClose}>
					Activity Logs: {name} ({username})
				</DialogTitle>
				<DialogContent>
					<CommonTable
						headers={headers}
						entries={get(data, "items", [])}
						page={page}
						totalEntries={get(data, "totalCount", 0)}
						rowsPerPage={rowsPerPage}
						onPageChange={onPageChange}
					/>
				</DialogContent>
			</UserDialog>{" "}
		</ThemeProvider>
	);
};

UserActivityLogsModal.propTypes = {
	open: PropTypes.bool,
	name: PropTypes.string,
	username: PropTypes.string,
	onClose: PropTypes.func,
	data: PropTypes.object,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	onPageChange: PropTypes.func,
};

UserActivityLogsModal.defaultProps = {
	open: false,
	name: "",
	username: "",
	onClose: () => {},
	data: [],
	page: 1,
	rowsPerPage: 25,
	onPageChange: () => {},
};

export default UserActivityLogsModal;
