import {
	createMuiTheme,
	InputAdornment,
	makeStyles,
	TextField,
	ThemeProvider,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";

const useStyles = makeStyles({
	label: {
		fontFamily: "Poppins-Regular",
	},
});

const textFieldTheme = createMuiTheme({
	overrides: {
		MuiInputLabel: {
			root: {
				fontFamily: "Poppins-Regular",
				fontSize: "14px",
			},
		},
	},
});

const SearchField = ({ label, ...props }) => {
	const classes = useStyles();
	return (
		<ThemeProvider theme={textFieldTheme}>
			<TextField
				{...props}
				them
				fullWidth
				label={label}
				variant="outlined"
				classes={{
					root: classes.label,
				}}
				InputProps={{
					...props.InputProps,
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
		</ThemeProvider>
	);
};

SearchField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

export default SearchField;
