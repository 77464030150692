import React from "react";
import { useSelector } from "react-redux";
import ReceiptData from "./ReceiptData";

import styles from "./PrintReceipt.module.css";

export const SSScode = ["SSS01"];
export const PlhCode = ["PHLTH", "PHLT1"];

const PrintReceipt = (_, ref) => {
	const selectedForPrint = useSelector((state) =>
		state.transaction.selectedForPrint != null
			? state.transaction.selectedForPrint.values
			: null
	);
	const tpaId = useSelector((state) => state.userInfo.terminal.name);
	const bottomCSS = styles.positionBayadFormBottom;

	return (
		<>
			<div className="printme" style={{ padding: "0" }} ref={ref}>
				<div className={styles.positionBayadFormTop}>
					<ReceiptData
						selectedForPrint={selectedForPrint}
						tpaId={tpaId}
					/>
				</div>
				<div className={bottomCSS}>
					<ReceiptData
						selectedForPrint={selectedForPrint}
						tpaId={tpaId}
					/>
				</div>
			</div>
		</>
	);
};

export default React.forwardRef(PrintReceipt);
