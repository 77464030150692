import { useState, FC, useCallback, useEffect } from "react";
import AccessDeniedDialog from "../Dialog/Dialog";
import HTTP from "../../helpers/ApiClient";
import failedIcon from "../../assets/icons/ic-failed.svg";
import { setSessionStorage } from "../../utils/storage";
import { AxiosInstance } from "axios";

export type AccessObject = "TPAID" | "Branch" | "User";
type AccessCheckerProps = {
	accessType: AccessObject;
	value: string;
	callback: () => void;
};

type EnabledStatus = {
	status: boolean;
	isEnabled: boolean;
	isApi3Enabled: boolean;
};

export async function getEnabledStatus(
	value: string,
	accessObject: AccessObject,
	instance: AxiosInstance = HTTP
): Promise<EnabledStatus> {
	const apiUrls = {
		Branch: `/v3/branch-access/${value}/is-enabled`,
		TPAID: `/v3/terminal/${value}/is-enabled`,
		User: `/v3/channel/is-enabled?userHash=${value}`,
		Channel: undefined,
	};
	let data;
	console.log("API URL", apiUrls[accessObject]);
	if (apiUrls[accessObject]) {
		const response = await instance.get(apiUrls[accessObject]);
		console.log("API URL RESPONSE", response);
		data = response.data;
	}

	if (data != undefined && Array.isArray(data.data)) {
		console.log("Access Checker Starting Logs...");
		console.log("Is enabled / API 3.0", data.data);
		setSessionStorage("accountId", data.data[0]?.account_id);
		const element = data.data?.find(
			(item) => item.is_api3_enabled || item.is_enabled
		);
		console.log("Access Checker Element", element);
		const dataResponse = {
			status: element.is_api3_enabled || element.is_enabled,
			isApi3Enabled: element.is_api3_enabled,
			isEnabled: element.is_enabled,
		};
		console.log("Access Checker Data", dataResponse);
		return dataResponse;
	} else if (!apiUrls[accessObject]) {
		console.log("CHANNEL VALUE", value);
		data = { data: value };
	}

	return {
		status: data.data.is_enabled && data.data.is_api3_enabled,
		isEnabled: data.data.is_enabled,
		isApi3Enabled: data.data.is_api3_enabled,
	};
}

export const accessErrorMessages = (platform, status) => {
	let disabledAPI = "";
	if (!status.isEnabled && !status.isApi3Enabled) {
		disabledAPI = "BIP (API 3.0) and BFA Admin Portal";
	} else if (!status.isEnabled) {
		disabledAPI = "BFA Admin Portal";
	} else if (!status.isApi3Enabled) {
		disabledAPI = "BIP (API 3.0)";
	}

	switch (platform) {
		case "TPAID":
			return (
				<>
					This TPAID is disabled
					<br />
					in {disabledAPI} at the moment. Kindly contact your Admin
					<br />
					for assistance.
				</>
			);
		case "Branch":
			return (
				<>
					This Branch is disabled
					<br />
					in {disabledAPI} at the moment. Kindly contact your Admin
					for assistance.
				</>
			);
		case "User":
			return (
				<>
					This TPA/Channel Account is disabled
					<br />
					in {disabledAPI} at the moment. Kindly contact your Admin
					<br />
					for assistance.
				</>
			);
		case "Channel":
			return (
				<>
					This TPA/Channel Account is disabled
					<br />
					in {disabledAPI} at the moment. Kindly contact your Admin
					<br />
					for assistance.
				</>
			);
	}
};

const AccessChecker: FC<AccessCheckerProps> = ({
	accessType,
	value,
	callback = () => {},
}) => {
	const [visible, setVisible] = useState(false);
	const [enabled, setEnabled] = useState({
		isEnabled: true,
		isApi3Enabled: true,
		status: true,
	});
	const handleClose = useCallback(() => {
		setVisible(false);
		callback();
	}, [callback]);

	useEffect(() => {
		(async () => {
			if (value) {
				const enabled = await getEnabledStatus(value, accessType);
				setEnabled(enabled);
				console.log("Access Type Enabled Status", enabled.status);
				if (enabled.status == undefined) {
					console.log("UNDEFINED ENABLED type", accessType);
					console.log("UNDEFINED ENABLED STATUS", enabled);
				}
				if (!enabled.status && enabled.status != undefined) {
					setVisible(true);
				}
			}
		})();
	}, [value, accessType]);

	return (
		<AccessDeniedDialog
			imgSrc={failedIcon}
			open={visible}
			toggleDialog={handleClose}
			title="Access Denied!"
			message={accessErrorMessages(accessType, enabled)}
		/>
	);
};

export default AccessChecker;
