import cx from "classnames";
import type { FC, ReactNode } from "react";
import { useCallback, useMemo, useState } from "react";

import styles from "./TabView.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionsSummary } from "../../../redux/thunks/transaction";

type TransactionsHistoryTab = "transactions" | "cash-flow";

export type Tab = {
	key: TransactionsHistoryTab;
	label: string;
	component: ReactNode;
};

export type TabViewProps = {
	tabs: Tab[];
	initial?: TransactionsHistoryTab;
};

const TabView: FC<TabViewProps> = ({ tabs = [], initial = "transactions" }) => {
	const [activeTab, setActiveTab] = useState<TransactionsHistoryTab>(initial);
	const dispatch = useDispatch();
	const tpaId = useSelector((state: any) => state.userInfo.terminal.name);

	const handleChangeTab = useCallback(
		(tabKey: TransactionsHistoryTab) => {
			dispatch(getTransactionsSummary({ tpaId }));
			setActiveTab(tabKey);
		},
		[dispatch, tpaId]
	);

	const component = useMemo(() => {
		return tabs.find((tab) => tab.key === activeTab)?.component;
	}, [activeTab, tabs]);

	return (
		<>
			<div className={cx(styles.container)}>
				{tabs.map((tab) => {
					return (
						<div
							onClick={() => handleChangeTab(tab.key)}
							key={tab.key}
							className={cx(styles.tab, {
								[styles.active]: tab.key === activeTab,
							})}
						>
							{tab.label}
						</div>
					);
				})}
			</div>
			{component}
		</>
	);
};

export default TabView;
