import moment from "moment";
import { addFirstDaytoMonthYearFormat } from "../../../utils/dateUtil";
import { TextField, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";


const SSSContributionDetails = (props) => {
	const { validTransactions, data, transaction, styles, classes } = props;
    const details = validTransactions[data.code].find(data => data.account === transaction.referenceNumber)
	return (
		<>
            {!isEmpty(details) && !isEmpty(details['details']) &&(
                <>
                    {details.code === 'SSSC1' && (
                        <>
                            <TextField size="small" fullWidth variant="outlined" name={'PayFor'}
                                style={{width: "200px"}}
                                InputProps={{
                                    classes: {
                                        input: styles.textInput,
                                        root: classes.inputWithValue,
                                    },
                                    disabled: true
                                }}
                                label={
                                    <Typography className={classes.inputLabel}>
                                        Pay for
                                    </Typography>
                                }
                                value={details['details']['PayFor']}
                            ></TextField>
                            {details['otherInfo']['PayFor'] === 'BRN' && (
                                <TextField size="small" fullWidth variant="outlined" name={'PaymentType'}
                                    style={{width: "200px"}}
                                    InputProps={{
                                        classes: {
                                            input: styles.textInput,
                                            root: classes.inputWithValue,
                                        },
                                        disabled: true
                                    }}
                                    label={
                                        <Typography className={classes.inputLabel}>
                                            Payment Type
                                        </Typography>
                                    }
                                    value={details['details']['PaymentType']}
                                ></TextField>)
                            }
                        </>
                    )}
                    <TextField size="small" fullWidth variant="outlined" name={'name'}
                        style={{width: "200px"}}
                        InputProps={{
                            classes: {
                                input: styles.textInput,
                                root: classes.inputWithValue,
                            },
                            disabled: true
                        }}
                        label={
                            <Typography className={classes.inputLabel}>
                                {details['otherInfo']['PayFor'] === "BRN" ? `Employer's Name` : `Member Name`}
                            </Typography>
                        }
                        value={details['otherInfo']['PayFor'] === "BRN" ? 
                            details['details']['Employer/CompanyName'] : 
                            details['details']['PayorName']}
                    ></TextField>
                    <TextField size="small" fullWidth variant="outlined" name={'applicablePeriod'}
                        style={{width: "200px"}}
                        InputProps={{
                            classes: {
                                input: styles.textInput,
                                root: classes.inputWithValue,
                            },
                            disabled: true
                        }}
                        label={
                            <Typography className={classes.inputLabel}>
                                Applicable Period
                            </Typography>
                        }
                        value={details['otherInfo']['PayFor'] === "BRN" ? 
                            moment(addFirstDaytoMonthYearFormat(details['details']['ApplicablePeriod'])).format("MMM YYYY") : 
                            `${moment(addFirstDaytoMonthYearFormat(details['details']['From'])).format("MMM")} - ${moment(addFirstDaytoMonthYearFormat(details['details']['To'])).format("MMM YYYY")}`}
                    ></TextField>
                </>
            )}
        </>
	);
};

export default SSSContributionDetails;