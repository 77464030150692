import { styled } from "@material-ui/core";
import {
	TextField,
	createMuiTheme,
	InputAdornment,
	makeStyles,
	ThemeProvider,
} from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "./Buttons";

const stylingButton = {
	backgroundColor: "#8B9095",
	textTransform: "capitalize",
	fontFamily: "normal normal normal 12px/16px Poppins-Regular",
};

const textFieldTheme = createMuiTheme({
	overrides: {
		MuiInputLabel: {
			root: {
				fontFamily: "Poppins-Regular",
				fontSize: "12px",
			},
		},
	},
});

const SearchBar = ({
	label,
	styling,
	icon,
	handleOnClick,
	placeholder,
	handleOnChange,
	...props
}) => {
	return (
		<ThemeProvider theme={textFieldTheme}>
			<TextField
				{...props}
				label={label}
				placeholder={placeholder}
				onChange={handleOnChange}
				variant="outlined"
				InputProps={{
					...props.InputProps,
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="search"
								onClick={handleOnClick}
								edge="end"
							>
								<SearchIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</ThemeProvider>
	);
};

export default SearchBar;
