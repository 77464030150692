import { Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSubscription } from "@apollo/client";
import { UserInfoUtil } from "../../utils/checkSession";
import { ON_UPDATE_TELLER_REPRINT_NOTIFICATION } from "../../graphql/queries";
import styles from "./Notifications.module.css";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import { updateCurrentReprintNotification } from "../../redux/modules/reprint";

function NotificationTellerBackground({ updateCurrentReprintNotification }) {
	const [notifSuccess, setNotifSuccess] = useState({
		visible: false,
		message: "",
	});

	const [notifError, setNotifError] = useState({
		visible: false,
		message: "",
	});
	const { uuid: user_id } = UserInfoUtil.get();
	const options = {
		variables: { user_id: user_id },
		context: { clientName: "reprint" },
	};

	const { data: subData } = useSubscription(
		ON_UPDATE_TELLER_REPRINT_NOTIFICATION,
		options
	);

	useEffect(() => {
		if (subData && subData.onUpdateReprintStatus) {
			const repStatus = subData.onUpdateReprintStatus;
			if (repStatus.notif_state === "APPROVED") {
				setNotifSuccess({
					visible: true,
					message: `Your request to reprint the receipt ${repStatus.receipt_no} was approved by your supervisor.`,
				});
			}
			if (repStatus.notif_state === "DECLINED") {
				setNotifError({
					visible: true,
					message: `Your request to reprint the receipt ${repStatus.receipt_no} was declined by your supervisor.`,
				});
			}
			updateCurrentReprintNotification([subData.onUpdateReprintStatus]);
		}
	}, [subData]);

	return (
		<>
			<Snackbar
				open={notifError.visible}
				autoHideDuration={7000}
				onClose={() => setNotifError({ visible: false, message: "" })}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				key={0}
			>
				<Alert
					severity="error"
					className={styles.errorNotif}
					sx={{ width: "100%" }}
					onClose={() =>
						setNotifError({ visible: false, message: "" })
					}
				>
					{notifError.message}
				</Alert>
			</Snackbar>
			<Snackbar
				open={notifSuccess.visible}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				autoHideDuration={7000}
				onClose={() => setNotifSuccess({ visible: false, message: "" })}
				key={0}
			>
				<Alert
					severity="success"
					className={styles.successNotif}
					sx={{ width: "100%" }}
					onClose={() =>
						setNotifSuccess({ visible: false, message: "" })
					}
				>
					{notifSuccess.message}
				</Alert>
			</Snackbar>
		</>
	);
}

export default connect(
	(state) => ({ reprintNotifList: state.reprint.currentReprintNotification }),
	{ updateCurrentReprintNotification }
)(NotificationTellerBackground);
