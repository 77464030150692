import React from "react";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./TransactionSummary.module.css";
import Divider from "@material-ui/core/Divider";

export default function PrintDropdown({
  name,
  menuitem,
  onUanbleClick,
  ...rest
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (onClick) => {
    setAnchorEl(null);
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<PrintIcon />}
        endIcon={<ArrowDropDownIcon />}
        {...rest}
        onClick={handleClick}
        classes={{ root: styles.printButtonRoot, disabled: styles.disabled }}
      >
        {name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        {menuitem &&
          menuitem.map((menu, i) => (
            <MenuItem
              key={i}
              classes={{
                gutters: styles.menuItemList,
                selected: styles.menuListSelected,
              }}
              ListItemClasses={{
                focusVisible: styles.menuItemFocusVisible,
              }}
              onClick={() => {
                handleClose(menu.onClick);
              }}
            >
              {menu.name}
            </MenuItem>
          ))}
        <Divider />
        <MenuItem
          onClick={() => handleClose(onUanbleClick)}
          classes={{
            gutters: styles.unableToPrintItem,
            selected: styles.menuListSelected,
          }}
          ListItemClasses={{
            focusVisible: styles.menuItemFocusVisible,
          }}
        >
          Unable to Print
        </MenuItem>
      </Menu>
    </>
  );
}
