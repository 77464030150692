import React from "react";
import { InitialState as WalletBalanceState } from "../../redux/modules/walletBalance";
import walletSvg from "../../assets/icons/img-wallet.svg";
import { TerminalUtil } from "../../utils/checkSession";
import WALLET_TYPE from "../../constants/walletType";
import styles from "./WalletBalanceCard.module.css";
import { useSelector } from "react-redux";
import cx from "classnames";
import Alert from "./Alert";

const WalletBalanceCard: React.FC<{}> = () => {
	const terminal = TerminalUtil.get();
	const { id, balance, status, type, showAlert, lastUpdate } = useSelector<
		any,
		WalletBalanceState
	>((state: any) => state.walletBalance);
	const isDeactivated =
		["INACTIVE", "DEACTIVATED"].some((e) => status === e) ||
		parseFloat(balance || "0") <= 0;
	const isActive = status === "ACTIVE";

	const formatNumber = (num: number) => {
		return new Intl.NumberFormat("en-US", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(num);
	};

	return id ? (
		<div
			className={cx(styles.cardContainer, {
				[styles.gray]: isDeactivated,
				[styles.green]: isActive && !showAlert,
				[styles.red]:
					parseFloat(balance || "0") <= 0 || (showAlert && isActive),
			})}
		>
			{showAlert && <Alert />}
			<div className={styles.body}>
				<img
					className={styles.icon}
					src={walletSvg}
					alt="Wallet Icon"
				/>
				<div className={styles.balanceContainer}>
					<span>Wallet Balance:</span>
					<h1>
						{/* @ts-ignore:next-line */}
						{formatNumber(parseFloat(balance || "0"))}
					</h1>
				</div>
			</div>

			<div className={styles.footer}>
				<div className={styles.column}>
					<div>
						<span>ID: </span>
						<b>{id}</b>
					</div>
					<div>
						<span>Type: </span>
						<b>{WALLET_TYPE[type]}</b>
					</div>
				</div>
				<div className={styles.column}>
					<div>
						<span>TPA ID Name: </span>
						<br />
						<b>
							TPA-{terminal.id}/{terminal.name}
						</b>
					</div>
					<div>
						<span>Last Update: </span>
						<br />
						<b>{lastUpdate}</b>
					</div>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};

export default WalletBalanceCard;
