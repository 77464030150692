import {
	createMuiTheme,
	FormControl,
	ThemeProvider,
	makeStyles,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useField } from "formik";
import { BsFillCalendarFill } from "react-icons/bs";

const DATE_FORMAT = "MM/DD/yyyy";

const datePickerTheme = createMuiTheme({
	overrides: {
		MuiInputLabel: {
			root: {
				fontFamily: "Poppins-Regular",
				fontSize: "14px",
			},
		},
		MuiIconButton: {
			root: {
				fontSize: "1.2rem",
				color: "#8B9095",
			},
		},
		MuiInputBase: {
			input: {
				cursor: "default",
				fontFamily: "Poppins-Regular",
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: "#0076C0",
			},
		},
		MuiPickersDay: {
			daySelected: {
				backgroundColor: "#F26122",
				"&:hover": {
					backgroundColor: "#FF5722",
				},
			},
		},
	},
});

export const makeDateRangeValidator =
	({ dateFrom, dateTo, errorMessage = "Invalid date input" }) =>
	() => {
		const errors = {};
		if (dateFrom && dateTo) {
			if (dateFrom.isAfter(dateTo) || dateTo.isBefore(dateFrom)) {
				errors.dateTo = errorMessage;
			}
		} else {
			if (dateFrom && !dateTo) {
				errors.dateTo = errorMessage;
			}

			if (!dateFrom && dateTo) {
				errors.dateFrom = errorMessage;
			}
		}
		return errors;
	};

const DatePicker = ({ label, ...props }) => {
	const classes = useStyles();
	return (
		<ThemeProvider theme={datePickerTheme}>
			<FormControl variant="outlined" fullWidth>
				<KeyboardDatePicker
					inputVariant="outlined"
					format={DATE_FORMAT}
					variant="inline"
					autoOk
					keyboardIcon={<BsFillCalendarFill />}
					inputProps={{
						readOnly: true,
					}}
					classes={{
						label: classes.label,
					}}
					label={label}
					placeholder={label}
					{...props}
				/>
			</FormControl>
		</ThemeProvider>
	);
};

const useStyles = makeStyles({
	label: {
		fontFamily: "Poppins-Medium",
	},
});

export const DatePickerFormik = (props) => {
	const [field, meta, helpers] = useField(props);
	const currentError = meta.error;

	return (
		<DatePicker
			name={field.name}
			helperText={currentError}
			value={field.value}
			error={Boolean(currentError)}
			onChange={(value) => helpers.setValue(value)}
			{...props}
		/>
	);
};

export default DatePicker;
