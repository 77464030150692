import React, { useCallback, useEffect, useState } from "react";
import {
	Grid,
	makeStyles,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	withStyles,
	IconButton,
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import Pagination from "../Pagination/Pagination";
import Collapse from "@material-ui/core/Collapse";
import _ from "underscore";

export const CommonTableHeadCell = withStyles({
	head: {
		backgroundColor: "#D5D5D5",
		fontFamily: "Poppins-Medium",
		textTransform: "uppercase",
	},
})(TableCell);

export const CommonTableCell = withStyles({
	root: {
		fontFamily: "Poppins-Regular",
	},
})(TableCell);

const useStyles = makeStyles({
	sortable: {
		cursor: "pointer",
	},
	sortableLabel: {
		marginRight: "0.7em",
	},
	noResult: {
		textAlign: "center",
		font: "normal normal normal 14px/18px Poppins-Regular",
		color: "var(--darkerGray)",
		opacity: "1",
		margin: "30px",
	},
	evenRow: ({ striped, evenRowColor }) => ({
		backgroundColor: striped ? evenRowColor : "white",
	}),
	oddRow: ({ striped, oddRowColor }) => ({
		backgroundColor: striped ? oddRowColor : "white",
	}),
});

const isFunctionComponent = (component) => {
	return (
		typeof component === "function" &&
		String(component).includes("return React.createElement")
	);
};

const SortIcon = ({ value }) => {
	if (!value) return <FaSort />;
	const v = value.toLowerCase().trim();
	if (!v) return <FaSort />;

	if (v === "asc") return <FaSortDown />;
	if (v === "desc") return <FaSortUp />;
	return <FaSort />;
};

SortIcon.propTypes = {
	value: PropTypes.string,
};

SortIcon.defaultProps = {
	value: null,
};

const cycleSort = (value = null) => {
	const v = (value || "").toLowerCase();

	if (v === "asc") {
		return "desc";
	}

	return "asc";
};

const TableHeadSection = ({ headers, isWhiteHeader, sortBy, sort, onSort }) => {
	const classes = useStyles();
	return (
		<TableHead>
			<TableRow>
				{headers.map(
					({ label: Label, sortable, identifier, ...props }, i) => {
						const currSort = sortBy === identifier ? sort : null;
						return (
							<CommonTableHeadCell
								{...props}
								key={i}
								classes={{
									head: classNames({
										[classes.sortable]: sortable,
									}),
								}}
								style={
									isWhiteHeader
										? {
												backgroundColor: "#FFFFFF",
												border: 0,
												fontFamily: "Poppins-Medium",
												textTransform: "uppercase",
										  }
										: {}
								}
								onClick={
									sortable
										? () =>
												onSort(
													identifier,
													cycleSort(currSort)
												)
										: () => {}
								}
							>
								<TableSortLabel
									active={sortable}
									hideSortIcon={!sortable}
									IconComponent={() => (
										<SortIcon value={currSort} />
									)}
								>
									<div
										className={classNames({
											[classes.sortableLabel]: sortable,
										})}
									>
										{typeof Label === "string" ? (
											Label
										) : (
											<Label />
										)}
									</div>
								</TableSortLabel>
							</CommonTableHeadCell>
						);
					}
				)}
			</TableRow>
		</TableHead>
	);
};

TableHeadSection.propTypes = {
	headers: PropTypes.arrayOf(PropTypes.object),
};

const Row = ({
	entry,
	classes,
	defaultRender,
	headers,
	expandRow,
	noStripes,
	rest,
	i,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<TableRow
				key={entry.id || uuid()}
				classes={
					!noStripes && {
						root: i % 2 === 0 ? classes.evenRow : classes.oddRow,
					}
				}
			>
				{headers.map((column) => {
					const { identifier, render = defaultRender } = column;
					return (
						<CommonTableCell key={identifier}>
							{render(entry[identifier], entry, rest, {
								isOpen,
								setIsOpen,
							})}
						</CommonTableCell>
					);
				})}
			</TableRow>
			{expandRow && (
				//uuid causes unnecessary rerenders
				<TableRow key={entry.id || uuid()}>
					{isOpen && (
						<TableCell
							colspan={headers.length}
							style={{ margin: 0, padding: 0 }}
						>
							{expandRow(entry)}
						</TableCell>
					)}
				</TableRow>
			)}
		</>
	);
};

const TableBodySection = ({
	headers,
	entries,
	striped,
	evenRowColor,
	oddRowColor,
	expandRow,
	noStripes,
	...rest
}) => {
	const defaultRender = (entry) => entry;
	const classes = useStyles({ striped, evenRowColor, oddRowColor });
	if (entries.length > 0) {
		return (
			<>
				{entries.map((entry, i) => {
					const key = Object.entries(entry)
						.filter(
							(object) =>
								!isFunctionComponent(object[1]) &&
								!React.isValidElement(object[1])
						)
						.reduce((a, b) => (a = { ...a, [b[0]]: b[1] }), {});

					return (
						<Row
							//using JSON.stringify causes issue with expandedRow items.
							//prefer id if it exists
							key={entry.id || JSON.stringify(key) + i}
							entry={entry}
							defaultRender={defaultRender}
							noStripes={noStripes}
							classes={classes}
							headers={headers}
							expandRow={expandRow}
							rest={rest}
							i={i}
						/>
					);
				})}
			</>
		);
	} else {
		return (
			<>
				<TableRow>
					<TableCell colSpan={headers.length}>
						<div className={classes.noResult}>No record found.</div>
					</TableCell>
				</TableRow>
			</>
		);
	}
};

TableBodySection.propTypes = {
	headers: PropTypes.arrayOf(PropTypes.object).isRequired,
	entries: PropTypes.arrayOf(PropTypes.object).isRequired,
	striped: PropTypes.bool,
	oddRowColor: PropTypes.string,
	evenRowColor: PropTypes.string,
};

const CommonTableContainer = withStyles({
	root: {
		minWidth: "600px",
		borderRadius: 10,
		border: "1px solid #D5D5D5",
	},
})(TableContainer);

const CommonTable = ({
	headers,
	entries,
	noPagination = false,
	noStripes = false,
	isWhiteHeader = false,
	borderless = false,
	className,
	spacing = 3,
	...rest
}) => {
	const {
		page,
		onPageChange,
		rowsPerPage,
		totalEntries,
		size,
		sort,
		sortBy,
		onSort,
		evenRowColor,
		oddRowColor,
		striped,
		isHidePageNumber,
	} = rest;

	const _headers = rest.expandRow
		? [
				{
					label: "",
					identifier: "",
					width: "88px",
					render: (_, __, ___, { isOpen, setIsOpen }) =>
						isOpen ? (
							<IconButton
								aria-label="expand-less"
								onClick={() => setIsOpen(false)}
							>
								<ExpandLess />
							</IconButton>
						) : (
							<IconButton
								aria-label="expand-more"
								onClick={() => setIsOpen(true)}
							>
								<ExpandMore />
							</IconButton>
						),
				},
				...headers,
		  ]
		: headers;

	return (
		<Grid container spacing={spacing} direction="column">
			<Grid item>
				<CommonTableContainer
					style={borderless ? { border: 0 } : {}}
					className={className}
				>
					<Table size={size}>
						<TableHeadSection
							headers={_headers}
							sort={sort}
							sortBy={sortBy}
							onSort={onSort}
							isWhiteHeader={isWhiteHeader}
						/>
						<TableBodySection
							headers={_headers}
							entries={entries}
							evenRowColor={evenRowColor}
							oddRowColor={oddRowColor}
							striped={striped}
							noStripes={noStripes}
							{...rest}
						/>
					</Table>
				</CommonTableContainer>
			</Grid>
			{!noPagination && (
				<Grid item>
					<Pagination
						page={page}
						totalCount={totalEntries}
						rowsPerPage={rowsPerPage}
						onPageChange={onPageChange}
						isHidePageNumber={isHidePageNumber}
					/>
				</Grid>
			)}
		</Grid>
	);
};

CommonTable.propTypes = {
	headers: PropTypes.arrayOf(PropTypes.object).isRequired,
	entries: PropTypes.arrayOf(PropTypes.object).isRequired,
	page: PropTypes.number.isRequired,
	totalEntries: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	onSort: PropTypes.func,
	onPageChange: PropTypes.func,
	sort: PropTypes.string,
	sortBy: PropTypes.string,
	size: PropTypes.string,
	onRowSelect: PropTypes.func,
	oddRowColor: PropTypes.string,
	evenRowColor: PropTypes.string,
	noPagination: PropTypes.bool,
};

CommonTable.defaultProps = {
	size: "small",
	sort: null,
	sortBy: null,
	onSort: () => {},
	onRowSelect: () => {},
	striped: true,
	oddRowColor: "#F3FAFF",
	evenRowColor: "white",
};

export default CommonTable;
