import { Grid } from "@material-ui/core";
import DatePicker from "../../Inputs/DatePicker";
import ClearFilterButton from "../../Transaction/TransactionFilter/ClearFilterButton";
import FilterContainer from "../../common/Filter/FilterContainer";
import SelectFilter from "./SelectFilter";
import BillerField from "./BillerField";
import { FC, useCallback, useMemo } from "react";
import { Moment } from "moment";

const TRANSACTION_OPTIONS = [
	{ name: "Bills Payment", value: "BILLS_PAYMENT" },
	{ name: "E-Loading", value: "E_LOADING" },
	{ name: "Instasurance", value: "INSTASURANCE" },
	{ name: "Pay Out", value: "PAY_OUT" },
	{ name: "Remittance In", value: "REMITTANCE_IN" },
	{ name: "Remittance Out", value: "REMITTANCE_OUT" },
	{ name: "Wallet Replenishment", value: "WALLET_REPLENISHMENT" },
	{ name: "Reversal", value: "REVERSAL" },
	{ name: "Wallet (Cash-Out)", value: "WALLET_CASH_OUT" },
	{ name: "Wallet (Top-Up)", value: "WALLET_TOP_UP" },
	{ name: "Wallet Adjustment - Credit", value: "WALLET_ADJUSTMENT_CREDIT" },
	{ name: "Wallet Adjustment - Debit", value: "WALLET_ADJUSTMENT_DEBIT" },
];

type TCashFlowFilter = {
	dateFrom?: Moment | null;
	dateTo?: Moment | null;
	biller: string | null;
	transactionType: string | null;
};

type CashFlowFilterProps = {
	clearFilters: () => void;
	filters: TCashFlowFilter;
	handlers: {
		[key: string]: Function;
	};
};

const CashFlowFilter: FC<CashFlowFilterProps> = ({
	clearFilters,
	filters,
	handlers,
}) => {
	const handleDateChange = useCallback(
		(value) => {
			const dateRange = {
				dateTo: filters.dateTo,
				dateFrom: filters.dateFrom,
			};

			handlers.dateRange({
				...dateRange,
				...value,
			});
		},
		[filters, handlers]
	);

	const invalidDateRange = useMemo<string | boolean>(() => {
		if (
			(!filters.dateFrom && !filters.dateTo) ||
			filters.dateFrom?.isSame(filters.dateTo)
		) {
			return false;
		}

		if (filters.dateFrom && !filters.dateTo) {
			return "Invalid date input";
		}

		if (!filters.dateFrom?.isBefore(filters.dateTo)) {
			return "You've selected an invalid date";
		}

		return "";
	}, [filters]);

	return (
		<FilterContainer>
			<Grid container justify="flex-end" direction="column">
				<Grid item xs container justify="flex-end">
					<ClearFilterButton onClear={clearFilters} />
				</Grid>
				<Grid
					item
					container
					spacing={3}
					direction="row"
					alignItems="center"
					xs
				>
					<Grid item>Filters:</Grid>
					<Grid item container xs>
						<Grid item container spacing={2} justify="space-evenly">
							<Grid item xs>
								<DatePicker
									label="Date From"
									name="dateFrom"
									value={filters.dateFrom}
									onChange={(value: Moment) =>
										handleDateChange({ dateFrom: value })
									}
								/>
							</Grid>
							<Grid item xs>
								<DatePicker
									label="Date To"
									name="dateTo"
									value={filters.dateTo}
									onChange={(value: Moment) =>
										handleDateChange({ dateTo: value })
									}
									error={!!invalidDateRange}
									helperText={invalidDateRange}
								/>
							</Grid>
							<Grid item xs>
								<BillerField
									value={filters.biller}
									onSelect={handlers.biller}
								/>
							</Grid>
							<Grid item xs>
								<SelectFilter
									options={TRANSACTION_OPTIONS}
									label="Service Type"
									onChange={(e) =>
										handlers.transactionType(e.target.value)
									}
									value={filters.transactionType}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</FilterContainer>
	);
};

export default CashFlowFilter;
