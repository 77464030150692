import {
	Grid,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { ReactComponent as DateIcon } from "../../../assets/icons/ic-date-input.svg";
import tStyles from "../TransactionList.module.css";
import CustomTooltip from "./CustomTooltip";

function CustomField(props: any) {
	const { field, classes, value, ...other } = props;

	return (
		<Grid item md={3} key={field.value}>
			<CustomTooltip
				title={field.readonly ? value : ""}
				placement={"right"}
				arrow
			>
				<TextField
					size="small"
					fullWidth
					variant="outlined"
					name={field.value}
					select={field.type == "dropdown"}
					InputProps={{
						classes: {
							input: tStyles.textInput,
						},
						endAdornment: (
							<InputAdornment position="end">
								{field.type == "date" ? (
									<DateIcon></DateIcon>
								) : (
									""
								)}
							</InputAdornment>
						),
						disabled: field.readonly,
					}}
					label={
						<Typography
							className={`${
								field.label.length < 21
									? classes.inputLabel
									: classes.inputLabelSmall
							}`}
						>
							{field.label}
						</Typography>
					}
					value={value}
					onChange={(v) => {}}
				></TextField>
			</CustomTooltip>
		</Grid>
	);
}

export default CustomField;
