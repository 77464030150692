import { Box, Paper, withStyles } from "@material-ui/core";
import CashFlow from "../CashFlow/CashFlow";
import TransactionSummary from "../Transaction/TransactionSummary/TransactionSummary";
import Transactions from "../Transaction/Transactions";
import TabView from "./TabView/TabView";
import styles from "./TransactionsHistory.module.css";

const Container = withStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
}))(Paper);

const TransactionsHistory = () => {
	return (
		<Box p={1}>
			<TransactionSummary />
			<Container>
				<TabView
					tabs={[
						{
							key: "transactions",
							label: "Transaction List",
							component: <Transactions />,
						},
						{
							key: "cash-flow",
							label: "Wallet Cash Flow",
							component: <CashFlow />,
						},
					]}
					initial={"transactions"}
				/>
			</Container>
			<Box p={1} className={styles.version}>
				Bayad FA version 1.0
			</Box>
		</Box>
	);
};

export default TransactionsHistory;
