import styles from "./CheckReminder.module.css";
import { ReactComponent as WarningIcon } from "../../../assets/icons/ic-warning.svg";

function CheckReminder(props: any) {
	const { selectedBillers, billerDetails, ...others } = props;
	const availableSpiel = "To be available soon.";

	return (
		<div className={styles.reminderWrapper}>
			<div className={styles.icon}>
				<WarningIcon />
			</div>
			<div className={styles.textContainer}>
				<div>
					Please ensure to input the correct payee name to avoid
					cancellation of checks and possible penalties.
				</div>
				<div style={{ marginTop: "18px" }}>
					<b>{selectedBillers[0].description}</b> Check Payment Details
					<div>
						<table>
							<tbody>
								<tr>
									<td style={{ width: "15%" }}>
										<b>Bank</b>
									</td>
									<td>:</td>
									<td>
										{billerDetails.bank_name ? (
											billerDetails.bank_name
										) : (
											<b>{availableSpiel}</b>
										)}
									</td>
								</tr>
								<tr>
									<td>
										<b>Accepted Payee Name</b>
									</td>
									<td>:</td>
									<td>
										{billerDetails.payee_names ? (
											billerDetails.payee_names
										) : (
											<b>{availableSpiel}</b>
										)}
									</td>
								</tr>
								<tr>
									<td>
										<b>Account No</b>
									</td>
									<td>:</td>
									<td>
										{billerDetails.account_no ? (
											billerDetails.account_no
										) : (
											<b>{availableSpiel}</b>
										)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CheckReminder;
