import { useState, FC, useCallback, useEffect } from "react";
import AccessDeniedDialog from "../Dialog/Dialog";
import failedIcon from "../../assets/icons/ic-failed.svg";

type ProductStatusType = {
	api3_status: boolean;
	bfaap_status: boolean;
};

type BillerStatusType = {
	api3: boolean;
	bfaap: boolean;
	isActive: boolean;
};

type BFAStatusProps = {
	productStatus: ProductStatusType;
	billerStatus: BillerStatusType;
	callback: () => void;
};

export const PRODUCT_CODE = {
	BillsPayment: "BPY",
	BayadTravel: "BAYAD_TRAVEL",
	BuyLoad: "BUY_LOAD",
	Remittance: "REMITTANCE",
	CashOutPayOut: "CASH_OUT",
	CashInTopUp: "CASH_IN",
	eCPTL: "E_CTPL",
	Instasurance: "INSTASURANCE",
	BayadCheckout: "BAYAD_CHECKOUT",
	BayadCheckoutOTC: "BAYAD_CHECKOUT_OTC",
	BayadATM: "BAYAD_ATM",
	BayadMedAssist: "BAYAD_MED",
};

export const accessErrorMessages = (entity, platform) => (
	<>
		Access Denied - This {entity} is not available. Please contact your
		admin to enable this product in {platform}
	</>
);

const BFAStatus: FC<BFAStatusProps> = ({
	productStatus,
	billerStatus,
	callback = () => {},
}) => {
	const [visible, setVisible] = useState(false);
	const [errorMessage, setErrorMessage] = useState(<></>);

	const handleClose = useCallback(() => {
		setVisible(false);
		callback();
	}, [callback]);

	useEffect(() => {
		(async () => {
			let platform = "";
			console.log("BFAStatus Cmpnt ProductStatus:", productStatus);
			if (!productStatus) return;
			console.log("Start BFA Paybill Status Cmpnt Logging.....");
			console.log("BFA Paybill Status Response", productStatus);
			console.log(
				"BFA Status Paybill API 3.0 Status",
				productStatus?.api3_status
			);
			console.log(
				"BFA Status Paybill BFA AP Status",
				productStatus?.bfaap_status
			);

			console.log("Start BFA Biller Status Cmpnt Logging.....");
			console.log("BFA Biller Status Response", billerStatus);
			console.log("BFA Biller Status API 3.0 Status", billerStatus?.api3);
			console.log("BFA Biller Status BFA AP Status", billerStatus?.bfaap);

			if (!productStatus?.api3_status || !productStatus?.bfaap_status) {
				if (
					!productStatus?.api3_status &&
					!productStatus?.bfaap_status
				) {
					platform = "BIP (API 3.0) and BFA Admin Portal";
				} else if (!productStatus?.api3_status) {
					platform = "BIP (API 3.0)";
				} else if (!productStatus?.bfaap_status) {
					platform = "BFA Admin Portal";
				}
				setErrorMessage(accessErrorMessages("Product", platform));
				setVisible(true);
				return;
			}

			if (
				billerStatus?.isActive == undefined &&
				billerStatus?.api3 == undefined &&
				billerStatus?.bfaap == undefined
			)
				return;

			if (!billerStatus?.isActive) {
				if (!billerStatus?.api3 && !billerStatus?.bfaap) {
					platform = "BIP (API 3.0) and BFA Admin Portal";
				} else if (!billerStatus?.api3) {
					platform = "BIP (API 3.0)";
				} else if (!billerStatus?.bfaap) {
					platform = "BFA Admin Portal";
				}
				setErrorMessage(
					accessErrorMessages("Product Partner", platform)
				);
				setVisible(true);
			}
		})();
	}, [productStatus, billerStatus]);

	return (
		<AccessDeniedDialog
			imgSrc={failedIcon}
			open={visible}
			toggleDialog={handleClose}
			title="Access Denied!"
			message={errorMessage}
		/>
	);
};

export default BFAStatus;
