export function requestWrapper(showLoader, hideLoader, cb) {
	return async function () {
		try {
			if (showLoader) showLoader();
			return await cb(...arguments);
		} catch (error) {
			console.log(error);
			throw error;
		} finally {
			if (hideLoader) hideLoader();
		}
	};
}
