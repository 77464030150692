// https://github.com/diegohaz/arc/wiki/Atomic-Design
import React from "react";
import PropTypes from "prop-types";
import Slide from "@mui/material/Slide";
import { Dialog, Divider, makeStyles, Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import styles from "../../../components/App/App.module.css";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import success from "../../../assets/icons/ic-success.svg";
import hourglass from "../../../assets/icons/ic-success.svg";
import dialog from "../../../redux/modules/dialog";
import { useHistory } from "react-router-dom";

const DialogContent = withStyles((theme) => ({
	root: {
		borderBottom: "0.5px solid #d8d8d8",
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		color: theme.palette.text.primary,
		fontSize: 14,
		lineHeight: "21px",
		fontVariant: "normal",
		letterSpacing: 0,
		padding: "0 0 11px 0",
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		alignItems:"center",
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export const OrangeColorButton = withStyles((theme) => ({
	root: {
		color: "#FFFFFF",
		backgroundColor: "#F26122",
		"&:hover": {
			backgroundColor: "#E1571C",
		},
	},
}))(Button);

const GreenColorButton = withStyles((theme) => ({
	root: {
		width: "126px",
		height: "42px",
		color: "#FFFFFF",
		backgroundColor: "#32AA4D",
		"&:hover": {
			backgroundColor: "#138906",
		},
	},
}))(Button);

const WhiteColorButton = withStyles((theme) => ({
	root: {
		width: "126px",
		height: "42px",
		color: "#4B5051",
		align: "center",
		backgroundColor: "#FFFFFF",
		border: "1px solid #CED4DA",
		"&:hover": {
			backgroundColor: "#32AA4D",
		},
	},
}))(Button);


const ImageBox = styled(Box)({
  justifyContent: "center",
  textAlign: "center", 
  padding: "10px"
});

const DynamicDialog = ({
  isOpen,
  onClose,
  buttonLabel,
  onOk,
  dialogEntry,
  isExtraLineSubHeader,
  isSecondaryBtn,
  onCloseModal,
  onConfirm,
  ...props
}) => {console.log(dialogEntry)
  return (
    <Dialog
		PaperProps={{ style: { width: "400px" } }}
		onClose={onCloseModal}
		open={isOpen}
	>
		<ImageBox>
          <img
            src={dialogEntry?.icon ? dialogEntry.icon : ""}
            loading="lazy"
            alt={dialogEntry?.icon ? dialogEntry.icon : ""}
	
          />
        </ImageBox>
		<DialogContent>
			<Typography
				align="center"
			>
				{dialogEntry.resultMessage}
			</Typography>
		</DialogContent>
		<DialogActions>
			<WhiteColorButton
				autoFocus
				onClick={dialogEntry.primaryBtnFunction}
				color="primary"
			>
				{dialogEntry.primaryBtnLabel}
			</WhiteColorButton>

			{dialogEntry.hasSecondaryButton && (
				<GreenColorButton
				autoFocus
				onClickHandler={dialogEntry.primaryBtnFunction}
				color="primary"
			>
				Confirm
			</GreenColorButton>
			)}						
		</DialogActions>
	</Dialog>
  );
};

DynamicDialog.propTypes = {
  isOpen: PropTypes.bool,
  onOk: PropTypes.func,
  isExtraLineSubHeader: PropTypes.bool,
};

export default DynamicDialog;
