import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	customerInformation: {},
	bayadID: null,
	isLoading: false,
	error: {},
	message: "",
};

const kycInquiry = createSlice({
	name: "kycInquiry",
	initialState,
	reducers: {
		prep: (state, payload) => {
			state.isLoading = true;
		},
		load: (state, payload) => {
			state.isLoading = false;
			state.customerInformation = payload.result.data;
			state.message = payload.result.message;
		},
		fulfilled: (state, payload) => {
			//
			state.isLoading = false;
			state.error = payload.result.message;
			state.errorStatus = payload.result.errorCode;
			state.message = payload.result.message;
		},
		error: (state, payload) => {
			//
			console.log("___error", state, "", payload);
		},
		verifyInfo: (state, action) => {
			state.bayadID = action.payload;
		},
		setKYCInformation: (state, action) => {
			state.customerInformation = action.payload;
		},
		clearCustomerInfo: (state) => {
			state.customerInformation = {};
			state.bayadID = "";
			state.isLoading = false;
			state.error = "";
			state.errorStatus = "";
		},
	},
});

export const {
	prep,
	load,
	fulfilled,
	error,
	verifyInfo,
	clearCustomerInfo,
	setKYCInformation,
} = kycInquiry.actions;

const validateCustomer = async (client, state) => {
	return await client.post("/v2/remittance/kyc/customers/inquiry", {
		bayadId: state.kycInquiry.bayadID,
	});
};

function validateInfo() {
	return {
		types: [prep.type, load.type, fulfilled.type, error.type],
		promise: validateCustomer,
	};
}

const validateCustomerInfo = async (client, state) => {
	return await client.post(
		"/v2/remittance/kyc/customers/inquiry",
		state.kycInquiry.customerInformation
	);
};

function validateCustomerInfoActionCreator() {
	return {
		types: [prep.type, load.type, fulfilled.type, error.type],
		promise: validateCustomerInfo,
	};
}

export default kycInquiry.reducer;
export { validateInfo, validateCustomerInfoActionCreator };
