import React, {Component} from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import styles from "./PrivacyPolicy.module.css";
import bfaLogoWhite from "../../assets/logo/logo-bayadFA-white.svg"
import scrollTopIcon from "../../assets/icons/ic-top.svg"
import Paper from "@material-ui/core/Paper";
import {Link} from "react-router-dom";

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    executeScroll = () => {
        this.contentRef.current.scrollIntoView();
        this.scrollToTop();
    }

    render() {
        return (
            <div className={styles.policyBackground}>
                <div className={styles.bar}>
                    <img src={bfaLogoWhite} className={styles.bfaLogo} alt="BFA Logo"/>
                </div>
                <Paper>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginTop: '25px',
                    }}>
                        <Link to="/" style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            cursor: "pointer",
                            textDecoration: "none"
                        }}>
                            <ArrowBackIosIcon style={{fontSize: "0.70rem", paddingLeft:"59px"}} />
                            <div className={styles.backBtn}>Back</div>
                        </Link>
                        <div className={styles.privacyTitle}>
                            PRIVACY POLICY
                        </div>
                    </div>
                    <div className={styles.policyContentContainer}>
                        <div className={styles.contents}>
                            <div ref={this.contentRef}>
                                Bayad adheres to data privacy principles of transparency, legitimate purpose, and proportionality.
                            </div>
                            <div className={styles.contentHeader}>
                                WE COLLECT ONLY DATA THAT MATTERS.
                            </div>
                            <div style={{marginTop:"25px"}}>
                                Bayad collects personal and sensitive personal information ranging from name, age, and address, to government issued IDs from our biller and channel partners, suppliers, customers, employees, and guests/visitors thru physical and/or digital means to process transactions or fulfill obligations, and to improve our services.
                            </div>
                            <div className={styles.contentHeader}>
                                WE PROTECT THE DATA THAT WE COLLECT.
                            </div>
                            <div>
                                We have appointed data stewards and information asset custodians to ensure that your data is properly handled and kept secure, in accordance with our Data Handling Policy, data privacy laws, and industry approved standards.
                            </div>
                            <div style={{marginTop:"25px"}}>
                                We will keep your personal data for 1 year from date of transaction unless the law requires a longer period. At the end of the retention period, your personal data shall be deleted and made unrecoverable using approved secure disposal methods (e.g. data sanitization, secure shredding). This includes physical media (e.g. printed, removable media, back-up tapes, etc.).
                            </div>
                            <div style={{marginTop:"25px"}}>
                                We keep a record of how, when, and by whom the information was destroyed, to provide an audit trail.
                            </div>
                            <div className={styles.contentHeader}>
                                WE UPHOLD YOUR RIGHTS.
                            </div>
                            <div>
                                We are an advocate of your rights under the data privacy law. These are the rights to information, access, data portability, rectification, erasure or blocking, as well as to object, to file a complaint, and to indemnification.
                            </div>
                            <div className={styles.contentHeader}>
                                WE ARE AT YOUR SERVICE.
                            </div>
                            <div style={{marginTop:"25px"}}>
                                We update our privacy statement and practices regularly to reflect changes in applicable laws, government and regulatory requirements, technological, industry and business practices. We may notify you on these changes whenever applicable.
                            </div>
                            <div style={{marginTop:"25px"}}>
                                For questions and more information regarding the exercise of your rights as a data subject, please contact:
                            </div>
                            <div style={{marginTop:"35px"}}>
                                Data Protection Officer
                            </div>
                            <div>
                                CIS Bayad Center, Inc.
                            </div>
                            <div>
                                Email: dpo@bayad.com
                            </div>
                            <div>
                                Contact Number: (02)8672-5710
                            </div>
                            <div>
                                Office Address: Ground Floor, Business Solutions Center,
                            </div>
                            <div>
                                Meralco Complex, Ortigas Ave., Pasig City
                            </div>
                        </div>
                    </div>
                    <div style={{textAlign:"right", marginTop:"25px", marginRight:"20px"}}>
                        <div onClick={this.executeScroll} style={{cursor: "pointer"}}>
                            <img src={scrollTopIcon} alt="Scroll to Top Icon"/>
                        </div>
                    </div>
                </Paper>
            </div>
    )
    }
}

export default PrivacyPolicy;
