import { Component } from "react";
import { Grid } from "@material-ui/core";
import dateformat from "dateformat";
import NumberFormatter from "../common/Format/NumberFormatter";
import { BranchUtil } from "../../utils/checkSession";
import { formatNumber } from "../PrintReceipt/PrintReceiptSoa";
import { SSScode, PlhCode } from "./PrintReceipt";
import { isEmpty} from "lodash";
import moment from "moment";
import { addFirstDaytoMonthYearFormat } from "../../utils/dateUtil";

const defaultFont = {
	fontSize: "11px",
	fontFamily: "Monaco",
	fontWeight: 700,
};

// For RLAND Biller
const NATURE_OF_PAYMENT = {
	"PropertyMaintenanceFee": "Property Maintenance Fee",
	"PropertyMaintenanceElectricity": "Property Maintenance - Electricity",
	"PropertyMaintenanceWater": "Property Maintenance - Water",
	"TotalSOAAmount": "Total SOA Amount",
	"FencingBond": "Construction/Fencing Bond",
	"ReservationFee": "Processing / Reservation Fee"
}

// For RLAND Biller
const PROPERTY_NAME = {
	"extraordinaryenclaves-pasinaya": "Extraordinary Enclaves - Pasinaya",
	"everglowenclaves-pagsinag": "Everglow Enclaves - Pagsinag",
	"joy-nostalg-corporate": "Joy-Nostalg - Corporate",
	"everyhomeenclaves-pagsibol": "Everyhome Enclaves - Pagsibol",
	"elanvitalenclaves": "Elanvital Enclaves",
	"elanvitalterraces": "Elanvital Terraces",
	"raemulanlands-corporate": "Raemulan Lands - Corporate"
}

const SSSAdditionalFields = ({ data }) => {
	const sssData = data.details;
	if (isEmpty(sssData))
		return (
			<span style={defaultFont}>
				{" "}
				<b>{data.otherInfo.PayFor}: </b> {data.accountNo}
			</span>
		);

	const name = data.otherInfo.PayFor === 'BRN'? (
		<>
			<b style={{ fontWeight: "bolder" }}>Employer&#39;s Name: </b>{" "}
			{sssData["Employer/CompanyName"]}
		</>
	) : (
		<>
			<b style={{ fontWeight: "bolder" }}>Member Name: </b>{" "}
			{sssData.PayorName}
		</>
	);

	return (
		<>
			<span style={defaultFont}>
				{" "}
				<b>{data.otherInfo.PayFor}: </b> {data.accountNo}
			</span>
			<br />
			<span style={defaultFont}>{name}</span>
			<br />
			{data.service_code.includes("SSS01") && (
				<span style={defaultFont}>
					{" "}
					<b style={{ fontWeight: "bolder" }}>
						Applicable Period:{" "}
					</b>{" "}
					{data.otherInfo.PayFor === 'PRN'
						? `${moment(
								addFirstDaytoMonthYearFormat(sssData.From)
						  ).format("MMM")}-${moment(
								addFirstDaytoMonthYearFormat(sssData.To)
						  ).format("MMM YYYY")}`
						: moment(addFirstDaytoMonthYearFormat(sssData.ApplicablePeriod)
						  ).format("MMM YYYY")}
				</span>
			)}
		</>
	);
};

const PhilheathAddtlData = ({ data = {} }) => {
	const pData = data.otherInfo || {};

	//attempt check if PHLTH has its signature data, revert if need
	if (isEmpty(pData))
		return (
			<span style={defaultFont}>
				{" "}
				<b>A/N: </b> {data.accountNo}
			</span>
		);

	return (
		<>
			{pData.FirstName && (
				<span style={defaultFont}>
					{" "}
					<b>Name: </b> {pData.FirstName + " " + pData.LastName}
				</span>
			)}
			<span style={defaultFont}>
				{" "}
				<b>Member Type: </b> {pData.MemberType}
			</span>
			<br />
			<span style={defaultFont}>
				{" "}
				<b>Applicable Period: </b> {pData.BillDate || pData.PeriodFrom}
				{" - "} {pData.DueDate || pData.PeriodTo}
			</span>
			<br />
			<span style={defaultFont}>
				{" "}
				<b>Amnt due: </b> {pData.amountDue || data.amount} <b> A/N: </b>{" "}
				{data.accountNo}
			</span>
			<br />
			{data?.details?.PHLTHSeqNo && (
				<span style={defaultFont}>
					{" "}
					<b>Philheath TR#: </b> {data?.details?.PHLTHSeqNo}
				</span>
			)}
		</>
	);
};

const RaemulanAddtlData = ({ data = {} }) => {
	const rData = data.otherInfo || {};

	if (isEmpty(rData))
		return (
			<span style={defaultFont}>
				{" "}
				<b>Homeowner Ref No.: </b> {data.accountNo}
			</span>
		);

	return (
		<>
			<span style={defaultFont}>
				<b>Homeowner Ref No.: </b> {data.accountNo}
				{" "}
				{PROPERTY_NAME[rData.PropertyName] && <><b>Property Name: </b> {PROPERTY_NAME[rData.PropertyName]}</>}
				{" "}
				{NATURE_OF_PAYMENT[rData.NatureofPayment] && <><b>Nature of Payment: </b> {NATURE_OF_PAYMENT[rData.NatureofPayment]}</>}
			</span>
		</>
	);
};

const LPUBIAddtlData = ({ data = {} }) => {
	const otherInfo = data.otherInfo || {};

	if (isEmpty(otherInfo))
		return (
			<span style={defaultFont}>
				{" "}
				<b>Student/Applicant No.: </b> {data.accountNo}
			</span>
		);

	return (
		<>
			<span style={defaultFont}>
				<b>Student/Applicant No.: </b> {data.accountNo}
				{" "}
				{otherInfo.studentName && <><b>Student Name: </b> {otherInfo.studentName}</>}
				{" "}
				{otherInfo.typeofPayment && <><b>Type of Payment: </b> {otherInfo.typeofPayment.replace(/_/g, ' ')}</>}
			</span>
		</>
	);
};

// HANDLE LABEL FOR DIFFERENT BILLER
const referenceNumberLabel = (code) => {
	let refLabel = "Acc No.:"
	switch (code) {
		case 'MYEGP':
			refLabel = "Reference No.:"
			break;
		
		case 'SSS02':
			refLabel = "PRN:"
			break;
	
		default:
			refLabel = "Acc No.:"
			break;
	}
	return refLabel
}

const CustomPrintBiller = ({ data, code }) => {
	if (code && SSScode.includes(code))
		return <SSSAdditionalFields data={data} />;

	if (code && PlhCode.includes(code))
		return <PhilheathAddtlData data={data} />;

	if (code && code === "RLAND")
		return <RaemulanAddtlData data={data} />

	if (code && code === "LPUBI")
		return <LPUBIAddtlData data={data} />

	return (
		<span style={defaultFont}>
			<b>{referenceNumberLabel(code)} </b> {data.accountNo}
		</span>
	);
};

class ReceiptData extends Component {
	render() {
		const { selectedForPrint, tpaId } = this.props;
		const code = selectedForPrint && selectedForPrint.service_code;
		const branchName = BranchUtil.get()[0].name;

		return (
			<Grid container className="modal-body">
				<div></div>
				{selectedForPrint && (
					<div style={{ width: "8.9cm", wordBreak: "break-all" }}>
						<span style={defaultFont}>
							{" "}
							{selectedForPrint.billerName}
						</span>
						<span style={defaultFont}>
							{" "}
							TRANS NO. {selectedForPrint.transactionNumber}
							{selectedForPrint.reprintCount !== undefined && (
								<span style={{ marginLeft: "2em" }}>
									({selectedForPrint.reprintCount + 1})
								</span>
							)}
						</span>
						<br />
						<span style={defaultFont}>
							{" "}
							{dateformat(
								selectedForPrint.createdAt,
								"mm/dd/yyyy h:MM:ss TT"
							)}
						</span>
						<br />
						<span style={defaultFont}>
							{" "}
							<b style={{ fontWeight: "bolder" }}>Teller:</b>{" "}
							{selectedForPrint.username}
						</span>
						<span style={defaultFont}> {tpaId}</span>
						<span style={defaultFont}>
							{" "}
							<b>Receipt No:</b> {selectedForPrint.receiptNo}
						</span>
						<br />
						<CustomPrintBiller
							data={selectedForPrint}
							code={code}
						/>
						<br />
						{selectedForPrint.paymentMethod == "CASH" && (
							<span style={defaultFont}>
								{" "}
								<b>Cash:</b>{" "}
								{`P${formatNumber(
									parseFloat(selectedForPrint.amount)
								)} `}
								<b>Check:</b> P0.00{" "}
							</span>
						)}
						{selectedForPrint.paymentMethod == "CHECK" && (
							<span style={defaultFont}>
								{" "}
								<b>Cash:</b> P0.00 Check:
								{` P${formatNumber(
									parseFloat(selectedForPrint.amount)
								)} `}
							</span>
						)}
						<span style={defaultFont}>
							{" "}
							<b>Fee:</b> P
							<NumberFormatter value={selectedForPrint.fee} />
						</span>
						<span
							style={{
								...defaultFont,
								whiteSpace: "nowrap",
							}}
						>
							{" "}
							<b>Total:</b>{" "}
							{`P${formatNumber(
								parseFloat(selectedForPrint.amount) +
									parseFloat(selectedForPrint.fee)
							)}`}
						</span>
						<br />
						<br />
						<span style={defaultFont}>
							{" "}
							THANK YOU FOR PAYING AT {branchName}!
						</span>
						<br />
						<span style={defaultFont}>
							{" "}
							AUTHORIZED PARTNER OF BAYAD CENTER.
						</span>
					</div>
				)}
			</Grid>
		);
	}
}

export default ReceiptData;
