import { useState } from "react";
import { getLocalStorage, setLocalStorage } from "./storage";

export const useToggle = (initialValue = false) => {
	const [value, setValue] = useState(initialValue);
	const valueOn = () => setValue(true);
	const valueOff = () => setValue(false);
	const toggleValue = () => setValue(!value);
	return [value, valueOn, valueOff, toggleValue];
};

export const usePagination = (defaultRowsPerPage) => {
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
	return { page, setPage, rowsPerPage, setRowsPerPage };
};

export const useLocalStorage = (key, defaultValue = undefined) => {
	if (!key) throw new Error("Please provide a valid key name");

	const initialValue = getLocalStorage(key, defaultValue);

	const [value, setValue] = useState(JSON.parse(initialValue));

	const storeValue = (v = "") => {
		setValue(v);
		setLocalStorage(key, v);
	};

	return [value, storeValue];
};
