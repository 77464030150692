import { Grid, IconButton, Tooltip } from "@material-ui/core";
import cx from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import { IoMdPaper } from "react-icons/io";
import { AiFillPrinter } from "react-icons/ai";
import { findKey, get } from "lodash";
import styles from "./TransactionTable.module.css";
import CommonTable from "../../common/CommonTable/CommonTable";
import VoidIcon from "./../../../assets/icons/ic-void.svg";
import { useToggle } from "../../../utils/hooks";
import { useCallback, useEffect, useState } from "react";
import TransactionModal from "./TransactionModal";
import ReprintModal from "./ReprintModal";
import NumberFormatter from "../../common/Format/NumberFormatter";
import HTTP from "../../../helpers/ApiClient";
import { updateSelectedForPrint } from "../../../redux/modules/transaction";
import { useDispatch } from "react-redux";
import { SSScode } from "../../PrintReceipt/PrintReceipt";
import { isReprintRequestScope } from "../../../utils/checkSession";
import VoidRequestModal from "./VoidRequestModal";

const NOT_AVAILABLE = "--";
const TRN_NOT_AVAILABLE = "No Transaction Record in Bayad Backend System (API 3.0)";
const MODE_OF_PAYMENT = {
	cash: {
		orderCode: "MBCSH",
		code: "CASH",
		label: "Cash",
	},
	check: {
		orderCode: "MBCHK",
		code: "CHECK",
		label: "Check",
	},
	cashCheck: {
		code: "CASH+CHECK",
		label: "Cash + Check",
	},
};

const ActionColumn = ({ entry, onView, onPrint, onVoid }) => {
	const status = entry?.void_item?.status?.toLowerCase();
	const transactionStatus = entry?.status?.toLowerCase();
	const voidStatus =
		status === "pending"
			? "Pending Void Request"
			: status === "approved"
			? "Voided"
			: "Void";
	const isVoidDisabled = ["pending", "approved"].includes(status);
	return (
		<Grid container spacing={1}>
			<Grid item>
				<ActionBtnTooltip title="View">
					<IconButton onClick={() => onView(entry)}>
						<IoMdPaper />
					</IconButton>
				</ActionBtnTooltip>
			</Grid>
			<Grid item>
				<ActionBtnTooltip title="Reprint">
					<IconButton>
						<AiFillPrinter />
					</IconButton>
				</ActionBtnTooltip>
			</Grid>
			{transactionStatus !== "failed" && (
				<Grid item>
					<ActionBtnTooltip title={voidStatus}>
						<IconButton onClick={() => onVoid(entry)}>
							<img
								src={VoidIcon}
								alt="void-icon"
								className={cx(
									isVoidDisabled && styles.disabled
								)}
							/>
						</IconButton>
					</ActionBtnTooltip>
				</Grid>
			)}
		</Grid>
	);
};

const headers = [
	{
		label: () => (
			<>
				<div>Transaction</div>
				<div>Date and Time</div>
			</>
		),
		identifier: "createdAt",
		render: (value) => moment(value).format("MM/DD/YYYY hh:mm:ss A"),
		sortable: true,
	},
	{
		label: "Order Number",
		identifier: "orderNumber",
		render: (value) => value || NOT_AVAILABLE,
	},
	{
		label: "Transaction Reference No.",
		identifier: "trn",
		render: (value) => value || TRN_NOT_AVAILABLE,
	},
	{
		label: "Biller",
		identifier: "billerName",
		sortable: true,
	},
	{
		label: "Account Number",
		identifier: "arn",
	},
	{
		label: "Mode of Payment",
		identifier: "paymentMethod",
		render: (value) => <ModeOfPayment mode={value} />,
	},
	{
		label: "Amount",
		identifier: "amount",
		render: (value) => <NumberFormatter value={value} />,
	},
	{
		label: "Status",
		identifier: "status",
		render: (value) => <TransactionStatus status={value} />,
	},
	{
		label: "Teller",
		identifier: "username",
		render: (value) => value || NOT_AVAILABLE,
	},
	{
		label: "Actions",
		identifier: "actions",
		render: (value, entry, { onView, onPrint, onVoid }) => (
			<>
				<ActionColumn
					entry={entry}
					onView={onView}
					onPrint={onPrint}
					onVoid={onVoid}
				/>
			</>
		),
	},
];

const STATUS = [
	{
		code: 0,
		label: "Success",
		className: styles.statusSuccess,
	},
	{
		code: 1,
		label: "Failed",
		className: styles.statusFailed,
	},
	{
		code: 2,
		label: "Processing",
		className: styles.statusProcessing,
	},
	{
		code: 3,
		label: "Re-Processing",
		className: styles.statusReprocessing,
	},
	{
		code: 4,
		label: "Voided",
		className: styles.statusFailed,
	},
];

const isTruthy = (value) => !!value && !!value.trim();

const NotAvailable = () => <>{NOT_AVAILABLE}</>;

export const TransactionStatus = ({ status }) => {
	if (!isTruthy(status)) return <NotAvailable />;
	const match = STATUS.find(({ label }) => status === label);
	if (!match) return <NotAvailable />;
	const { label, className } = match;
	return <div className={className}>{label}</div>;
};

TransactionStatus.propTypes = {
	status: PropTypes.string.isRequired,
};

const ModeOfPayment = ({ mode }) => {
	if (!isTruthy(mode)) return <NotAvailable />;
	const matchKey = findKey(MODE_OF_PAYMENT, { code: mode.toUpperCase() });
	if (!matchKey) return <NotAvailable />;
	return <>{MODE_OF_PAYMENT[matchKey].label}</>;
};

ModeOfPayment.propTypes = {
	mode: PropTypes.string.isRequired,
};

const ActionBtnTooltip = ({ children, title, placement }) => (
	<Tooltip title={title} placement={placement}>
		{children}
	</Tooltip>
);

ActionBtnTooltip.propTypes = {
	children: PropTypes.element.isRequired,
	title: PropTypes.string.isRequired,
	placement: PropTypes.string,
};

ActionBtnTooltip.defaultProps = {
	placement: "top",
};

/**
 * Component that holds records
 * of the transaction
 * @component
 */
const TransactionTable = ({
	page,
	data,
	rowsPerPage,
	onPageChange,
	onSort,
	sortBy,
	sort,
	handleRefetchTransaction,
	showLoader = () => {},
	hideLoader = () => {},
}) => {
	const [isModalVisible, setIsModalVisible] = useState();
	const [isVoidRequestModalVisible, setIsVoidRequestModalVisible] =
		useState(false);
	const [isReprintModalVisible, showReprintModal, hideReprintModal] =
		useToggle();
	const [selectedEntry, setSelectedEntry] = useState(null);

	const [selectedTransactionInfo, setSelectedTransactionInfo] = useState();

	const handleView = async (entry, refresh = false, getFromBE = false) => {
		setSelectedTransactionInfo(entry);
		showLoader();
		const reprintCheck = await HTTP.get(
			`v3/receipts/check/reprint/${entry.crn}/counts`
		)
			.then((response) => response.data.data)
			.catch((err) => {
				console.log("err", err);
			});

		// overwrite if from validate code (w/ aprroval), get BE data (txn_date signature data)
		return await HTTP.get(
			`/v2/receipts?type=reprint&transactionId=${entry.crn}`
		).then(({ data }) => {
			entry.reprintCount = data.data.count;
			entry.reprintStatus = data.data.status || null;
			entry.reprintCheckCount = reprintCheck.count;
			entry.reprintCheckStatus = reprintCheck.status;
			entry.checkReceiptNo = reprintCheck.checkReceiptNo;
			entry.refresh = refresh;

			const isApprovedFromBE = !!data.data.txn_date;

			getFromBE
				? setSelectedEntry({
						...data.data,
						crn: entry.crn,
						billerName: data.data.biller_name,
						transactionNumber: data.data.trn,
						createdAt: data.data.txn_date,
						receiptNo: data.data.receipt_no,
						paymentMethod: data.data.payment_method,
						otherInfo: data.data.other_info,
						reprintCount: isApprovedFromBE && entry.reprintCount,
						reprintStatus: isApprovedFromBE && entry.reprintStatus,
						reprintCheckCount: entry.reprintCheckCount,
						reprintCheckStatus: entry.reprintCheckStatus,
						checkReceiptNo: entry.checkReceiptNo,
						getFromBE,
				  })
				: setSelectedEntry({
						...selectedEntry,
						...entry,
						isApprovedFromBE,
				  });

			!refresh && setIsModalVisible(true);
			hideLoader();

			return getFromBE
				? {
						...data.data,
						crn: entry.crn,
						billerName: data.data.biller_name,
						transactionNumber: data.data.trn,
						createdAt: data.data.txn_date,
						receiptNo: data.data.receipt_no,
						paymentMethod: data.data.payment_method,
						otherInfo: data.data.other_info,
						reprintCount: entry.reprintCount,
						reprintStatus: entry.reprintStatus,
						reprintCheckCount: entry.reprintCheckCount,
						reprintCheckStatus: entry.reprintCheckStatus,
						checkReceiptNo: entry.checkReceiptNo,
				  }
				: { ...selectedEntry, ...entry };
		});
	};

	/** Triggers upon viewing void request via red void icon */
	const handleVoidRequestView = async (transactionInfo = null) => {
		if (transactionInfo) {
			const voidRequest = transactionInfo?.void_item || null;
			const transactionStatus = transactionInfo?.status?.toLowerCase();
			if (transactionStatus === "failed") {
				return;
			}
			if (
				voidRequest &&
				(voidRequest?.status.toLowerCase() === "pending" ||
					voidRequest?.status.toLowerCase() === "approved")
			) {
				return;
			}
			setSelectedTransactionInfo(transactionInfo);
			if (voidRequest?.status.toLowerCase() === "reject") {
				await handleOverrideVoidItem(
					transactionInfo?.void_item?.reference_id
				);
				return;
			}
			setIsVoidRequestModalVisible(true);
		} else {
			setSelectedTransactionInfo(null);
			setIsVoidRequestModalVisible(false);
		}
	};

	const handleViewClose = () => {
		setIsModalVisible(false);
		setSelectedEntry(null);
		setSelectedTransactionInfo(null);
	};

	const handleReprint = (
		reprintType,
		reprintDetails = {},
		getFromBE = false
	) => {
		setIsModalVisible(false);

		// if user/teller has reprint request approval, get from backend (after validate code), if not get from FE
		if (isReprintRequestScope() && getFromBE) {
			setSelectedEntry({
				...reprintDetails,
				printType: reprintType,
				getFromBE,
			});
		} else {
			setSelectedEntry({
				...selectedEntry,
				printType: reprintType,
				getFromBE,
			});
		}

		showReprintModal();
	};

	const handleReprintClose = () => {
		hideReprintModal();
		setSelectedEntry(null);
	};

	const dispatch = useDispatch();

	const handleBackButton = useCallback(() => {
		handleView(selectedTransactionInfo);
		setIsVoidRequestModalVisible(false);
	}, [selectedTransactionInfo]);

	const handleOverrideVoidItem = (rid = "") => {
		const referenceID = rid
			? rid
			: selectedTransactionInfo?.void_item?.reference_id;
		showLoader();
		//if staging stg.api.bfa.bayad.com
		HTTP.get(`/v3/transaction/void/item/view?reference_id=${referenceID}`)
			.then((response) => {
				setSelectedTransactionInfo((prevTransactionInfo) => ({
					...prevTransactionInfo,
					void_item: response?.data?.void_item,
				}));
				setIsVoidRequestModalVisible(true);
			})
			.catch((error) => {
				console.error(
					"Something went wrong on getting the void item",
					error
				);
			})
			.finally(() => {
				hideLoader();
			});
	};

	useEffect(() => {
		if (selectedEntry) {
			(async () => {
				const sssData = {}
					//Remove inquire api for SSS 
					// SSScode.includes(selectedEntry.service_code) &&
					// (await HTTP.get(
					// 	`/v2/billers/${selectedEntry.service_code}/inquiry/${selectedEntry.arn}?getFromCache=true`
					// )
					// 	.then(({ data }) => data.data && data.data.details)
					// 	.catch((e) => {
					// 		console.log("SSS Inquire Print Error: ", e);
					// 	}));
				
				const values = {
					...selectedEntry,
					transactionNumber: selectedEntry.trn,
					channelName: selectedEntry.channel_name,
					accountNo: selectedEntry.arn,
					crn: selectedEntry.crn,
					sssData,
				};
				
				// tagging if has txn_date (from BE print details)
				if (selectedEntry.getFromBE) {
					dispatch(
						updateSelectedForPrint(
							selectedEntry.txn_date ? { values } : undefined
						)
					);
					return;
				}

				dispatch(updateSelectedForPrint({ values }));
			})();
		}
	}, [selectedEntry]);

	return (
		<>
			<CommonTable
				headers={headers}
				entries={get(data, "items", [])}
				page={page}
				totalEntries={get(data, "count", 0)}
				rowsPerPage={rowsPerPage}
				onPageChange={onPageChange}
				onSort={onSort}
				onView={handleView}
				onVoid={handleVoidRequestView}
				sortBy={sortBy}
				sort={sort}
			/>
			{isModalVisible && (
				<TransactionModal
					open={isModalVisible}
					data={selectedEntry}
					closeThis={() => setIsModalVisible(false)}
					onClose={handleViewClose}
					onReprint={handleReprint}
					fetchReprintStatus={handleView}
					selectedTransactionInfo={selectedTransactionInfo}
					openVoidRequestModal={() => handleOverrideVoidItem()}
				/>
			)}

			<ReprintModal
				open={isReprintModalVisible}
				data={selectedEntry}
				onClose={handleReprintClose}
			/>

			<VoidRequestModal
				selectedTransactionInfo={selectedTransactionInfo}
				isOpen={isVoidRequestModalVisible}
				onClose={() => handleVoidRequestView()}
				handleBackButton={handleBackButton}
				handleRefetchTransaction={handleRefetchTransaction}
			/>
		</>
	);
};

TransactionTable.propTypes = {
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	data: PropTypes.object.isRequired,
	onPageChange: PropTypes.func,
	onSort: PropTypes.func,
	sortBy: PropTypes.string,
	sort: PropTypes.string,
	handleRefetchTransaction: PropTypes.func,
	showLoader: PropTypes.func,
	hideLoader: PropTypes.func,
};

export default TransactionTable;
