import { useEffect, useState } from "react";
import {
	FormControl,
	Grid,
	MenuItem,
	OutlinedInput,
	TextField,
	Paper,
	withStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import { Formik, useField } from "formik";
import { Autocomplete } from "@material-ui/lab";
import styles from "./TransactionFilter.module.css";
import BillerListModal from "../BillerListModal/BillerListModal";
import { useToggle } from "../../../utils/hooks";
import FilterContainer from "../../common/Filter/FilterContainer";
import ClearFilterButton from "./ClearFilterButton";
import {
	DatePickerFormik,
	makeDateRangeValidator,
} from "../../Inputs/DatePicker";
import FilterUpdateWatcher from "../../common/Filter/FilterUpdateWatcher";
import HTTP from "../../../helpers/ApiClient";
import { chunk, isEmpty } from "underscore";

const STATUS_FILTERS = [
	{ name: "Success", value: "0" },
	{ name: "Failed", value: "1" },
];
const MODE_OF_PAYMENT_FILTERS = [
	{ name: "Cash", value: "CASH" },
	{ name: "Check", value: "CHECK" },
	{ name: "Cash + Check", value: "CASH+CHECK" },
];

const getBillers = async (page = 1, items = 10) => {
	const result = await HTTP.get("/v2/billers", { page, items });
	try {
		if (result && result.status == 200) {
			return result.data;
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};

const FilterField = ({ label, ...inputProps }) => {
	return (
		<FormControl variant="outlined">
			<OutlinedInput label={label} {...inputProps} />
		</FormControl>
	);
};
FilterField.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
};

const StyledMenuItem = withStyles({
	root: {
		fontFamily: "Poppins-Regular",
	},
	selected: {
		color: "#F26122",
	},
})(MenuItem);

const FilterSelect = ({ label, options, ...props }) => {
	const [field, _, helpers] = useField(props);
	return (
		<FormControl variant="outlined" fullWidth>
			<TextField
				name={field.name}
				value={field.value}
				onChange={(event) => helpers.setValue(event.target.value)}
				label={label}
				variant="outlined"
				select
				{...props}
			>
				{options.map(({ name, value }, i) => (
					<StyledMenuItem key={i} value={value}>
						{name}
					</StyledMenuItem>
				))}
			</TextField>
		</FormControl>
	);
};

const StyledAutoComplete = withStyles({
	popupIndicator: {
		paddingRight: "0.5em",
	},
	popupIndicatorOpen: {
		transform: `rotate(0deg)`,
	},
})(Autocomplete);

const BillerField = ({ ...props }) => {
	const [field, _, helpers] = useField(props);
	const [isBillerModalOpen, showBillerModal, hideBillerModal] = useToggle();
	const [
		isBillerModalLoading,
		startBillerModalLoading,
		stopBillerModalLoading,
	] = useToggle();
	const [isAutoCompleteOpen, openAutoComplete, closeAutoComplete] =
		useToggle();
	const [isLoading, startLoading, stopLoading] = useToggle(true);
	const [billers, setBillers] = useState([]);
	const [billersDropdown, setBillersDropdown] = useState([]);
	const [page, setPage] = useState(1);
	const [expandedBillers, setExpandedBillers] = useState({
		data: [],
		count: 0,
	});

	const fetchBillers = async () => {
		startLoading();
		const result = (await getBillers()) || [];
		const out = result.data.slice(0, 10);
		setBillers([...result.data, { code: "", name: "", link: true }]);
		setBillersDropdown([...out, { code: "", name: "", link: true }]);
		stopLoading();
	};

	const fetchExpandedBillers = async (page = 1, rowsPerPage = 25) => {
		startBillerModalLoading();
		setPage(page);
		const result = await getBillers(page, rowsPerPage);
		const { data, count } = result;
		setExpandedBillers({ count, data: chunk(data, rowsPerPage)[page - 1] });
		stopBillerModalLoading();
	};
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		if (!field.value) {
			setInputValue("");
			handleSearch("");
		}
	}, [field.value]);

	useEffect(() => {
		if (isLoading && isAutoCompleteOpen) {
			fetchBillers();
		}
	}, [isLoading, isAutoCompleteOpen]);

	const handleClose = (event, reason) => {
		hideBillerModal();
		setPage(1);
	};

	const handleSeeMoreClick = async () => {
		showBillerModal();
		await fetchExpandedBillers();
	};

	const handleSearch = (v) => {
		if (isEmpty(v) || !v.trim())
			return setBillersDropdown([
				...billers.slice(0, 10),
				{ code: "", name: "", link: true },
			]);
		const searchBillers =
			billers
				.filter(
					(biller) =>
						biller.name &&
						biller.name.toLowerCase().includes(v.toLowerCase())
				)
				.slice(0, 10) || [];
		setBillersDropdown(searchBillers);
	};

	return (
		<div styles={styles.filterTextboxes}>
			<StyledAutoComplete
				disableClearable
				popupIcon={<SearchIcon style={{ rotate: "0deg !important" }} />}
				open={isAutoCompleteOpen}
				onOpen={openAutoComplete}
				onClose={closeAutoComplete}
				name={field.name}
				loading={isLoading}
				value={field.value}
				noOptionsText="No match"
				inputValue={inputValue}
				onInputChange={(e, value = "") => {
					if (e?.target) {
						const textValue = value || e.target?.value;
						handleSearch(textValue);
						setInputValue(textValue);
					}
				}}
				onChange={(e, newValue) => {
					if (newValue && !newValue.link) {
						helpers.setValue(newValue ? newValue.code : null);
					}
				}}
				options={billersDropdown}
				getOptionLabel={(option) => option.name}
				PaperComponent={(params) => (
					<Paper {...params} style={{ height: "450px" }} />
				)}
				renderOption={(option) => {
					if (option && option.link) {
						return (
							<div
								className={styles.seeMoreLabel}
								onClick={() => {
									handleSeeMoreClick();
									helpers.setValue(null);
								}}
							>
								See all results
							</div>
						);
					}

					return <div>{option.name}</div>;
				}}
				renderInput={(params) => (
					<TextField {...params} label="Billers" variant="outlined" />
				)}
			/>
			<BillerListModal
				open={isBillerModalOpen}
				loading={isBillerModalLoading}
				onClose={handleClose}
				data={expandedBillers}
				page={page}
				onPageChange={fetchExpandedBillers}
				onSelect={(value) => {
					handleSearch(value.name);
					setInputValue(value.name);
					helpers.setValue(value.code);
				}}
			/>
		</div>
	);
};

const validate = (values) => {
	const { dateFrom, dateTo } = values;
	return makeDateRangeValidator({ dateFrom, dateTo })();
};

const TransactionFilter = ({ initialValues, onChange, onSubmit }) => {
	const handleClear = (resetForm) => () => {
		resetForm({});
		onChange(initialValues);
		onSubmit(initialValues);
	};
	return (
		<Formik initialValues={initialValues} validate={validate}>
			{({ resetForm }) => (
				<FilterContainer>
					<Grid container justify="flex-end" direction="column">
						<Grid item xs container justify="flex-end">
							<ClearFilterButton
								onClear={handleClear(resetForm)}
							/>
						</Grid>
						<Grid
							item
							container
							spacing={3}
							direction="row"
							alignItems="center"
							xs
						>
							<Grid item>Filters:</Grid>
							<Grid item container xs>
								<Grid
									item
									container
									spacing={2}
									justify="space-evenly"
								>
									<Grid item xs>
										<DatePickerFormik
											label="Date From"
											name="dateFrom"
										/>
									</Grid>
									<Grid item xs>
										<DatePickerFormik
											label="Date To"
											name="dateTo"
										/>
									</Grid>
									<Grid item xs>
										<BillerField name="biller" />
									</Grid>
									<Grid item xs>
										<FilterSelect
											label="Status"
											name="status"
											options={STATUS_FILTERS}
										/>
									</Grid>
									<Grid item xs>
										<FilterSelect
											label="Mode of Payment"
											name="paymentMethod"
											options={MODE_OF_PAYMENT_FILTERS}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<FilterUpdateWatcher
						onChange={onChange}
						onSubmit={onSubmit}
					/>
				</FilterContainer>
			)}
		</Formik>
	);
};

TransactionFilter.propTypes = {
	values: PropTypes.object,
	initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
};

TransactionFilter.defaultProps = {
	values: {
		dateFrom: null,
		dateTo: null,
	},
	initialValues: {
		dateFrom: null,
		dateTo: null,
	},
	onSubmit: () => {},
	onChange: () => {},
};

export default TransactionFilter;
