import styled from "@emotion/styled";
import axios from "axios";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	Box,
	Grid,
	Button,
	FormControlLabel,
	Checkbox,
	FormGroup,
	FormControl,
} from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Formik, Field, Form } from "formik";
import { filter, includes, split, uniqueId } from "lodash";
import moment from "moment";
import {
	FC,
	useState,
	useMemo,
	useEffect,
	useCallback,
	useRef,
	ChangeEvent,
} from "react";
import * as Yup from "yup";
import VoidBadge from "./components/VoidBadge";
import { SuccessModal } from "../../Modal/SuccessModal";
import { ErrorModal } from "../../Modal/ErrorModal";

import HTTP from "../../../helpers/ApiClient";
import { TransactionInfo, TransactionVoidRequest } from "../../../types";
import { UserInfoUtil } from "../../../utils/checkSession";
import { useToggle } from "../../../utils/hooks";
import PreviewModal from "./components/PreviewModal";
import BlockIcon from "@material-ui/icons/Block";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import styles from "./VoidRequestModal.module.css";
import { Divider, IconButton, Typography } from "@material-ui/core";
import DeleteAttachmentConfirmationModal from "../../UserManagement/ConfirmationModal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { GreenColorButton, WhiteColorButton } from "../../App/App";
import VoidAttachmentField from "./components/VoidAttachmentField";
import FullPageLoader from "../../common/FullPageLoader/FullPageLoader";
import ModalScrollBar from "../../common/ModalScrollBar/ModalScrollBar";
import { isToday } from "../../../utils/dateUtil";
import { DatePickerFormik } from "../../Inputs/DatePicker";
import TextField from "../../Inputs/TextField";

interface RequestInformationValueProps {
	textTransform?: string;
}

const CHECKBOX_FIELDS_REASON = [
	{
		id: 0,
		label: "Altered Check",
		value: "ALTERED_CHECK",
	},
	{
		id: 1,
		label: "Wrong Payment Type",
		value: "WRONG_PAYMENT_TYPE",
	},
	{
		id: 2,
		label: "Cancelled Payment",
		value: "CANCELLED_PAYMENT",
	},
	{
		id: 3,
		label: "Already paid by the Customer",
		value: "ALREADY_PAID_BY_THE_CUSTOMER",
	},
	{
		id: 4,
		label: "Cash encoded as Check",
		value: "CASH_ENCODED_AS_CHECK",
	},
	{
		id: 5,
		label: "Voided/Cancelled by Biller",
		value: "VOIDED_CANCELLED_BY_BILLER",
	},
	{
		id: 6,
		label: "Check encoded as Cash",
		value: "CHECK_ENCODED_AS_CASH",
	},
	{
		id: 7,
		label: "For Refund/Reversal",
		value: "FOR_REFUND_REVERSAL",
	},
	{
		id: 8,
		label: "Post Dated Check",
		value: "POST_DATED_CHECK",
	},
	{
		id: 9,
		label: "Customer Error",
		value: "CUSTOMER_ERROR",
	},
	{
		id: 10,
		label: "Staled Check",
		value: "STALED_CHECK",
	},
	{
		id: 11,
		label: "Customer Initiated Request",
		value: "CUSTOMER_INITIATED_REQUEST",
	},
	{
		id: 12,
		label: "Unsigned Check",
		value: "UNSIGNED_CHECK",
	},
	{
		id: 13,
		label: "Teller Error",
		value: "TELLER_ERROR",
	},
	{
		id: 14,
		label: "Wrong Bank Code",
		value: "WRONG_BANK_CODE",
	},
	{
		id: 15,
		label: "No Payment Received",
		value: "NO_PAYMENT_RECEIVED",
	},
	{
		id: 16,
		label: "Wrong Check Number",
		value: "WRONG_CHECK_NUMBER",
	},
	{
		id: 17,
		label: "Erroneous Deposit",
		value: "ERRONEOUS_DEPOSIT",
	},
	{
		id: 18,
		label: "Wrong Amount in Words & Figures",
		value: "WRONG_AMOUNT_IN_WORDS_&_FIGURES",
	},
	{
		id: 19,
		label: "Over Deposit",
		value: "OVER_DEPOSIT",
	},
	{
		id: 20,
		label: "Wrong Amount",
		value: "WRONG_AMOUNT",
	},
	{
		id: 21,
		label: "Under Deposit",
		value: "UNDER_DEPOSIT",
	},

	{
		id: 22,
		label: "Wrong Biller",
		value: "WRONG_BILLER",
	},
	{
		id: 23,
		label: "Double Posting",
		value: "DOUBLE_POSTING",
	},
	{
		id: 24,
		label: "Wrong Bill Presented",
		value: "WRONG_BILL_PRESENTED",
	},
	{
		id: 25,
		label: "System Error",
		value: "SYSTEM_ERROR",
	},
	{
		id: 26,
		label: "Wrong System Date",
		value: "WRONG_SYSTEM_DATE",
	},
	{
		id: 27,
		label: "No Teller Code",
		value: "NO_TELLER_CODE",
	},
	{
		id: 28,
		label: "Wrong Customer Account / Reference No.",
		value: "WRONG_CUSTOMER_ACCOUNT_OR_REFERENCE_NO",
	},
	{
		id: 29,
		label: "Unique Constraint",
		value: "UNIQUE_CONSTRAINT",
	},
	{
		id: 30,
		label: "Invalid Account No.",
		value: "INVALID_ACCOUNT_NO",
	},
	{
		id: 31,
		label: "Others",
		value: "OTHERS",
	},
];

export const MAX_SIZE = 5000000;

export const ACCEPTABLE_FILE_TYPES = [
	"image/png",
	"image/jpeg",
	"image/jpg",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/pdf",
	"application/msword",
];

/** Tiny component for required field indication */
const RequiredIndicator: FC = () => <RequiredText>*</RequiredText>;

const RequiredText = styled.span(`
	color: red;
`);

function keyedFiles(arr: File[]) {
	return arr.map((a: File) => {
		return {
			key:
				Math.random().toString(36).substring(2, 10) +
				Math.random().toString(36).substring(2, 10),
			file: a,
		};
	});
}

const VoidRequestErrorSchema = Yup.object().shape({
	requestDate: Yup.string()
		.required("Request Date is required")
		.matches(/[A-Za-z0-9_-]/, "Please use alphanumeric characters"),
	depositoryAccountNumber: Yup.string()
		.max(255, "You can only input a max of 255 characters.")
		.notRequired()
		.matches(/^\d+$/, "You can only input numeric characters."),
	requestorName: Yup.string()
		.trim()
		.required("Input Name of Void Requestor")
		.max(255, "You can only input a max of 255 characters.")
		.matches(/[A-Za-z0-9_-]/, "Please use alphanumeric characters"),
	requestorContactNumber: Yup.string()
		.trim()
		.required("Input Contact Number of Void Requestor")
		.max(12, "You can only input a 12 numeric characters.")
		.matches(/^\d+$/, "Input valid contact number"),
	incidentReport: Yup.string()
		.max(1000, "You can only input a max of 1000 characters.")
		.trim()
		.required("Input Incident Report")
		.matches(/[A-Za-z0-9_-]/, "Please use alphanumeric characters"),
});

type VoidRequestModalProps = {
	isOpen: boolean;
	selectedTransactionInfo: TransactionInfo;
	onClose: () => void;
	handleBackButton: () => void;
	handleRefetchTransaction: () => void;
};

export enum VoidAttachmentErrorType {
	Timeout,
	MaxFiles,
	InvalidFile,
	MaxSize,
}

function sortErrors(errors = {}) {
	return Object.entries(errors)
		.map(([key, value]: any) => {
			return {
				key,
				...value,
			};
		})
		.sort((a, b) => a.type - b.type);
}

const addFloatCommaSeparation = (value: string) => {
	const floatV = parseFloat(value);
	return floatV.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

const RequiredInputStyleSX = {
	input: {
		fontFamily: "Poppins-Regular",
		"&::placeholder": {
			fontFamily: "Poppins-Regular",
		},
	},
	textarea: {
		fontFamily: "Poppins-Regular",
		"&::placeholder": {
			fontFamily: "Poppins-Regular",
		},
	},
	label: { fontFamily: "Poppins-Regular", fontSize: "14.7px" },
};

/**
 * Void request modal
 * @component {VoidRequestModalProps}
 */
const VoidRequestModal: FC<VoidRequestModalProps> = ({
	isOpen,
	selectedTransactionInfo,
	onClose,
	handleBackButton,
	handleRefetchTransaction,
}) => {
	const submitButtonRef = useRef<HTMLButtonElement | null>(null);

	const memoDepositDate = useMemo(() => {
		const depositDate = selectedTransactionInfo?.void_item?.deposit_date;
		if (!depositDate || !moment(depositDate).isValid()) {
			return null;
		}

		return moment(depositDate).format("YYYY-MM-DD");
	}, [selectedTransactionInfo?.void_item?.deposit_date]);

	const memoTransactionDate = useMemo(
		() =>
			moment(selectedTransactionInfo?.createdAt).format(
				"MM/DD/YYYY hh:mm:ss A"
			),
		[selectedTransactionInfo?.createdAt]
	);

	const amount = useMemo(() => {
		if (!selectedTransactionInfo?.amount) return null;

		return addFloatCommaSeparation(selectedTransactionInfo?.amount);
	}, [selectedTransactionInfo?.amount]);

	// Loading states
	const [isLoading, showLoading, hideLoading] = useToggle();

	const voidRequestData: TransactionVoidRequest = {
		...selectedTransactionInfo?.void_item,
		// @ts-ignore
		reason: split(selectedTransactionInfo?.void_item?.reason, ","),
	};

	const isEdit = !!selectedTransactionInfo?.void_item;

	const isView = ["pending", "approved"].includes(
		selectedTransactionInfo?.void_item?.status?.toLowerCase()
	);

	const [isSuccessModalOpen, showSuccessModal, hideSuccessModal] =
		useToggle();
	const [
		isTimeoutErrorModalOpen,
		showTimeoutErrorModal,
		hideTimeoutErrorModal,
	] = useToggle();

	const [viewFileModal, setViewFileModal] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [deleteAttachmentModalInfo, setDeleteAttachmentModalInfo] = useState({
		isOpen: false,
		fileName: "",
		fileIndex: -1,
		key: null,
	});
	const [confirmExitModal, setConfirmExitModal] = useState(false);

	// form control
	const [isSelectAllReasonChecked, setIsSelectAllReasonChecked] =
		useState(false);

	// field states
	const [acceptedFiles, setAcceptedFiles] = useState<any[]>([]); // raw files for form
	const [selectedFile, setSelectedFile] = useState<any>(null); // state to view selected image
	const [uploadedFiles, setUploadedFiles] = useState<any>([]);
	const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
	const [reasonSearchInput, setReasonSearchInput] = useState("");
	const [othersInput, setOthersInput] = useState<string | null>("");
	const [isConfirmCheckboxChecked, setIsConfirmCheckboxChecked] =
		useState(false);

	// custom error states
	const [errorSelectedReasons, setErrorSelectedReasons] = useState<
		string | null
	>(null);
	const [errorConfirmCheckboxMessage, setErrorConfirmCheckboxMessage] =
		useState<string | null>(null);
	const [errorOthersInput, setErrorOthersInput] = useState<string | null>("");
	const [isVoidCheckboxesTouched, setIsVoidCheckboxesTouched] =
		useState(false);
	// attachment error states
	const [attachmentErrors, setAttachmentErrors] = useState<{
		[key: string]: Object;
	}>({});

	const sumOfSelectedVoidReason = useRef<number>(0);

	const initialValues = {
		requestDate:
			moment(selectedTransactionInfo?.void_item?.request_date).format(
				"MM/DD/YYYY"
			) || null,
		billerType: selectedTransactionInfo?.biller_type || null,
		forCancellationWithinDay:
			selectedTransactionInfo?.void_item?.forCancellationWithinDay,
		depositoryAccountNumber:
			selectedTransactionInfo?.void_item?.depository_account_number ||
			null,
		depositDate: memoDepositDate,
		requestorName:
			selectedTransactionInfo?.void_item?.requestor_name || null,
		requestorContactNumber:
			selectedTransactionInfo?.void_item?.requestor_contact_number ||
			null,
		incidentReport:
			selectedTransactionInfo?.void_item?.incident_report || null,
	};

	/** Triggers upon void request checkbox click to select void request reasons */
	const handleSelectReason = (value: string) => {
		//CHECK IF CURRENT SELECTED VOID REASON IS EQUIVALENT TO CHECKBOX FIELD REASON
		//IF YES, IT MEANS "SELECT ALL" IS UNCHECKED/CLICKED
		//IF NO, IT MEANS "VOID REASON/INDIVIDUAL CHECKBOX" IS UNCHECKED/CLICKED - TO PREVENT UNCHECKING ALL VOID REASONS WHEN JUST UNCHECKING THE VOID REASON
		if (
			value === "none" &&
			sumOfSelectedVoidReason.current === CHECKBOX_FIELDS_REASON.length
		) {
			setSelectedReasons([]);
			sumOfSelectedVoidReason.current = 0;
			return;
		}

		if (value === "all") {
			setSelectedReasons(CHECKBOX_FIELDS_REASON.map((v) => v?.value));
			sumOfSelectedVoidReason.current = CHECKBOX_FIELDS_REASON.length;
			setOthersInput("");
			setErrorOthersInput("Input Others");
			return;
		}

		if (
			includes(selectedReasons, value) &&
			!["none", "all"].includes(value)
		) {
			setSelectedReasons(selectedReasons.filter((v) => v !== value));
			return;
		}

		if (!["none", "all"].includes(value)) {
			setSelectedReasons((prevState) => prevState.concat(value));
		}
	};

	/** Side effect to check if 'Select all' checkbox is checked */
	useEffect(() => {
		if (!isEdit)
			handleSelectReason(isSelectAllReasonChecked ? "all" : "none");
	}, [isSelectAllReasonChecked]);

	/** Memoized state to filter out checkboxes based on search input */
	const filteredCheckboxReason = useMemo(() => {
		if (reasonSearchInput !== "") {
			return filter(CHECKBOX_FIELDS_REASON, ({ label }) => {
				return includes(
					label.toLowerCase(),
					reasonSearchInput.toLowerCase()
				);
			});
		}
		return CHECKBOX_FIELDS_REASON;
	}, [reasonSearchInput]);

	const addError = useCallback((error: any) => {
		setAttachmentErrors((errors) => {
			return {
				...errors,
				[`${error.key}${error.content.type}`]: error.content,
			};
		});
	}, []);

	const removeError = useCallback((fileKey: string) => {
		setAttachmentErrors((errors) => {
			const temp = { ...errors };
			for (const key in errors) {
				if (key.startsWith(fileKey)) {
					delete temp[key];
				}
			}

			return temp;
		});
	}, []);

	const sortedErrors = useMemo(() => {
		return sortErrors(attachmentErrors);
	}, [attachmentErrors]);

	/* ATTACHMENTS METHODS */

	const attachments = useMemo(() => {
		let existingAttachments: any[] = [];
		if (Array.isArray(voidRequestData?.attachments)) {
			existingAttachments =
				voidRequestData?.attachments?.map((val) => ({
					file: { ...val, name: val.file_name },
					key: uniqueId(),
				})) ?? [];
		}
		const data = [...acceptedFiles, ...existingAttachments];
		return data.slice(0, 10);
	}, [acceptedFiles, voidRequestData]);

	/**
	 * Checks if all files are acceptable, mostly in charge of displaying
	 * banners in the void request modal
	 */
	const verifyAttachments = useCallback(
		(files) => {
			files.forEach(({ file, key }) => {
				if (!ACCEPTABLE_FILE_TYPES.includes(file.type)) {
					addError({
						key,
						content: {
							error: (
								<span>
									<strong>
										.{file.name.split(".").pop()}
									</strong>{" "}
									is not a valid file type.
								</span>
							),
							type: VoidAttachmentErrorType.InvalidFile,
						},
					});
				}
				if (file.size > MAX_SIZE) {
					addError({
						key,
						content: {
							error: "Maximum allowed file size per attachment exceeded. Only 5 mb per attachment is allowed.",
							type: VoidAttachmentErrorType.MaxSize,
						},
					});
				}
			});
		},
		[addError]
	);

	/** Handles on file drop */
	const onDrop = useCallback(
		(files: any) => {
			const newFiles = keyedFiles(files);
			const allFiles = [...acceptedFiles, ...newFiles];

			if (allFiles.length > 10) {
				addError({
					key: "maxFile",
					content: {
						error: "Maximum allowed number of files exceeded. Only 10 files are allowed.",
						type: VoidAttachmentErrorType.MaxFiles,
					},
				});
			}

			const newUploadedFiles = newFiles.slice(
				0,
				10 - acceptedFiles.length
			);
			const accepted = allFiles.slice(0, 10);
			verifyAttachments(newUploadedFiles);
			setAcceptedFiles(accepted);
		},
		[acceptedFiles, addError, verifyAttachments]
	);

	/** Triggers on eye button click */
	const handleFileView = (index: number) => {
		const existingAttachments = voidRequestData?.attachments || [];
		//@ts-ignore
		let images: any[] = [...acceptedFiles, ...existingAttachments];
		if (!isEdit) {
			setSelectedFile({ ...images[index] });
			setViewFileModal(true);
			return;
		}

		//@ts-ignore
		showLoading();
		HTTP.get("/v3/transaction/void/attachment/preview", {
			// ts-ignore
			params: { key: images[index].key },
		})
			.then((data: any) => {
				setSelectedFile({
					...images[index],
					download_url: data.data.download_url,
				});
				setViewFileModal(true);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				//@ts-ignore
				hideLoading();
			});
		return;
	};

	/** Triggers upon remove icon click */
	const handleFileRemove = (index: number) => {
		setUploadedFiles(uploadedFiles.filter((v, i) => i !== index));
		setAcceptedFiles(acceptedFiles.filter((v, i) => i !== index));
	};

	const setAttachmentTimeout = useCallback(
		(file: File, fileKey: string) => {
			addError({
				key: fileKey,
				content: {
					error: "Timeout error: Failed to upload attachment.",
					type: VoidAttachmentErrorType.Timeout,
				},
			});
		},
		[addError]
	);

	/** Updates files individually when upload is successful*/
	const updateAttachment = async (
		data: any,
		selectedFile: File,
		key: string
	) => {
		try {
			const url = data.presigned_keys.url;
			const formData = new FormData();
			Object.entries(data.presigned_keys.fields).forEach(
				([key, value]: any) => {
					formData.append(key, value);
				}
			);
			formData.append("file", selectedFile ? selectedFile : "");
			removeError(key);

			const UNINTERCEPTED_HTTP = axios.create({
				baseURL: process.env.REACT_APP_API_ENDPOINT,
				headers: {
					"Content-Type": "application/json",
					"x-bayad-platform-id": process.env.REACT_APP_APP_KEY,
				},
			});
			await UNINTERCEPTED_HTTP.post(url, formData);

			setUploadedFiles((prevState: any) =>
				prevState.concat({
					path: data?.file_name,
				})
			);
		} catch (error) {
			console.error(error);
		}
	};

	/* END OF ATTACHMENT STATES AND METHODS*/

	// form-states
	const [formValues, setFormValues] = useState<any>(initialValues);
	const [isFormValid, setIsFormValid] = useState(false);

	// post-submission states
	const [successVoidMessage, setSuccessVoidMessage] = useState<any>(<></>);

	const httpRetry = useRef<any>();

	/** Memoized state to enable/disable the submit button */
	const isSubmitDisabled = useMemo(() => {
		if (
			!isFormValid ||
			selectedReasons.length === 0 ||
			(includes(selectedReasons, "OTHERS") && errorOthersInput) ||
			!isConfirmCheckboxChecked
		) {
			return true;
		}
		return false;
	}, [
		selectedReasons,
		uploadedFiles,
		isConfirmCheckboxChecked,
		othersInput,
		formValues,
		isFormValid,
	]);

	/** Pre-set values */
	const isForCancelledWithinDay = useMemo(
		() =>
			isToday(new Date(selectedTransactionInfo?.createdAt))
				? "Yes"
				: "No",
		[selectedTransactionInfo?.createdAt]
	);
	const dateOfRequest = useMemo(
		() =>
			moment(selectedTransactionInfo?.void_item?.request_date).format(
				"MM/DD/YYYY hh:mm:ss A"
			),
		[selectedTransactionInfo?.void_item?.request_date]
	);

	/** Main method to submit new void request */
	const submitVoidRequest = async (values: any, actions: any) => {
		// @ts-ignore
		const { username, emailAddress } = UserInfoUtil.get();

		if (!isEdit) {
			let data = {
				voidRequestInfo: {
					...values,
					requestDate: moment(values?.requestDate).format(
						"MM/DD/YYYY"
					),
					forCancellationWithinDay: isForCancelledWithinDay,
					depositDate: values?.depositDate
						? moment(values?.depositDate).format("MM/DD/YYYY")
						: null,
					id: selectedTransactionInfo?.id,
					attachments: uploadedFiles,
					reason: selectedReasons.toString(),
					others: othersInput,
					bankName:
						selectedTransactionInfo?.otherInfo?.CheckDetails
							?.BankName,
					checkNo:
						selectedTransactionInfo?.otherInfo?.CheckDetails
							?.CheckNo,
					username,
					emailAddress,
				},
			};

			const {
				data: { data: newVoidData },
			} = await HTTP.post(
				`/v3/transaction/void/${selectedTransactionInfo?.trn}`,
				{
					data,
				}
			);

			//@ts-ignore
			const isVoidSubmitSuccess = !!newVoidData?.reference_id;
			if (isVoidSubmitSuccess) {
				return {
					success: "Void created successfully",
					referenceId: newVoidData?.reference_id,
				};
			} else {
				return {
					error: "",
				};
			}
		}
	};

	/** Resets custom errors */
	const resetErrors = () => {
		setErrorConfirmCheckboxMessage(null);
		setAttachmentErrors({});
		setIsVoidCheckboxesTouched(false);
	};

	const handleCloseModal = () => {
		resetErrors();
		setAcceptedFiles([]);
		setUploadedFiles([]);
		setSelectedFile(null);
		setSelectedReasons([]);
		setErrorSelectedReasons(null);
		setErrorOthersInput(null);
		setIsSelectAllReasonChecked(false);
		setIsConfirmCheckboxChecked(false);
		sumOfSelectedVoidReason.current = 0;
		onClose();
	};

	const handleSuccessfulVoidSubmission = () => {
		//@ts-ignore
		hideSuccessModal();
		handleRefetchTransaction();
	};

	const handleOthersInput = useCallback(
		(e: any) => {
			if (!isVoidCheckboxesTouched) {
				setIsVoidCheckboxesTouched(true);
			}
			const value = e?.target?.value;
			setOthersInput(value);
			setErrorOthersInput(null);
			if (!includes(selectedReasons, "OTHERS")) {
				return;
			}
			if (value?.trim()?.length === 0) {
				setErrorOthersInput("Input Others");
				return;
			}
			if (value?.trim()?.length > 255) {
				setErrorOthersInput(
					"You can only input a max of 255 characters."
				);
			}
		},
		[isVoidCheckboxesTouched, setIsVoidCheckboxesTouched, selectedReasons]
	);

	/** Handles logic for submitting Void Request Form */
	const handleSubmitVoidRequest = async (values: any, actions: any) => {
		// @ts-ignore
		hideTimeoutErrorModal();
		// @ts-ignore
		showLoading();
		try {
			const request = await submitVoidRequest(values, actions);
			if (request?.success) {
				const successMessage = (
					<>
						Your request <strong>{request?.referenceId}</strong> is
						subject for approval. We will notify you via email when
						void request is approved.
					</>
				);
				setSuccessVoidMessage(successMessage);
				actions.resetForm();

				// @ts-ignore
				showSuccessModal();
				resetErrors();
				actions.resetForm({
					values: initialValues,
				});
				handleCloseModal();
			}
		} catch (error) {
			httpRetry.current = () => {
				handleSubmitVoidRequest(values, actions);
			};
			// @ts-ignore
			showTimeoutErrorModal();
		} finally {
			// @ts-ignore
			hideLoading();
		}
	};

	const handleOnChangeVoidReason = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (!isVoidCheckboxesTouched) {
				setIsVoidCheckboxesTouched(true);
			}

			if (e?.target?.value === "OTHERS") {
				setOthersInput("");
				setErrorOthersInput("Input Others");
			}
			!isEdit && handleSelectReason(e?.target?.value);

			sumOfSelectedVoidReason.current += e?.target?.checked ? 1 : -1;
			setIsSelectAllReasonChecked(
				sumOfSelectedVoidReason.current ===
					CHECKBOX_FIELDS_REASON.length
			);
		},
		[isVoidCheckboxesTouched, isEdit, handleSelectReason]
	);

	/** Display loader during opening of modal */
	useEffect(() => {
		if (isOpen && selectedTransactionInfo) {
			// @ts-ignore
			showLoading();
			setTimeout(() => {
				// @ts-ignore
				hideLoading();
			}, 1000);
		}
	}, [isOpen, selectedTransactionInfo]);

	/** Runs when a void request is present */
	useEffect(() => {
		if (isEdit) {
			if (
				CHECKBOX_FIELDS_REASON.length === voidRequestData?.reason.length
			) {
				setIsSelectAllReasonChecked(true);
			}

			setOthersInput(selectedTransactionInfo?.void_item?.others);
		}
	}, [isEdit]);

	/** Error handler for void reasons */
	useEffect(() => {
		if (isVoidCheckboxesTouched) {
			setErrorSelectedReasons(
				selectedReasons?.length === 0
					? "*You must select at least 1 reason"
					: null
			);
		}
	}, [selectedReasons, isVoidCheckboxesTouched]);

	return (
		<>
			{/* Main component */}
			<StyledDialog
				maxWidth="md"
				open={isOpen}
				onClose={() =>
					isEdit ? handleCloseModal() : setConfirmExitModal(true)
				}
				disableEnforceFocus
			>
				<FullPageLoader
					// type error (useToggle not assignable to open)
					// @ts-ignore
					open={isLoading}
				/>
				<Formik
					initialValues={initialValues}
					validationSchema={VoidRequestErrorSchema}
					validateOnChange={true}
					validateOnMount={true}
					onSubmit={async (values: any, actions: any) => {
						handleSubmitVoidRequest(values, actions);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						setFieldTouched,
						handleSubmit,
						isValid,
					}) => {
						setFormValues(values);
						setIsFormValid(isValid);
						return (
							<Form
								onSubmit={handleSubmit}
								style={{ overflow: "hidden" }}
							>
								<Grid>
									<DialogActions
										style={{
											display: "flex",
											justifyContent: isView
												? "space-between"
												: "flex-end",
										}}
									>
										{isView && (
											<BackButton
												onClick={handleBackButton}
												startIcon={
													<ArrowBackIosNewIcon />
												}
											>
												Back
											</BackButton>
										)}
										<CloseIcon
											onClick={() =>
												isEdit
													? handleCloseModal()
													: setConfirmExitModal(true)
											}
											className={styles.close}
										/>
									</DialogActions>
								</Grid>

								{/* HEADER */}
								<Grid
									container
									style={{
										justifyContent: "space-between",
										padding: "0 24px",
									}}
								>
									{/* ERROR BANNERS */}
									<Grid container>
										{sortedErrors.map((error) => {
											return (
												<Grid
													key={error.key}
													item
													xs={12}
												>
													<DropErrorMessage>
														<DropErrorContent>
															<BlockIcon />
															<span>
																{error.error}
															</span>
														</DropErrorContent>
														<CloseIcon
															onClick={() =>
																removeError(
																	error.key
																)
															}
														/>
													</DropErrorMessage>
												</Grid>
											);
										})}
									</Grid>
									<Grid item>
										<span
											style={{
												fontFamily: "Wavehaus-Regular",
											}}
										>
											{isEdit
												? "Void Request"
												: "Void Transaction"}
										</span>
									</Grid>

									<Grid item>
										<VoidRequestStatus>
											<li
												style={{ marginBottom: "10px" }}
											>
												Void Reference ID:{" "}
												<ReferenceIdText>
													{
														voidRequestData?.reference_id
													}
												</ReferenceIdText>
											</li>
											<li>
												Void Request Status:{" "}
												<VoidBadge
													status={
														voidRequestData?.status
													}
												/>
											</li>
										</VoidRequestStatus>
									</Grid>
								</Grid>

								<DialogContent
									style={{
										overflowY: "hidden",
									}}
								>
									<StyledHeading>
										Void Request Information
									</StyledHeading>
									<ModalScrollBar isOverflowXhidden>
										<RequestInformationContainer>
											<li>
												<RequestInformationLabel>
													Transaction Date
													<span>:</span>
												</RequestInformationLabel>
												<RequestInformationValue>
													{memoTransactionDate}
												</RequestInformationValue>
											</li>
											<li>
												<RequestInformationLabel>
													Transaction Reference No.
													<span>:</span>
												</RequestInformationLabel>
												<RequestInformationValue>
													{
														selectedTransactionInfo?.trn
													}
												</RequestInformationValue>
											</li>
											<li>
												<RequestInformationLabel>
													Branch<span>:</span>
												</RequestInformationLabel>
												<RequestInformationValue>
													{
														selectedTransactionInfo?.branch_name
													}
												</RequestInformationValue>
											</li>
											<li>
												<RequestInformationLabel>
													TPAID<span>:</span>
												</RequestInformationLabel>
												<RequestInformationValue>
													{
														selectedTransactionInfo?.tpaid
													}
												</RequestInformationValue>
											</li>
											<li>
												<RequestInformationLabel>
													Biller<span>:</span>
												</RequestInformationLabel>
												<RequestInformationValue>
													{
														selectedTransactionInfo?.billerName
													}
												</RequestInformationValue>
											</li>
											<li>
												<RequestInformationLabel>
													Mode of Payment
													<span>:</span>
												</RequestInformationLabel>
												<RequestInformationValue textTransform="capitalize">
													{selectedTransactionInfo?.paymentMethod?.toLowerCase()}
												</RequestInformationValue>
											</li>
											<li>
												<RequestInformationLabel>
													Amount<span>:</span>
												</RequestInformationLabel>
												<RequestInformationValue>
													{amount}
												</RequestInformationValue>
											</li>
											<li>
												<RequestInformationLabel>
													Account Number<span>:</span>
												</RequestInformationLabel>

												<RequestInformationValue>
													{
														selectedTransactionInfo?.arn
													}
												</RequestInformationValue>
											</li>
											{selectedTransactionInfo?.paymentMethod !==
												"CASH" && (
												<>
													<li>
														<RequestInformationLabel>
															Bank Name
															<span>:</span>
														</RequestInformationLabel>

														<RequestInformationValue>
															{
																selectedTransactionInfo
																	?.otherInfo
																	?.CheckDetails
																	?.BankName
															}
														</RequestInformationValue>
													</li>
													<li>
														<RequestInformationLabel>
															Check Number
															<span>:</span>
														</RequestInformationLabel>

														<RequestInformationValue>
															{
																selectedTransactionInfo
																	?.otherInfo
																	?.CheckDetails
																	?.CheckNo
															}
														</RequestInformationValue>
													</li>
												</>
											)}
											<li>
												<RequestInformationLabel>
													Date of Request
													<span>:</span>
												</RequestInformationLabel>

												<RequestInformationValue>
													{dateOfRequest}
												</RequestInformationValue>
											</li>
											<li>
												<RequestInformationLabel>
													Biller Type<span>:</span>
												</RequestInformationLabel>

												<RequestInformationValue>
													{
														selectedTransactionInfo?.biller_type
													}
												</RequestInformationValue>
											</li>
											{selectedTransactionInfo?.biller_type ===
												"NON-RTP" && (
												<li>
													<RequestInformationLabel>
														For Within the Day
														Cancellation?
														<span>:</span>
													</RequestInformationLabel>

													<RequestInformationValue>
														{
															isForCancelledWithinDay
														}
													</RequestInformationValue>
												</li>
											)}
											{isEdit && (
												<li>
													<RequestInformationLabel>
														Bayad Center Name
														<span>:</span>
													</RequestInformationLabel>

													<RequestInformationValue>
														{
															selectedTransactionInfo?.channel_name
														}
													</RequestInformationValue>
												</li>
											)}
										</RequestInformationContainer>

										<Grid
											container
											spacing={3}
											alignItems={"space-between"}
										>
											<Grid item xs>
												<Field
													as={TextField}
													name="depositoryAccountNumber"
													label={
														<span
															style={{
																fontFamily:
																	"Poppins-Regular",
															}}
														>
															Depository Bank
															Account
															No.(Optional)
														</span>
													}
													disabled={isEdit}
													onChange={(e: any) => {
														if (
															!touched?.depositoryAccountNumber
														) {
															setFieldTouched(
																"depositoryAccountNumber"
															);
														}
														handleChange(e);
													}}
													defaultValue={
														isEdit
															? voidRequestData?.depositoryAccountNumber
															: ""
													}
													fullWidth
													size="small"
												/>
												{
													// @ts-ignore
													errors?.depositoryAccountNumber &&
														touched?.depositoryAccountNumber && (
															<ErrorMessage>
																{/* @ts-ignore */}
																{
																	errors?.depositoryAccountNumber
																}
															</ErrorMessage>
														)
												}
											</Grid>
											<Grid item xs>
												<DatePickerFormik
													disabled={isEdit}
													label={
														<span
															style={{
																fontFamily:
																	"Poppins-Regular",
																fontSize:
																	"1rem",
															}}
														>
															Date of Deposit
															(Optional)
														</span>
													}
													placeholder="Date of Deposit (Optional)"
													size="small"
													name="depositDate"
												/>
												{
													// @ts-ignore
													errors?.depositDate &&
														touched?.depositDate && (
															<ErrorMessage>
																{/* @ts-ignore */}
																{
																	errors?.depositDate
																}
															</ErrorMessage>
														)
												}
											</Grid>
										</Grid>

										<StyledHeading>
											Void Reason
										</StyledHeading>
										{/* Checkbox fields */}
										<StyledReasonWrapper>
											{errorSelectedReasons &&
												isVoidCheckboxesTouched && (
													<ErrorMessage>
														{errorSelectedReasons}
													</ErrorMessage>
												)}
											<FormControlLabel
												label={
													<span
														style={{
															fontFamily:
																"Poppins-Regular",
															marginLeft: "10px",
														}}
													>
														Select all
													</span>
												}
												control={
													<span
														style={{
															margin: "10px 0 12px 10px",
														}}
													>
														<Checkbox
															checked={
																isSelectAllReasonChecked
															}
															disabled={isEdit}
															sx={{
																color: "#b8b8b8",
																"&.Mui-checked":
																	{
																		color: isEdit
																			? "#f6a07b"
																			: "var(--orange)",
																	},
															}}
															onChange={(
																e: any
															) => {
																if (
																	!isVoidCheckboxesTouched
																) {
																	setIsVoidCheckboxesTouched(
																		true
																	);
																}
																setIsSelectAllReasonChecked(
																	e?.target
																		?.checked
																);
															}}
														/>
													</span>
												}
											/>
											<StyledReasonContainer>
												<TextField
													label={
														reasonSearchInput && (
															<>Search</>
														)
													}
													sx={RequiredInputStyleSX}
													placeholder="Search"
													size="small"
													fullWidth
													value={reasonSearchInput}
													disabled={isEdit}
													onChange={(e: any) => {
														setReasonSearchInput(
															e?.target?.value
														);
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<SearchIcon color="action" />
															</InputAdornment>
														),
													}}
												/>

												<Grid container>
													{filteredCheckboxReason?.map(
														(item) => {
															const isChecked =
																isEdit
																	? includes(
																			voidRequestData?.reason,
																			item?.value
																	  )
																	: includes(
																			selectedReasons,
																			item?.value
																	  );
															return (
																<Grid
																	item
																	key={
																		item.id
																	}
																	xs={6}
																>
																	<FormControlLabel
																		label={
																			<span
																				style={{
																					fontFamily:
																						"Poppins-Regular",
																					marginLeft:
																						"10px",
																				}}
																			>
																				{
																					item?.label
																				}
																			</span>
																		}
																		control={
																			<span
																				style={{
																					margin: "10px 0 12px 10px",
																				}}
																			>
																				<Checkbox
																					name="aa"
																					size="small"
																					checked={
																						isChecked
																					}
																					value={
																						item?.value
																					}
																					sx={{
																						color: "#b8b8b8",
																						"&.Mui-checked":
																							{
																								color: isEdit
																									? "#f6a07b"
																									: "var(--orange)",
																							},
																					}}
																					disabled={
																						isEdit
																					}
																					onChange={(
																						e: ChangeEvent<HTMLInputElement>
																					) => {
																						handleOnChangeVoidReason(
																							e
																						);
																					}}
																				/>
																			</span>
																		}
																	/>
																	{item?.value ===
																		"OTHERS" &&
																		(includes(
																			selectedReasons,
																			"OTHERS"
																		) ||
																			includes(
																				voidRequestData?.reason,
																				"OTHERS"
																			)) && (
																			<>
																				<Field
																					as={
																						TextField
																					}
																					name="others"
																					type="text"
																					size="small"
																					sx={{
																						textarea:
																							{
																								fontFamily:
																									"Poppins-Regular",
																								"&::placeholder":
																									{
																										fontFamily:
																											"Poppins-Regular",
																									},
																							},
																					}}
																					disabled={
																						isEdit
																					}
																					placeholder="Please specify"
																					fullWidth
																					multiline
																					value={
																						othersInput
																					}
																					onBlur={
																						handleOthersInput
																					}
																					onChange={
																						handleOthersInput
																					}
																				/>
																				{errorOthersInput && (
																					<ErrorMessage>
																						{
																							errorOthersInput
																						}
																					</ErrorMessage>
																				)}
																			</>
																		)}
																</Grid>
															);
														}
													)}
												</Grid>
											</StyledReasonContainer>
										</StyledReasonWrapper>

										<StyledHeading>
											Upload Attachments
										</StyledHeading>
										<VoidAttachmentField
											onDrop={onDrop}
											isEdit={isEdit}
											acceptedFiles={attachments}
											onView={handleFileView}
											onRemove={
												setDeleteAttachmentModalInfo
											}
											onUploadTimeout={
												setAttachmentTimeout
											}
											onUploadSuccess={updateAttachment}
										/>
										<FormDivider />
										<Grid container direction="column">
											<StyledHeading>
												Void Requestor Information
											</StyledHeading>
											<Grid
												spacing={3}
												item
												container
												alignItems={"space-between"}
											>
												<Grid item xs={12} md={6}>
													<Field
														as={TextField}
														name="requestorName"
														sx={
															RequiredInputStyleSX
														}
														label={
															values?.requestorName && (
																<>
																	Name of Void
																	Requestor{" "}
																	{!isEdit && (
																		<RequiredIndicator />
																	)}{" "}
																</>
															)
														}
														placeholder="Name of Void Requestor"
														onChange={(e: any) => {
															if (
																!touched?.requestorName
															) {
																setFieldTouched(
																	"requestorName"
																);
															}
															handleChange(e);
														}}
														disabled={isEdit}
														defaultValue={
															isEdit
																? voidRequestData?.requestor_name
																: ""
														}
														fullWidth
														size="small"
													/>
													{
														// @ts-ignore
														errors?.requestorName &&
															touched?.requestorName && (
																<ErrorMessage>
																	{/* @ts-ignore */}
																	{
																		errors?.requestorName
																	}
																</ErrorMessage>
															)
													}
												</Grid>
												<Grid item xs={12} md={6}>
													<Field
														as={TextField}
														name="requestorContactNumber"
														sx={
															RequiredInputStyleSX
														}
														label={
															values?.requestorContactNumber && (
																<>
																	Contact
																	Number of
																	Void
																	Requestor{" "}
																	{!isEdit && (
																		<RequiredIndicator />
																	)}{" "}
																</>
															)
														}
														disabled={isEdit}
														placeholder="Contact Number of Void Requestor"
														onChange={(e: any) => {
															if (
																!touched?.requestorContactNumber
															) {
																setFieldTouched(
																	"requestorContactNumber"
																);
															}
															handleChange(e);
														}}
														defaultValue={
															isEdit
																? voidRequestData?.requestor_contact_number
																: ""
														}
														fullWidth
														size="small"
													/>
													{errors?.requestorContactNumber &&
														touched?.requestorContactNumber && (
															// @ts-ignore
															<ErrorMessage>
																{
																	errors?.requestorContactNumber
																}
															</ErrorMessage>
														)}
												</Grid>
											</Grid>
										</Grid>

										<FormDivider />
										<Grid container direction="column">
											<StyledHeading>
												Incident report
											</StyledHeading>
											<Field
												as={TextField}
												name="incidentReport"
												disabled={isEdit}
												defaultValue={
													isEdit
														? voidRequestData?.incident_report
														: ""
												}
												multiline
												size="small"
												minRows={3}
												sx={RequiredInputStyleSX}
												placeholder="Incident Report"
												label={
													values?.incidentReport && (
														<>
															Incident Report{" "}
															{!isEdit && (
																<RequiredIndicator />
															)}{" "}
														</>
													)
												}
												onChange={(e: any) => {
													if (
														!touched?.incidentReport
													) {
														setFieldTouched(
															"incidentReport"
														);
													}
													handleChange(e);
												}}
											/>
											{
												// @ts-ignore
												errors?.incidentReport &&
													touched?.incidentReport && (
														<ErrorMessage>
															{/* @ts-ignore */}
															{
																errors?.incidentReport
															}
														</ErrorMessage>
													)
											}
										</Grid>
									</ModalScrollBar>
								</DialogContent>

								<Grid
									container
									style={{
										justifyContent: "space-between",
										padding: "5px 24px",
									}}
								>
									<Grid item alignItems="start">
										<FormControl
											variant="standard"
											error={Boolean(
												!isConfirmCheckboxChecked &&
													errorConfirmCheckboxMessage
											)}
										>
											<FormGroup>
												<FormControlLabel
													label={
														<span
															style={{
																fontFamily:
																	"Poppins-Regular",
																fontSize:
																	"13.7px",
																marginLeft:
																	"12px",
															}}
														>
															I will take full
															responsibility in
															case the customer
															has any dispute in
															the posted
															transaction versus
															the validated amount
															in the statement of
															account or payment
															form. I will not
															hold Bayad Center
															liable for the
															difference, if any.
														</span>
													}
													control={
														<div
															style={{
																alignSelf:
																	"flex-start",
															}}
														>
															<Checkbox
																name="aa"
																size="small"
																checked={
																	isConfirmCheckboxChecked ||
																	isEdit
																}
																disabled={
																	isEdit
																}
																onChange={(
																	e: any
																) =>
																	setIsConfirmCheckboxChecked(
																		e
																			?.target
																			?.checked
																	)
																}
																sx={{
																	"&.Mui-checked":
																		{
																			color: isEdit
																				? "#f6a07b"
																				: "var(--orange)",
																		},
																}}
															/>
														</div>
													}
												/>
												{errorConfirmCheckboxMessage &&
													!isConfirmCheckboxChecked && (
														<ErrorMessage>
															{
																errorConfirmCheckboxMessage
															}
														</ErrorMessage>
													)}
											</FormGroup>
										</FormControl>
									</Grid>
								</Grid>

								{!isEdit && (
									<DialogActions>
										{/* @ts-ignore */}
										<button
											type="submit"
											ref={submitButtonRef}
											hidden
										/>
										<Button
											// @ts-ignore
											disabled={isLoading}
											variant="outlined"
											color="primary"
											onClick={() =>
												setConfirmExitModal(true)
											}
											style={{
												fontFamily: "Poppins-Regular",
											}}
										>
											Cancel
										</Button>
										<Button
											// @ts-ignore
											disabled={
												isSubmitDisabled || isLoading
											}
											color="primary"
											variant="contained"
											onClick={() => {
												setConfirmModal(true);
											}}
											style={{
												fontFamily: "Poppins-Regular",
											}}
										>
											{isLoading
												? "Submitting"
												: "Submit"}
										</Button>
									</DialogActions>
								)}
							</Form>
						);
					}}
				</Formik>
			</StyledDialog>

			{/* Confirmation Modal */}
			<Dialog
				maxWidth="xs"
				fullWidth={true}
				open={confirmModal}
				onClose={() => setConfirmModal(false)}
			>
				<div className={styles.voidConfirmModalTitle}>
					<Typography className={styles.voidConfirmModalTitleText}>
						Void Transaction
					</Typography>

					<IconButton
						onClick={() => setConfirmModal(false)}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</div>
				<Divider />
				<Grid>
					<div className={styles.voidConfirmModalContent}>
						<Typography
							className={styles.voidConfirmModalContentText}
						>
							Are you sure you want to proceed?
						</Typography>
					</div>
				</Grid>
				<Divider />
				<DialogActions>
					<WhiteColorButton onClick={() => setConfirmModal(false)}>
						Cancel
					</WhiteColorButton>
					<GreenColorButton
						onClick={() => {
							// @ts-ignore
							submitButtonRef?.current?.click();
							setConfirmModal(false);
						}}
						autoFocus
						color="primary"
					>
						Confirm
					</GreenColorButton>
				</DialogActions>
			</Dialog>

			{/* Delete Attachment Confirmation Modal */}
			<DeleteAttachmentConfirmationModal
				isOpen={deleteAttachmentModalInfo.isOpen}
				content={`Are you sure want to remove <b>${deleteAttachmentModalInfo.fileName}</b>?`}
				onClickCancel={() =>
					setDeleteAttachmentModalInfo((prev) => ({
						...prev,
						isOpen: false,
					}))
				}
				isBreakWordContent
				onClickConfirm={() => {
					handleFileRemove(deleteAttachmentModalInfo.fileIndex);
					removeError(deleteAttachmentModalInfo.key ?? "");
					setDeleteAttachmentModalInfo((prev) => ({
						...prev,
						isOpen: false,
					}));
				}}
				title={"Remove Attachment"}
			/>

			{/* Exit Confirmation Modal */}
			<Dialog
				maxWidth="md"
				open={confirmExitModal}
				onClose={() => setConfirmExitModal(false)}
				PaperProps={{
					style: {
						padding: 0,
					},
				}}
			>
				<DialogTitle style={{ padding: 0, margin: 0 }}>
					<Box className={styles.exitConfirmationHeader}>
						<Typography>Close Void Transaction</Typography>
						<DialogActions>
							<CloseIcon
								onClick={() => setConfirmExitModal(false)}
								className={styles.exitConfirmationClose}
							/>
						</DialogActions>
					</Box>
				</DialogTitle>

				<DialogContent
					style={{ padding: 0, margin: 0 }}
					className={styles.exitConfirmationMessage}
				>
					<span>
						Are you sure you want to close <b>Void Transaction</b>?
						<br />
						Your progress will not be saved, if you wish to
						continue.
					</span>
				</DialogContent>
				<DialogActions
					style={{ padding: 0, margin: 0 }}
					className={styles.exitConfirmationButtons}
				>
					<WhiteColorButton
						onClick={() => setConfirmExitModal(false)}
					>
						Cancel
					</WhiteColorButton>
					<GreenColorButton
						onClick={() => {
							setConfirmExitModal(false);
							handleCloseModal();
						}}
						autoFocus
						color="primary"
					>
						Confirm
					</GreenColorButton>
				</DialogActions>
			</Dialog>

			{/* Modal component to view a selected image */}
			{selectedFile && (
				<PreviewModal
					file={selectedFile}
					isOpen={viewFileModal}
					isEdit={isEdit}
					onClose={() => {
						setViewFileModal(false);
						setSelectedFile(null);
					}}
				/>
			)}

			<SuccessModal
				open={isSuccessModalOpen}
				title="Void Transaction Request Submitted!"
				message={successVoidMessage}
				toggleDialog={handleSuccessfulVoidSubmission}
				buttonTitle="Done"
				disableScrollLock
			/>

			<ErrorModal
				open={isTimeoutErrorModalOpen}
				title="Timeout Error"
				message="A problem occured while submitting the data. Please try again."
				buttonText="Retry"
				toggleDialog={() => {
					httpRetry?.current();
				}}
			/>
		</>
	);
};

export default VoidRequestModal;

const ErrorMessage = styled(FormHelperText)`
	color: #d93025;
`;

const DropErrorContent = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
`;

const DropErrorMessage = styled(FormHelperText)`
	display: flex;
	gap: 10px;
	padding: 10px 20px;
	padding: 10px 20px;
	align-items: center;
	justify-content: space-between;
	background: #d93025;
	color: #ffffff;
	font-family: Poppins-Regular;
	font-size: 12px;
`;

const RequestInformationLabel = styled.span`
	width: 250px;
	font-weight: 600;
	display: flex;
	justify-content: space-between;
	font-family: Poppins-Regular !important;
`;

const RequestInformationValue = styled.span<RequestInformationValueProps>`
	text-align: left;
	white-space: pre;
	width: 300px;
	font-family: Poppins-Regular !important;
	text-transform: ${(props) => props.textTransform || "none"};
`;

const RequestInformationContainer = styled.ul`
	list-style: none;
	padding: 0;
	width: 100%;
	li {
		display: flex;
		flex-direction: row;
		justify-content: start;
		gap: 30px;
		margin-bottom: 10px;
	}
`;

const StyledReasonContainer = styled.div`
	margin: auto;
	width: 95%;
`;

export const VoidRequestStatus = styled.ul`
	list-style: none;
	width: 25em;
	font-family: Poppins-Regular !important;
	li {
		display: flex;
		justify-content: space-between;
	}
`;

const FormDivider = styled.hr`
	border: 1px solid var(--headerBlue);
`;

export const ReferenceIdText = styled.span`
	font-weight: 600;
	color: #0076c0;
`;

const StyledHeading = styled.h3`
	margin: 22px 0px 11px 0px;
	font-family: Poppins-Regular !important;
`;

const StyledDialog = styled(Dialog)`
	width: 100%;
	border: 1px solid #3f3;
	overflow: hidden;
`;

const Card = styled.div`
	border: 1px solid var(--ligtherGray);
	border-radius: 4px;
	padding: 10px;
	margin: 10px 0;
`;

const StyledReasonWrapper = styled(Card)`
	font-size: 14px !important;
	border-color: #b8b8b8;
`;

const BackButton = styled(Button)`
	font: normal normal normal 14px Poppins-Regular;
	letter-spacing: 0px;
	color: #0076c0;
	text-transform: none;
`;
