import axios from "axios";
import {
	IsRefreshingUtil,
	TerminalUtil,
	TokenUtil,
} from "../utils/checkSession";
import { clearSessionAndRedirect } from "../utils/logout";
import checkChannelBranchTpa from "./ChannelBranchTpaAccess";

//List of APIs exempted from the refresh issue
const exemptRefreshAPIs = ["/v2/user/refreshToken"];

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
	headers: {
		"Content-Type": "application/json",
		"x-bayad-platform-id": process.env.REACT_APP_APP_KEY,
		// Pragma: "no-cache",
	},
});

export const session_instance = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
	headers: {
		"Content-Type": "application/json",
		"x-bayad-platform-id": process.env.REACT_APP_APP_KEY,
		// Pragma: "no-cache",
	},
});

const DENIED_WHITELIST = [
	"/v2/user/logout",
	"/branch-access/closing-time",
	"/user/refreshToken",
];

export function setupInterceptors() {
	session_instance.interceptors.request.use(function (config) {
		const token = TokenUtil.get();

		const cachedResponse = sessionStorage.getItem(config.url);
		if (cachedResponse) {
			const { data, timestamp } = JSON.parse(cachedResponse);
			const currentTime = Date.now();
			if (currentTime - timestamp < 60000) {
				const cfg = { ...config, data };
				cfg.headers.Authorization = `Bearer ${token}`;
				return cfg;
			} else {
				sessionStorage.removeItem(config.url); // Remove stale data
			}
		}

		config.headers.Authorization = `Bearer ${token}`;

		return config;
	});

	session_instance.interceptors.response.use((response) => {
		if (response.config.method === "get") {
			const timestamp = Date.now();
			const cachedData = {
				data: response.data,
				timestamp: timestamp,
			};
			sessionStorage.setItem(
				response.config.url,
				JSON.stringify(cachedData)
			);
		}
		return response;
	});

	instance.interceptors.request.use(function (config) {
		const token = TokenUtil.get();

		if (token) {
			config.headers.Authorization = "Bearer " + token;
			const terminal = TerminalUtil.get();

			if (
				window.setDisabledAccess &&
				terminal &&
				!DENIED_WHITELIST.includes(config.url)
			) {
				return new Promise((resolve) => {
					checkChannelBranchTpa()
						.then((disabledAccessTo) => {
							if (disabledAccessTo) {
								window.setDisabledAccess(disabledAccessTo);
								throw new Error(`${disabledAccessTo} Disabled`);
							}

							resolve(config);
						})
						.catch((err) => {
							resolve(config);
						});
				});
			}
		}

		return config;
	});

	instance.interceptors.response.use(
		(response) => {
			!isRequestRefreshTokenOrClosingTime(response.config.url) &&
				window.resetTimeOut &&
				window.resetTimeOut();
			return response;
		},
		async (error) => {
			const config = error.config;

			!isRequestRefreshTokenOrClosingTime(
				...(error?.response?.config?.url ?? "")
			) &&
				window.resetTimeOut &&
				window.resetTimeOut();
			if (
				error.response.status === 401 &&
				!exemptRefreshAPIs.includes(config.url)
			) {
				return checkRefresh(5, config.headers.Authorization)
					.then((token) => {
						config.headers.Authorization = token;
						return axios.request(config);
					})
					.catch(() => {
						clearSessionAndRedirect(true);
					});
			}
			if (error.response.status === 403)
				window.showAccessDeniedModal && window.showAccessDeniedModal();

			return Promise.reject(error);
		}
	);

	function isRequestRefreshTokenOrClosingTime(url = "") {
		return ["/branch-access/closing-time", "/user/refreshToken"].some(
			(_url) => url.search(_url) >= 0
		);
	}

	let refreshTokenTimeout = null;
	function checkRefresh(maxRetries, token) {
		return new Promise((resolve, reject) => {
			const refresh = (i) => {
				refreshTokenTimeout = setTimeout(() => {
					const storageToken = `Bearer ${TokenUtil.get()}`;
					//if token in storage is different from the original token
					if (storageToken != token) {
						resolve(storageToken);
						clearTimeout(refreshTokenTimeout);
						return;
					} else {
						//if refreshToken is not being called, reject
						if (!IsRefreshingUtil.get()) {
							reject({});
							clearTimeout(refreshTokenTimeout);
							return;
						}
					}
					if (--i) {
						refresh(i);
					} else {
						//if reached max retries, reject
						reject({});
						clearTimeout(refreshTokenTimeout);
					}
				}, 3000);
			};
			refresh(maxRetries);
		});
	}
}

export default instance;
