import { Grid, IconButton, Tooltip } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import { IoMdPaper } from "react-icons/io";
import { get } from "lodash";
import styles from "./UserTable.module.css";
import CommonTable from "../../common/CommonTable/CommonTable";
import { useToggle, usePagination } from "../../../utils/hooks";
import { useState } from "react";
import UserActivityLogsModal from "./UserActivityLogsModal";
import HTTP from "../../../helpers/ApiClient";
import { useCallback } from "react";
import FullPageLoader from "../../common/FullPageLoader/FullPageLoader";
import { useDispatch } from "react-redux";
import { toggleDialog } from "../../../redux/modules/dialog";

const NOT_AVAILABLE = "--";

const ActionColumn = ({ entry, onView }) => (
	<Grid container spacing={1}>
		<Grid item>
			<ActionBtnTooltip title="View">
				<IconButton>
					<IoMdPaper
						onClick={() => {
							onView(entry);
						}}
					/>
				</IconButton>
			</ActionBtnTooltip>
		</Grid>
	</Grid>
);

// const headers = [
// 	{
// 		label: () => (
// 			<>
// 				<div>Date and Time</div>
// 			</>
// 		),
// 		identifier: "date_created",
// 		render: (value) => moment(value).format("MM/DD/YYYY hh:mm:ss A"),
// 		sortable: true,
// 	},
// 	{
// 		label: "Teller Name",
// 		identifier: "name",
// 	},
// 	{
// 		label: "Username",
// 		identifier: "username",
// 		sortable: true,
// 	},
// 	{
// 		label: "Branch",
// 		identifier: "branch_name",
// 		sortable: true,
// 	},
// 	{
// 		label: "TPA ID",
// 		identifier: "tpa_id",
// 	},
// 	{
// 		label: "Last Activity",
// 		identifier: "last_activity",
// 	},
// 	{
// 		label: "Status",
// 		identifier: "status",
// 		render: (value) => <UserListStatus status={value} />,
// 	},
// 	{
// 		label: "Actions",
// 		identifier: "actions",
// 		render: (value, entry, { onView }) => (
// 			<>
// 				<ActionColumn entry={entry} onView={onView} />
// 			</>
// 		),
// 	},
// ];

const STATUS = [
	{
		code: 0,
		label: "Active",
		className: styles.statusActive,
	},
	{
		code: 1,
		label: "Inactive",
		className: styles.statusInactive,
	},
];

function isTruthy(value) {
	return !!value && !!value.trim();
}

const NotAvailable = () => <>{NOT_AVAILABLE}</>;

export const UserListStatus = ({ status }) => {
	if (!isTruthy(status)) return <NotAvailable />;
	const match = STATUS.find(({ label }) => status === label);
	if (!match) return <NotAvailable />;
	const { label, className } = match;
	return <div className={className}>{label}</div>;
};

UserListStatus.propTypes = {
	status: PropTypes.string.isRequired,
};

const ActionBtnTooltip = ({ children, title, placement }) => (
	<Tooltip title={title} placement={placement}>
		{children}
	</Tooltip>
);

ActionBtnTooltip.propTypes = {
	children: PropTypes.element.isRequired,
	title: PropTypes.string.isRequired,
	placement: PropTypes.string,
};

ActionBtnTooltip.defaultProps = {
	placement: "top",
};

async function getUserLogs(filters) {
	const { username, limit, page = 1, sortBy, sort } = filters;

	const resolveParam = (p) => p || undefined;

	try {
		const params = {
			limit,
			page,
			sortBy: resolveParam(sortBy),
			sort: resolveParam(sort),
		};

		const result = await HTTP.get("/v2/users/logs?username=" + username, {
			params,
		});

		if (!result || result.status != 200) return null;

		const {
			data: {
				data: { totalCount, items },
			},
		} = result;

		return {
			totalCount,
			items,
		};
	} catch (error) {
		console.error(error);
		return null;
	}
}

const UserTable = ({
	headers,
	className,
	page,
	data,
	rowsPerPage,
	onPageChange,
	onSort,
	sortBy,
	sort,
	expandRow,
	handlers,
	isHidePageNumber,
	hasActivityLogPermission,
}) => {
	const dispatch = useDispatch();
	const [isModalVisible, showModal, hideModal] = useToggle();

	const [selectedEntry, setSelectedEntry] = useState(null);
	const [entry, setEntry] = useState(null);
	const { ...pagination } = usePagination(25);
	const [isLoading, showLoader, hideLoader] = useToggle();
	const [isTimeoutError, showTimeoutError, hideTimeoutError] = useToggle();

	const handleView = useCallback(
		async (
			entry,
			page = pagination.page,
			limit = pagination.rowsPerPage
		) => {
			if (hasActivityLogPermission) {
				setEntry(entry);
				showLoader();

				const result = await getUserLogs({
					username: entry.username,
					limit: limit,
					page: page,
					sortBy: "date_created",
					sort: "desc",
				});

				if (result === null) {
					showTimeoutError();
					hideLoader();
					return;
				}

				hideLoader();
				showModal();
				setSelectedEntry(result);
			} else {
				dispatch(toggleDialog());
			}
		},
		[
			pagination.page,
			hideLoader,
			pagination.rowsPerPage,
			showLoader,
			showTimeoutError,
		]
	);

	const handleViewClose = () => {
		hideModal();
		setSelectedEntry(null);
	};

	const handlePageChange = (page, limit) => {
		pagination.setPage(page);
		pagination.setRowsPerPage(limit);
		handleView(entry, page, limit);
	};

	return (
		<>
			<CommonTable
				{...handlers}
				className={className}
				headers={headers}
				entries={get(data, "items", [])}
				page={page}
				totalEntries={get(data, "totalCount", 0)}
				rowsPerPage={rowsPerPage}
				onPageChange={onPageChange}
				onSort={onSort}
				onView={handleView}
				sortBy={sortBy}
				sort={sort}
				expandRow={expandRow}
				isHidePageNumber={isHidePageNumber}
			/>
			<UserActivityLogsModal
				open={isModalVisible}
				name={get(entry, "name", "")}
				username={get(entry, "username", "")}
				data={selectedEntry}
				page={pagination.page}
				rowsPerPage={pagination.rowsPerPage}
				onPageChange={handlePageChange}
				onClose={handleViewClose}
			/>
			<FullPageLoader open={isLoading} />
		</>
	);
};

UserTable.propTypes = {
	headers: PropTypes.arrayOf(PropTypes.object),
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	data: PropTypes.object.isRequired,
	onPageChange: PropTypes.func,
	onSort: PropTypes.func,
	sortBy: PropTypes.string,
	sort: PropTypes.string,
	isHidePageNumber: PropTypes.bool,
};

export default UserTable;
