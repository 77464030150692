import { Component } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import styles from "./CustomTable.module.css";
import TableRow from "@material-ui/core/TableRow";

export default class CustomTable extends Component {
  render() {
    const { CustomTableCell, CustomTableRow, columns, data } = this.props;
    return (
      <TableContainer component={Paper} className={styles.container}>
        <Table>
          <TableHead>
            <TableRow>
              {CustomTableCell &&
                columns.map((column, i) => (
                  <CustomTableCell key={`head-${i}`}>{column}</CustomTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {CustomTableRow &&
              CustomTableCell &&
              data.map((d, i) => (
                <CustomTableRow key={i}>
                  {d.map((k, j) => {
                    const value =
                      typeof k === "object" && k !== null && "value" in k
                        ? k.value
                        : k;
                    const customStyle =
                      typeof k === "object" && k !== null && "style" in k
                        ? k.style
                        : {};
                    return (
                      <CustomTableCell
                        key={`cell-${i}-${j}`}
                        style={customStyle}
                      >
                        {value}
                      </CustomTableCell>
                    );
                  })}
                </CustomTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
