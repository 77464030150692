import React, { useEffect } from "react";
import { useSubscription } from "@apollo/client";
import { ON_UPDATE_WALLET_BALANCE } from "../../graphql/queries";
import { updateWallet } from "../../redux/modules/walletBalance";

interface Props {
	walletId: string;
	updateWallet: typeof updateWallet;
}

const SubscribeWalletBalance: React.FC<Props> = ({
	walletId,
	updateWallet,
}) => {
	const { data, error } = useSubscription(ON_UPDATE_WALLET_BALANCE, {
		variables: { wallet_id: walletId },
		context: { clientName: "reprint" },
	});

	useEffect(() => {
		if (error) console.log(error);
		if (data && data.onWalletBalanceUpdate) {
			const {
				wallet_id: wallet_identifier,
				wallet_balance: balance,
				wallet_status: status,
				wallet_type: type,
				wallet_threshold: threshold_amount,
				last_update,
			} = data.onWalletBalanceUpdate;
			updateWallet({
				wallet_identifier,
				balance,
				status,
				type,
				threshold_amount,
				last_update,
			});
		}
	}, [data, error]);
	return <></>;
};

export default React.memo(SubscribeWalletBalance);
