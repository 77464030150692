import React from "react";
import {
	Grid,
	Divider,
	Dialog,
	Box,
	DialogActions,
	Button,
	Typography,
	DialogTitle,
	CircularProgress,
	IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import styles from "./TransactionDisconnected.module.css";

export default function TransactionDisconnectedModal({
	msg,
	clearError,
	codemsg,
}) {
	const staticMessage = `Upon checking Meralco’s records, your service is temporarily disconnected due to an unpaid balance as of today. Please settle your account in full to get reconnected.`;
	const staticMessage2 = `Payments made from 7:00AM to 6:59PM will be posted in real-time, otherwise, your payment will be posted on the next day. Your service will be reconnected within the next business day after payment posting. Please expect a reconnection fee on your next bill.`;
	const staticMessage3 = `If you have been disconnected for at least 60 days, your service contract may be for termination. You can still pay your bill with us, but if Meralco already has pulled out your meter, kindly go to the Meralco Business Center as you might need to submit necessary documents for reconnection.`;
	const staticMessage4 = `Do you still wish to proceed?`
	const staticMessage5 = `(Please disregard this message if payment has been made)`
	const dfoStaticMessage = `Hey, your account is already for disconnection. A crew might already be on its way and your payment may not be posted in time to avoid disconnection. In case your service is disconnected, you will be reconnected within the next business day from the time your payment is posted. A reconnection fee will be included on your next bill.`;
	const dfoStaticMessage2 = `Do you still want to proceed?`;
	const breakThrought = () => {
		if (codemsg === 1) {
			return (
				<>
					{dfoStaticMessage}
					<br />
					<br />
					<br />
					{dfoStaticMessage2}
				</>
			);
		} else {
			return (
				<>
					{staticMessage}
					<br />
					<br />
					{staticMessage2}
					<br />
					<br />
					{staticMessage3}
					<br />
					<br />
					{staticMessage4}
					<br />
					<br />
					<i>{staticMessage5}</i>
				</>
			);
		}
	};
	const headerChanger = () => {
		if (codemsg === 1) {
			return "Account for Disconnection!";
		} else {
			return "Disconnected Service!";
		}
	};
	return (
		<Dialog
			disableBackdropClick
			open={true}
			classes={{ paper: styles.dialogPaper }}
		>
			<Grid
			container
			direction="row"
			justifyContent="space-between"
			alignItems="center">
				<Grid item>
					<DialogTitle>
						<Typography className={styles.countHeader}>
							{headerChanger()}
						</Typography>
					</DialogTitle>
				</Grid>
				<Grid item className={styles.closeButton}>
					<IconButton
						aria-label="close"
						onClick={() => clearError()}
					>
						<CloseIcon />
					</IconButton>
				</Grid>
			</Grid>
			<Divider />
			<Box>
				<Typography className={styles.countBody}>
					<div>{breakThrought()}</div>
				</Typography>
			</Box>

			<Divider />
			<DialogActions>
				<Button
					variant="outlined"
					className={styles.buttonLogin}
					label="close"
					onClick={() => clearError()}
				>
					Cancel
				</Button>

				<Button
					variant="outlined"
					className={styles.buttonLogout}
					label="close"
					onClick={() => clearError()}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
