import { Dialog, Box, DialogActions, Typography } from "@material-ui/core";
import { sanitize } from "dompurify";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import styles from "./SuccessModal.module.css";
import { GreenColorButton } from "../App/App";

//TODO: check if this can be merge with  '../Modal/SuccessModal'
//Different design
type SuccessModalProps = {
	title: string;
	message?: string;
	isOpen: boolean;
	buttonTitle?: string;
	fullWidth?: boolean;
};

interface ISuccessModalProps {
	toggleDialog: () => void;
}

export default function SuccessModal({
	title,
	message = "Success!",
	toggleDialog,
	isOpen,
	buttonTitle = "Okay",
	fullWidth = false,
}: SuccessModalProps & ISuccessModalProps) {
	return (
		<Dialog disableBackdropClick open={isOpen} maxWidth={"xs"} fullWidth={fullWidth}>
			<Box className={styles.iconContainer}>
				<CheckCircleOutlineRoundedIcon className={styles.sucessIcon} />
			</Box>
			<Box>
				<Typography className={styles.modalTitle}>{title}</Typography>
			</Box>
			<Box>
				<Typography
					className={styles.modalMessage}
					dangerouslySetInnerHTML={{
						__html: sanitize(message),
					}}
				/>
			</Box>
			<DialogActions className={styles.modalActions}>
				<GreenColorButton
					autoFocus
					onClick={toggleDialog}
					color="primary"
					className={styles.button}
				>
					{buttonTitle}
				</GreenColorButton>
			</DialogActions>
		</Dialog>
	);
}

export { SuccessModal };
