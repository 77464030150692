import { useSubscription } from "@apollo/client";
import {
	BranchUtil,
	ChannelUtil,
	TerminalUtil,
	UserInfoUtil,
} from "../../utils/checkSession";
import {
	ON_UPDATE_ACCOUNT_STATUS,
	ON_UPDATE_BRANCH_STATUS,
	ON_UPDATE_TERMINAL_STATUS,
} from "../../graphql/queries";
import { useEffect, useState } from "react";
import AccessDeniedDialog from "../Dialog/Dialog";
import failedIcon from "../../assets/icons/ic-failed.svg";
import { accessErrorMessages, AccessObject } from "../Login/AccessChecker";
import { logOut } from "../../utils/logout";
// import { getSessionStorage } from "../../utils/storage";
import { useSelector } from "react-redux";
import { clearAllStorage } from "../../utils/storage";

const useSessionAccess = () => {
	const [branch] = BranchUtil.get();
	const [channel] = ChannelUtil.get();
	const terminal = TerminalUtil.get();
	// const accountId = getSessionStorage;
	const [disabled, setDisabled] = useState<AccessObject | null>(null);
	const [statusEnabled, setEnabled] = useState(true);
	const [statusBFAEnabled, setBFAEnabled] = useState(true);
	const [statusApi3Enabled, setApi3Enabled] = useState(true);

	const { disabledAccessTo } = useSelector(
		(state: any) => state.sessionAccess
	);

	const { data: tpaStatus } = useSubscription(ON_UPDATE_TERMINAL_STATUS, {
		variables: { tpaId: terminal.id },
		context: { clientName: "reprint" },
	});

	const { data: branchStatus } = useSubscription(ON_UPDATE_BRANCH_STATUS, {
		variables: { branchId: branch.id },
		context: { clientName: "reprint" },
	});

	const { data: channelStatus } = useSubscription(ON_UPDATE_ACCOUNT_STATUS, {
		variables: { channelId: channel?.channel_id },
		context: { clientName: "reprint" },
	});

	useEffect(() => {
		let isEnabled = true;
		let isBFAEnabled = true;
		let isApi3Enabled = true;
		const branchEnabled =
			branchStatus?.onUpdateBranchIsEnable?.is_enabled ?? true;
		const terminalEnabled =
			tpaStatus?.onUpdateTerminalIsEnable?.is_enabled ?? true;
		const channelEnabled =
			channelStatus?.onUpdateChannelIsEnable?.is_enabled ?? true;

		let disabledAccess: AccessObject | null = null;
		if (!terminalEnabled) {
			disabledAccess = "TPAID";
			isEnabled = tpaStatus?.onUpdateTerminalIsEnable?.is_enabled;
			tpaStatus?.onUpdateTerminalIsEnable?.is_bfa_platform
				? (isBFAEnabled = false)
				: (isApi3Enabled = false);
		}
		if (!branchEnabled) {
			disabledAccess = "Branch";
			isEnabled = branchStatus?.onUpdateBranchIsEnable?.is_enabled;
			branchStatus?.onUpdateBranchIsEnable?.is_bfa_platform
				? (isBFAEnabled = false)
				: (isApi3Enabled = false);
		}
		if (!channelEnabled) {
			disabledAccess = "User";
			isEnabled = channelStatus?.onUpdateChannelIsEnable?.is_enabled;
			channelStatus?.onUpdateChannelIsEnable?.is_bfa_platform
				? (isBFAEnabled = false)
				: (isApi3Enabled = false);
		}

		setDisabled(disabledAccess);
		setEnabled(isEnabled);
		setBFAEnabled(isBFAEnabled);
		setApi3Enabled(isApi3Enabled);
	}, [channelStatus, branchStatus, tpaStatus]);

	return {
		status: (disabledAccessTo !== false && statusEnabled !== false) ?? true,
		platform: disabled,
		isEnabled: statusBFAEnabled,
		isApi3Enabled: statusApi3Enabled,
	};
};

const SessionAccessMonitor = () => {
	const isDisabled = useSessionAccess();

	if (!isDisabled.status) {
		const userDetails = UserInfoUtil.get();
		clearAllStorage();
		return (
			<AccessDeniedDialog
				imgSrc={failedIcon}
				open={true}
				toggleDialog={() => logOut(false, userDetails)}
				title="Access Denied!"
				message={accessErrorMessages(isDisabled.platform, isDisabled)}
			/>
		);
	}

	return null;
};

export default SessionAccessMonitor;
