
import {
	Dialog,
	Box,
	DialogActions,
	Button,
	Typography,
	useScrollTrigger,
} from "@material-ui/core";
import styles from "./RetryDialog.module.css";
import retryIcon from "../../../assets/icons/retry.svg"
import { Checkbox, Stack } from "@mui/material";
import { storeCookie } from "../../../utils/cookies";
import { useEffect, useState } from "react";




const RetryDialog = ({toggleDialog, open}) => {
	const [checkBoxState, setCheckBoxState] = useState(false)


	

	const handleCheckChange = (requestState) =>
		{
		setCheckBoxState(requestState)
		if(requestState == true){
			const midnight = new Date();
			midnight.setHours(23, 59, 59, 0);
			storeCookie("showServiceFeeRetryDialog", false, midnight);
		}else{
			const midnight = new Date();
			midnight.setHours(23, 59, 59, 0);
			storeCookie("showServiceFeeRetryDialog", true, midnight);
		}
	}

	return (
		<Dialog
			classes={{ paper: styles.dialogPaper }}
			open={open}
			maxWidth="lg"
		>
			<Box>
				<img src={retryIcon} alt="icon" className={styles.img} />
			</Box>
			<Box>
				<Typography className={styles.header}>Convenience Fee</Typography>
			</Box>
			<Box>
				<Typography className={styles.body}>We were unable to display the convenience fee. Kindly click the refresh button to display the correct convenience fee.</Typography>
			</Box>
			<DialogActions>
				<Button
					onClick={toggleDialog}
					variant="outlined"
					className={styles.button}
				>
					Close
				</Button>
			</DialogActions>
			

			 
			<Stack direction="row" alignItems="center" justifyContent="center" >
				<Checkbox onChange = { ()=>{handleCheckChange(!checkBoxState)}} checked={checkBoxState} style={{color: "#F26122",}}/> 
			<Typography className={styles.note} >Don't show this message again.</Typography>
        	</Stack>
			
		</Dialog>
	);
};

export default RetryDialog;
