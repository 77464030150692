import { FC, useCallback, useMemo } from "react";
import styled from "@emotion/styled";

type VoidBadgeProps = {
	status?: string;
};

const VOID_BADGE_COLOR = {
	pending: "var(--orange)",
	approved: "#14bb02",
	reject: "#ff0000",
};
const VoidBadge: FC<VoidBadgeProps> = ({ status }) => {
	if (!status) {
		return null;
	}
	status = status.toLowerCase();
	const messageStatus = status === "reject" ? "rejected" : status;

	return (
		<Wrapper
			style={{
				background: VOID_BADGE_COLOR[status] || "var(--ligtherGray)",
				color: "var(--white)",
				textTransform: "capitalize",
			}}
		>
			{messageStatus}
		</Wrapper>
	);
};

export default VoidBadge;

const Wrapper = styled.div`
	display: inline-block;
	padding: 3px 10px;
	font-weight: 600;
	border-radius: 25px;
`;
