import { createSlice } from "@reduxjs/toolkit";

export type WalletType = "mother" | "prefunded" | "bonded" | "cws" | "";
export type Status = "ACTIVE" | "INACTIVE" | "DEACTIVATED" | "";

export interface InitialState {
	id: string;
	threshold: string;
	balance: string;
	status: Status;
	type: WalletType;
	showAlert: boolean;
	lastUpdate: string;
	isWalletSufficient: boolean;
}

const initialState: InitialState = {
	id: "",
	balance: "",
	threshold: "",
	status: "",
	type: "",
	showAlert: false,
	lastUpdate: "",
	isWalletSufficient: true,
};

const walletBalance = createSlice({
	name: "walletBalance",
	initialState,
	reducers: {
		updateWallet: (state, { payload }) => {
			const {
				wallet_identifier,
				type,
				status,
				balance,
				threshold_amount,
				last_update,
			} = payload;
			state.id = wallet_identifier;
			state.threshold = threshold_amount;
			state.type = type;
			state.status = status;
			state.balance = balance;
			state.lastUpdate = last_update;
		},
		setShowAlert: (state, { payload }) => {
			state.showAlert = payload;
		},
		setIsWalletSufficient: (state, { payload }) => {
			state.isWalletSufficient = payload;
		},
	},
});

export const { updateWallet, setShowAlert, setIsWalletSufficient } =
	walletBalance.actions;
export default walletBalance.reducer;
