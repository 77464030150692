import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { ThreeDots } from "react-loader-spinner";
import styles from "./FullPageLoader.module.css";

const secondsToTime = (secs) => {
	let hours, minutes, seconds;
	hours = Math.floor(secs / (60 * 60));
	let devisor_for_minutes = secs % (60 * 60);
	minutes = Math.floor(devisor_for_minutes / 60);
	let devisor_for_seconds = devisor_for_minutes % 60;
	seconds = Math.ceil(devisor_for_seconds);
	let obj = {
		h: hours,
		m: minutes,
		s: seconds,
	};
	return obj;
};

const FullPageLoader = ({ open, message }) => {
	const timerRef = useRef(null);
	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		return () => clearInterval(timerRef.current);
	}, []);

	const startTimer = () => {
		if (timerRef.current === null && seconds === 0) {
			timerRef.current = setInterval(counter, 1000);
		}
	};

	const counter = () => {
		setSeconds((seconds) => seconds + 1);
	};

	useEffect(() => {
		// stops when meets 5 minutes 30 secs = 331 seconds
		if (seconds === 331) {
			clearInterval(timerRef.current);
			timerRef.current = null;
			setSeconds(0);
		}
	}, [seconds]);

	useEffect(() => {
		startTimer();
	}, []);

	const time = secondsToTime(seconds);

	if (!open) return <></>;

	const portalDOM = document.getElementById("portal-root");

	if (!portalDOM) return <></>;

	return ReactDOM.createPortal(
		<div className={styles.fullpageMain}>
			<div className={styles.fullpageBody}>
				<div className={styles.fullpageMessage}>
					{message ||
						"Please wait while we validate your transaction request..."}
				</div>
				<div className={styles.fullpageTimer}>
					{time.m === 0
						? ""
						: time.m > 1
						? time.m + " minutes "
						: time.m + " minute "}
					{time.s + "s"}
				</div>
				<ThreeDots
					height={55}
					width={55}
					radius={9}
					color="#f26122"
					ariaLabel="three-dots-loading"
					wrapperStyle={{
						marginTop: 0,
						paddingTop: 0,
					}}
					visible={true}
				/>
			</div>
		</div>,
		portalDOM
	);
};
export default FullPageLoader;
