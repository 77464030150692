import { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { InputAdornment, styled } from "@mui/material";
import { Grid, Avatar } from "@material-ui/core";
import textStyles from "../textStyles.module.css";
import SearchIcon from "@mui/icons-material/Search";
import favoriteIcon from "../../../assets/icons/ic-favorites.svg";
import selectedFavoriteIcon from "../../../assets/icons/ic-favorites-selected.svg";
import styles from "./PartnerSection.module.css";

import Dropdown from "../Input/Dropdown";

import { useDispatch, connect } from "react-redux";
import { setTransactionType } from "../../../redux/modules/kycRemittance";
import {
	partnerActionCreator,
	getPartnerDetailsActionCreator,
	searchPartnerActionCreator,
	selectPartner,
	setSearchParams,
	getPartnerBySeach,
} from "../../../redux/modules/kycPartnerList";

const StyledRowGrid = styled(Grid)(({ theme }) => ({
	padding: "10px 0",
	borderBottom: "1px solid #EBEBEB",
	"& p": {
		padding: "12px 0",
	},
	"& .remittance-selected-partner-icon": {
		padding: "15px",
		paddingRight: "0px",
	},
	"& .partner-name": {
		cursor: "pointer",
	},
	"& .active-partner": {
		color: "#F26122",
	},
	"& .loading-label": {
		textAlignment: "center",
	},
}));

const StyledAvatarGrid = styled(Grid)(({ theme }) => ({
	padding: "0 8px",
}));

const PartnersComponent = (props) => {
	let dispatch = useDispatch();

	const [mockData, setMockData] = useState(["CASHOUT", "CLAIM", "SEND"]); // test data only
	const [transaction, setTransaction] = useState("");

	const handleChange = (e) => {
		setTransaction(e.target.value);
		dispatch(setTransactionType(e.target.value));
	};

	const handleSelectedPartner = (code) => {
		dispatch(selectPartner(code));
		dispatch(getPartnerDetailsActionCreator());
	};

	const handleInputSearch = (e) => {
		e.preventDefault;
		if (e.key == "Enter") {
			dispatch(setSearchParams(e.target.value));
			dispatch(searchPartnerActionCreator());
		}
	};

	useEffect(() => {
		dispatch(partnerActionCreator());
	}, []);

	return (
		<>
			<Dropdown
				text="Select your transaction here"
				items={mockData}
				handleChange={handleChange}
				selectedValue={transaction}
				variant="outlined"
			/>
			<StyledRowGrid container>
				<StyledAvatarGrid item xs={2}>
					<img src={favoriteIcon} />
				</StyledAvatarGrid>
				<Grid item xs={10}>
					<TextField
						style={{ width: "100%" }}
						id="filled-search"
						label="Search Partner"
						type="search"
						variant="filled"
						size="small"
						onKeyPress={(e) => handleInputSearch(e)}
						InputProps={{
							disableUnderline: true,

							endAdornment: (
								<InputAdornment>
									<SearchIcon />
								</InputAdornment>
							),
						}}
						InputLabelProps={{
							classes: {
								root: styles.searchLabel,
							},
						}}
					/>
				</Grid>
			</StyledRowGrid>
			{props.isLoading == true ? (
				<>
					<p className="loading-label">Loading...</p>
				</>
			) : (
				props.partnersList?.map((partner, i) => {
					return (
						<StyledRowGrid container key={i}>
							<StyledAvatarGrid item xs={2}>
								<Avatar>
									<img src={partner.logo} alt="logo" />
								</Avatar>
							</StyledAvatarGrid>
							<Grid
								item
								xs={9}
								className="partner-name"
								onClick={() =>
									handleSelectedPartner(partner.code)
								}
							>
								<p
									className={`${
										textStyles.textStylesNormal1418Poppins
									} ${
										props.partnerCode == partner.code
											? "active-partner"
											: ""
									}`}
								>
									{partner.name}
								</p>
							</Grid>
							<Grid item xs={1}>
								<a href="#">
									<img
										className="remittance-selected-partner-icon"
										src={selectedFavoriteIcon}
									/>
								</a>
							</Grid>
						</StyledRowGrid>
					);
				})
			)}
		</>
	);
};

export default connect(
	(state) => ({
		transactionType: state.kycRemittance.transactionType,
		partnersList: state.kycPartnerList.partnersList,
		isLoading: state.kycPartnerList.isLoading,
		partnerCode: state.kycPartnerList.partnerCode,
	}),
	{
		setTransactionType,
		partnerActionCreator,
		getPartnerDetailsActionCreator,
		searchPartnerActionCreator,
		getPartnerBySeach,
	}
)(PartnersComponent);
