// https://github.com/diegohaz/arc/wiki/Atomic-Design
import { TextField } from "@material-ui/core";
import { MenuItem, FormControl } from "@mui/material";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import FormattedTextField from "./FormattedTextField.js";

const textFieldTheme = createMuiTheme({
	overrides: {
		MuiInputLabel: {
			root: {
				fontFamily: "Poppins",
				fontSize: "12px",
			},
		},
		MuiOutlinedInput: {
			input: {
				padding: "16px",
			},
		},
	},
});

const SelectField = ({
	className,
	values,
	fieldValue,
	markAsTheSame,
	...props
}) => {
	return (
		<ThemeProvider theme={textFieldTheme}>
			<FormControl fullWidth>
				<TextField
					value={fieldValue}
					select
					variant="outlined"
					{...props}
				>
					{values.map((vals, x) => {
						return (
							<MenuItem value={vals.id} key={x}>
								<FormattedTextField className={className}>
									{vals.name}
								</FormattedTextField>
							</MenuItem>
						);
					})}
				</TextField>
			</FormControl>
		</ThemeProvider>
	);
};

SelectField.propTypes = {};

export default SelectField;
