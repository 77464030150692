import { TextField } from "@material-ui/core";
import { useState } from "react";
import { formatter } from "../util/formatter";

function AmountField(props: any) {
	const { onPaymentDetailsChange, ...others } = props;
	const [value, setValue] = useState(null);
	const _allowedKeyCodes = [189, 190, 8, 37, 39, 9, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
	const _allowedShiftKeyCodes = [37, 39];
	const _allowedControlKeyCodes = [88, 67, 9];

	// Function to fix the caret for formatted values
	// Fixes the issue where the caret jumps to the end after every update
	const keepCaret = (event: any, _val: any) => {
		const _oldVal = event?.target.value;
		const _newVal = _val;
		const target = event.target;

		if (_newVal && _oldVal) {
			// Compute the shift based on the diffence of commas between the original value and formatted value
			let shift = _newVal.split(",").length - _oldVal.split(",").length;
			shift = isNaN(shift) ? 0 : shift;

			if (target.selectionStart) {
				// Set the shifted caret
				moveCaret(target, shift);
			}
		}
	};

	const handleCaretPeriod = (event: any) => {
		const target = event.target;
		if (target.value.split(".").length >= 1) event.preventDefault();

		if (
			target.selectionStart &&
			target.value.indexOf(".") == target.selectionStart
		) {
			// Set the shifted caret
			event.preventDefault();
			moveCaret(target, 1);
		}
	};

	const moveCaret = (target: any, shift: any) => {
		const caret = target.selectionStart + shift;
		const element = target;

		window.requestAnimationFrame(() => {
			element.selectionStart = caret;
			element.selectionEnd = caret;
		});
	};

	return (
		<TextField
			{...others}
			onChange={(v) => {
				const newVal = formatter.amountFormat(v.target.value);
				setValue(newVal);
				keepCaret(v, newVal);
				onPaymentDetailsChange(v);
			}}
			value={value}
			onKeyDown={(e: any) => {
				if (
					!(
						(e.shiftKey &&
							_allowedShiftKeyCodes.find((v) => v == e.keyCode) !=
								undefined) ||
						(e.ctrlKey &&
							_allowedControlKeyCodes.find(
								(v) => v == e.keyCode
							) != undefined)
					)
				) {
					if (
						(!(e.keyCode >= 48 && e.keyCode <= 57) &&
							_allowedKeyCodes.find((v) => v == e.keyCode) ==
								undefined) ||
						e.shiftKey ||
						e.ctrlKey
					) {
						e.preventDefault();
					}
				}

				switch (e.keyCode) {
					case 190:
						//handling when typing period
						handleCaretPeriod(e);
						break;
					case 8:
						{
							const currSelected = e.target.selectionStart;

							//handling for backspace on comma, and decimal zeros
							if (
								(e.target.value.length - currSelected <= 2 &&
									e.target.value[currSelected - 1] == 0) ||
								e.target.value[currSelected - 1] == ","
							) {
								moveCaret(e.target, -1);
								e.preventDefault();
							}

							//handling for backspace for period
							if (
								e.target.value[currSelected - 1] == "." &&
								e.target.value?.split(".").length - 1 == 1
							) {
								moveCaret(e.target, -1);
								e.preventDefault();
							}
						}
						break;
				}
			}}
		/>
	);
}

export default AmountField;
