import TableRow from "@material-ui/core/TableRow";
import withStyles from "@material-ui/core/styles/withStyles";

const PrimaryTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F3FAFF",
    },
  },
}))(TableRow);

export { PrimaryTableRow };
