import React from "react";
import styles from "./NoFunds.module.css";
import noFundsSvg from "../../assets/icons/img-wallet-nofunds.svg";

const NoFunds: React.FC<{}> = () => {
	return (
		<div className={styles.container}>
			<img src={noFundsSvg} alt="No Funds Icon" />
			<h4>Cannot create a new transaction</h4>
			<span className={styles.subText}>
				You have <span className={styles.blue}>no funds</span> in your
				wallet. Kindly replenish <br />
				your wallet to continue processing payments.
			</span>
		</div>
	);
};

export default NoFunds;
