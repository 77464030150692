import { React, Component } from 'react';
import { Grid, Dialog, Box, DialogActions, Button, Typography } from '@material-ui/core';
import failed from '../../assets/icons/ic-failed.svg'
import styles from "./SessionExpiredModal.module.css"

class SessionExpiredModal extends Component {
    redirect() {
        window.location.replace(process.env.REACT_APP_URL);
    }

    render() {
        return (
            <>
                <Dialog disableBackdropClick classes={{ paper: styles.dialogPaper }} open={this.props.isExpired} maxWidth="lg">
                    <Box>
                        <img src={failed} alt="failed" className={styles.failedImg} />
                    </Box>
                    <Box>
                        <Typography className={styles.expiredHeader}>Session Expired</Typography>
                    </Box>
                    <Box>
                        <Typography className={styles.expiredBody}>Your session has already expired</Typography>
                    </Box>
                    <DialogActions>
                        <Button onClick={this.redirect} variant="outlined" className={styles.expiredButton}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default SessionExpiredModal;