export const formatter = {
	commaSeparated: (v: any) => {
		try {
			const sanitized = v.replaceAll(",", "");
			if (!isANumber(sanitized)) return v;
			else if (-sanitized)
				return sanitized
					.split("")
					.reverse()
					.join("")
					.replace(/(\d{3}\B)/g, "$1,")
					.split("")
					.reverse()
					.join("");
		} catch (error) {
			return v;
		}
	},
	decimalPlaces:
		(decimalPlaces = 2) =>
		(v: any) => {
			const allNum = v
				.toString()
				.replaceAll(",", "")
				.replace(/[^\d.-]/g, "");

			const res = new Intl.NumberFormat("en-US", {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			}).format(allNum);
			return res;
		},
	amountFormat: (v: any) => {
		try {
			const sanitized = v.replaceAll(",", "")?.split(".");
			const _biggo = BigInt(sanitized[0]);

			return (
				_biggo.toLocaleString("en-US") +
				"." +
				(sanitized[1] || "00").toString().slice(0, 2).padEnd(2, "0")
			);
		} catch (e) {
			return v;
		}
	},
	contactNumber: (e: any) => {
		if (
			[46, 8, 9, 27, 13, 110, 190].findIndex((v) => v == e.keyCode) !==
				-1 ||
			// Allow: Ctrl+A
			(e.keyCode == 65 && e.ctrlKey === true) ||
			// Allow: +
			(e.keyCode == 187 && e.shiftKey == true) ||
			// Allow: home, end, left, right
			(e.keyCode >= 35 && e.keyCode <= 39)
		) {
			// let it happen, don't do anything
			return;
		}

		var charValue = String.fromCharCode(e.keyCode),
			valid = /^[0-9]+$/.test(charValue);
		if (!valid || e.shiftKey == true) {
			e.preventDefault();
		}
	},
	mobileNumber: (e: any) => {
		if (
			[46, 9, 27, 13, 8].findIndex((v) => v == e.keyCode) !==
				-1 ||
			// Allow: numpad 0 to 9
			(e.keyCode >= 96 && e.keyCode <= 105) //||
			//Dont allow to remove 63 at the string value
			//(e.keyCode == 8 && e.target.value.length > 2)
		) {
			// let it happen, don't do anything
			return;
		}

		var charValue = String.fromCharCode(e.keyCode), 
			valid = /^[0-9]+$/.test(charValue);
		if (!valid || e.shiftKey == true) {
			e.preventDefault();
		}
	},
	emailAddress: (email: any) => {
		const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
		return !(!email || regex.test(email) === false);
	},
	alphaNumericSpace: (e: any) => {
		const regex = /^[a-zA-Z0-9 ]+$/;
		if (
			[46, 9, 27, 8].findIndex((v) => v == e.keyCode) !== -1 ||
			// Allow: numpad 0 to 9
			(e.keyCode >= 96 && e.keyCode <= 105) ||
			// Allow: home, end, left, right
			((e.keyCode >= 65 && e.keyCode <= 90) && e.shiftKey == true)
		) {
			// let it happen, don't do anything
			return;
		}

		var charValue = String.fromCharCode(e.keyCode), 
			valid = regex.test(charValue);
		if (!valid || e.shiftKey == true || e.ctrlKey == true) {
			e.preventDefault();
		}
	},
	alphaNumericSpaceDotComma: (e: any) => {
		const regex = /^[a-zA-Z0-9 .,]+$/;
		if (
			[46, 9, 27, 8, 188, 190].findIndex((v) => v == e.keyCode) !== -1 ||
			// Allow: numpad 0 to 9
			(e.keyCode >= 96 && e.keyCode <= 105) ||
			// Allow: home, end, left, right
			(e.keyCode >= 65 && e.keyCode <= 90 && e.shiftKey == true)
		) {
			// let it happen, don't do anything
			return;
		}

		var charValue = String.fromCharCode(e.keyCode),
			valid = regex.test(charValue);
		if (!valid || e.shiftKey == true || e.ctrlKey == true) {
			e.preventDefault();
		}
	},
	omitSpace: (e: any) => {
		if (e.keyCode == 32 || e.ctrlKey == true) {
			e.preventDefault();
		}
	},
	charMax100: (value: any) => {
		let isValid = false;
		if(value.length > 0 && value.length <= 100) isValid = true
		return (isValid)
	},
	charMin2: (value: any) => {
		let isValid = value.length <= 1 ? false : true;
		return (isValid)
	},
};

export function isANumber(value?: string): boolean {
	try {
		const v = parseFloat(`${value}`);

		if (isNaN(v)) {
			return false;
		}
		return true;
	} catch (e) {
		return false;
	}
}

export const truncateDecimal = (
	number: string | number,
	digits: number
): BigInt | string => {
	if (!number.toString().includes("."))
		return BigInt(number.toString() + "." + "00".substr(0, digits));

	const _numArray = number.toString().split(".");
	return BigInt(_numArray[0]) + "." + _numArray[1].substr(0, digits);
};
