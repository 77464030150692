import { sleep } from "./utils";

export async function retry(
	{ statusCodes, status, tries, timeout = 0 },
	callback,
	verbose = false
) {
	if (
		(statusCodes && statusCodes.some((_status) => _status === status)) ||
		!statusCodes
	) {
		return await new Promise((resolve, reject) => {
			setTimeout(async () => {
				let err;
				for (let index = 0; index < tries; index++) {
					try {
						resolve(await callback(index));
						break;
					} catch (error) {
						if (verbose) console.log(error);
						err = error;
					}
				}

				if (err) reject(err);
			}, timeout * 1000);
		});
	}
}

export async function retry_f(
	{
		statusCodes,
		status,
		initialTries = 0,
		tries = 0,
		timeout = 0,
		initialTimeout = 0,
		checkPending = false,
	},
	callback,
	verbose = false
) {
	return new Promise((resolve, reject) => {
		let returnValue = {
			status: status,
		};
		let setTimeoutVar;
		if (
			(statusCodes &&
				statusCodes.some((_status) => _status === status)) ||
			!statusCodes
		) {
			const retryFunc = (i, tr, to, hasInitial = false) => {
				return function () {
					if (i > tr) {
						// skip reject if only initial
						if (hasInitial) {
							return;
						}
						//pass to catchblock error if tries exceeded
						clearTimeout(setTimeoutVar);
						return reject(returnValue);
					} else {
						callback(i)
							.then((response) => {
								//success block
								returnValue = response;

								// if 200 but pending status, loop again
								if (
									checkPending &&
									(response?.data?.data?.status ===
										"PENDING" ||
										response?.data?.data?.status ===
											"PROCESSING")
								) {
									setTimeout(
										retryFunc(++i, tr, to, hasInitial),
										to * 1000
									);
								} else {
									clearTimeout(setTimeoutVar);
									return resolve(returnValue);
								}
							})
							.catch((error) => {
								if (verbose)
									console.log(
										"Client retry error: ",
										error.response
									);
								returnValue = error.response;

								//if different error, return to catch block immediately
								if (
									returnValue?.status &&
									!statusCodes.some(
										(_status) =>
											_status === returnValue.status
									)
								) {
									clearTimeout(setTimeoutVar);
									return reject(returnValue);
								} else {
									//recursive call to setTimeout
									setTimeout(
										retryFunc(++i, tr, to, hasInitial),
										to * 1000
									);
								}
							});
					}
				};
			};

			(async () => {
				// if has initial, execute retry seperately
				if (initialTimeout && initialTries) {
					setTimeoutVar = setTimeout(
						retryFunc(1, initialTries, initialTimeout, true),
						initialTimeout * 1000
					);
					sleep(initialTimeout * initialTries);
				}

				// main retry execute
				setTimeoutVar = setTimeout(
					retryFunc(1, tries, timeout, false),
					timeout * 1000
				);
			})();
		} else {
			//pass to catchblock error
			clearTimeout(setTimeoutVar);
			reject(returnValue);
		}
	});
}
