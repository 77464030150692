import { isEmpty, remove } from "lodash";

export function getLocalStorage(key, defaultValue = undefined) {
	const value = localStorage.getItem(key);

	if (!value) return defaultValue;

	return JSON.parse(value);
}

export function setLocalStorage(key, value) {
	if (!key) return;

	localStorage.setItem(key, value);
}

export function deleteLocalStorage(key) {
	localStorage.removeItem(key);
}

export function clearLocalStorage() {
	localStorage.clear();
}

export function getSessionStorage(key, defaultValue = undefined) {
	const value = sessionStorage.getItem(key);

	if (isEmpty(value)) return defaultValue;

	return JSON.parse(value);
}

export function setSessionStorage(key, value) {
	if (!key) return;

	const v = value ? JSON.stringify(value) : value;

	sessionStorage.setItem(key, v);
}

export function deleteSessionStorage(key) {
	sessionStorage.removeItem(key);
}

export function clearSessionStorage() {
	sessionStorage.clear();
}

export function removeObjectSessionList (key, fieldkey, fieldvalue){
	//get session data list
	let sessionStorageList = getSessionStorage(key) || []
	//use remove function (lodash) and set two fields for filter match value.
	//object having equal value to the two fields will removed in session data list
	remove(sessionStorageList, s => s[fieldkey] === fieldvalue)
	//re-assign new set of list in session using key and data
	setSessionStorage(key,sessionStorageList)
}

export function clearSessionAndRedirect(isExpired) {
	clearSessionStorage();

	window.location.replace(
		process.env.REACT_APP_URL + (isExpired ? "?session=expired" : "")
	);
	console.log("Successfully removed");
}

export function clearAllStorage() {
	clearLocalStorage();
	clearSessionStorage();
}
