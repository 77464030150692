import { React, Component } from "react";
import { refresh } from "../../utils/sessionTimeout";
import { logOut } from "../../utils/logout";
import {
	Grid,
	Divider,
	Dialog,
	Box,
	DialogActions,
	Button,
	Typography,
	DialogTitle,
	CircularProgress,
} from "@material-ui/core";
import styles from "./SessionTimeoutModal.module.css";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

class SessionTimeoutModal extends Component {
	constructor(props) {
		super(props);
		this.handleKeepMeLoggedIn = this.handleKeepMeLoggedIn.bind(this);
		this.logoutSession = this.logoutSession.bind(this);
		this.state = {
			remainingTime: 60,
			isLoading: false,
		};
		this.timer = undefined;
		this.isSessionLogout = true;
	}

	componentDidMount() {
		this.timer = setInterval(() => {
			this.setState(
				{
					remainingTime: this.state.remainingTime - 1,
				},
				() => {
					if (this.state.remainingTime === 0) {
						this.logoutSession();
					}
				}
			);
		}, 1000);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	async handleKeepMeLoggedIn() {
		this.setState({ isLoading: true }, async () => {
			clearTimeout(this.timer);
			await refresh();
			window.resetTimeOut();
			this.props.toggleModal();
			this.props.toggleUnlockReload();
		});
	}

	logoutSession() {
		logOut(true);
		this.props.toggleModal();
	}

	render() {
		return (
			<Dialog
				disableBackdropClick
				open={true}
				classes={{ paper: styles.dialogPaper }}
			>
				<Grid container>
					<DialogTitle>
						<Typography className={styles.countHeader}>
							Your session is about to expire!
						</Typography>
					</DialogTitle>
					<IconButton
						aria-label="close"
						className={styles.closeButton}
						onClick={this.handleKeepMeLoggedIn}
						disabled={this.state.isLoading}
					>
						<CloseIcon />
					</IconButton>
				</Grid>
				<Divider />
				<Box>
					<Typography className={styles.countBody}>
						{this.state.isLoading ? (
							<Grid
								container
								justify="center"
								alignItems="center"
								style={{ height: "100%" }}
							>
								<Grid item>
									<CircularProgress
										style={{ color: "#f26122" }}
									/>
								</Grid>
							</Grid>
						) : (
							<div>
								You will be logged out in{" "}
								<span style={{ color: "#0076C0" }}>
									{this.state.remainingTime}
								</span>{" "}
								seconds.
								<br /> Do you want to stay logged in?
							</div>
						)}
					</Typography>
				</Box>

				<Divider />
				<DialogActions>
					<Button
						variant="outlined"
						className={styles.buttonLogin}
						label="loggedIn"
						onClick={this.handleKeepMeLoggedIn}
						disabled={this.state.isLoading}
					>
						Yes, Keep Me Logged In
					</Button>
					<Button
						variant="outlined"
						className={styles.buttonLogout}
						label="logout"
						onClick={() => this.logoutSession()}
						disabled={this.state.isLoading}
					>
						No, Logout
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default SessionTimeoutModal;
