import {Button, Dialog, DialogActions, Grid, Typography} from "@material-ui/core";
import React, {Component, useState} from "react";
import styles from "./UnablePrintReceiptModal.module.css";
import CloseIcon from '@material-ui/icons/Close';
import {connect} from "react-redux";
import {
    toggleTransactionSummary,
    disablePrintButton,
    showPrintReceiptModal,
    updateSelectedForPrint,
    updateCheckList
} from "../../redux/modules/transaction"
import TextField from '@material-ui/core/TextField';
import CustomCheckBox from './CheckBox'
import HTTP from "../../helpers/ApiClient";  
import { removeObjectSessionList } from "../../utils/storage";
class UnablePrintReceiptModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            remarks: '',
            isApplyToAll: false,
            type: 'receipt'
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.logAll = this.logAll.bind(this);
        this.getCurrentDate = this.getCurrentDate.bind(this)
    }
    closeModal(){
        this.props.showPrintReceiptModal({show: false, data: {}})
    }
    onSubmitButtonEnable(){
        const remarks = this.state.remarks
        if(remarks == '' || remarks == null){
            return true;
        }
        return false;
    }
    logAll(event){
        this.setState({isApplyToAll: event.target.checked})        
    }

    onInputChange(e){
        const val = e.target.value;
        this.setState({remarks: val})
    }

    getCurrentDate(){
        const currentDate = new Date();
        const currentDayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const currentTime = currentDate.toString().slice(16,24);
        const dateString =currentYear + "-" + (currentMonth + 1) + "-" + currentDayOfMonth + " " + currentTime;
        return dateString
    }
    async onSubmit(){
        if (this.props.selectedForPrint.type === 'check') {
        
		const receiptNo = this.props.selectedForPrint.values.receiptNo
		const data = {
			remarks: this.state.remarks,
			status: "FAILED",
		};

		HTTP.put(`/v2/receipts/check/${receiptNo}`, JSON.stringify(data))
			.then(() => {
				this.props.updateCheckList({ index: this.props.selectedForPrint.index, status: data.status });
                removeObjectSessionList("transactionDetails", "checkReceiptNo", receiptNo);
				this.closeModal()
			})
			.catch((error) => {
				console.log("Error: ", error);
			});
        return;
        }

        if(!this.state.isApplyToAll){
            let body ={
                createdAt: this.getCurrentDate(),
                username: this.props.selectedForPrint.values.username,
                transactionRefNo: this.props.selectedForPrint.values.transactionNumber,
                action: "Unable to Print",
                remarks: this.state.remarks,
                isPrinted: "0",
                printCount: "0",
                receiptNo: "-"
            };
            const updateStatus = {
                status: "FAILED"
            }
            HTTP.put(`/v2/receipts/transactions/${body.transactionRefNo}/status`, updateStatus).
                catch(error => {
                    console.log(error)
                });

            HTTP.post("/v2/admin/audit/log", body)
                .then(data => {
                    const newData = this.props.transactionList.map(valuesPerRow => {
                        if(valuesPerRow.transactionNumber === this.props.selectedForPrint.values.transactionNumber){
                            return {...valuesPerRow, printStatus: "FAILED"};
                        }return valuesPerRow;
                      }
                    )
                    this.props.disablePrintButton({data: newData})
                    console.log(data)
                    if(this.props.selectedForPrint.values.paymentMethod === "CASH") removeObjectSessionList("transactionDetails", "receiptNo", this.props.selectedForPrint.values.receiptNo);
                    this.closeModal()
                })
                .catch(err => {
                    console.log(err)
                });
        }else{
            const newData = this.props.transactionList.map(valuesPerRow => {
            if(valuesPerRow.printStatus.toUpperCase() === "PENDING"){
                let body ={
                    createdAt: this.getCurrentDate(),
                    username: valuesPerRow.username,
                    transactionRefNo: valuesPerRow.transactionNumber,
                    action: "Unable to Print",
                    remarks: this.state.remarks,
                    isPrinted: "0",
                    printCount: "0",
                    receiptNo: "-"
                };
                
                const updateStatus = {
                    status: "FAILED"
                }
                HTTP.put(`/v2/receipts/transactions/${body.transactionRefNo}/status`, updateStatus).
                    catch(error => {
                        console.log(error)
                    });

                HTTP.post("/v2/admin/audit/log", body)
                    .then(data => {
                        console.log(data)
                        if(valuesPerRow.paymentMethod === "CASH") removeObjectSessionList("transactionDetails", "receiptNo", valuesPerRow.receiptNo);
                    })
                    .catch(err => {
                        console.log(err)
                    });
                return {...valuesPerRow, printStatus: "FAILED"};
            }
            return valuesPerRow;
            })
            this.props.disablePrintButton({data: newData})
            this.closeModal()
        }
    }
    render(){
        return(
            <div>
                <Dialog aria-labelledby="customized-dialog-title" open={this.props.openModal}
                        fullWidth={true} maxWidth={"xs"}
                        style={{padding: "None"}}>
                    <DialogActions>
                            <span className={styles.unable_to_print}>Unable to Print</span>
                            <CloseIcon onClick={this.closeModal} style={{color: "#8B9095"}} />
                    </DialogActions>
                    <Grid container style={{borderTop: "1px solid #a8abad"}}>
                        <Grid item xs={12}>
                            <div style={{padding: "0 0 18px 0"}}>
                                <form id="textfieldForm" style={{paddingBottom: 0}}>
                                    <TextField
                                        id="outlined-full-width"
                                        label=""
                                        style={{padding: 5}}
                                        onChange={this.onInputChange}
                                        placeholder="Remarks"
                                        helperText=""
                                        fullWidth
                                        multiline
                                        margin="normal"
                                        rows={8}
                                        cols={10}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            maxLength: 1000,
                                            color: "black"
                                        }}
                                        variant="outlined"
                                    />
                                </form>
                                <Typography gutterBottom className={styles.max_message}>
                                    *Maximum of 1000 characters only.
                                </Typography>
                                <Typography gutterBottom className={styles.all_pending_status_message}>
                                    <CustomCheckBox 
                                        logAll={this.logAll}
                                    />
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <DialogActions className={styles.dialog_actions_bottom} style={{borderTop: "1px solid #a8abad"}}>
                        <Button
                            className={styles.disabled} onClick={this.closeModal}>
                            Cancel
                        </Button>
                        <Button disabled={this.onSubmitButtonEnable()} onClick={this.onSubmit}
                                className={styles.enable}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect((state) => ({
    openModal: state.transaction.showPrintReceiptModal,
    selectedForPrint: state.transaction.selectedForPrint,
    transactionList: state.transaction.transactionList
}), { toggleTransactionSummary, disablePrintButton, showPrintReceiptModal, updateSelectedForPrint, updateCheckList}
) (UnablePrintReceiptModal);
