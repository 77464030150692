import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	branchReportList: [],
	summaryDetails: {
		branch_name: "",
		total_cash: "",
		total_check: "",
		total_count: "",
		total_amount: "",
	},
	isLoading: {
		details: false,
		logs: false,
	},
	error: "",
};

const branchReport = createSlice({
	name: "branchReport",
	initialState,
	reducers: {
		prep: (state) => {
			state.isLoading.details = true;
			state.summaryDetails = initialState.summaryDetails;
		},
		load: (state, payload) => {
			state.isLoading.details = false;
			state.summaryDetails = payload.result.data;
		},
		error: (state, payload) => {
			state.error = payload.result.result;
		},
		prepReports: (state) => {
			state.isLoading.logs = true;
		},
		loadReports: (state, payload) => {
			state.isLoading.logs = false;
			state.branchReportList = payload.result;
		},
	},
});

const getSummaryDetails = (client, state) => {
	return client.get("/v2/branch-report");
};

const { prep, load, error, prepReports, loadReports } = branchReport.actions;

function getSummaryDetailsActionCreator() {
	return {
		types: [prep.type, load.type, error.type],
		promise: getSummaryDetails,
	};
}

function getBranchReportListActionCreator(v) {
	const params = {};
	if (v) {
		v.page && (params.page = v.page);
		v.rowsPerPage && (params.limit = v.rowsPerPage);
		v.sort && (params.sort = v.sort);
		v.sortBy && (params.sortBy = v.sortBy);
	}

	return {
		types: [prepReports.type, loadReports.type, error.type],
		promise: (client, state) => {
			return client.get("v2/branch-report/logs", {
				params: params,
			});
		},
	};
}

export default branchReport.reducer;

export { getSummaryDetailsActionCreator, getBranchReportListActionCreator };
