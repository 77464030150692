import { React } from "react";
import {
	Dialog,
	Box,
	DialogActions,
	Typography,
	Button,
} from "@material-ui/core";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
// import Button from '@mui/material/Button';
import styles from "./ErrorModal.module.css";
import { sanitize } from "dompurify";

const ErrorModal = ({
	title,
	message,
	toggleDialog,
	open,
	buttonText = "Close",
}) => {
	return (
		<Dialog disableBackdropClick open={open} className={styles.container}>
			<Box className={styles.iconContainer}>
				<HighlightOffRoundedIcon className={styles.errorIcon} />
			</Box>
			<Box>
				<Typography className={styles.modalTitle}>{title}</Typography>
			</Box>
			<Box>
				<Typography
					className={styles.modalMessage}
					dangerouslySetInnerHTML={{
						__html: sanitize(message),
					}}
				/>
			</Box>
			<DialogActions className={styles.modalActions}>
				<Button
					onClick={toggleDialog}
					variant="outlined"
					size="small"
					className={styles.button}
				>
					{buttonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export { ErrorModal };
