import ApiClient from "../helpers/ApiClient";
import {
	deleteSessionStorage,
	getSessionStorage,
	setSessionStorage,
} from "./storage";
import { UserInfoUtil as userInfoUtil } from "./checkSession";
import { isEmpty } from "lodash";

export function isReprintRequestScope() {
	return (
		ScopesUtil.get().find(
			(s) => s.scope === "bfa.transaction.reprint.request"
		) || false
	);
}
export function isReprintReceiptRequestScope() {
	return (
		ScopesUtil.get().find(
			(s) => s.scope === "bfa.transaction.reprint.request"
		) || false
	);
}
export function isReprintCheckRequestScope() {
	return (
		ScopesUtil.get().find(
			(s) => s.scope === "bfa.branch.reprint.check.request"
		) || false
	);
}
export function isWithoutApprovalReprintCheckRequestScope() {
	return (
		ScopesUtil.get().find((s) => s.scope === "bfa.branch.reprint.check") ||
		false
	);
}
export function isWithoutApprovalReprintReceiptRequestScope() {
	return (
		ScopesUtil.get().find((s) => s.scope === "bfa.transaction.reprint") ||
		false
	);
}
function sessionStorageUtilBuilder(key, defaultValue = undefined) {
	return {
		set: (value) => setSessionStorage(key, value),
		get: (dv) =>
			getSessionStorage(key, dv !== undefined ? dv : defaultValue),
		delete: () => deleteSessionStorage(key),
	};
}

export const TokenUtil = sessionStorageUtilBuilder("token");
export const RefreshTokenUtil = sessionStorageUtilBuilder("refreshToken");
export const ChannelUtil = sessionStorageUtilBuilder("channel", []);
export const TerminalUtil = sessionStorageUtilBuilder("terminal", {});
export const UserInfoUtil = sessionStorageUtilBuilder("userInfo", {});
export const BranchUtil = sessionStorageUtilBuilder("branch", []);
export const ScopesUtil = sessionStorageUtilBuilder("allScopes", []);
export const ReminderUtil = sessionStorageUtilBuilder("reminder");
export const IsRefreshingUtil = sessionStorageUtilBuilder("isRefreshing");
export const RefreshCountUtil = sessionStorageUtilBuilder("refreshCount");

export const defaultSvScopes = [
	"bfa.login.request.approval",
	"bfa.branch.reprint.approve",
];

export async function getSupervisorScopes() {
	const data = await ApiClient.get(
		process.env.REACT_APP_ACCOUNTS_API + "/v1/oauth/scopes"
	).then(({ data }) => {
		const svScopes = [];
		data &&
			data.data.map((v) => {
				if (v?.scope && defaultSvScopes.includes(v.scope))
					svScopes.push(v.scope);
			});

		return {
			svScopes,
			allScopes: data.data || [],
		};
	});
	return data;
}

export async function getScopes() {
	const { data } = await ApiClient.get(
		process.env.REACT_APP_ACCOUNTS_API + "/v1/oauth/scopes"
	);

	return data;
}

export function checkIfSupervisor() {
	const svScopesStorage = [...getSessionStorage("mainRole")] || [];
	return !!svScopesStorage.find((s) => defaultSvScopes.includes(s));
}

export function checkSimultaneousLoginScope(scopes) {
	return scopes.find((s) => s.scope === "bfa.login.concurrent")
		? true
		: false;
}

function setExpiresInOfUser(expires_in) {
	const userInfo = userInfoUtil.get();
	console.log("setToUserInfo: ", expires_in);
	userInfoUtil.set({ ...userInfo, expires_in });
}

async function checkIfHasUserSession(userInfo, callback) {
	if (userInfo) {
		try {
			const response = await ApiClient.get(
				`/v2/user/${userInfo.uuid}/check-session`
			);

			/** only called if not 4xx */
			const { svScopes, allScopes } = await getSupervisorScopes();
			setSessionStorage("mainRole", svScopes);

			if (response.status === 200) {
				const { current_time, expires_in } = response.data;
				setExpiresInOfUser(
					parseInt(current_time) + parseInt(expires_in)
				);
				storeSessionData({
					...response.data.data,
					role_supervisor: svScopes,
				});
				ScopesUtil.set(allScopes);
				window.location.reload();
			}
		} catch (e) {
			console.log(e, "Error user session");
			if (e.response.data.data.terminal !== undefined) {
				const data = e.response.data.data.terminal[0];
				storeSessionData({ ...data, start_amount: undefined });
				return window.location.reload();
			}
			const { current_time, expires_in } = e.response.data;
			if (current_time && expires_in) {
				setExpiresInOfUser(
					parseInt(current_time) + parseInt(expires_in)
				);
			}
			console.log("No session");
			window.history.pushState(null, "", "/");
		}
	}

	callback(userInfo);
}

export function mapPrintData(printData = []) {
	return printData.map((pd) => ({
		billerName: pd.billerName,
		accountNo: pd.arn,
		transactionNumber: pd.trn,
		amount: pd.amount,
		paymentStatus: pd.status.toUpperCase(),
		printStatus: "PENDING",
		paymentMethod: pd.paymentMethod,
		fee: pd.fee,
		createdAt: pd.createdAt,
		otherInfo: pd.otherInfo,
		username: pd.username,
		orderNumber: pd.orderNumber,
		receiptNo: pd.receiptNo,
		checkReceiptNo: pd.checkReceiptNo ? pd.checkReceiptNo : null,
		trn: pd.trn,
		crn: pd.crn,
	}));
}

export function addTransactionCheckReceiptNo(trxnList = [], checkList = []) {
	let checkData = null;
	trxnList.forEach((t) => {
		const check = checkList.find((c) => t.crn === c.crn);
		if (!isEmpty(check)) checkData = check;
	});
	trxnList.map((t) => {
		t.checkReceiptNo = checkData?.checkReceiptNo ?? null;
		return t;
	});
}

export async function getCheckReceipts(checks = []) {
	if (!checks.length) return checks;

	const transIds = checks.map((v) => v.transaction_id);
	const transIdsJoin = "'" + transIds.join("','") + "'";
	const resp = await ApiClient.get(
		"/v2/receipts/txn-details?crn=" + transIdsJoin
	);
	const replaceReceipt = resp.data.data.map((r) => {
		const foundCkReiept = checks.find((c) => r.crn === c.transaction_id);
		if (!isEmpty(foundCkReiept)) {
			r.receiptNo = foundCkReiept.receipt_no;
			r.checkReceiptNo = foundCkReiept.receipt_no;
		}
		return r;
	});
	const checkReceiptData = mapPrintData(replaceReceipt);
	return checkReceiptData;
}

async function checkIfHasTpaSession(tpaid, userInfo, callback) {
	if (getSessionStorage("logout") === "endSession") {
		window.history.pushState(null, "", "/cash-management");
	} else {
		const { svScopes, allScopes } = await getSupervisorScopes();

		const hasSession = await ApiClient.get(
			`/v2/user/${userInfo.uuid}/check-session`
		)
			.then(({ status, data }) => {
				const { current_time, expires_in } = data;
				setExpiresInOfUser(
					parseInt(current_time) + parseInt(expires_in)
				);
				storeSessionData({
					...data.data,
					role_supervisor: svScopes,
				});
				return status >= status;
			})
			.catch(({ response: { status, data } }) => {
				const { current_time, expires_in } = data;
				if (current_time && expires_in) {
					setExpiresInOfUser(
						parseInt(current_time) + parseInt(expires_in)
					);
				}
				return status === 422 && false;
			});

		hasSession
			? setSessionStorage("withSession", 1)
			: setSessionStorage("withSession", 0);

		setSessionStorage("mainRole", svScopes);
		ScopesUtil.set(allScopes);

		if (window.location.pathname === "/pay-bills") {
			window.history.pushState(
				null,
				"",
				hasSession ? "/pay-bills" : window.location.pathname
			);
		} else {
			if (window.location.pathname === "/" && hasSession) {
				window.history.pushState(null, "", "/pay-bills");
			} else {
				const _url = checkSimultaneousLoginScope(allScopes)
					? "/cash-management"
					: window.location.pathname;
				window.history.pushState(null, "", _url);
			}
		}
	}

	const printingTransactionStatus = await ApiClient.get(
		`/v2/receipts?status=pending`
	);
	let transactionIdList = [];
	let transactionList = [];
	const printingTransactionReceiptList =
		printingTransactionStatus["data"]["data"]["txn_receipts"];
	const printingCheckReceiptList =
		printingTransactionStatus["data"]["data"]["check_receipts"];

	printingTransactionReceiptList.map((val) => {
		transactionIdList.push(val["transaction_id"]);
	});

	transactionIdList = [...new Set(transactionIdList)];

	if (transactionIdList.length > 0) {
		transactionIdList = transactionIdList.join("','");
		transactionIdList = "'" + transactionIdList + "'";
		const resp = await ApiClient.get(
			"/v2/receipts/txn-details?crn=" + transactionIdList
		);

		transactionList = mapPrintData(resp.data.data);
		// const orderNumber = resp.data.data[0].orderNumber;

		// if (orderNumber) {
		// 	ApiClient.get(
		// 		`/v2/transactions/failed?orderNumber=${orderNumber}`
		// 	).then((v) => {
		// 		mapPrintData(v.data.data).forEach((data) => {
		// 			transactionList.push(data);
		// 		});
		// 	});
		// }
	}

	const checkReceiptsList = printingCheckReceiptList.length
		? await getCheckReceipts(printingCheckReceiptList)
		: [];

	const showViewReceiptModal =
		transactionList.length || checkReceiptsList.length;
	const formatData = { transactionList, checkReceiptsList };
	callback(showViewReceiptModal, formatData);
	console.log("Done Checking Session!");
}

function storeSessionData(data = {}) {
	if (data) {
		const {
			branch_id: branchId,
			terminal_id: terminalId,
			tpa_id: terminalName,
			branch_name: branchName,
			start_amount: changeFund,
			role_supervisor: roleSupervisor,
		} = data;

		TerminalUtil.set({
			id: terminalId,
			name: terminalName,
		});

		BranchUtil.set([
			{
				id: branchId,
				name: branchName,
			},
		]);

		setSessionStorage("changeFund", changeFund);
		setSessionStorage("mainRole", roleSupervisor || null);
	}
}

export { checkIfHasTpaSession, checkIfHasUserSession };
