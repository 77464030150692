import styled from "@emotion/styled";
import { Button, IconButton } from "@mui/material";
import { IoMdEye, IoMdDownload, IoMdTrash } from "react-icons/io";
import { Description as DescriptionIcon } from "@material-ui/icons";
import { FC, useState, useEffect, useMemo, memo } from "react";
import HTTP from "../../../../helpers/ApiClient";
import cx from "classnames";
import retryIcon from "../../../../assets/icons/refresh.png";
import axios from "axios";
import FullPageLoader from "../../../common/FullPageLoader/FullPageLoader";

import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

type AttachmentCardProps = {
	file?: any; // only used when uploading attachments
	path?: string; // optional; only used in uploading attachments
	url?: string; // optional; only used in viewing files from aws
	fileName: string;
	isViewOnly?: boolean; // used for only displaying
	isEdit?: boolean;
	isUploaded?: boolean;
	onView?: () => void;
	onRemove?: () => void;
	onDownload?: () => void;
	onUploadSuccess?: (res: any, file: File | any) => Promise<void | null>;
	onUploadTimeout: (file: File | any) => void;
	onError: (errorType?: string) => void;
	hasError: string | boolean | null;
	errorMessage?: string;
	clearError: () => void;
	fileKey: string;
};

type TLoaderProps = {
	done?: boolean;
};

const Loader: FC<TLoaderProps> = ({ done = false }) => {
	const [progress, setProgress] = useState(0);
	useEffect(() => {
		let timeout;
		if (!done) {
			timeout = setTimeout(() => {
				setProgress((prev: number) => {
					const next = prev + 0.1;
					if (next <= 1) return next;
					return 1;
				});
			}, 1000);
		} else {
			setProgress(1);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [done]);

	return (
		<div className="loader">
			<div className="loader-container">
				<div
					className="loader-progress"
					style={{ width: `${progress * 100}%` }}
				></div>
			</div>
		</div>
	);
};

const AttachmentCard: FC<AttachmentCardProps> = ({
	file,
	path,
	fileName,
	url,
	isViewOnly,
	onView,
	onRemove,
	onUploadSuccess,
	onUploadTimeout,
	isEdit,
	onError,
	hasError = null,
	errorMessage,
	clearError,
	fileKey,
}) => {
	const memoFile = useMemo(() => {
		if (isEdit) {
			return {
				path: file?.url,
				type: file?.type,
				name: file?.name,
				url: file?.url,
			};
		}
		return file;
	}, [file, isEdit]);

	const filePath = useMemo(() => {
		if (isEdit) {
			return memoFile?.path;
		}

		return URL.createObjectURL(memoFile);
	}, [isEdit, memoFile]);
	const [isUploading, setIsUploading] = useState(!isViewOnly);
	const [isDownloadLoading, setIsDownloadLoading] = useState(false);
	const [isRetryEnabled, setIsRetryEnabled] = useState(false);

	const [loaderVisible, setLoaderVisible] = useState(!isViewOnly);

	const readableFileType = useMemo(() => {
		switch (memoFile?.type) {
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
			case "application/msword":
			case ".doc":
			case ".docx":
				return "docx";
			case "application/pdf":
			case ".pdf":
				return "pdf";
			case "image/jpeg" || "image/jpg":
			case ".jpg":
			case ".jpeg":
				return "jpeg";
			case "image/png":
			case ".png":
				return "png";
			default:
				return null;
		}
	}, [memoFile]);

	/** Method to download the selected file */
	const handleDownload = () => {
		if (memoFile) {
			// ts-ignore
			setIsDownloadLoading(true);
			axios
				.get(memoFile.url, {
					responseType: "blob",
					headers: {
						"Cache-Control": "no-cache",
					},
				})
				.then((response) => {
					const f = memoFile.name.substring(
						memoFile.name.lastIndexOf("/") + 1
					);
					const url = window.URL.createObjectURL(
						new Blob([response.data])
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", f);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					setIsDownloadLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setIsDownloadLoading(false);
				});
		}
	};

	/**
	 * Uploads the current attachment details to the database
	 */
	const validateAttachmentCall = () => {
		clearError();
		setIsUploading(true);
		setLoaderVisible(true);
		setIsRetryEnabled(false);
		const url = "/v3/transaction/void/upload/url";
		if (memoFile?.type) {
			HTTP.get(url, {
				params: {
					fileName: memoFile?.name,
					contentType: memoFile?.type,
				},
			})
				.then((response) => {
					// @ts-ignore
					if (response.data.message !== "Invalid content") {
						if (onUploadSuccess) {
							//@ts-ignore
							onUploadSuccess(response.data, memoFile);
						}
					}
				})
				.catch((error) => {
					if (!window.navigator.onLine) {
						setIsRetryEnabled(true);
						onUploadTimeout(memoFile);
					}
					onError();
				})
				.finally(() => {
					setIsUploading(false);
				});
			return;
		} else {
			setIsUploading(false);
		}
	};

	useEffect(() => {
		if (memoFile && !isViewOnly) {
			validateAttachmentCall();
		}
	}, [memoFile]);

	const thumbnail = useMemo(() => {
		if (!readableFileType || ["doc", "docx"].includes(readableFileType)) {
			return (
				<IconContainer>
					<DescriptionIcon />
				</IconContainer>
			);
		}

		if (["jpeg", "png"].includes(readableFileType)) {
			return (
				<div
					className="image"
					style={{ backgroundImage: `url(${filePath})` }}
				/>
			);
		}

		if (["pdf"].includes(readableFileType)) {
			return (
				<div>
					<Document file={filePath}>
						<Page pageNumber={1} scale={0.5} />
					</Document>
				</div>
			);
		}

		return null;
	}, [readableFileType, filePath]);

	useEffect(() => {
		if (!isUploading) {
			setTimeout(() => {
				setLoaderVisible(false);
			}, 500);
		}
	}, [isUploading]);

	return (
		<>
			<FullPageLoader open={isDownloadLoading} />
			<CardContainer
				className={cx(
					(hasError || isRetryEnabled) && "hasError",
					loaderVisible && "loading"
				)}
				data-error={errorMessage}
				onClick={() => {
					if (isRetryEnabled && !hasError) validateAttachmentCall();
				}}
			>
				<div className="thumbnail">
					<div>{thumbnail}</div>
				</div>
				<div className="namePlate">
					<p className="namePlateText">{memoFile?.name}</p>
				</div>
				{isRetryEnabled && !hasError && !isUploading ? (
					<div className="centerButton">
						<img src={retryIcon} />
					</div>
				) : (
					<>
						{!isUploading && (
							<div className="overlay">
								<OverlayControls>
									{!hasError && (
										<OverlayButton
											variant="contained"
											size="medium"
											color="primary"
											disableFocusRipple
											disableTouchRipple
											onClick={onView}
										>
											<IoMdEye />
										</OverlayButton>
									)}
									{isEdit && (
										<OverlayButton
											variant="contained"
											size="medium"
											color="primary"
											disableFocusRipple
											disableTouchRipple
											onClick={handleDownload}
										>
											<IoMdDownload />
										</OverlayButton>
									)}
									{!isEdit && onRemove && (
										<OverlayButton
											variant="contained"
											size="medium"
											color="error"
											disableFocusRipple
											disableTouchRipple
											onClick={onRemove}
										>
											<IoMdTrash />
										</OverlayButton>
									)}
								</OverlayControls>
							</div>
						)}
					</>
				)}

				{loaderVisible && (
					<Loader done={!isUploading || !readableFileType} />
				)}
			</CardContainer>
		</>
	);
};

export default memo(AttachmentCard);

const CardContainer = styled.div`
	box-shadow: 0px 3px 4px #0000003b;
	border-radius: 4px;
	min-width: 175px;
	height: 120px;
	position: relative;
	outline: 1px solid transparent;

	& .thumbnail {
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		top: 0;
		left: 0;
		overflow: hidden;

		& div.image {
			width: 100%;
			height: 100%;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		& > div {
			position: relative;
			width: 100%;
			height: 100%;
		}
	}

	& .namePlate {
		position: absolute;
		padding-left: 5%;
		padding-right: 5%;
		bottom: 0;
		height: 34px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
		border-radius: 4px;
		z-index: 5;
	}

	& .namePlateText {
		overflow: hidden;
		white-space: nowrap;
		text-align: left;
		text-overflow: ellipsis;
		font-family: Poppins-Regular;
		font-size: 10px;
	}

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.63);
		top: 0;
		left: 0;
		border-radius: 4px;
		opacity: 0;
		z-index: -1;
		transition: opacity 300ms;
	}

	& .overlay {
		z-index: -1;
		opacity: 0;
		transition: opacity 300ms;
		position: absolute;
		right: 4px;
		top: 4px;
	}

	&:hover .overlay {
		z-index: 20;
		opacity: 1;
	}

	&.hasError::before,
	&:hover::before,
	&.loading::before {
		z-index: 10;
		opacity: 1;
	}

	& .loader {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: -1;

		& .loader-container {
			position: absolute;
			width: 80%;
			height: 6px;
			background-color: #ffffff;
			bottom: 24px;

			& .loader-progress {
				background-color: #f26122;
				height: 6px;
				transition: width 300ms;
			}
		}
	}

	&.loading .loader {
		z-index: 15;
	}

	& .centerButton {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: -1;

		& img {
			cursor: pointer;
			filter: invert(40%) sepia(26%) saturate(3454%) hue-rotate(350deg)
				brightness(105%) contrast(90%);
		}
	}

	&.hasError {
		outline-color: #d93025;

		& .centerButton {
			z-index: 10;
		}

		&:hover {
			cursor: pointer;
		}
	}

	&.hasError::after {
		position: absolute;
		content: attr(data-error);
		color: #d93025;
		font-family: Poppins-Regular;
		font-size: 10px;
		top: calc(100% + 6px);
		left: 0;
	}
`;

const IconContainer = styled.div`
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		height: 100px;
		width: 50px;
		color: var(--gray);
	}
`;

const OverlayButton = styled(Button)`
	border-radius: 50%;
	min-width: 24px !important;
	height: 24px;
	background: var(--dirtyWhite);
	color: #333;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 !important;

	> img {
		width: auto;
		height: 14px;
	}
`;

const OverlayControls = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: right;
	gap: 4px;
`;
