import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	Grid,
	Typography,
} from "@material-ui/core";
import { OrangeColorButton } from "../../BillSummary/BillSummary";
import styles from "./BranchReport.module.css";
import BranchReportLogs from "./BranchReportLogs";
import { connect } from "react-redux";
import {
	getSummaryDetailsActionCreator,
	getBranchReportListActionCreator,
} from "../../../redux/modules/branchReport";
import { useEffect, useState } from "react";
import ReportGenerationModal from "../SessionReports/ReportGenerationModal";
import HTTP from "../../../helpers/ApiClient";
import { ReactComponent as FailedLogo } from "../../../assets/icons/ic-failed.svg";
import printJS from "print-js";
import { formatter } from "../../TransactionList/util/formatter";

const _summaryDetails = [
	{
		key: "Branch Name",
		prop: "branch_name",
	},
	{
		key: "Branch Total Cash on Hand",
		prop: "total_cash",
		type: "amount",
	},
	{
		key: "Branch Total Check on Hand",
		prop: "total_check",
		type: "amount",
	},
	{
		key: "Branch Total Transaction Count",
		prop: "total_count",
	},
	{
		key: "Branch Total Collection Amount",
		prop: "total_amount",
		type: "amount",
	},
];

function BranchReport(props: any) {
	const {
		branchReportList,
		summaryDetails,
		getSummaryDetailsActionCreator,
		getBranchReportListActionCreator,
		...others
	} = props;

	const [loading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const [generateSuccess, setGenerateSuccess] = useState(false);

	const [filters, setFilters] = useState({
		dateFrom: null,
		dateTo: null,
		search: "",
		sort: "desc",
		sortBy: "dateTimeGenerated",
		page: 1,
		rowsPerPage: 25,
	});

	useEffect(() => {
		getSummaryDetailsActionCreator();
	}, []);

	const handlePageChange = (v: number, i: number) => {
		setFilters({
			...filters,
			page: v,
			rowsPerPage: i,
		});
	};

	const handleSort = (sortBy: any, sort: any) => {
		setFilters({
			...filters,
			sortBy: sortBy,
			sort: sort,
		});
	};

	const generateReport = () => {
		setGenerateSuccess(false);
		setLoading(true);
		setError(false);
		if (!window.navigator.onLine) {
			setError(true);
			setLoading(false);
			return;
		}

		HTTP.post("/v2/branch-report/generate")
			.then((response: any) => {
				setGenerateSuccess(true);
				const pdf_url = response.data.presigned_url;
				fetch(pdf_url)
					.then((resp) => resp.arrayBuffer())
					.then((resp) => {
						const file = new Blob([resp], {
							type: "application/pdf",
						});
						printJS({
							printable: URL.createObjectURL(file),
							type: "pdf",
							onPrintDialogClose: () => {
								getBranchReportListActionCreator(filters);
							},
							onError: (resp: any) => {
								setError(true);
							},
						});
					});
			})
			.catch((error: any) => {
				if (error.response?.status == 403) {
					return;
				}
				setError(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getBranchReportListActionCreator(filters);
	}, [filters]);

	return (
		<Grid container className={styles.wrapper}>
			<Grid item sm={12} className={styles.title}>
				Branch Report
			</Grid>
			<Grid item sm={12}>
				{_summaryDetails.map((v: any) => {
					return (
						<Grid
							container
							key={v.key}
							className={styles.detailsRow}
						>
							<Grid item sm={10}>
								{v.key}
							</Grid>
							<Grid className={styles.detailsValue} item sm={2}>
								{v.type == "amount"
									? formatter.amountFormat(
											"" + summaryDetails[v.prop]
									  )
									: summaryDetails[v.prop]}
							</Grid>
						</Grid>
					);
				})}
			</Grid>
			<Grid
				container
				item
				sm={12}
				justify={"flex-end"}
				style={{ padding: "44px 0px 71px 0px" }}
			>
				<OrangeColorButton
					onClick={generateReport}
					className={styles.generateButton}
				>
					Generate Branch Report
				</OrangeColorButton>
			</Grid>
			<BranchReportLogs
				onPageChange={handlePageChange}
				onSort={handleSort}
				filters={filters}
				reports={branchReportList}
			/>
			<ReportGenerationModal
				open={loading}
				isRequestSuccess={generateSuccess}
				handleClose={() => {
					setLoading(false);
				}}
			/>
			<Dialog
				PaperProps={{ style: { width: "400px" } }}
				onClose={() => setError(false)}
				open={isError}
			>
				<DialogContent>
					<DialogContentText id="download">
						<FailedLogo className={styles.failedImage} />
						<Typography
							style={{
								font: "normal normal 600 21px Wavehaus-SemiBold",
								textAlign: "center",
								marginTop: "15px",
							}}
						>
							Timeout Error!
						</Typography>
						<Typography
							style={{
								font: "normal normal 300 14px Poppins-Regular",
								textAlign: "center",
								marginTop: "15px",
								color: "#3B3B3B",
							}}
						>
							{" "}
							A problem occurred while generating <br />
							the report. Please try again.
						</Typography>
					</DialogContentText>
				</DialogContent>
				<Button
					onClick={generateReport}
					autoFocus
					variant="outlined"
					style={{
						width: "50%",
						left: "25%",
						height: "45px",
						marginTop: "10px",
					}}
				>
					Retry
				</Button>
			</Dialog>
		</Grid>
	);
}

export default connect(
	(state: any) => ({
		branchReportList: state.branchReport.branchReportList,
		summaryDetails: state.branchReport.summaryDetails,
	}),
	{ getSummaryDetailsActionCreator, getBranchReportListActionCreator }
)(BranchReport);
