import React from "react";
import {
	Grid,
	Divider,
	Dialog,
	Box,
	DialogActions,
	Button,
	Typography,
	DialogTitle,
	CircularProgress,
	IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import styles from "./SessionExpiredValidateModal.module.css";

export default function SessionExpiredValidateModal({
	toggleModal,
	onCloseBtn,
}) {
	const coreToggle = () => {
		onCloseBtn();
		toggleModal();
	};
	return (
		<Dialog
			disableBackdropClick
			open={true}
			classes={{ paper: styles.dialogPaper }}
		>
			<Grid container>
				<DialogTitle>
					<Typography className={styles.countHeader}>
						Expired Token
					</Typography>
				</DialogTitle>
				<IconButton
					aria-label="close"
					className={styles.closeButton}
					onClick={() => toggleModal()}
				>
					<CloseIcon />
				</IconButton>
			</Grid>
			<Divider />
			<Box>
				<Typography className={styles.countBody}>
					<div>Please validate bills information again</div>
				</Typography>
			</Box>

			<Divider />
			<DialogActions>
				<center>
					<Button
						variant="outlined"
						className={styles.buttonLogin}
						label="close"
						onClick={() => {
							coreToggle();
						}}
					>
						Close
					</Button>
				</center>
			</DialogActions>
		</Dialog>
	);
}
