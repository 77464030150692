import {
	Grid,
	Button,
	Typography,
	TextField,
	Accordion,
	Box,
} from "@material-ui/core";
import { Component } from "react";
import styles from "./EndSession.module.css";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import ReportGenerationModal from "../CashManagement/SessionReports/ReportGenerationModal";
import EndSessionLogs from "./EndSessionLogs/EndSessionLogs";
import HTTP from "../../helpers/ApiClient";
import { invert, reject } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import failedLogo from "../../assets/icons/ic-failed.svg";
import printJS from "print-js";
import LogoutDialog from "../CashManagement/SessionReports/LogoutDialog";
import { getSessionStorage } from "../../utils/storage";
import FullPageLoader from "../TransactionList/Components/FullPageLoader";
import { timeoutErrorCodes } from "../../redux/modules/validation";
import { retry, retry_f } from "../../utils/client";

const filterMap = {
	cash_diff: "cashVsCashEnd",
	gen: "dateTimeGenerated",
	uname: "username",
	partial_deposit: "partialDeposit",
	total_cash: "totalCash",
	total_check: "totalCheck",
	cash_end: "cashEnd",
	fname: "fileName",
};

const INITIAL_FILTERS = {
	dateFrom: null,
	dateTo: null,
	search: "",
	sort: "desc",
	sortBy: "dateTimeGenerated",
	page: 1,
	rowsPerPage: 25,
};

const resolveEndSessionLogsResponse = (items = []) => {
	return items.map((item) => {
		const keys = Object.keys(item);
		return keys.reduce(
			(prev, next) => ({ ...prev, [filterMap[next]]: item[next] }),
			{}
		);
	});
};

function round(value, decimalPlaces = 2) {
	const d = 10 ** decimalPlaces;
	return Math.round(value * d) / d;
}

async function getEndSessionLogs(filters = {}) {
	try {
		const {
			dateFrom,
			dateTo,
			search,
			sort,
			sortBy,
			page = 1,
			limit = 25,
		} = filters;

		const resolveSortBy = () => {
			const s = resolveParam(sortBy);
			if (!s) return undefined;

			return invert(filterMap)[sortBy] || undefined;
		};

		const format = (date) => {
			if (!date) return undefined;
			return date.format("yyyy/MM/DD");
		};
		const resolveParam = (p, defaultValue = undefined) => p || defaultValue;
		const getDateRange = () => {
			if ([dateFrom, dateTo].some((d) => !d)) return undefined;
			return [dateFrom, dateTo].map(format).join("-");
		};

		const params = {
			dateFromAndTo: getDateRange(),
			page,
			limit,
			sort: resolveParam(sort),
			sortBy: resolveParam(sortBy),
			search: resolveParam(search),
		};

		const result = await HTTP.get("/v2/endsession/logs", {
			params,
		});
		if (!result || result.status != 200) return null;
		const {
			data: { count, data: items },
		} = result;

		console.log(result, "result end session");
		return {
			count,
			items,
		};
	} catch (error) {
		return null;
	}
}

class EndSession extends Component {
	constructor(props) {
		super(props);

		const denom = getSessionStorage("encode");
		if (denom) {
			this.denom = denom;
		} else {
			this.denom = {
				oneThousand: null,
				fiveHundred: null,
				twoHundred: null,
				oneHundred: null,
				fifty: null,
				twenty: null,
				ten: null,
				five: null,
				one: null,
				twentyFiveCents: null,
				tenCents: null,
				fiveCents: null,
				oneCent: null,
			};
		}

		this.state = {
			labels: [],
			denoms: [
				{
					name: "oneThousand",
					quantity: this.denom.oneThousand,
					value: 1000,
				},
				{
					name: "fiveHundred",
					quantity: this.denom.fiveHundred,
					value: 500,
				},
				{
					name: "twoHundred",
					quantity: this.denom.twoHundred,
					value: 200,
				},
				{
					name: "oneHundred",
					quantity: this.denom.oneHundred,
					value: 100,
				},
				{ name: "fifty", quantity: this.denom.fifty, value: 50 },
				{ name: "twenty", quantity: this.denom.twenty, value: 20 },
				{ name: "ten", quantity: this.denom.ten, value: 10 },
				{ name: "five", quantity: this.denom.five, value: 5 },
				{ name: "one", quantity: this.denom.one, value: 1 },
				{
					name: "twentyFiveCents",
					quantity: this.denom.twentyFiveCents,
					value: 0.25,
				},
				{ name: "tenCents", quantity: this.denom.tenCents, value: 0.1 },
				{
					name: "fiveCents",
					quantity: this.denom.fiveCents,
					value: 0.05,
				},
				{ name: "oneCent", quantity: this.denom.oneCent, value: 0.01 },
			],
			oneThousand: this.denom.oneThousand,
			fiveHundred: this.denom.fiveHundred,
			twoHundred: this.denom.twoHundred,
			oneHundred: this.denom.oneHundred,
			fifty: this.denom.fifty,
			twenty: this.denom.twenty,
			ten: this.denom.ten,
			five: this.denom.five,
			one: this.denom.one,
			twentyFiveCents: this.denom.twentyFiveCents,
			tenCents: this.denom.tenCents,
			fiveCents: this.denom.fiveCents,
			oneCent: this.denom.oneCent,

			oneThousandTotal: this.denom.oneThousand * 1000,
			fiveHundredTotal: this.denom.fiveHundred * 500,
			twoHundredTotal: this.denom.twoHundred * 200,
			oneHundredTotal: this.denom.oneHundred * 100,
			fiftyTotal: this.denom.fifty * 50,
			twentyTotal: this.denom.twenty * 20,
			tenTotal: this.denom.ten * 10,
			fiveTotal: this.denom.five * 5,
			oneTotal: this.denom.one * 1,
			twentyFiveCentsTotal: this.denom.twentyFiveCents * 0.25,
			tenCentsTotal: this.denom.tenCents * 0.1,
			fiveCentsTotal: this.denom.fiveCents * 0.05,
			oneCentTotal: this.denom.oneCent * 0.01,
			totalAmount: getSessionStorage("cashEnd"),

			isGenerateDisabled: true,
			remarksValue: "",
			remarksFlag: 0,
			endSessionLogs: [],
			filters: INITIAL_FILTERS,

			openGenerateDialog: false,
			hideError: true,
			showError: false,
			toggleLogoutDialog: false,
			isRequestSuccess: false,
		};

		this.denom = [];
		this.stateKey = [
			{ oneThousand: 1000 },
			{ fiveHundred: 500 },
			{ twoHundred: 200 },
			{ oneHundred: 100 },
			{ fifty: 50 },
			{ twenty: 20 },
			{ ten: 10 },
			{ five: 5 },
			{ one: 1 },
			{ twentyFiveCents: 0.25 },
			{ tenCents: 0.1 },
			{ fiveCents: 0.05 },
			{ oneCent: 0.01 },
		];

		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleTotalCashDeposit = this.handleTotalCashDeposit.bind(this);
		this.handleRemarksChange = this.handleRemarksChange.bind(this);
		this.handleGenerateButton = this.handleGenerateButton.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSort = this.handleSort.bind(this);
		this.fetchEndSessionLogs = this.fetchEndSessionLogs.bind(this);
		this.generateSession = this.generateSession.bind(this);
		this.toggleCheckReceiptFlow = this.toggleCheckReceiptFlow.bind(this);
		this.toggleLogoutDialog = this.toggleLogoutDialog.bind(this);
		this.getTransactionPerTPA = this.getTransactionPerTPA.bind(this);
	}

	componentDidMount() {
		this.fetchEndSessionLogs(this.state.filters);
		this.setEndsessionSummaryDetails((obj) => {
			this.setState({ ...obj });
		});
	}

	async fetchEndSessionLogs(values = {}) {
		const logs = await getEndSessionLogs({
			...this.state.filters,
			...values,
		});
		if (logs) {
			this.setState({ endSessionLogs: logs });
		}
	}

	toggleCheckReceiptFlow() {}

	handleFilterChange(values = {}) {
		this.setState(({ filters }) => ({
			filters: {
				...filters,
				...values,
			},
		}));
	}

	handleSort(sortBy, sort) {
		this.setState(
			({ filters }) => ({
				filters: { ...filters, sortBy, sort, page: 1 },
			}),
			() => {
				this.handleFilterSubmit(this.state.filters);
			}
		);
	}

	handlePageChange(page, rowsPerPage) {
		this.setState(
			({ filters }) => ({
				filters: {
					...filters,
					page,
					rowsPerPage,
				},
			}),
			() => {
				this.handleFilterSubmit(this.state.filters);
			}
		);
	}

	async handleFilterSubmit(values = {}) {
		await this.fetchEndSessionLogs(values);
	}

	handleOnChange(event) {
		const number = /^[0-9\b]+$/;
		var value = event.target.id;
		if (number.test(event.target.value)) {
			this.setState({
				[value]: event.target.value,
			});
		} else {
			this.setState({
				[value]: "",
			});
		}

		if (event.target.value >= 0 && event.target.value !== "") {
			this.stateKey.forEach((value) => {
				if (event.target.id === Object.keys(value).toString()) {
					this.setState(
						{
							[Object.keys(value).toString() + "Total"]: (
								event.target.value * Object.values(value)
							).toLocaleString(undefined, {
								minimumFractionDigits: 2,
							}),
						},
						() => this.handleTotalCashDeposit()
					);
				}
			});
		} else {
			this.stateKey.forEach((value) => {
				if (event.target.id === Object.keys(value).toString()) {
					this.setState(
						{
							[Object.keys(value).toString() + "Total"]: null,
						},
						() => this.handleTotalCashDeposit()
					);
				}
			});
		}
	}

	handleTotalCashDeposit() {
		var fieldCounter = 0;
		var totalAmountComputed = 0;
		this.stateKey.forEach((value) => {
			var denomTotal =
				this.state[Object.keys(value).toString() + "Total"];
			if (denomTotal !== null) {
				totalAmountComputed += parseFloat(
					denomTotal.toString().replace(/,/g, "")
				);
				fieldCounter++;
			}
		});
		if (fieldCounter === 13) {
			this.setState(
				{
					totalAmount: totalAmountComputed.toLocaleString(undefined, {
						minimumFractionDigits: 2,
					}),
				},
				() => this.handleGenerateButton()
			);
		} else {
			this.setState(
				{
					totalAmount: 0,
				},
				() => this.handleGenerateButton()
			);
		}
	}

	handleRemarksChange(event) {
		if (event.target.value !== "") {
			this.setState(
				{
					remarksFlag: 1,
				},
				() => this.handleGenerateButton()
			);
		} else {
			this.setState(
				{
					remarksFlag: 0,
				},
				() => this.handleGenerateButton()
			);
		}
	}

	handleGenerateButton() {
		if (this.state.totalAmount !== 0 && this.state.remarksFlag === 1) {
			this.setState({
				isGenerateDisabled: false,
			});
		} else {
			this.setState({
				isGenerateDisabled: true,
			});
		}
	}

	toggleLogoutDialog() {
		this.setState({
			toggleLogoutDialog: true,
		});
	}

	generateSuccess = (presigned_url) => {
		this.setState({
			isRequestSuccess: true,
			openGenerateDialog: false,
		});

		const pdf_url = presigned_url;
		fetch(pdf_url)
			.then((resp) => resp.arrayBuffer())
			.then((resp) => {
				const file = new Blob([resp], {
					type: "application/pdf",
				});
				printJS({
					printable: URL.createObjectURL(file),
					type: "pdf",
					onPrintDialogClose: () => {
						this.toggleLogoutDialog();
					},
					onError: () => {
						this.setState({ showError: true });
					},
				});
			});
	};

	generateSession() {
		this.setState({ showError: false });
		this.setState({
			openGenerateDialog: true,
		});
		let cashEnd = getSessionStorage("cashEnd");
		let formattedCashEnd = "";
		if (cashEnd) {
			formattedCashEnd = cashEnd.replace(",", "");
		}

		var status = null;

		HTTP.post("/v2/session/report/log", {
			cash_end: formattedCashEnd,
			isRetry: 0,
		})
			.then((response) => {
				this.generateSuccess(response.data.presigned_url);
			})
			.catch(({ response: { data, status } }) => {
				if (timeoutErrorCodes.indexOf(status) != -1) {
					//retry block
					const options = {
						statusCodes: timeoutErrorCodes,
						status,
						tries: 10,
						timeout: 30,
					};

					const callback = () => {
						return HTTP.post("/v2/session/report/log", {
							cash_end: formattedCashEnd,
							isRetry: 1,
						});
					};

					retry_f(options, callback, true)
						.then((response) => {
							this.generateSuccess(response.data.presigned_url);
						})
						.catch((e) => {
							this.setState({
								showError: true,
								openGenerateDialog: false,
							});
						});
				} else {
					this.setState({
						showError: true,
						openGenerateDialog: false,
					});
				}
			});
	}

	async setEndsessionSummaryDetails(setState) {
		let totalCash = 0;
		let totalCheck = 0;
		let systemTotal = 0;
		let cashEnd = 0;
		let difference = 0;
		const tranx = await this.getTransactionPerTPA();
		totalCash = tranx.totalCash;
		totalCheck = tranx.totalCheck;

		systemTotal = (totalCash + totalCheck).toLocaleString(undefined, {
			minimumFractionDigits: 2,
		});

		if (getSessionStorage("cashEnd")) {
			cashEnd = getSessionStorage("cashEnd");
			difference =
				totalCash - parseFloat(cashEnd.replace(/,/g, "")).toFixed(2);
			if (Math.abs(difference) === 0) difference = 0;
			difference = difference.toLocaleString(undefined, {
				minimumFractionDigits: 2,
			});
		}

		let changeFund = parseFloat(
			getSessionStorage("changeFund")
		).toLocaleString(undefined, { minimumFractionDigits: 2 });
		// TODO Replace when partial deposit exist
		const partialDeposit = "0.00";
		let totalCashOnHand = (totalCash - partialDeposit).toLocaleString(
			undefined,
			{ minimumFractionDigits: 2 }
		);
		let totalCheckOnHand = totalCheck.toLocaleString(undefined, {
			minimumFractionDigits: 2,
		});

		totalCash = totalCash.toLocaleString(undefined, {
			minimumFractionDigits: 2,
		});
		totalCheck = totalCheck.toLocaleString(undefined, {
			minimumFractionDigits: 2,
		});

		setState({
			labels: [
				{ title: "Change Fund", value: changeFund },
				{ title: "System Total", value: systemTotal },
				{ title: "Cash", value: totalCash },
				{ title: "Check", value: totalCheck },
				{ title: "Partial Deposit", value: partialDeposit },
				{ title: "Total Cash on Hand", value: totalCashOnHand },
				{ title: "Total Check on Hand", value: totalCheckOnHand },
				{ title: "Cash End", value: cashEnd },
				{ title: "Total Cash on Hand vs Cash End", value: difference },
				{ title: "Total Cash for Deposit", value: cashEnd },
			],
		});
	}

	async getTransactionPerTPA() {
		const transaction = await HTTP.get("/v2/terminals/transactions")
			.then((data) => {
				return data;
			})
			.catch((error) => console.log(error));

		let totalCash = 0;
		let totalCheck = 0;

		if (!transaction) return { totalCash, totalCheck };

		const txn = transaction.data.data;

		txn.forEach((transaction) => {
			totalCash += parseFloat(transaction.other_charges);

			if (transaction.payment_method === "CASH") {
				totalCash += parseFloat(transaction.amount);
			} else if (transaction.payment_method === "CHECK") {
				totalCheck += parseFloat(transaction.amount);
			}
		});

		totalCash = round(totalCash);
		totalCheck = round(totalCheck);

		return { totalCash, totalCheck };
	}

	render() {
		const withSession = getSessionStorage("withSession");
		return (
			<>
				<Dialog
					PaperProps={{ style: { width: "400px" } }}
					onClose={this.state.hideError}
					open={this.state.showError}
					disableBackdropClick
					disableEscapeKeyDown
				>
					<DialogContent>
						<DialogContentText id="download">
							<img
								src={failedLogo}
								className={styles.failedImage}
							/>
							<Typography
								style={{
									font: "normal normal 600 21px Wavehaus-SemiBold",
									textAlign: "center",
									marginTop: "15px",
								}}
							>
								Error!
							</Typography>
							<Typography
								style={{
									font: "normal normal 300 14px Poppins-Regular",
									textAlign: "center",
									marginTop: "15px",
									color: "#3B3B3B",
								}}
							>
								{" "}
								A problem accurred while generating <br />
								the report. Please try again.
							</Typography>
						</DialogContentText>
					</DialogContent>
					<Button
						onClick={this.generateSession}
						autoFocus
						variant="outlined"
						disabled={status}
						style={{
							width: "50%",
							left: "25%",
							height: "45px",
							marginTop: "10px",
						}}
					>
						Retry
					</Button>
				</Dialog>

				{this.state.openGenerateDialog ? (
					<FullPageLoader
						open={this.state.openGenerateDialog}
						message={
							"Please wait while reports are being generated"
						}
					/>
				) : (
					<></>
				)}

				<LogoutDialog
					openModal={this.state.toggleLogoutDialog}
					type="endSession"
				/>

				{withSession == null || withSession == 1 ? (
					<>
						<Grid container style={{ padding: "20px" }}>
							<Typography
								style={{
									fontFamily: "Poppins-Regular",
									color: "#373D3F",
									marginBottom: "20px",
									marginTop: "10px",
									fontWeight: "600",
								}}
							>
								End Session Details
							</Typography>
							{this.state.labels.map((label) => (
								<Grid
									container
									className={styles.labelBorder}
									key={label.title}
								>
									<Grid item xs={10}>
										{label.title === "Cash End" ||
										label.title ===
											"Total Cash for Deposit" ? (
											<Accordion
												style={{
													width: "100%",
													marginLeft: "-14px",
													boxShadow: "none",
													padding: "0px",
												}}
											>
												<AccordionSummary
													id={label.title}
													className={
														styles.accordionSummary
													}
												>
													<Typography
														style={{
															fontFamily:
																"Poppins-Regular",
															color: "#373D3F",
															marginBottom:
																"10px",
															marginTop: "10px",
														}}
													>
														{label.title}
														<ExpandMoreIcon
															style={{
																paddingTop:
																	"12px",
															}}
														/>
													</Typography>
												</AccordionSummary>

												<AccordionDetails>
													<Typography
														style={{
															fontWeight: 400,
															textAlign: "right",
															fontFamily:
																"Poppins-Regular",
															color: "#373D3F",
															marginBottom:
																"10px",
															marginTop: "10px",
														}}
													></Typography>
													<TableContainer
														style={{
															width: 330,
															margin: "30px auto auto 40%",
															borderRadius:
																"10px",
															border: "1px",
															borderStyle:
																"solid",
															borderColor:
																"#0076C0",
														}}
													>
														<Table>
															<TableHead
																style={{
																	backgroundColor:
																		"#0076C0",
																}}
															>
																<TableRow
																	style={{}}
																>
																	<TableCell
																		style={{
																			fontFamily:
																				"Poppins-Regular",
																			fontSize:
																				"12px",
																			paddingLeft:
																				"40px",
																			color: "white",
																		}}
																	>
																		QUANTITY
																	</TableCell>
																	<TableCell
																		style={{
																			fontFamily:
																				"Poppins-Regular",
																			fontSize:
																				"12px",
																			color: "white",
																		}}
																	>
																		DENOM
																	</TableCell>
																	<TableCell
																		style={{
																			fontFamily:
																				"Poppins-Regular",
																			fontSize:
																				"12px",
																			color: "white",
																		}}
																	>
																		AMOUNT
																	</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{label.title ===
																"Cash End"
																	? this.state.denoms.map(
																			(
																				denom,
																				index
																			) =>
																				index %
																					2 ==
																				0 ? (
																					<TableRow
																						key={
																							denom.name
																						}
																					>
																						<TableCell
																							style={{
																								textAlign:
																									"right",
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{
																								denom.quantity
																							}
																						</TableCell>
																						<TableCell
																							style={{
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{
																								denom.value
																							}
																						</TableCell>
																						<TableCell
																							style={{
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{(
																								denom.quantity *
																								denom.value
																							).toLocaleString(
																								undefined,
																								{
																									minimumFractionDigits: 2,
																								}
																							)}
																						</TableCell>
																					</TableRow>
																				) : (
																					<TableRow
																						key={
																							denom.name
																						}
																						style={{
																							backgroundColor:
																								"#F3FAFF",
																						}}
																					>
																						<TableCell
																							style={{
																								textAlign:
																									"right",
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{
																								denom.quantity
																							}
																						</TableCell>
																						<TableCell
																							style={{
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{
																								denom.value
																							}
																						</TableCell>
																						<TableCell
																							style={{
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{(
																								denom.quantity *
																								denom.value
																							).toLocaleString(
																								undefined,
																								{
																									minimumFractionDigits: 2,
																								}
																							)}
																						</TableCell>
																					</TableRow>
																				)
																	  )
																	: this.stateKey.map(
																			(
																				key,
																				index
																			) =>
																				index %
																					2 ==
																				0 ? (
																					<TableRow
																						key={Object.keys(
																							key
																						).toString()}
																					>
																						<TableCell>
																							<TextField
																								id={Object.keys(
																									key
																								).toString()}
																								onChange={
																									this
																										.handleOnChange
																								}
																								value={
																									this
																										.state[
																										Object.keys(
																											key
																										).toString()
																									]
																								}
																								inputProps={{
																									min: 0,
																									style: {
																										textAlign:
																											"center",
																										width: 70,
																										fontSize:
																											"12px",
																									},
																								}}
																								variant="outlined"
																								size="small"
																							></TextField>
																						</TableCell>
																						<TableCell
																							style={{
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{Object.values(
																								key
																							).toString()}
																						</TableCell>
																						<TableCell
																							style={{
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{this
																								.state[
																								Object.keys(
																									key
																								).toString() +
																									"Total"
																							] !==
																							null
																								? this.state[
																										Object.keys(
																											key
																										).toString() +
																											"Total"
																								  ].toLocaleString(
																										undefined,
																										{
																											minimumFractionDigits: 2,
																										}
																								  )
																								: "0.00"}
																						</TableCell>
																					</TableRow>
																				) : (
																					<TableRow
																						key={Object.keys(
																							key
																						).toString()}
																						style={{
																							backgroundColor:
																								"#F3FAFF",
																						}}
																					>
																						<TableCell>
																							<TextField
																								id={Object.keys(
																									key
																								).toString()}
																								onChange={
																									this
																										.handleOnChange
																								}
																								value={
																									this
																										.state[
																										Object.keys(
																											key
																										).toString()
																									]
																								}
																								inputProps={{
																									min: 0,
																									style: {
																										textAlign:
																											"center",
																										width: 70,
																										fontSize:
																											"12px",
																									},
																								}}
																								variant="outlined"
																								size="small"
																							></TextField>
																						</TableCell>
																						<TableCell
																							style={{
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{Object.values(
																								key
																							).toString()}
																						</TableCell>
																						<TableCell
																							style={{
																								fontFamily:
																									"Poppins-Regular",
																								fontSize:
																									"12px",
																							}}
																						>
																							{this
																								.state[
																								Object.keys(
																									key
																								).toString() +
																									"Total"
																							] !==
																							null
																								? this.state[
																										Object.keys(
																											key
																										).toString() +
																											"Total"
																								  ].toLocaleString(
																										undefined,
																										{
																											minimumFractionDigits: 2,
																										}
																								  )
																								: "0.00"}
																						</TableCell>
																					</TableRow>
																				)
																	  )}
															</TableBody>
														</Table>
													</TableContainer>
												</AccordionDetails>
											</Accordion>
										) : label.title === "Cash" ||
										  label.title === "Check" ? (
											<Typography
												style={{
													fontFamily:
														"Poppins-Regular",
													color: "#373D3F",
													marginBottom: "10px",
													marginTop: "10px",
													marginLeft: "30px",
												}}
											>
												{label.title}
											</Typography>
										) : (
											<Typography
												style={{
													fontFamily:
														"Poppins-Regular",
													color: "#373D3F",
													marginBottom: "10px",
													marginTop: "10px",
												}}
											>
												{label.title}
											</Typography>
										)}
									</Grid>
									<Grid item xs={2}>
										{label.title ===
										"Total Cash for Deposit" ? (
											<Typography
												style={{
													fontWeight: 400,
													textAlign: "right",
													fontFamily:
														"Poppins-Regular",
													color: "#373D3F",
													marginBottom: "10px",
													marginTop: "10px",
												}}
											>
												{this.state.totalAmount}
											</Typography>
										) : label.title === "Cash" ||
										  label.title === "Check" ? (
											<Typography
												style={{
													fontWeight: 400,
													textAlign: "right",
													fontFamily:
														"Poppins-Regular",
													color: "#8B9095",
													fontSize: "13px",
													marginBottom: "10px",
													marginTop: "10px",
												}}
											>
												{label.value}
											</Typography>
										) : +label.value !== 0 &&
										  label.title ===
												"Total Cash on Hand vs Cash End" ? (
											<Typography
												style={{
													fontWeight: 400,
													textAlign: "right",
													fontFamily:
														"Poppins-Regular",
													color: "#D93025",
													marginBottom: "10px",
													marginTop: "10px",
												}}
											>
												{label.value}
											</Typography>
										) : (
											<Typography
												style={{
													fontWeight: 400,
													textAlign: "right",
													fontFamily:
														"Poppins-Regular",
													color: "#3B3B3B",
													marginBottom: "10px",
													marginTop: "10px",
												}}
											>
												{label.value}
											</Typography>
										)}
									</Grid>
								</Grid>
							))}
							<TextField
								className={styles.remarks}
								id="remarks"
								label="Remarks"
								variant="outlined"
								style={{ width: "100%" }}
								inputProps={{
									style: { height: 100 },
									maxLength: 1000,
								}}
								multiline
								onChange={this.handleRemarksChange}
								value={this.state.value}
							/>
						</Grid>
						<Button
							className={styles.logoutButtons}
							style={{
								width: "20%",
								backgroundColor: "#F26122",
								color: "#FFFFFF",
								font: "normal normal normal 12px/18px Poppins",
								marginLeft: "79%",
								height: "50px",
							}}
							disabled={this.state.isGenerateDisabled}
							onClick={this.generateSession}
						>
							{" "}
							Generate Session Report{" "}
						</Button>

						<Box mt={10} p={2}>
							<EndSessionLogs
								data={this.state.endSessionLogs}
								filters={this.state.filters}
								initialFilters={INITIAL_FILTERS}
								onFilterChange={this.handleFilterChange}
								onFilterSubmit={this.handleFilterSubmit}
								onSort={this.handleSort}
								onPageChange={this.handlePageChange}
							/>
						</Box>
					</>
				) : (
					<Box p={2}>
						<EndSessionLogs
							data={this.state.endSessionLogs}
							filters={this.state.filters}
							initialFilters={INITIAL_FILTERS}
							onFilterChange={this.handleFilterChange}
							onFilterSubmit={this.handleFilterSubmit}
							onSort={this.handleSort}
							onPageChange={this.handlePageChange}
						/>
					</Box>
				)}
			</>
		);
	}
}
export default EndSession;
