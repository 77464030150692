import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { ApolloLink } from "apollo-link";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { TokenUtil } from "../utils/checkSession";
import { RetryLink } from "apollo-link-retry";

const token = TokenUtil.get();

const loginReqAppSyncConfig = {
	url: process.env.REACT_APP_AWS_APPSYNC_LOGIN_GRAPHQL_ENDPOINT,
	region: process.env.REACT_APP_AWS_APPSYNC_REGION,
	auth: {
		type: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
		apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
		token: `Bearer ${token}`,
	},
};

const reprintAppSyncConfig = {
	url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
	region: process.env.REACT_APP_AWS_APPSYNC_REGION,
	auth: {
		type: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
		apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
		token: () => {
			const _token = TokenUtil.get()
			return `Bearer ${_token}`
			}
	},
};

const loginReqHttp = createHttpLink({
	uri: loginReqAppSyncConfig.url,
	headers: {
		authorization: `Bearer ${token}`,
		"x-bayad-platform-id": process.env.REACT_APP_APP_KEY,
	},
});

const reprintHttp = createHttpLink({
	uri: reprintAppSyncConfig.url,
	headers: {
		authorization: `Bearer ${token}`,
		"x-bayad-platform-id": process.env.REACT_APP_APP_KEY,
	},
});

const retryLink = new RetryLink({
	delay: {
		initial: 300,
		max: Infinity,
		jitter: true,
	},
	attempts: {
		max: 5,
		retryIf: (error, _operation) => !!error,
	},
});

export const apolloClient = new ApolloClient({
	link: ApolloLink.split(
		(o) => {
			return o.getContext().clientName === "reprint";
		},
		ApolloLink.from([
			retryLink,
			createAuthLink(reprintAppSyncConfig),
			retryLink,
			createSubscriptionHandshakeLink(reprintAppSyncConfig, reprintHttp),
		]),
		ApolloLink.from([
			createAuthLink(loginReqAppSyncConfig),
			createSubscriptionHandshakeLink(
				loginReqAppSyncConfig,
				loginReqHttp
			),
		])
	),
	cache: new InMemoryCache(),
	disableOffline: true,
});

export default apolloClient;
