import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/ic-logout.svg";
import Slide from "@material-ui/core/Slide";
import styles from "./ReportGenerationModal.module.css";
import Typography from "@material-ui/core/Typography";
import HTTP from "../../../helpers/ApiClient";
import { withRouter } from "react-router-dom";
import { TerminalUtil, UserInfoUtil } from "../../../utils/checkSession";
import { clearSessionStorage, getSessionStorage } from "../../../utils/storage";
import { clearSessionAndRedirect } from "../../../utils/storage";
import { getCurrentDate } from "../../App/App";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export const handleLogout = ({ type }) => {
	const userInfo = UserInfoUtil.get();
	const body = {
		user_id: userInfo.uuid,
		action:
			type === "turnOverSession"
				? "Turn Over Session Logout"
				: "End Session Logout",
		owner_id: userInfo.roles[0].platform.id,
		owner_type: userInfo.uamRole,
		created_at: getCurrentDate(),
		updated_at: getCurrentDate(),
		new_values: "{}",
		old_values: "{}",
		source_ip: "",
	};

	HTTP.post("/v2/user/logout", body)
		.then(() => {
			clearSessionAndRedirect();
		})
		.catch((error) => {
			console.error(error);
			if (error.response.status === 401) {
				clearSessionAndRedirect();
			}
		});
};

export const LogoutDialog = (props) => {
	const [timer, setTimer] = React.useState(15);
	let openFlg = props.openModal;
	React.useEffect(() => {
		if (openFlg) {
			const counter = setInterval(() => {
				setTimer((prevProgress) =>
					prevProgress <= 0 ? 15 : prevProgress - 1
				);
			}, 1000);
			return () => {
				clearInterval(counter);
			};
		}
	}, [openFlg]);

	React.useEffect(() => {
		if (timer == 0) {
			handleLogout(props);
		}
	}, [timer]);

	return (
		<div>
			<Dialog
				open={props.openModal}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				PaperProps={{
					style: {
						width: "439px",
						height: "333px",
						boxShadow: "none",
						color: "white",
						fontSize: "17",
						alignItems: "center",
						padding: "25px 10px 0px",
					},
				}}
			>
				<DialogContent
					className={styles.dialog}
					style={{ height: "inherit", width: "auto" }}
				>
					<div style={{ textAlign: "center", color: "black" }}>
						<LogoutIcon className={styles.logoutIcon} />
					</div>

					<DialogContentText
						id="alert-dialog-slide-description"
						style={{ margin: "0" }}
					>
						<p className={styles.logout}>Logout</p>
						<p
							className={styles.p_message}
							style={{
								fontSize: "14px",
								textAlign: "center",
								fontWeight: "200",
							}}
						>
							<p className={styles.p_message}>
								End session report was successfully generated.
							</p>
							<p className={styles.p_message}>
								You will now be logged-out of your account to
								end your
							</p>
							<p className={styles.p_message}>
								Pay Bills session. To view or download the
							</p>
							<p
								className={styles.p_message}
								style={{
									marginBottom: "0px",
									paddingBottom: "0px",
								}}
							>
								reports kindly re log-in. Thank you!
							</p>
						</p>
					</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{
						justifyContent: "center",
						borderTop: "1px solid #8B9095",
						width: "100%",
					}}
				>
					<Button
						onClick={() => handleLogout(props)}
						className={styles.logoutBtn}
					>
						<span className={styles.btnLabel}>
							<Typography
								variant="caption"
								component="div"
								color="white"
							>{`Logout [${Math.round(timer)} secs]`}</Typography>
						</span>
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default LogoutDialog;
