import HTTP from "../helpers/ApiClient";
import { logOut } from "./logout";
import {
	UserInfoUtil,
	TerminalUtil,
	RefreshTokenUtil,
	TokenUtil,
	ReminderUtil,
	IsRefreshingUtil,
} from "./checkSession";
import moment from "moment";

async function refresh(showInvalidIpModal) {
	const refreshToken = RefreshTokenUtil.get();
	const req = JSON.stringify({
		grant_type: "refresh_token",
		refresh_token: refreshToken,
		redirect_uri: "",
		client_id: process.env.REACT_APP_APP_KEY,
	});

	IsRefreshingUtil.set("true");
	await HTTP.post("/v2/user/refreshToken", req)
		.then((data) => {
			const userInfo = UserInfoUtil.get();
			TokenUtil.set(data["data"]["data"]["access_token"]);
			RefreshTokenUtil.set(data["data"]["data"]["refresh_token"]);
			UserInfoUtil.set({
				...userInfo,
				expires_in: data["data"]["expires_in"],
			});
		})
		.catch((error) => {
			console.error(error);
			if (error.response.status === 403) {
				logOut(false);
				showInvalidIpModal();
			}
		})
		.finally(() => {
			IsRefreshingUtil.set("false");
		});
}

if (process.env.REACT_APP_DEBUG == "true") {
	window.refreshToken = refresh;
}

async function getClosingTime() {
	const terminal = TerminalUtil.get();
	const user = UserInfoUtil.get();
	if (terminal.id) {
		const { data } = await HTTP.get(
			`v3/branch-access/get-closing-time/${terminal.id}?userHash=${user.uuid}`
		);
		return data;
	}
}

async function updateAndValidateClosingTime(
	showEndSession,
	showReminderModal,
	setSchedule,
	history
) {
	const data = await getClosingTime();

	setSchedule({
		status: data.terminal_status,
		closingTime: data.next_closing_time,
	});

	const closingTime = moment(data.next_closing_time, "HH:mm:ss");
	const reminderTime = moment(closingTime).subtract(5, "minutes");

	if (data.terminal_status !== "CLOSED" && data.next_closing_time) {
		clearInterval(window.checkClosingTimeInterval);
		window.checkClosingTimeInterval = setInterval(() => {
			if (
				!ReminderUtil.get() &&
				moment().isSameOrAfter(reminderTime) &&
				moment().isBefore(moment(reminderTime).add(1, "minute")) &&
				window.location.pathname !== "/cash-management"
			) {
				showReminderModal(true);
			} else if (
				moment().isSameOrAfter(closingTime) &&
				window.location.pathname !== "/cash-management"
			) {
				showReminderModal(false);
				clearInterval(window.checkClosingTimeInterval);
			}
		}, 1000);
	} else if (
		data.terminal_status === "CLOSED" &&
		window.location.pathname !== "/cash-management"
	) {
		// showReminderModal(false);
		history.push("/cash-management");
		history.go(0);
	}

	return { closingTime, reminderTime };
}

async function refreshTokenBeforeExpiry(
	showEndSession,
	showInvalidIpModal,
	showReminderModal,
	setSchedule,
	history
) {
	const { expires_in } = UserInfoUtil.get() || {};
	const defaultExpiry = moment(moment().add(15, "minutes")).unix();
	const session_expiry = moment.unix(expires_in || defaultExpiry);
	const durationDiff = moment.duration(
		session_expiry.subtract(1, "minutes").diff(moment())
	);
	const timeout = parseInt(durationDiff.asMinutes()) * 1000 * 60;

	const refreshTokenTimeout = setTimeout(async () => {
		await refresh(showInvalidIpModal);
		clearTimeout(refreshTokenTimeout);
		window.initializeRefreshTokenExpiry();
	}, timeout);

	try {
		const { closingTime, reminderTime } =
			await updateAndValidateClosingTime(
				showEndSession,
				showReminderModal,
				setSchedule,
				history
			);

		const apiCall = {
			reminder: false,
			closing: false,
		};
		clearInterval(window.updateClosingTimeInterval);
		window.updateClosingTimeInterval = setInterval(async () => {
			const oneMinBeforeFn = (time, property) => {
				const isApiCalled = apiCall[property];
				const isCurrentMinBefore =
					moment().isSameOrAfter(
						moment(time).subtract(1, "minute")
					) && moment().isBefore(time);
				if (isCurrentMinBefore) {
					apiCall[property] = true;
				}

				return isCurrentMinBefore && !isApiCalled;
			};

			if (
				oneMinBeforeFn(reminderTime, "reminder") ||
				oneMinBeforeFn(closingTime, "closing")
			) {
				await updateAndValidateClosingTime(
					showEndSession,
					showReminderModal,
					setSchedule,
					history
				);
			}

			if (apiCall.reminder && apiCall.closing) {
				clearInterval(window.updateClosingTimeInterval);
			}
		}, 1000);
	} catch (error) {
		console.error(error);
		clearInterval(window.updateClosingTimeInterval);
	}
}

function updateDateTimeExpiry(unix) {
	const max = moment(unix).add(
		process.env.REACT_APP_TOKEN_SESSION_EXPIRY,
		"minutes"
	);
	const min = moment(max).subtract(1, "minutes");
	return { min, max };
}

function showSessionExpiry(
	dispatch,
	secondDispatch,
	thirdDispatch,
	{ saved_min, saved_max, saved_unix }
) {
	const unix = new Date().getTime();

	const sessionTimeout = setInterval(() => {
		const { min, max } =
			saved_unix !== unix
				? updateDateTimeExpiry(unix)
				: { min: saved_min, max: saved_max };

		if (moment().isSameOrAfter(min) && moment().isBefore(max)) {
			dispatch();
			secondDispatch();
			clearInterval(sessionTimeout);
		} else if (moment().isSameOrAfter(max)) {
			thirdDispatch();
			logOut(true);
			clearInterval(sessionTimeout);
		}
	}, 1000);

	window.resetTimeOut = () => {
		const new_unix = new Date().getTime();
		const { min: new_min, max: new_max } = updateDateTimeExpiry(new_unix);
		clearInterval(sessionTimeout);
		window.initializeShowSessionExpiry({
			saved_min: new_min,
			saved_max: new_max,
			saved_unix: new_unix,
		});
	};
}

function showValidateExpiry(dispatch, secondDispatch) {
	const timeout = 15 * 60 * 1000;

	window.sessionValidateExpiryTimeout = setTimeout(() => {
		dispatch();
		secondDispatch();
	}, timeout);

	window.resetValidateExpiryTimeOut = () => {
		clearTimeout(window.sessionValidateExpiryTimeout);
	};
}

export {
	refresh,
	showSessionExpiry,
	showValidateExpiry,
	refreshTokenBeforeExpiry,
};
