import React, { useEffect, useState } from "react";
import { useToggle } from "../../utils/hooks";
import { chunk, sortBy } from "lodash";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../Table/CustomTableWithPagination";
import "../Table/CustomTable.module.css";
import { connect, useSelector } from "react-redux";
import http from "../../helpers/ApiClient";
import CustomRow from "../../components/Table/CustomRow";
import { getSessionReportsActionCreator } from "../../redux/modules/sessionReports";
import { CompassCalibrationOutlined } from "@material-ui/icons";
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";
import { transform } from "dot-object";
import { Fragment } from "react";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	gridRoot: {
		marginTop: 10,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

const getSessionReport = async (filter) => {
	return await http.get("/v2/cash-management/session-report", {
		params: { ...prepareParams(filter) },
	});
};

function prepareParams(state) {
	let params = {};
	if (state.type) {
		params = {
			...params,
			type: state.type,
		};
	}
	if (state.filename) {
		params = {
			...params,
			filename: state.filename,
		};
	}
	return params;
}

function SessionReports({ getSessionReportsActionCreator, sessionReports }) {
	const classes = useStyles();
	const [sessionReport, setSessionReport] = useState([]);
	const [filteredReport, setFilteredReport] = useState([]);
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const [orderBy, setOrderBy] = React.useState("date_and_time_generated");
	const [order, setOrder] = React.useState("asc");

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy, transform = (value) => value) {
		return order === "desc"
			? (a, b) =>
					descendingComparator(transform(a), transform(b), orderBy)
			: (a, b) =>
					-descendingComparator(transform(a), transform(b), orderBy);
	}

	const handleRequestSort = (orderBy, order) => {
		const header = headCells.find(({ id }) => id === orderBy);
		const transform = header ? header.transform : (value) => value;
		setOrderBy(orderBy);
		setOrder(order);
		const sorted = doSort(sessionReport, transform, order);
		const pages = chunk(sorted, rowsPerPage);
		setFilteredReport(pages[page - 1]);
		// setFilteredReport(tableSort(sessionReport, (order ,orderBy) => getComparator(order, orderBy, transform)))
	};

	const handleChangePage = (page, rowsPerPage) => {
		setPage(page);
		setRowsPerPage(rowsPerPage);
		updateFiltredReport(sessionReport, page, order, orderBy, rowsPerPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const [isLoading, showLoading, hideLoading] = useToggle();
	const [isError, showError, hideError] = useToggle();
	const [filter, setFilter] = React.useState({
		type: "",
		date_time_generated: "",
		filename: "",
	});

	function formatDate(date) {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}

	function doSort(array, transform, order = "asc") {
		const out = sortBy(array, transform);
		if (order !== "asc") return out.reverse();

		return out;
	}

	const updateFiltredReport = (value, page, order, orderBy, rowsPerPage) => {
		const header = headCells.find(({ id }) => id === orderBy);
		const transform = header ? header.transform : (value) => value;
		const sorted = doSort(value, transform, order);
		const pages = chunk(sorted, rowsPerPage);
		setFilteredReport(pages[page - 1]);
	};

	const headCells = [
		{
			identifier: "date_time_generated",
			id: "date_time_generated",
			numeric: false,
			disablePadding: false,
			label: "DATE AND TIME GENERATED",
			sortable: true,
			transform: (value) => {
				return new Date(value);
			},
		},
		{
			identifier: "session_start_-_end",
			id: "session_start_-_end",
			numeric: true,
			disablePadding: false,
			label: "SESSION START - END",
		},
		{
			identifier: "type",
			id: "type",
			numeric: true,
			disablePadding: false,
			label: "TYPE",
		},
		{
			identifier: "filename",
			id: "filename",
			numeric: true,
			disablePadding: false,
			label: "FILENAME",
		},
		{
			identifier: "actions",
			id: "actions",
			numeric: true,
			disablePadding: false,
			label: "ACTIONS",
			render: (value, entry) => {
				return (
					<>
						<CustomRow icon={"Print"} row={entry} />
						<CustomRow icon={"Download"} row={entry} />
					</>
				);
			},
		},
	];

	const fetchSessionReports = async (values = {}, page = 1) => {
		try {
			showLoading();
			const result = await getSessionReport({ ...filter, ...values });
			let finalResult = [];
			result.data.result.map((report) => {
				if (
					values.date_time_generated &&
					values.date_time_generated != ""
				) {
					const newReportDate = formatDate(
						new Date(report.date_time_generated)
					);
					const newFilterDate = formatDate(
						new Date(values.date_time_generated)
					);
					if (newReportDate === newFilterDate) {
						report.icon1 = (
							<CustomRow icon={"Print"} row={report} />
						);
						report.icon2 = (
							<CustomRow icon={"Download"} row={report} />
						);
						finalResult.push(report);
					}
				} else {
					report.icon1 = <CustomRow icon={"Print"} row={report} />;
					report.icon2 = <CustomRow icon={"Download"} row={report} />;
					finalResult.push(report);
				}
			});
			setPage(page);
			setSessionReport(finalResult);
			updateFiltredReport(finalResult, page, order, orderBy, rowsPerPage);
			hideLoading();
		} catch (error) {
			console.log(error);
			hideLoading();
			showError();
		}
	};
	useEffect(() => {
		fetchSessionReports();
	}, []);
	return (
		<>
			<Table
				sort={order}
				sortBy={orderBy}
				totalCount={sessionReport.length}
				headCells={headCells}
				getComparator={getComparator}
				handleRequestSort={handleRequestSort}
				order={order}
				setOrder={setOrder}
				orderBy={orderBy}
				setOrderBy={setOrderBy}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				handleChangePage={handleChangePage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
				page={page}
				setPage={setPage}
				filter={filter}
				hideLoading={hideLoading}
				setFilter={setFilter}
				fetchSessionReports={fetchSessionReports}
				showLoading={isLoading}
				hideError={hideError}
				showError={isError}
				sessionReport={filteredReport}
				sessionReports={sessionReports}
				getSessionReportsActionCreator={getSessionReportsActionCreator}
			/>
		</>
	);
}

export default connect(
	(state) => ({
		search: state.sessionReports.search,
		sessionReports: state.sessionReports.sessionReports,
		showLoading: state.sessionReports.showLoading,
		showErrorLoading: state.sessionReports.showErrorLoading,
	}),
	{ getSessionReportsActionCreator }
)(SessionReports);
