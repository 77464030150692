import Button, { ButtonProps } from "@mui/material/Button";
import { FaSpinner } from "react-icons/fa";

export const Buttons = ({
	variant,
	text,
	radius,
	styling,
	handleOnClick,
	isDisabled,
	isLoading,
	...props
}) => {
	return (
		<>
			<Button
				variant={variant}
				onClick={handleOnClick}
				sx={{ borderRadius: radius ? "21px" : null }}
				style={styling}
				disabled={isDisabled}
				{...props}
			>
				{text}
				{isLoading == true ? <FaSpinner spin="true" /> : null}
			</Button>
		</>
	);
};

export default Buttons;
