import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	endSession: false,
	showModal: false,
	showReminderModal: false,
	isUserHasTime: true,
	showValidateExpiryModal: false,
	isUserLoggingOut: false,
	isReloadLocked: true,
	isDisableCommit: false,
	schedule: {
		status: "ACTIVE",
		closingTime: null,
	},
};

const sessionTimeout = createSlice({
	name: "sessionTimeout",
	initialState,
	reducers: {
		setSchedule: (state, action) => {
			state.schedule = action.payload;
		},
		showEndSession: (state) => {
			state.endSession = true;
		},
		hideEndSession: (state) => {
			state.endSession = false;
		},
		toggleModal: (state) => {
			state.showModal = !state.showModal;
		},
		showReminderModal: (state, action) => {
			state.isUserHasTime = action.payload;
			state.showReminderModal = true;
		},
		hideReminderModal: (state) => {
			state.showReminderModal = false;
		},
		toggleUnlockReload: (state) => {
			state.isReloadLocked = !state.isReloadLocked;
		},
		toggleShowValidateExpiryModal: (state) => {
			state.showValidateExpiryModal = !state.showValidateExpiryModal;
		},
		toggleUserLoggingOut: (state) => {
			state.isUserLoggingOut = !state.isUserLoggingOut;
		},
		disableCommit: (state) => {
			state.isDisableCommit = true;
		},
		enableCommit: (state) => {
			state.isDisableCommit = false;
		},
	},
});

export const {
	showEndSession,
	hideEndSession,
	setSchedule,
	toggleUserLoggingOut,
	showReminderModal,
	hideReminderModal,
	toggleModal,
	toggleUnlockReload,
	toggleShowValidateExpiryModal,
	disableCommit,
	enableCommit,
} = sessionTimeout.actions;
export default sessionTimeout.reducer;
