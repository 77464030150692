import { Component } from "react";
import cx from "classnames";
import avatar from "../../assets/icons/ic-avatar.svg";
import { Box, Typography } from "@material-ui/core";
import styles from "./Account.module.css";
import ProfilePopover from "../Profile/ProfilePopover";
import { BranchUtil, TerminalUtil } from "../../utils/checkSession";
class Account extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	render() {
		const terminal = TerminalUtil.get();
		const branch = BranchUtil.get([{ name: "", id: "" }]);

		return (
			<Box display="flex" justifyItems="center" alignContent="center">
				<Box
					display="flex"
					justifyItems="center"
					alignItems={"center"}
					alignContent={"center"}
				>
					<img src={avatar} alt="avatar" />
					&nbsp;
					<Box>
						<Typography
							className={cx(styles.textLarge, styles.text)}
						>
							BRANCH: {branch[0].name}
						</Typography>
						<Typography
							className={cx(styles.textSmall, styles.text)}
						>
							TPA/USER TPA-{terminal.id}/{terminal.name}
						</Typography>
					</Box>
				</Box>

				<ProfilePopover />
			</Box>
		);
	}
}

export default Account;
