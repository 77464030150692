import HTTP from "../helpers/ApiClient";
import { UserInfoUtil } from "../utils/checkSession";
import { clearSessionAndRedirect } from "./storage";
import { getCurrentDate } from "../components/App/App";

async function logOut(isExpired = false, userDetailed) {
	const userDetails = userDetailed ??  UserInfoUtil.get();
	const body = {
		user_id: userDetails.uuid,
		action: isExpired ? "Logout" : "Temporary Logout",
		owner_id: userDetails.roles[0].platform.id,
		owner_type: userDetails.uamRole,
		created_at: getCurrentDate(),
		updated_at: getCurrentDate(),
		new_values: "{}",
		old_values: "{}",
		source_ip: "",
	};
	HTTP.post("/v2/user/logout", body)
		.then(() => {
			clearSessionAndRedirect(isExpired);
		})
		.catch((error) => {
			console.error(error);
			if (error.response.status === 401) {
				clearSessionAndRedirect(isExpired);
			}
		});
}

export { logOut, clearSessionAndRedirect };
