import { TextField, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";


const SSSShortTermLoanDetails = (props) => {
	const { validTransactions, data, transaction, styles, classes } = props;
    const details = validTransactions[data.code].find(data => data.account === transaction.referenceNumber)
	return (
		<>
            {!isEmpty(details) && !isEmpty(details['details']) &&(
                <>
                    <TextField size="small" fullWidth variant="outlined" name={'PaymentType'}
                        style={{width: "200px"}}
                        InputProps={{
                            classes: {
                                input: styles.textInput,
                                root: classes.inputWithValue,
                            },
                            disabled: true
                        }}
                        label={
                            <Typography className={classes.inputLabel}>
                                Payment Type
                            </Typography>
                        }
                        value={details['details']['PaymentType']}
                    ></TextField>
                    <TextField size="small" fullWidth variant="outlined" name={'SSID'}
                        style={{width: "200px"}}
                        InputProps={{
                            classes: {
                                input: styles.textInput,
                                root: classes.inputWithValue,
                            },
                            disabled: true
                        }}
                        label={
                            <Typography className={classes.inputLabel}>
                               SSID
                            </Typography>
                        }
                        value={details['details']['SSID']}
                    ></TextField>
                </>
            )}
        </>
	);
};

export default SSSShortTermLoanDetails;