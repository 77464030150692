import Cookies from "js-cookie";

export const storeCookie = (name, value, expiration) => {
	Cookies.set(name, JSON.stringify(value), {
		expires: expiration,
		secure: true,
		sameSite: "Strict",
	});
};

export const getCookie = (name) => {
	const value = Cookies.get(name);
	if (value) {
		return JSON.parse(value);
	}
	return value;
};
