import { Tooltip, withStyles } from "@material-ui/core";
import cx from "classnames";
import moment from "moment";
import { FaInfoCircle } from "react-icons/fa";
import { TransactionStatus } from "../Transaction/TransactionTable/TransactionTable";
import CommonTable from "../common/CommonTable/CommonTable";
import styles from "./CashFlow.module.css";
import { FC } from "react";

const LightTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: "#F1F1F1",
		color: "#000000",
		boxShadow: "0px 3px 6px #00000029",
		fontSize: 11,
		borderRadius: 2,
	},
	arrow: {
		color: "#F1F1F1",
	},
}))(Tooltip);

const fmt = (num: number, decimal = 0) =>
	(num ?? 0).toLocaleString("en-US", { minimumFractionDigits: decimal });
const headers = [
	{
		label: () => (
			<>
				<div>Transaction</div>
				<div>Date and Time</div>
			</>
		),
		identifier: "date_created",
		render: (value: string) =>
			moment(value).format("MM/DD/YYYY hh:mm:ss A"),
		sortable: true,
	},
	{
		label: "Transaction Reference No.",
		identifier: "transaction_reference_no",
	},
	{
		label: "Transaction Type",
		identifier: "transaction_type",
	},
	{
		label: "Product Type",
		identifier: "product_type",
	},
	{
		label: "Biller",
		identifier: "biller_name",
		sortable: true,
	},
	{
		label: "Transaction Amount",
		identifier: "amount_due",
		render: (value: number) => fmt(value, 2),
	},
	{
		label: "Service Fee",
		identifier: "service_fee",
		render: (value: number) => fmt(value, 2),
	},
	{
		label: "Total Amount Deducted",
		identifier: "total_amount_debit",
		render: (value: number) => fmt(value, 2),
	},
	{
		label: () => (
			<div className={cx(styles.tooltipHeader)}>
				<span>Wallet Balance</span>
				<LightTooltip
					title="Note: The balance reflected here is based on the overall usage of the channels connected to this wallet. Previous logged balance may not equal the amount deducted due to your current transaction."
					placement="bottom-end"
					arrow
				>
					<div style={{ marginTop: 2 }}>
						<FaInfoCircle size={12} />
					</div>
				</LightTooltip>
			</div>
		),
		identifier: "running_balance",
		render: (value: number) => fmt(value, 2),
		sortable: true,
	},
];

type CashFlowTableProps = {
	data: any[];
	handlers: {
		[key: string]: Function;
	};
	meta: {
		page: number;
		pageSize: number;
		total: number;
	};
	sorter: [string | null, "asc" | "desc"];
};

const CashFlowTable: FC<CashFlowTableProps> = ({
	data,
	handlers,
	meta,
	sorter: [sortField, sortOrder],
}) => {
	return (
		<CommonTable
			headers={headers}
			entries={data}
			page={meta?.page ?? 1}
			totalEntries={meta?.total ?? 0}
			rowsPerPage={meta?.pageSize ?? 25}
			onPageChange={(page: number, limit: number) => {
				handlers.page(page);
				handlers.limit(limit);
			}}
			onSort={(field: any, order: any) => handlers.sorter([field, order])}
			sortBy={sortField}
			sort={sortOrder}
			className=""
		/>
	);
};

export default CashFlowTable;
