import { Badge } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery, useSubscription } from "@apollo/client";
import { BranchUtil } from "../../utils/checkSession";
import {
	GET_NOTIFICATIONS,
	ON_UPDATE_NOTIFICATION,
} from "../../graphql/queries";
import notification from "../../assets/icons/ic-notification.svg";
import {
	NotificationPopoverTeller,
	NotificationPopoverSupervisor,
} from "./NotificationPopover";
import styles from "./Notifications.module.css";

export function NotificationTeller() {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<Badge badgeContent={0} className={styles.badge} color="error">
				<img
					src={notification}
					alt="Notification"
					className={styles.img}
					onClick={handleClick}
				/>
				<NotificationPopoverTeller
					anchorEl={anchorEl}
					handleClose={handleClose}
					options={[]}
				/>
			</Badge>
		</>
	);
}

export function NotificationSupervisor() {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useMemo(async () => await Notification.requestPermission(), []);

	const [branch] = BranchUtil.get();
	const options = {
		variables: { branch_id: branch.id },
		context: { clientName: "reprint" },
	};

	const {
		data: requestData,
		loading,
		error,
		refetch,
	} = useQuery(GET_NOTIFICATIONS, options);

	const { data: subData, error: subDataError } = useSubscription(
		ON_UPDATE_NOTIFICATION,
		options
	);

	useEffect(() => {
		subData &&
			subData.onCreateBranchAppNotif.notif_state === "PENDING" &&
			new Notification("New Request!", {
				body: "Head to BFA to respond to this request within 15 minutes.",
			});
	}, [subData]);

	const countPending = () =>
		requestData
			? requestData.getAppNotifList.data.filter(
					(request) => request.notif_state === "PENDING"
			  )
			: [];

	return (
		<>
			<Badge
				badgeContent={countPending().length}
				className={styles.badge}
				color="error"
			>
				<img
					src={notification}
					alt="Notification"
					className={styles.img}
					onClick={handleClick}
				/>
				<NotificationPopoverSupervisor
					anchorEl={anchorEl}
					requestData={requestData}
					refetch={refetch}
					options={options}
					handleClose={handleClose}
					subData={subData}
					subError={subDataError}
				/>
			</Badge>
		</>
	);
}

export default NotificationTeller;
