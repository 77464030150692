import {
	Button,
	Dialog,
	DialogActions,
	Grid,
	Typography,
	Divider,
	FormControl,
	RadioGroup,
	Radio,
	FormControlLabel,
} from "@material-ui/core";
import { ChangeEvent, useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { GreenColorButton } from "../../App/App";
import styles from "./AddScheduleOptionModal.module.css";

type AddScheduleOptionModalProps = {
	isOpen: boolean;
	branchName: string;
};

interface IAddScheduleOptionModal {
	onClickCancel: () => void;
	onClickConfirm: (value: string) => void;
}

const radioGroupOption: Array<{ value: string; label: string }> = [
	{
		value: "0",
		label: "Apply to all TPA IDs.",
	},
	{
		value: "1",
		label: "Apply only to TPA IDs that have no existing schedule.",
	},
];

export default function AddScheduleOptionModal({
	isOpen = false,
	branchName = "Branch Name",
	onClickCancel,
	onClickConfirm,
}: AddScheduleOptionModalProps & IAddScheduleOptionModal) {
	const [selected, setSelected] = useState("0");

	useEffect(() => {
		if (isOpen) {
			setSelected("0");
		}
	}, [isOpen]);

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSelected(e.target.value);
	};
	return (
		<Dialog
			disableBackdropClick
			open={isOpen}
			fullWidth={true}
			maxWidth={"xs"}
		>
			<div className={styles.dialogTitle}>
				<Typography className={styles.dialogTitleText}>
					Add Schedule{" "}
				</Typography>

				<IconButton onClick={onClickCancel} aria-label="close">
					<CloseIcon />
				</IconButton>
			</div>
			<Divider />
			<Grid container>
				<div className={styles.dialogContent}>
					<Typography className={styles.dialogContentText}>
						Are you sure you want to add schedule to{" "}
						<b>{branchName}</b>?
					</Typography>
					<span className={styles.hint}>
						* This change will be applied to the TPA IDs under this
						branch{" "}
					</span>

					<div className={styles.radioGroupContainer}>
						<FormControl>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								defaultValue="female"
								name="radio-buttons-group"
								onChange={handleOnChange}
								value={selected}
							>
								{radioGroupOption.map((r) => {
									return (
										<FormControlLabel
											key={r.value}
											value={r.value}
											control={<Radio />}
											label={
												<Typography
													className={
														styles.dialogContentText
													}
												>
													{r.label}
												</Typography>
											}
										/>
									);
								})}
							</RadioGroup>
						</FormControl>
					</div>
				</div>
			</Grid>
			<Divider />
			<DialogActions>
				<Button
					onClick={onClickCancel}
					className={styles.dialogCancelButton}
				>
					Cancel
				</Button>
				<GreenColorButton
					onClick={() => onClickConfirm(selected)}
					autoFocus
					color="primary"
				>
					Confirm
				</GreenColorButton>
			</DialogActions>
		</Dialog>
	);
}
