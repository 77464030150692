import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	AccessObject,
	getEnabledStatus,
} from "../../components/Login/AccessChecker";
import {
	BranchUtil,
	TerminalUtil,
	UserInfoUtil,
} from "../../utils/checkSession";

interface ISessionAccess {
	disabledAccessTo: AccessObject | null;
}

const initialState: ISessionAccess = {
	disabledAccessTo: null,
};

export const getEnabledStatuses = createAsyncThunk<AccessObject | null>(
	"sessionAccess/getStatus",
	async () => {
		const [branch] = BranchUtil.get();
		const terminal = TerminalUtil.get();
		const user = UserInfoUtil.get();

		const [userStatus, branchStatus, tpaidStatus] = await Promise.all([
			getEnabledStatus(user.uuid, "User"),
			getEnabledStatus(branch.id, "Branch"),
			getEnabledStatus(terminal.id, "TPAID"),
		]);

		if (!userStatus) return "User";
		if (!branchStatus) return "Branch";
		if (!tpaidStatus) return "TPAID";
		return null;
	}
);

const sessionAccess = createSlice({
	name: "sessionAccess",
	initialState,
	reducers: {
		setDisabledAccess: (state, action) => {
			state.disabledAccessTo = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getEnabledStatuses.fulfilled, (state, action) => {
			state.disabledAccessTo = action.payload;
		});
		builder.addCase(getEnabledStatuses.rejected, (state) => {
			state.disabledAccessTo = null;
		});
	},
});

export const { setDisabledAccess } = sessionAccess.actions;
export default sessionAccess.reducer;
