import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import moment from "moment";
import CommonTable from "../../common/CommonTable/CommonTable";
import NumberFormatter from "../../common/Format/NumberFormatter";
import SearchField from "../../Inputs/SearchField";
import styles from "./BranchReport.module.css";
import { get } from "lodash";
import CustomRow from "../../../components/Table/CustomRow";

const renderNumber = (value: any) => <NumberFormatter value={value} />;

const headers = [
	{
		label: () => (
			<>
				<div>DATE AND TIME</div>
				<div>GENERATED</div>
			</>
		),
		identifier: "date_time_generated",
		render: (value: any) => moment(value).format("MM/DD/YYYY hh:mm:ss A"),
		sortable: true,
	},
	{
		label: "COLLECTION DATE",
		render: (value: any) => moment(value).format("MM/DD/YYYY"),
		identifier: "collection_date",
	},
	{
		label: () => (
			<>
				<div>BRANCH TOTAL</div>
				<div>CASH ON HAND</div>
			</>
		),
		identifier: "total_cash",
		render: renderNumber,
	},
	{
		label: () => (
			<>
				<div>BRANCH TOTAL</div>
				<div>CHECK ON HAND</div>
			</>
		),
		identifier: "total_check",
		render: renderNumber,
	},
	{
		label: () => (
			<>
				<div>BRANCH TOTAL</div>
				<div>COLLECTION AMOUNT</div>
			</>
		),
		identifier: "total_collection_amount",
		render: renderNumber,
	},
	{
		label: "FILENAME",
		identifier: "filename",
	},
	{
		label: "ACTION",
		render: (value: any, entry: any) => {
			return (
				<>
					<CustomRow icon={"Print"} row={entry} />
					<CustomRow icon={"Download"} row={entry} />
				</>
			);
		},
	},
];

function BranchReportLogs(props: any) {
	const { filters, reports, onPageChange, onSort, ...others } = props;
	// const { dateFrom, dateTo } = filters;

	return (
		<Grid container spacing={2} direction="column">
			<Grid container alignItems="center" justify="space-between">
				<Grid item className={styles.title}>
					Branch Collection Summary Report Logs
				</Grid>
				{/* <Grid item xs={3}>
					<SearchField label="Search" />
				</Grid> */}
			</Grid>
			{/* <Grid item xs>
                <Formik initialValues={{ dateFrom, dateTo}}></Formik>
            </Grid> */}
			<Grid item xs>
				<CommonTable
					className={""}
					headers={headers}
					entries={get(reports, "data", [])}
					size="medium"
					page={filters.page}
					rowsPerPage={filters.rowsPerPage}
					sort={filters.sort}
					sortBy={filters.sortBy}
					totalEntries={get(reports.metadata, "total", 0)}
					onPageChange={onPageChange}
					onSort={onSort}
				/>
			</Grid>
		</Grid>
	);
}

export default BranchReportLogs;
