import React, { Component, useState } from "react";
import { connect } from "react-redux";
import CustomizedDialogs from "./CustomizedDialogs";

class ReportGenerationModal extends Component {
	constructor(props) {
		super(props);
	}
	closeModal() {}

	onSubmitButtonEnable() {}

	onInputChange(e) {}

	async onSubmit() {}

	render() {
		return (
			<div>
				<CustomizedDialogs {...this.props} />
			</div>
		);
	}
}

export default connect((state) => ({}), {})(ReportGenerationModal);
