import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	reprintRequestBody: {},
	currentReprintNotification: [],
};

const reprint = createSlice({
	name: "reprint",
	initialState,
	reducers: {
		updateReprintRequestBody: (state, { payload }) => {
			state.reprintRequestBody = payload.data;
		},
		updateCurrentReprintNotification: (state, { payload }) => {
			state.currentReprintNotification = payload;
		},
		resetCurrentReprintNotification: (state) => {
			state.currentReprintNotification = [];
		},
	},
});

const {
	updateReprintRequestBody,
	updateCurrentReprintNotification,
	resetCurrentReprintNotification,
} = reprint.actions;

export {
	updateReprintRequestBody,
	updateCurrentReprintNotification,
	resetCurrentReprintNotification,
};

export default reprint.reducer;
