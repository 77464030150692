import { Component } from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import styles from "./Login.module.css";

class TpaSelectModal extends Component {
  render() {
    return (
      <Modal
        open={this.props.show}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={this.props.show}>
          <Card
            style={{
              width: 400,
            }}
            classes={{ root: styles.cardRoot }}
          >
            <CardHeader
              title={this.props.title}
              action={
                <IconButton onClick={this.props.handleOnClose}>
                  <CloseIcon />
                </IconButton>
              }
              classes={{ title: styles.modalTitle, root: styles.modalRoot }}
            />
            <CardContent
              style={{
                textAlign: this.props.center ? "center" : "left",
              }}
            >
              {this.props.content}
            </CardContent>
            <CardActions style={{ borderTop: "0.5px solid #8B9095" }}>
              {this.props.children}
            </CardActions>
          </Card>
        </Fade>
      </Modal>
    );
  }
}

export default TpaSelectModal;
