let timeout = null;

let barcode = {
	startTiming: 0, //first character input timing
	currentTiming: 0, //current character input timing
	data: "", //barcode data that's being read
};

const barcodeRead = (e, allowBarcode, updateBillerTxn, data) => {
	//check if barcode.data is from the barcode scanner
	if (barcode?.data.length >= 9) {
		let value = "";
		if (!allowBarcode) {
			value = e.target.value = ""; //set input field to empty string
		} else {
			value = barcode.data.split("Enter")[0]; //set input field to barcode.data
		}
		e.target.value = value;

		//update biller transaction state to reflect new value
		data.value = value;
		data.key = e.target.id;
		updateBillerTxn(data);
	}
};

/**
 * Function that interprets a barcode scanner
 * @author   Val
 * @param    {KeyboardEvent} e              onKeyDown event
 * @param    {Boolean} allowBarcode         if custom:enable_barcode is present in the field rules
 * @param    {Function} updateBillerTxn     function to update billerTransaction state
 * @param    {Object} data                  includes the transactionKey and biller code to pass to the update biller function
 */
export const barcodeScan = (e, allowBarcode, updateBillerTxn, data) => {
	//exclude non alphanumeric characters
	if (!/^[a-z0-9]$/i.test(e.key.toLowerCase())) {
		return;
	}

	if (barcode.data == "" || e.timeStamp - barcode.currentTiming < 50) {
		if (barcode.startTiming == 0) {
			barcode.startTiming = e.timeStamp; //set startTiming to be the timeStamp of first input
		}

		//append key(string) to barcode.data
		barcode.data += e.key;
		//set currentTiming to event timestamp
		barcode.currentTiming = e.timeStamp;

		//clear existing timeouts
		clearTimeout(timeout);

		//set timeout 30ms (this timeout refreshes for every keypress)
		timeout = setTimeout(
			() => barcodeRead(e, allowBarcode, updateBillerTxn, data),
			50
		);
	} else {
		//if new input, clear barcode json
		barcode = {
			startTiming: 0,
			currentTiming: e.timeStamp,
			data: e.key,
		};
	}
};
