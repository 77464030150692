import React, {Component} from "react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import styles from "./ContactUs.module.css"
import {Grid} from "@material-ui/core";
import mailIcon from "../../assets/icons/ic-email.svg"

class ContactUs extends Component {

    render() {
        return(
            <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginTop: '25px',
                    marginLeft: '35px'
                }}>
                    <div onClick={this.props.onBackClick} style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        cursor: "pointer"
                    }}>
                        <ArrowBackIosIcon style={{fontSize: "0.70rem"}} />
                        <div className={styles.backBtn}>Back</div>
                    </div>
                </div>
                <Grid container justify="center" direction="column">
                    <Grid item>
                        <div style={{textAlign: "center", marginTop:"80px"}}>
                            <img src={mailIcon} alt="Mail Icon"/>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{textAlign: "center", marginTop:"30px"}} className={styles.contactTitle}>
                            Contact Us
                        </div>
                    </Grid>
                    <Grid item>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={6} alignItems="center" justify="center">
                                <div className={styles.contactText} style={{textAlign: "center", marginTop:"24px"}}>
                                    For inquiries and concerns, you may contact us at
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container justify="center" alignItems="center" style={{marginTop:"45px"}}>
                            <Grid item>
                                <div className={styles.contactText}>
                                    Email: itservicedesk@bayad.com
                                </div>
                                <div className={styles.contactText} style={{marginTop:"20px", marginBottom:"20px"}}>
                                    IT Service Desk Hotline: +632 8672-5752
                                </div>
                                <div className={styles.contactText}>
                                    Mobile Number: +639190819865
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default ContactUs;