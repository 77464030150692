import React, { useEffect, useState } from "react";
import approve from "../../assets/icons/ic-approve.svg";
import decline from "../../assets/icons/ic-decline.svg";
import person from "../../assets/icons/ic-person.svg";
import print from "../../assets/icons/ic-print.svg";
import {
	Divider,
	Grid,
	Typography,
	Popover,
	Avatar,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Box,
	Dialog,
	Button,
	Badge,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import {
	APPROVE_LOGIN_REQUEST,
	DECLINE_LOGIN_REQUEST,
} from "../../graphql/queries";
import moment from "moment";
import styles from "./Notifications.module.css";
import close from "../../assets/icons/ic-failed.svg";
import cx from "classnames";
import HTTP from "../../helpers/ApiClient";

const ErrorModal = ({ errorMessage, setErrorMessage }) => {
	return (
		<Dialog
			disableBackdropClick
			open={true}
			classes={{ paper: styles.dialogDeclined }}
		>
			<Box className={styles.contentError}>
				<img src={close} alt="close" />
				<Typography>
					<h1 className={styles.headerError}>Invalid Request</h1>
				</Typography>
				<Typography className={styles.contentText}>
					{errorMessage || "This module has encountered an error."}
				</Typography>
				<Button
					variant="outlined"
					className={styles.buttonOkay}
					label="back"
					onClick={() => setErrorMessage(null)}
					disabled={false}
				>
					Back
				</Button>
			</Box>
		</Dialog>
	);
};

export const capitalize = (s) =>
	s && `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}`;

export const PillStatus = ({ status = "", ...rest }) => {
	const optionStatus = [
		{
			colorClass: "pill_approved",
			name: "APPROVED",
		},
		{
			colorClass: "pill_declined",
			name: "DECLINED",
		},
		{
			colorClass: "pill_invalid",
			name: "INVALID",
		},
		{
			colorClass: "pill_cancelled",
			name: "CANCELLED",
		},
	];

	const selected = optionStatus.find(
		(option) => option.name.toLowerCase() === status.toLowerCase()
	) || { name: status, colorClass: optionStatus[3].colorClass };

	return (
		<span
			className={cx(styles.pill, styles[selected.colorClass])}
			{...rest}
		>
			{capitalize(selected.name)}
		</span>
	);
};

export function NotificationPopoverTeller({ anchorEl, handleClose }) {
	return (
		<Popover
			id="notification-popover"
			open={!!anchorEl}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<div>
				<Grid container alignItems="center">
					<Grid item xs>
						<span className={styles.header}>Notifications</span>
					</Grid>
				</Grid>
			</div>

			<Divider />

			<List
				sx={{
					width: "100%",
					maxWidth: 360,
					bgcolor: "background.paper",
				}}
				style={{ maxHeight: "500px" }}
			>
				<span style={{ margin: "30px" }}>No new notifications</span>
			</List>
		</Popover>
	);
}

export function NotificationPopoverSupervisor({
	anchorEl,
	handleClose,
	requestData,
	refetch,
	subData,
	subDataError,
}) {
	const [notifications, setNotifications] = useState([]);

	const [approveRequest, { data: approvedUser, error: approvedError }] =
		useMutation(APPROVE_LOGIN_REQUEST);
	const [declineRequest, { data: declineUser, error: declineError }] =
		useMutation(DECLINE_LOGIN_REQUEST);
	const [errorMessage, setErrorMessage] = useState(null);

	const handleApprove = async (v) => {
		let error;
		try {
			await approveRequest({
				variables: v,
			});
			refetch();
		} catch (e) {
			error = e;
			setErrorMessage(e && e.message);
		} finally {
			refetch();
		}
		console.log("Approved Data: ", approvedUser, approvedError, {
			...error,
		});
	};

	const handleDecline = async (v) => {
		let error;
		try {
			await declineRequest({
				variables: v,
			});
			refetch();
		} catch (e) {
			error = e;
			setErrorMessage(e && e.message);
		} finally {
			refetch();
		}
		console.log("Declined Data: ", declineUser, declineError, error);
	};

	useEffect(() => {
		console.log(requestData, "REQ DATA");
		if (requestData) {
			setNotifications(requestData.getAppNotifList.data);
		}
	}, [requestData]);

	useEffect(() => {
		console.log(subData, "SUB DATA");
		if (subData) {
			if (subData.onCreateBranchAppNotif.login_status !== "CANCELLED")
				refetch();
		}
	}, [subData]);

	useEffect(() => {
		const checkError = {
			errorType: "BFATellerLoginRequestException",
			message: "The teller cancelled this request.",
		};

		if (approvedError) {
			if (
				approvedError.graphQLErrors[0].errorType ===
					checkError.errorType &&
				approvedError.graphQLErrors[0].message === checkError.message
			)
				refetch();
		} else if (declineError) {
			if (
				declineError.graphQLErrors[0].errorType ===
					checkError.errorType &&
				declineError.graphQLErrors[0].message === checkError.message
			)
				refetch();
		}
	}, [approvedError, declineError]);

	console.log(subDataError, "sub data error");

	const approveReprint = (txnNo, type) => {
		const endpoint = type === 'REPRINT_CHECK_REQUEST'
			? `v3/receipts/check/reprint/${txnNo}/approve`
			: `v2/receipts/reprint/${txnNo}/approve`
		HTTP.post(endpoint).then((data) => {
			console.log(data, "success");
		});
	};

	const declineReprint = (txnNo, type) => {
		const endpoint = type === 'REPRINT_CHECK_REQUEST'
			? `v3/receipts/check/reprint/${txnNo}/decline`
			: `v2/receipts/reprint/${txnNo}/decline`
		HTTP.post(endpoint).then((data) => {
			console.log(data, "decline");
		});
	};

	const LoginApprovalItem = ({ data }) => (
		<>
			<ListItemAvatar style={{ minWidth: 35, height: 24 }}>
				<Avatar
					style={{
						width: 24,
						height: 24,
					}}
					alt="logo"
					src={person}
				/>
			</ListItemAvatar>
			<ListItemText
				primary={
					<div style={{ display: "inline-flex" }}>
						<div className={styles.bodyText}>
							Log-in request from:
						</div>
						{data.notif_state !== "PENDING" && (
							<div className={styles.statusDetails}>
								<PillStatus
									status={capitalize(data.notif_state)}
								/>
							</div>
						)}
					</div>
				}
				secondary={
					<Box className={styles.listBody}>
						<Box>
							<span className={styles.content}>
								{data.teller_name +
									", " +
									data.branch_name +
									", " +
									data.tpa_id}
							</span>
							<small className={styles.datetime}>
								{moment(data.created_at * 1000).format(
									"MM/DD/YYYY, h:mm A"
								)}
							</small>
						</Box>

						<Box className={styles.actionsContainer}>
							<Box className={styles.actionsBody}>
								{data.notif_state === "PENDING" && (
									<>
										<img
											src={approve}
											alt="Approve"
											className={styles.action}
											onClick={() =>
												handleApprove({
													uuid: data.user_id,
													branchId: data.branch_id,
													id: data.id,
													tpaId: data.tpa_id,
												})
											}
										/>
										<img
											src={decline}
											alt="Decline"
											className={styles.action}
											onClick={() =>
												handleDecline({
													uuid: data.user_id,
													branchId: data.branch_id,
													id: data.id,
													tpaId: data.tpa_id,
												})
											}
										/>
									</>
								)}
							</Box>
						</Box>
					</Box>
				}
			/>
		</>
	);

	const ReprintRequestItem = ({ data, title }) => (
		<>
			<ListItemAvatar style={{ minWidth: 35, height: 24 }}>
				<Avatar
					style={{
						width: 15,
						height: 15,
						marginLeft: 3,
					}}
					alt="logo"
					src={print}
				/>
			</ListItemAvatar>
			<ListItemText
				primary={
					<div style={{ display: "inline-flex" }}>
						<div className={styles.bodyText}>{title}</div>
						{data.notif_state !== "PENDING" && (
							<div className={styles.statusDetailsReprint}>
								<PillStatus
									status={capitalize(data.notif_state)}
								/>
							</div>
						)}
					</div>
				}
				secondary={
					<Box className={styles.listBody}>
						<Box>
							<span className={styles.content}>
								{data.teller_name +
									", " +
									data.branch_name +
									", " +
									data.tpa_id}
							</span>
							<small className={styles.datetime}>
								{moment(data.created_at * 1000).format(
									"MM/DD/YYYY, h:mm A"
								)}
							</small>
						</Box>

						<Box className={styles.actionsContainer}>
							<Box className={styles.actionsBody}>
								{data.notif_state === "PENDING" && (
									<>
										<img
											src={approve}
											alt="Approve"
											className={styles.action}
											onClick={() =>
												approveReprint(data.txn_no, data.type)
											}
										/>
										<img
											src={decline}
											alt="Decline"
											className={styles.action}
											onClick={() =>
												declineReprint(data.txn_no, data.type)
											}
										/>
									</>
								)}
							</Box>
						</Box>
					</Box>
				}
			/>
		</>
	);

	return (
		<>
			{errorMessage && (
				<ErrorModal
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			)}

			<Popover
				id="notification-popover"
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<div>
					<Grid container alignItems="center">
						<Grid item xs>
							<span className={styles.header}>Notifications</span>
						</Grid>
					</Grid>
				</div>

				<Divider />

				<List
					sx={{
						width: "100%",
						maxWidth: 360,
						bgcolor: "background.paper",
					}}
					style={{ maxHeight: "500px", paddingTop: 0 }}
				>
					{notifications.length ? (
						notifications.map((data, i) => {
							const notifData = {
								...data,
								...JSON.parse(data.data),
							};
							return (
								<ListItem
									style={{
										paddingTop: "7px",
										paddingBottom: "8px",
										background:
											i % 2 === 0 ? "#F3FAFF" : "#FFF",
									}}
									key={data.id}
									alignItems="flex-start"
								>
									{data.type === "LOGIN_REQUEST" && (
										<LoginApprovalItem data={notifData} />
									)}

									{data.type === "REPRINT_REQUEST" && (
										<ReprintRequestItem data={notifData} title={'Reprint Receipt'} />
									)}

									{data.type === "REPRINT_CHECK_REQUEST" && (
										<ReprintRequestItem data={notifData} title={'Reprint Check'} />
									)}
								</ListItem>
							);
						})
					) : (
						<span style={{ margin: "30px" }}>
							No new notifications
						</span>
					)}
				</List>
			</Popover>
		</>
	);
}

export default NotificationPopoverTeller;
