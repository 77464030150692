import React, { useState, useEffect, useCallback, useRef } from "react";
import {
	Box,
	Grid,
	Paper,
	withStyles,
	Tooltip,
	IconButton,
	OutlinedInput,
	InputAdornment,
	Button,
	Typography,
	CircularProgress,
	Slide,
} from "@material-ui/core";
import { IoMdPaper, IoIosRemoveCircle } from "react-icons/io";
import { debounce } from "lodash";
import UserFilter from "./UserFilter/UserFilter";
import styles from "./UserManagement.module.css";
import UserTable from "./UserTable/UserTable";
import HTTP from "../../helpers/ApiClient";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useSubscription } from "@apollo/client";
import PropTypes from "prop-types";
import FullPageLoader from "../common/FullPageLoader/FullPageLoader";
import { usePagination, useToggle } from "../../utils/hooks";
import SearchField from "../Inputs/SearchField";
import { Tabs, TabPanel } from "../Tab/Tab";
import { connect } from "react-redux";
import { UserInfoUtil, BranchUtil, getScopes } from "../../utils/checkSession";
import {
	GET_LOGIN_REQUEST_BRANCH,
	ON_UPDATE_BRANCH_ACCESS_SCHEDULES,
	ON_UPDATE_NOTIFICATION,
} from "../../graphql/queries";
import { BranchAccessControlSwitch as Switch } from "../Switch/Switch";
import { batch } from "react-redux";
import {
	WatchLater,
	AccessTime,
	VisibilityOff,
	Visibility,
} from "@material-ui/icons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment";
import TPAIDTable, { getTerminals } from "./TPAIDTable";
import BranchAccessControlModal from "./BranchAccessControlModal/BranchAccessControlModal.tsx";
import { Snackbar } from "@mui/material";
import { Alert } from "@material-ui/lab";
import ReasonDetailModal from "../common/ReasonDetailModal/ReasonDetailModal";
import { ErrorModal } from "../Modal/ErrorModal";
import ConfirmationModal from "./ConfirmationModal";
import {
	setBranchToDisable,
	setBranchTerminalStatus,
	setBranchTerminals,
	setInitialStateBranchAccessControl,
	STATUS as BranchAccessControlStatus,
	setBranchStatus,
} from "../../redux/modules/branchAccessControl";
import { requestWrapper } from "../../utils/requestWrapper";
import SuccessModal from "./SuccessModal";

const resolveValue = (value) => value || undefined;
const BranchActionsTooltip = withStyles({
	tooltip: {
		backgroundColor: "#F1F1F1",
		color: "#3B3B3B",
		fontSize: "12px",
		fontFamily: "Poppins-Regular",
		padding: "8px",
	},
})(Tooltip);

const ActionBtnTooltip = ({ children, title, placement }) => (
	<Tooltip title={title} placement={placement}>
		{children}
	</Tooltip>
);

function isTruthy(value) {
	return !!value && !!value.trim();
}

export const STATUS = [
	{
		code: 0,
		label: "Active",
		className: styles.statusActive,
	},
	{
		code: 1,
		label: "Inactive",
		className: styles.statusInactive,
	},
];

export const STATUS_BRANCH_ACCESS_CONTROL = [
	{
		code: 0,
		label: "Active",
		value: "active",
		className: styles.statusActive,
	},
	{
		code: 1,
		label: "Active (No Schedule)",
		value: "active_no_sched",
		className: styles.statusActiveNoSched,
	},
	{
		code: 2,
		label: "Closed",
		value: "closed",
		className: styles.statusInactive,
	},
];

const STATUS_REQUESTS = [
	{
		code: 1,
		label: "Cancelled",
		className: styles.statusCancelled,
	},
	{
		code: 2,
		label: "Invalid",
		className: styles.statusInvalid,
	},
	{
		code: 3,
		label: "Approved",
		className: styles.statusApproved,
	},
	{
		code: 4,
		label: "Declined",
		className: styles.statusDeclined,
	},
];

const UAM_STATUS = [
	{
		code: 1,
		label: "Active",
		className: styles.statusActive,
	},
	{
		code: 2,
		label: "Inactive",
		className: styles.statusInactive,
	},
	{
		code: 3,
		label: "Blocked",
		className: styles.statusBlocked,
	},
	{
		code: 4,
		label: "Temporary Blocked",
		value: "temporary_blocked",
		className: styles.statusTemporaryBlocked,
	},
];

const NotAvailable = () => <>--</>;

export const UserListStatus = ({ status, list }) => {
	if (!isTruthy(status)) return <NotAvailable />;
	const match = list.find(
		({ label, value }) =>
			(status ?? "").toLowerCase() ===
			(value ?? label ?? "").toLowerCase()
	);

	if (!match) return <NotAvailable />;
	const { label, className } = match;
	return <div className={className}>{label}</div>;
};

const STATUS_FILTERS = [
	{ name: "Active", value: "active" },
	{ name: "Inactive", value: "inactive" },
];

const UAM_STATUS_FILTERS = [
	{ name: "Active", value: "active" },
	{ name: "Inactive", value: "inactive" },
	{ name: "Blocked", value: "blocked" },
	{ name: "Temporary Blocked", value: "temporary_blocked" },
];

const STATUS_BRANCH_ACCESS_CONTROL_FILTERS = [
	{ name: "Active", value: "active" },
	{ name: "Active (No Schedule)", value: "active_no_sched" },
	{ name: "Closed", value: "closed" },
];

const STATUS_REQUEST_FILTERS = [
	{ name: "Cancelled", value: "CANCELLED" },
	{ name: "Invalid", value: "INVALID" },
	{ name: "Approved", value: "APPROVED" },
	{ name: "Declined", value: "DECLINED" },
];

const request_headers = (tab) => {
	const headers = [
		{
			label: () => (
				<>
					<div>Date and Time</div>
				</>
			),
			identifier: "created_at",
			render: (value) =>
				moment(value * 1000).format("MM/DD/YYYY hh:mm:ss A"),
			sortable: true,
		},
		{
			label: "Teller Name",
			identifier: "user",
			render: (_, row) => row.user.first_name + " " + row.user.last_name,
		},
		{
			label: "Username",
			identifier: "username",
			sortable: true,
			render: (_, row) => row.user.username,
		},
		{
			label: "Branch",
			identifier: "branch",
			sortable: true,
			render: (_, row) => row.branch.branch_name,
		},
		{
			label: "TPA ID",
			identifier: "terminal",
			render: (_, row) => row.terminal.tpa_id,
		},
		{
			label: "Status",
			identifier: "login_status",
			render: (value) => (
				<UserListStatus status={value} list={STATUS_REQUESTS} />
			),
		},
	];
	if (tab == 1) {
		headers.pop();
	}

	return headers;
};

export const BranchActions = ({
	status,
	isOperating,
	hasSchedule,
	terminals,
	handleOpen,
	row,
	isTerminal = false,
	showBranchDisableModal = () => {},
}) => {
	const Icon = ({ onClick }) => {
		if (status === BranchAccessControlStatus.ACTIVE) {
			return (
				<BranchActionsTooltip title="Scheduled">
					<WatchLater
						className={styles.hover}
						onClick={onClick}
						style={{ color: "#1F76C0" }}
					/>
				</BranchActionsTooltip>
			);
		} else if (status === BranchAccessControlStatus.CLOSED) {
			return (
				<BranchActionsTooltip title="Scheduled">
					<WatchLater
						className={styles.hover}
						onClick={onClick}
						style={{ color: "#4A5051" }}
					/>
				</BranchActionsTooltip>
			);
		} else {
			return (
				<BranchActionsTooltip title="No Schedule">
					<AccessTime style={{ color: "#4A5051" }} />
				</BranchActionsTooltip>
			);
		}
	};

	const handleChange = (event) => {
		handleOpen(row);
	};

	const handleToggle = () => {
		if (row.status !== BranchAccessControlStatus.ACTIVE_NO_SCHED) {
			showBranchDisableModal(row);
			return;
		}
		handleOpen(row);
	};

	return (
		<div className={styles.branchAction}>
			<Switch
				onClick={handleToggle}
				checked={status !== BranchAccessControlStatus.ACTIVE_NO_SCHED}
			/>
			<Icon onClick={handleChange} />
			{!isTerminal && (
				<span className={styles.tpaidText}>
					{terminals.enabled}/{terminals.total} TPAID enabled
				</span>
			)}
		</div>
	);
};

const branchListHeaders = [
	{
		label: "Branch",
		identifier: "name",
		width: "10%",
	},
	{
		label: "",
		identifier: "",
		width: "40%",
	},
	{
		label: "Status",
		identifier: "status",
		width: "25%",
		render: (value) => (
			<UserListStatus
				status={value}
				list={STATUS_BRANCH_ACCESS_CONTROL}
			/>
		),
	},
	{
		label: "Actions",
		identifier: "status",
		render: (
			value,
			row,
			{ handleOpenBranchModal, showConfirmationModal }
		) => (
			<BranchActions
				status={value}
				row={{
					branchId: row.id,
					branchName: row.name,
					tpaid: null,
					tpaidName: null,
					status: row.status,
					isOperating: row.is_operating,
				}}
				showBranchDisableModal={showConfirmationModal}
				handleOpen={handleOpenBranchModal}
				isOperating={row.is_operating}
				hasSchedule={row.has_schedule}
				terminals={{
					enabled: row.enabled_terminals,
					total: row.total_terminals,
				}}
			/>
		),
	},
];

const resolveParam = (p) => p || undefined;

async function getUsers(filters) {
	const {
		tpaid,
		dateFrom,
		dateTo,
		status,
		tellerName,
		username,
		branch,
		limit,
		page = 1,
		search,
		sort,
		sortBy,
		uamStatus,
	} = filters;
	const format = (date) => {
		if (!date) return undefined;
		return date.format("yyyy/MM/DD");
	};

	try {
		const getDateRange = () => {
			if ([dateFrom, dateTo].some((d) => !d)) return undefined;
			return `${format(dateFrom)} 00:00:00-${format(dateTo)} 23:59:00`;
		};

		const params = {
			name: resolveParam(tellerName),
			username: resolveParam(username),
			branch_name: resolveParam(branch),
			status: resolveParam(status),
			dateFromAndTo: getDateRange(),
			page,
			limit,
			search: resolveParam(search),
			sortBy: resolveParam(sortBy),
			sort: resolveParam(sort),
			uamStatus: resolveParam(uamStatus),
		};

		const result = await HTTP.get("/v3/users", {
			params,
		});

		if (!result || result.status != 200) return null;

		const { data } = result;
		const { totalCount, items } = data;

		return {
			totalCount,
			items,
		};
	} catch (error) {
		console.error(error);
		return null;
	}
}

async function getUsersData() {
	try {
		const [tellers, users, branches] = await Promise.all([
			HTTP.get("/v3/dropdowns/tellers"),
			HTTP.get("/v3/dropdowns/usernames"),
			HTTP.get("/v3/dropdowns/branches"),
		]);

		return {
			tellerData: tellers.data?.data,
			userData: users.data?.data,
			branchData: branches.data?.data,
		};
	} catch (err) {
		console.error(err);
		return err;
	}
}

async function getBranchesDropdown(userHash) {
	try {
		const { data } = await HTTP.get("/v2/branch-access/branches", {
			params: { userHash },
		});
		return data;
	} catch (error) {
		console.log(error);
	}
}

async function getBranches(filters, userHash) {
	try {
		const { branch, status, tpa, sortBy, sort, page, limit } = filters;
		const params = {
			branchId: resolveParam(branch),
			status: resolveParam(status),
			page,
			limit,
			tpa: resolveParam(tpa),
			sortBy: resolveParam(sortBy),
			sort: resolveParam(sort),
			userHash,
		};
		const result = await HTTP.get("/v3/branch-access", { params });

		const {
			data: {
				data: items,
				metadata: { total: totalCount },
			},
		} = result;

		return {
			totalCount,
			items,
		};
	} catch (error) {
		console.log(error);
		return {
			totalCount: 0,
			items: [],
		};
	}
}

const ListContainer = withStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
}))(Paper);

const TableContainer = ({
	statusOptions,
	uamStatusOptions,
	className,
	headers,
	title,
	page,
	rowsPerPage,
	data,
	onPageChange,
	searchTitle,
	onSort,
	filters,
	defaultFilters,
	onFilterSubmit,
	onFilterChange,
	isReloadLocked,
	tellerNamesData,
	userNamesData,
	branchesData,
	resetFormOnTab,
	expandRow,
	isHidePageNumber,
	hasActivityLogPermission,
	handlers,
	isFilterValueEmpty = true,
	isUamStatusFiltered = false,
}) => {
	const handleSearchChange = debounce((event) => {
		const {
			target: { value: search },
		} = event;
		onFilterChange({ ...filters, search });
		if (search.length >= 3 || (search === "" && filters.search !== "")) {
			onFilterSubmit({ username: search });
		}
	}, 500);

	return (
		<>
			<Grid container spacing={2} direction="column">
				<Grid
					container
					item
					justify="space-between"
					alignItems="center"
					style={{ marginTop: "12px" }}
				>
					<Grid item>
						<span className={styles.header}>{title}</span>
					</Grid>
					<Grid item xs={3}>
						<div className={styles.searchbar}>
							<SearchField
								label={searchTitle}
								onChange={handleSearchChange}
								size="small"
							/>
						</div>
					</Grid>
				</Grid>
				<Grid item xs>
					<UserFilter
						resetFormOnTab={resetFormOnTab}
						statusOptions={statusOptions}
						title={title}
						values={filters}
						data={data}
						initialValues={defaultFilters}
						onSubmit={onFilterSubmit}
						onChange={onFilterChange}
						tellerNames={tellerNamesData}
						userNames={userNamesData}
						branches={branchesData}
						uamStatusOptions={uamStatusOptions}
						isFilterValueEmpty={isFilterValueEmpty}
						isUamStatusFiltered={isUamStatusFiltered}
					/>
				</Grid>
				<Grid item xs>
					<UserTable
						className={className}
						page={page}
						rowsPerPage={rowsPerPage}
						data={data}
						headers={headers}
						onPageChange={onPageChange}
						onSort={onSort}
						sortBy={filters.sortBy}
						sort={filters.sort}
						expandRow={expandRow}
						handlers={handlers}
						isHidePageNumber={isHidePageNumber}
						hasActivityLogPermission={hasActivityLogPermission}
					/>
				</Grid>
			</Grid>
		</>
	);
};

TableContainer.propTypes = {
	headers: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	data: PropTypes.arrayOf(PropTypes.object),
	onPageChange: PropTypes.func,
	onSort: PropTypes.func,
	filters: PropTypes.object,
	defaultFilters: PropTypes.object,
	onFilterSubmit: PropTypes.func,
	onFilterChange: PropTypes.func,
	isReloadLocked: PropTypes.bool,
	resetFormOnTab: PropTypes.object,
	isHidePageNumber: PropTypes.bool,
};

const UserManagement = ({
	isReloadLocked,
	branchAccessControl: branchAccessControlList,
}) => {
	var isSupervisor = false;

	const roles = UserInfoUtil.get().roles;
	const userHash = UserInfoUtil.get().uuid;
	var mappedRole = roles.filter((e) => {
		return e.name === "Super Admin" || e.name === "Supervisor";
	});
	if (mappedRole.length > 0) {
		isSupervisor = true;
	}

	const dispatch = useDispatch();
	const [selectedBranch, setSelectedBranch] = useState({});
	const [confirmationModalInfo, setConfirmationModalInfo] = useState({
		isOpen: false,
		title: "",
		content: "",
		contentHint: "",
	});
	const [successModalInfo, setSuccessModalInfo] = useState({
		title: "",
		message: "",
		isOpen: false,
		fullWidth: false,
	});
	const [currentTab, setCurrentTab] = useState("1");
	const { page, setPage, rowsPerPage, setRowsPerPage } = usePagination(25);
	const {
		page: branchPage,
		setPage: setBranchPage,
		rowsPerPage: rowsPerBranchPage,
		setRowsPerPage: setRowsPerBranchPage,
	} = usePagination(25);
	const {
		page: requestPage,
		setPage: setRequestPage,
		rowsPerPage: rowsPerRequestPage,
		setRowsPerPage: setRowsPerRequestPage,
	} = usePagination(25);

	const httpRetry = useRef();

	const [users, setUsers] = useState({
		totalCount: 0,
		items: [],
	});
	const [tellerNames, setTellerNames] = useState([]);
	const [userNames, setUserNames] = useState([]);
	const [branches, setBranches] = useState([]);
	const [branches1, setBranches1] = useState([]);
	const terminalId = useSelector((state) => state.userInfo.terminal.name);
	const [tabs, setTabs] = useState([
		// {
		// 	label: "Pending Request for Approval",
		// 	value: "1",
		// },
		// {
		// 	label: "History of Teller Requests",
		// 	value: "2",
		// },
		{
			label: "Reprint Receipt Approval",
			value: "3",
		},
		{
			label: "User List",
			value: "4",
		},
	]);
	const defaultFilters = {
		tpaid: terminalId,
		dateFrom: null,
		dateTo: null,
		status: "",
		tellerName: "",
		username: "",
		branch: "",
		sortBy: "username",
		sort: "asc",
		uamStatus: "",
	};
	const defaultBranchListFilter = {
		branch: "",
		status: "",
		sortBy: "name",
		sort: "asc",
	};
	const defaultTokens = {
		current_key_token: "",
		next_key_token: "",
	};
	const defaultPrevTokens = {
		1: [{ page: 1, prev_token: null }],
		2: [{ page: 1, prev_token: null }],
	};
	const [permissions, setPermissions] = useState({
		view_pending_request: {
			scope: "bfa.login.request.pending",
			hasPermission: false,
		},
		view_history: {
			scope: "bfa.login.request.history",
			hasPermission: false,
		},
		view_activity_logs: {
			scope: "bfa.user.logs",
			hasPermission: false,
		},
		view_user_list: {
			scope: "bfa.user.list",
			hasPermission: false,
		},
		view_branch_access_control_list: {
			scope: "bfa.branch.access.list",
			hasPermission: false,
		},
		view_reprint_code: {
			scope: "bfa.branch.get.reprint.code",
			hasPermission: false,
		},
	});
	const [branch] = BranchUtil.get();
	const [filters, setFilters] = useState(defaultFilters);
	const [branchListFilter, setBranchListFilter] = useState(
		defaultBranchListFilter
	);
	const [requestFilters, setRequestFilters] = useState(defaultFilters);
	const [tokens, setTokens] = useState(defaultTokens);
	const [prevTokens, setPrevTokens] = useState(defaultPrevTokens);
	const [requests, setRequests] = useState({
		totalCount: 0,
		items: [],
	});

	const variables = {
		branch_id: branch.id,
		filter: {
			date_from: resolveValue(
				requestFilters.dateFrom
					? moment(requestFilters.dateFrom).format("YYYY/MM/DD") +
							" 00:00:00"
					: undefined
			),
			date_to: resolveValue(
				requestFilters.dateTo
					? moment(requestFilters.dateTo).format("YYYY/MM/DD") +
							" 23:59:00"
					: undefined
			),
			limit: rowsPerRequestPage,
			name: resolveValue(requestFilters.tellerName),
			status: resolveValue(
				currentTab == "1" ? "PENDING" : requestFilters.status
			),
			inactive_only:
				currentTab == "1"
					? undefined
					: !isTruthy(requestFilters.status),
			username: resolveValue(requestFilters.username),
		},
	};

	const {
		data: requestData,
		loading,
		error,
		refetch,
		variables: dataVar,
	} = useQuery(GET_LOGIN_REQUEST_BRANCH, {
		variables,
		nextFetchPolicy: "network-only",
	});

	//to check if branch access is displayed in table for graphql subscription
	const branchAccessTableAvailability = useRef({});

	useEffect(() => {
		if (loading) {
			showLoader();
		} else {
			hideLoader();
		}
	}, [loading]);

	const { data: subData, error: subDataError } = useSubscription(
		ON_UPDATE_NOTIFICATION,
		{
			variables: { branch_id: branch.id },
			context: { clientName: "reprint" },
		}
	);

	//for graphql subscription branch access
	const { data: branchAccessData, error: branchAccessError } =
		useSubscription(ON_UPDATE_BRANCH_ACCESS_SCHEDULES, {
			variables: { user_id: userHash },
			context: { clientName: "reprint" },
		});

	useEffect(() => {
		console.log("branchAcessData error:", branchAccessError);
		try {
			if (
				!branchAccessData?.onUpdateBranchAccessSchedules ||
				branchAccessData?.onUpdateBranchAccessSchedules?.branches
					?.length <= 0
			) {
				return;
			}
			batch(() => {
				for (let item of branchAccessData?.onUpdateBranchAccessSchedules
					?.branches || []) {
					if (!branchAccessTableAvailability.current[item.id]) {
						//skip if branch is not displayed
						continue;
					}
					dispatch(
						setBranchStatus({
							branchID: item.id,
							enabledTerminals: item.enabled_terminals,
							totalTerminals: item.total_terminals,
							status: item.status,
							is_operating: item.is_operating,
						})
					);
					//only run loop if tpaid is expanded on a certain branch
					if (
						branchAccessTableAvailability.current[item.id]?.expanded
					) {
						for (let terminal of item?.terminals || []) {
							dispatch(
								setBranchTerminalStatus({
									branchID: item.id,
									tpaID: terminal.id,
									status: terminal.status,
									is_operating: terminal.is_operating,
									disableUpdateEnabledTerminalCount: true,
								})
							);
						}
					}
				}
			});
		} catch (e) {
			console.error(e);
		}
	}, [branchAccessData]);

	useEffect(() => {
		if (subData) {
			if (requestPage == 1) {
				refetch(variables);
			}
		}
	}, [subData]);

	useEffect(() => {
		if (requestData) {
			hideLoader();
			const { getLoginRequestsByBranch } = requestData;
			const {
				data,
				query_count: totalCount,
				prev_key_token,
				next_key_token,
			} = getLoginRequestsByBranch || { data: [], ...defaultTokens };

			setRequests({
				totalCount,
				items: data,
			});
			setTokens((_data) => ({
				prev_key_token,
				current_key_token: dataVar.key_token,
				next_key_token,
			}));
		}
	}, [requestData]);

	useEffect(() => {
		if (error) {
			hideLoader();
		}
	}, [error]);

	const [isBranchModalOpen, showBranchModal, hideBranchModal] = useToggle();
	const [isInitialized, initialize] = useToggle();
	const [isTimeoutError, showTimeoutError, hideTimeoutError] = useToggle();
	const [isLoading, showLoader, hideLoader] = useToggle();
	const [code, setCode] = useState(null);
	const [isCodeGenerating, setIsCodeGenerating] = useState(null);
	const [showPasscode, setShowPasscode] = useState(false);

	const submitFilters = useCallback(
		async (values = filters, page = 1, limit = rowsPerPage) => {
			showLoader();
			try {
				if (permissions.view_user_list.hasPermission) {
					const result = await getUsers({
						...values,
						tpaid: terminalId,
						limit,
						page,
					});
					setUsers(result);
				}
			} catch (error) {
				showTimeoutError();
			}

			try {
				const { tellerData, userData, branchData } =
					await getUsersData();

				setTellerNames(tellerData);
				setUserNames(userData);
				setBranches(branchData);
			} catch (error) {
				showTimeoutError();
			}

			hideLoader();
		},
		[
			permissions,
			filters,
			hideLoader,
			rowsPerPage,
			showLoader,
			showTimeoutError,
			terminalId,
		]
	);

	const handleSort = (sortBy, sort, isRefetchQuery = false) => {
		const updatedFilters = { ...filters, sortBy, sort };
		if (isRefetchQuery) {
			setRequestFilters(updatedFilters);
		} else {
			setFilters(updatedFilters);
			submitFilters(updatedFilters, page, rowsPerPage);
		}
	};

	useEffect(() => {
		if (
			!isInitialized &&
			Object.values(permissions).some((e) => e.hasPermission)
		) {
			submitFilters();
			initialize();
		}
	}, [submitFilters, isInitialized, initialize, permissions]);

	const isDateFromAndToValid = (values) =>
		(values.dateFrom && values.dateTo) ||
		(!values.dateFrom && !values.dateTo);

	const handleFilterChange = (values, isRefetchQuery) => {
		if (isRefetchQuery) {
			if (isDateFromAndToValid(values)) {
				setRequestFilters(values);
			}
		} else {
			setFilters(values);
		}
	};

	const handleFilterSubmit = async (values = {}, isRefetchQuery = false) => {
		if (isRefetchQuery) {
			setRequestPage(1);
			setPrevTokens(defaultPrevTokens);
			if (isDateFromAndToValid(values)) {
				setRequestFilters((data) => ({
					...data,
					username: resolveValue(values.username),
				}));
			}
		} else {
			setPage(1);
			await submitFilters(values, 1, rowsPerPage);
		}
	};

	const handlePageChange = (_page, limit, isRefetchQuery = false) => {
		if (isRefetchQuery) {
			const isNextPage = _page > requestPage;
			const isLimitOnlyChanged = rowsPerRequestPage != limit;

			if (!isLimitOnlyChanged) {
				setRequestPage(isNextPage ? requestPage + 1 : requestPage - 1);
				setPrevTokens((_data) => {
					const _prevTokens = { ..._data };
					const _tokens = !_prevTokens[currentTab].find(
						(e) => e.page == _page
					)
						? _prevTokens[currentTab].concat({
								page: _page,
								prev_token: tokens.current_key_token,
						  })
						: _prevTokens[currentTab];

					_prevTokens[currentTab] = _tokens;
					return _prevTokens;
				});
			} else {
				setRequestPage(1);
				setPrevTokens(defaultPrevTokens);
			}

			setRowsPerRequestPage(limit);
			const key_token = !isLimitOnlyChanged
				? isNextPage
					? tokens.next_key_token
					: prevTokens[currentTab].find((e) => e.page == requestPage)
							?.prev_token
				: "";

			refetch({
				branch_id: variables.branch_id,
				filter: { ...variables.filter, limit },
				key_token: resolveValue(key_token),
			});
		} else {
			setPage(_page);
			setRowsPerPage(limit);
			submitFilters(filters, _page, limit);
		}
	};

	const handleChangeTab = (tab) => {
		setRequestFilters(defaultFilters);
		setRequestPage(1);
		setPrevTokens(defaultPrevTokens);
		setRowsPerRequestPage(25);
		setCurrentTab(tab);
		refetch({
			branch_id: variables.branch_id,
			filter: {
				...variables.filter,
				limit: 25,
				inactive_only:
					tab == "1" ? undefined : !isTruthy(variables.status),
				status: resolveValue(tab == "1" ? "PENDING" : variables.status),
			},
		});
	};

	const handleDisableBranchOrTerminal = requestWrapper(
		showLoader,
		hideLoader,
		async () => {
			const url = selectedBranch.tpaid
				? `/v3/branch-access/${selectedBranch.branchId}/terminals/${selectedBranch.tpaid}/set-status`
				: `/v3/branch-access/${selectedBranch.branchId}/set-status`;

			try {
				await HTTP.put(url, { status: 0 });

				const newStatus = BranchAccessControlStatus.ACTIVE_NO_SCHED;
				if (selectedBranch.tpaid) {
					dispatch(
						setBranchTerminalStatus({
							branchID: selectedBranch.branchId,
							tpaID: selectedBranch.tpaid,
							status: newStatus,
							is_operating: true,
						})
					);
				} else {
					dispatch(
						setBranchToDisable({
							branchID: selectedBranch.branchId,
						})
					);
				}

				const successInfo = {
					title: "Successfully Disabled",
					isOpen: true,
					message: `<b>${selectedBranch.branchName}</b> is now Active (No Schedule)`,
					fullWidth: false,
				};

				if (selectedBranch.tpaidName) {
					successInfo.message = `<b>${selectedBranch.branchName} ${selectedBranch.tpaidName}</b> is now Active (No Schedule)`;
				}

				setSuccessModalInfo(successInfo);
			} catch (error) {
				if (error?.response?.status === 403) {
					//to stop GET call if no add permission
					return;
				}
			}
		}
	);

	const fetchBranches = async (
		values = branchListFilter,
		page = 1,
		limit = rowsPerPage,
		showLoading = false
	) => {
		if (showLoading) showLoader();
		const result = await getBranches({ ...values, page, limit }, userHash);
		const displayedBranchAccess = {};
		for (let item of result?.items) {
			if (branchAccessTableAvailability.current[item.id]) {
				displayedBranchAccess[item.id] =
					branchAccessTableAvailability.current[item.id];
			} else {
				displayedBranchAccess[item.id] = {
					expanded: false,
				};
			}
		}
		branchAccessTableAvailability.current = displayedBranchAccess;
		dispatch(setInitialStateBranchAccessControl(result));
		if (showLoader) hideLoader();
	};

	const setIsTerminalExpanded = (branchId, expanded = false) => {
		if (!branchAccessTableAvailability.current[branchId]) return;

		branchAccessTableAvailability.current[branchId] = {
			expanded,
		};
	};

	const fetchBranchTerminals = useCallback(
		async (branchID) => {
			try {
				const branch = branchAccessControlList.items.find(
					(branch) => branch.id === branchID
				);

				if (!branch) {
					return;
				}

				const hasTPAList = branch.tpaList.items.length > 0;

				if (!hasTPAList) {
					return;
				}

				showLoader();
				const result = await getTerminals(filters, branchID);
				dispatch(
					setBranchTerminals({
						tpaList: result.items ?? [],
						branchID,
					})
				);
			} catch (e) {
				console.error(e);
			} finally {
				hideLoader();
			}
		},
		[branchAccessControlList.items]
	);

	const generateReprintCode = async () => {
		setIsCodeGenerating(true);
		await HTTP.post(`v2/branches/reprint-code/${branch.id}`)
			.then(({ data: { data } }) => {
				setCode(data.reprint_code);

				setNotifSuccess({
					visible: true,
					message:
						"You've successfully generated reprint code! Copied to clipboard.",
				});
				navigator.clipboard.writeText(data.reprint_code);
			})
			.catch((e) => {
				setNotifError({
					visible: true,
					message:
						"There's an error generating reprint code. Please try again.",
				});
			});
		setIsCodeGenerating(false);
	};

	useEffect(() => {
		(async () => {
			HTTP.get(`v2/branches/reprint-code/${branch.id}`).then(
				({ data: { data } }) => {
					setCode(data.reprint_code);
				}
			);

			const data = await getScopes();
			setPermissions((values) => {
				const _values = { ...values };
				Object.entries(_values).forEach(async (_v) => {
					if (
						data &&
						data.data.find((v) => v.scope === _v[1].scope)
					) {
						_values[_v[0]].hasPermission = true;
						if (_v[0] === "view_branch_access_control_list") {
							const [_, result] = await Promise.all([
								fetchBranches(),
								getBranchesDropdown(userHash),
							]);
							setBranches1(
								result.data.map((item) => ({
									name: item.name,
									value: item.id,
								}))
							);
						}
					} else {
						if (
							[
								// "view_pending_request",
								// "view_history",
								"reprint_code",
							].some((e) => e === _v[0])
						) {
							setTabs((_tabs) => {
								let _t = [..._tabs];
								// if (_v[0] === "view_pending_request") {
								// 	_t = _t.filter((e) => e.value != "1");
								// } else if (_v[0] === "view_history") {
								// 	_t = _t.filter((e) => e.value != "2");
								if (_v[0] === "view_reprint_code") {
									_t = _t.filter((e) => e.value != "3");
								}

								return _t;
							});
						}
						_values[_v[0]].hasPermission = false;
					}
				});

				return _values;
			});
		})();
	}, []);

	useEffect(() => {
		if (!isBranchModalOpen) {
			setSelectedBranch({});
		}
	}, [isBranchModalOpen]);

	const handleOpenBranchModal = useCallback((row) => {
		showBranchModal();
		setSelectedBranch(row);
	}, []);

	const [notifSuccess, setNotifSuccess] = useState({
		visible: false,
		message: "",
	});

	const showConfirmationModal = (row) => {
		const info = {
			title: "Disable Branch",
			content: `Are you sure you want to disable <b>${row.branchName}</b>?`,
			contentHint:
				"* This change will be applied to the TPA IDs under this branch.",
			isOpen: true,
		};

		if (row.tpaidName) {
			info.title = "Disable TPAID";
			info.content = `Are you sure you want to disable <b>${row.branchName} ${row.tpaidName}</b>?`;
			info.contentHint = "";
		}
		setConfirmationModalInfo(info);
		setSelectedBranch(row);
	};

	const [notifError, setNotifError] = useState({
		visible: false,
		message: "",
	});

	const [reasonDetailModalInfo, setReasonDetailModalInfo] = useState({
		isOpen: false,
		header: "",
		message: null,
		placeholder: "",
		reason: null,
		confirmButtonLabel: "",
	});

	const [errorModalInfo, setErrorModalInfo] = useState({
		isError: false,
		title: "",
		message: "",
	});

	const [reasonDetailModalUserData, setReasonDetailModalUserData] =
		useState(null);
	const [isFilterValueEmpty, setIsFilterValueEmpty] = useState(true);
	const [isUamStatusFiltered, setIsUamStatusFiltered] = useState(false);

	const headers = [
		{
			label: "Date and Time",
			identifier: "date_created",
			render: (value) => moment(value).format("MM/DD/YYYY hh:mm:ss A"),
		},
		{
			label: "Teller Name",
			identifier: "name",
		},
		{
			label: "Username",
			identifier: "username",
		},
		{
			label: "Branch",
			identifier: "branch_name",
		},
		{
			label: "TPA ID",
			identifier: "tpa_id",
		},
		{
			label: "Last Activity",
			identifier: "last_activity",
		},
		{
			label: "User Activity Status",
			identifier: "status",
			render: (value) => <UserListStatus status={value} list={STATUS} />,
		},
		{
			label: "UAM Status",
			identifier: "uamStatus",
			render: (value) => (
				<UserListStatus status={value} list={UAM_STATUS} />
			),
		},
		{
			label: "Actions",
			identifier: "actions",
			render: (value, entry, { onView }) => (
				<>
					<ActionColumn entry={entry} onView={onView} />
				</>
			),
		},
	];

	const showBlockUnblockModal = (entry) => {
		setReasonDetailModalUserData(entry);
		if (entry.uamStatus.toLowerCase().includes("blocked")) {
			const reasonDetailModal = {
				isOpen: true,
				header:
					"Unblock User " + entry.name + " (" + entry.username + ")",
				message:
					"This action will unblock " +
					entry.name +
					"'s login. this will also send a link to the user's email (" +
					entry.email +
					") to reset his/her password.",
				placeholder: "Explain the reason for unblocking the user",
				reason: null,
				confirmButtonLabel: "Unblock",
			};

			setReasonDetailModalInfo(reasonDetailModal);
		} else {
			const reasonDetailModal = {
				isOpen: true,
				header:
					"Block User " + entry.name + " (" + entry.username + ")",
				message: null,
				placeholder: "Explain the reason for blocking the user",
				reason: null,
				confirmButtonLabel: "Block",
			};

			setReasonDetailModalInfo(reasonDetailModal);
		}
	};

	const ActionColumn = ({ entry, onView }) => (
		<Grid container spacing={1} direction="row">
			<Grid item>
				<ActionBtnTooltip
					title={
						entry.uamStatus.toLowerCase().includes("blocked")
							? "Unblock"
							: "Block"
					}
				>
					<IconButton size="small">
						<IoIosRemoveCircle
							color={
								entry.uamStatus
									.toLowerCase()
									.includes("blocked")
									? ""
									: "#d92f25"
							}
							fontSize={"25px"}
							onClick={() => {
								showBlockUnblockModal(entry);
							}}
						/>
					</IconButton>
				</ActionBtnTooltip>
				<ActionBtnTooltip title="View">
					<IconButton size="small">
						<IoMdPaper
							fontSize={"25px"}
							onClick={() => {
								onView(entry);
							}}
						/>
					</IconButton>
				</ActionBtnTooltip>
			</Grid>
		</Grid>
	);

	const handleReasonDetailModal = (reason) => {
		setReasonDetailModalInfo((prev) => ({ ...prev, reason: reason }));
	};

	const submitBlockUnblockRequest = () => {
		const url = reasonDetailModalUserData.uamStatus
			.toLowerCase()
			.includes("blocked")
			? `/v3/user/${reasonDetailModalUserData.username}/unblock`
			: `/v3/user/${reasonDetailModalUserData.username}/block`;

		const data = {
			reason: reasonDetailModalInfo.reason,
		};

		showLoader();
		HTTP.put(url, JSON.stringify(data))
			.then(() => {
				setReasonDetailModalInfo({
					isOpen: false,
					header: "",
					message: null,
					placeholder: "",
					reason: null,
					confirmButtonLabel: "",
				});

				const errorInfo = {
					isError: false,
					title: "",
					message: "",
				};
				setErrorModalInfo(errorInfo);

				const message = reasonDetailModalUserData.uamStatus
					.toLowerCase()
					.includes("blocked")
					? "unblocked"
					: "blocked";

				const successInfo = {
					title: "Success!",
					isOpen: true,
					message: `You ${message} <b>${reasonDetailModalUserData.username}</b>.`,
					fullWidth: true,
				};

				submitFilters();
				setSuccessModalInfo(successInfo);
			})
			.catch((error) => {
				httpRetry.current = () => {
					submitBlockUnblockRequest();
				};
				setReasonDetailModalInfo({
					isOpen: false,
					header: "",
					message: null,
					placeholder: "",
					reason: null,
					confirmButtonLabel: "",
				});

				const errorMessage = reasonDetailModalUserData.uamStatus
					.toLowerCase()
					.includes("blocked")
					? `A problem occurred while unblocking 
						  <b>${reasonDetailModalUserData.username}</b>. 
						  Please try again.`
					: `A problem occurred while blocking 
						  <b>${reasonDetailModalUserData.username}</b>. 
						  Please try again.`;

				const errorInfo = {
					isError: true,
					title: "Timeout Error!",
					message: errorMessage,
				};

				setErrorModalInfo(errorInfo);
				console.error(error);
			})
			.finally(() => {
				hideLoader();
			});
	};

	useEffect(() => {
		if (reasonDetailModalUserData && reasonDetailModalInfo.reason) {
			submitBlockUnblockRequest();
		}
	}, [reasonDetailModalInfo]);

	useEffect(() => {
		if (isTruthy(filters.uamStatus)) {
			setIsFilterValueEmpty(false);
			setIsUamStatusFiltered(true);
		} else if (
			filters.dateFrom !== null ||
			filters.dateTo !== null ||
			isTruthy(filters.tellerName) ||
			isTruthy(filters.username) ||
			isTruthy(filters.branch) ||
			isTruthy(filters.status)
		) {
			setIsFilterValueEmpty(false);
			setIsUamStatusFiltered(false);
		} else {
			setIsUamStatusFiltered(false);
			setIsFilterValueEmpty(true);
		}
	}, [filters]);

	return (
		<>
			<Snackbar
				open={notifError.visible}
				autoHideDuration={6000}
				onClose={() => setNotifError({ visible: false, message: "" })}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			>
				<Alert
					onClose={() =>
						setNotifError({ visible: false, message: "" })
					}
					severity="error"
					className={styles.errorNotif}
					sx={{ width: "100%" }}
				>
					{notifError.message}
				</Alert>
			</Snackbar>
			<Snackbar
				open={notifSuccess.visible}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				autoHideDuration={6000}
				onClose={() => setNotifSuccess({ visible: false, message: "" })}
				key={0}
			>
				<Alert
					onClose={() =>
						setNotifSuccess({ visible: false, message: "" })
					}
					severity="success"
					className={styles.successNotif}
					sx={{ width: "100%" }}
				>
					{notifSuccess.message}
				</Alert>
			</Snackbar>
			<FullPageLoader open={isLoading} />
			<BranchAccessControlModal
				isOpen={isBranchModalOpen}
				selectedBranch={selectedBranch}
				setSuccessModalInfo={setSuccessModalInfo}
				handleClose={hideBranchModal}
				showLoader={showLoader}
				hideLoader={hideLoader}
				fetchBranchTerminals={fetchBranchTerminals}
			/>
			<Box p={1} mt={4}>
				<ListContainer>
					<Tabs tabs={tabs} onChange={handleChangeTab}>
						{/* {permissions.view_pending_request.hasPermission && (
							<TabPanel value="1">
								<TableContainer
									title="Pending Request for Approval"
									page={requestPage}
									searchTitle="Username"
									rowsPerPage={rowsPerRequestPage}
									statusOptions={STATUS_REQUEST_FILTERS}
									data={requests}
									headers={request_headers(1)}
									tellerNamesData={tellerNames}
									userNamesData={userNames}
									branchesData={branches}
									resetFormOnTab={{ currentTab, tab: 1 }}
									isHidePageNumber={true}
									onPageChange={(page, limit) =>
										handlePageChange(page, limit, true)
									}
									onSort={(sortBy, sort) =>
										handleSort(sortBy, sort, true)
									}
									filters={requestFilters}
									defaultFilters={defaultFilters}
									onFilterChange={(values) =>
										handleFilterChange(values, true)
									}
									onFilterSubmit={(values) =>
										handleFilterSubmit(values, true)
									}
									isReloadLocked={isReloadLocked}
								/>
							</TabPanel>
						)}
						{permissions.view_history.hasPermission && (
							<TabPanel value="2">
								<TableContainer
									title="History of Teller Requests"
									page={requestPage}
									rowsPerPage={rowsPerRequestPage}
									statusOptions={STATUS_REQUEST_FILTERS}
									data={requests}
									searchTitle="Username"
									headers={request_headers(2)}
									tellerNamesData={tellerNames}
									userNamesData={userNames}
									branchesData={branches}
									resetFormOnTab={{ currentTab, tab: 2 }}
									isHidePageNumber={true}
									onPageChange={(page, limit) =>
										handlePageChange(page, limit, true)
									}
									onSort={(sortBy, sort) =>
										handleSort(sortBy, sort, true)
									}
									filters={requestFilters}
									defaultFilters={defaultFilters}
									onFilterChange={(values) =>
										handleFilterChange(values, true)
									}
									onFilterSubmit={(values) =>
										handleFilterSubmit(values, true)
									}
									isReloadLocked={isReloadLocked}
								/>
							</TabPanel>
						)} */}
						{permissions.view_reprint_code.hasPermission && (
							<TabPanel value="3">
								<Typography className={styles.headerCode}>
									Generate Code
								</Typography>
								<div
									container
									style={{ padding: "25px 0 10px 0" }}
								>
									<OutlinedInput
										id="outlined-adornment-password"
										type={
											showPasscode ? "text" : "password"
										}
										style={{
											height: "42px",
											width: "267px",
										}}
										value={code}
										disabled={true}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														navigator.clipboard.writeText(
															code
														);
														setNotifSuccess({
															visible: true,
															message:
																"You've successfully copied the reprint code!",
														});
													}}
													onMouseDown={(e) =>
														e.preventDefault()
													}
													edge="end"
												>
													<ContentCopyIcon
														style={{
															fontSize: "18px",
														}}
													/>
												</IconButton>
												<IconButton
													aria-label="toggle password visibility"
													onClick={() =>
														setShowPasscode(
															!showPasscode
														)
													}
													onMouseDown={(e) =>
														e.preventDefault()
													}
													edge="end"
												>
													{showPasscode ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										}
										fullWidth={true}
										InputProps={{
											classes: {
												input: styles.modalBody,
											},
										}}
										InputLabelProps={{
											shrink: true,
											classes: {
												root: styles.modalBody,
											},
										}}
									/>
									<Button
										onClick={generateReprintCode}
										className={styles.generate}
										disabled={isCodeGenerating}
									>
										{isCodeGenerating ? (
											<CircularProgress
												size={18}
												style={{ marginRight: "2px" }}
											/>
										) : (
											"Generate Code"
										)}
									</Button>
								</div>
							</TabPanel>
						)}
						{permissions.view_user_list.hasPermission && (
							<TabPanel value="4">
								<TableContainer
									title="User List"
									page={page}
									rowsPerPage={rowsPerPage}
									statusOptions={STATUS_FILTERS}
									data={users}
									headers={headers}
									searchTitle="Username"
									tellerNamesData={tellerNames}
									userNamesData={userNames}
									branchesData={branches}
									onPageChange={handlePageChange}
									onSort={handleSort}
									filters={{ tpaid: terminalId, ...filters }}
									defaultFilters={defaultFilters}
									isHidePageNumber={false}
									onFilterChange={(values) =>
										handleFilterChange(values, false)
									}
									onFilterSubmit={handleFilterSubmit}
									isReloadLocked={isReloadLocked}
									hasActivityLogPermission={
										permissions.view_activity_logs
											.hasPermission
									}
									uamStatusOptions={UAM_STATUS_FILTERS}
									isFilterValueEmpty={isFilterValueEmpty}
									isUamStatusFiltered={isUamStatusFiltered}
								/>
							</TabPanel>
						)}
					</Tabs>
					<br />

					{permissions.view_branch_access_control_list
						.hasPermission && (
						<TableContainer
							title="Branch Access Control"
							className={styles.branchAccessControlTable}
							page={branchPage}
							searchTitle="Search TPAID"
							rowsPerPage={rowsPerBranchPage}
							statusOptions={STATUS_BRANCH_ACCESS_CONTROL_FILTERS}
							data={branchAccessControlList}
							headers={branchListHeaders}
							onPageChange={(_page, limit) => {
								setBranchPage(_page);
								setRowsPerBranchPage(limit);
								fetchBranches(
									branchListFilter,
									_page,
									limit,
									true
								);
							}}
							onSort={handleSort}
							filters={branchListFilter}
							branchesData={branches1}
							defaultFilters={defaultBranchListFilter}
							isHidePageNumber={false}
							onFilterChange={(values) =>
								setBranchListFilter(values)
							}
							onFilterSubmit={async (values = {}) => {
								const { username, ...rest } = values;
								setBranchPage(1);
								await fetchBranches(
									{ tpa: username, ...rest },
									1,
									rowsPerBranchPage,
									true
								);
							}}
							expandRow={(data) => (
								<TPAIDTable
									data={data}
									filters={branchListFilter}
									rowsPerPage={rowsPerBranchPage}
									handleOpenBranchModal={
										handleOpenBranchModal
									}
									showConfirmationModal={
										showConfirmationModal
									}
									setIsTerminalExpanded={
										setIsTerminalExpanded
									}
								/>
							)}
							handlers={{
								handleOpenBranchModal,
								showConfirmationModal,
							}}
							isReloadLocked={isReloadLocked}
							uamStatusOptions={UAM_STATUS}
						/>
					)}

					<ReasonDetailModal
						open={reasonDetailModalInfo.isOpen}
						title={reasonDetailModalInfo.header}
						message={reasonDetailModalInfo.message}
						placeholder={reasonDetailModalInfo.placeholder}
						cancelBtnLabel={"Cancel"}
						confirmBtnLabel={
							reasonDetailModalInfo.confirmButtonLabel
						}
						onClose={() => {
							setReasonDetailModalInfo((prev) => ({
								...prev,
								isOpen: false,
							}));
						}}
						reason={handleReasonDetailModal}
					/>

					<ErrorModal
						open={errorModalInfo.isError}
						toggleDialog={() => {
							httpRetry?.current();
						}}
						title={errorModalInfo.title}
						message={errorModalInfo.message}
						buttonText={"Retry"}
					/>

					<Box p={1} className={styles.version}>
						Bayad FA version 1.0
					</Box>
				</ListContainer>
			</Box>
			<ConfirmationModal
				isOpen={confirmationModalInfo.isOpen}
				title={confirmationModalInfo.title}
				content={confirmationModalInfo.content}
				contentHint={confirmationModalInfo.contentHint}
				onClickCancel={() =>
					setConfirmationModalInfo((prev) => ({
						...prev,
						isOpen: false,
					}))
				}
				onClickConfirm={() => {
					handleDisableBranchOrTerminal();
					setConfirmationModalInfo((prev) => ({
						...prev,
						isOpen: false,
					}));
				}}
			/>
			<SuccessModal
				isOpen={successModalInfo.isOpen}
				title={successModalInfo.title}
				message={successModalInfo.message}
				fullWidth={successModalInfo.fullWidth}
				buttonTitle="Done"
				toggleDialog={() =>
					setSuccessModalInfo((prev) => ({ ...prev, isOpen: false }))
				}
			/>
		</>
	);
};

UserManagement.propTypes = {
	isReloadLocked: PropTypes.bool,
};

export default connect((state) => ({
	isReloadLocked: state.timeout.isReloadLocked,
	branchAccessControl: state.branchAccessControl,
}))(UserManagement);
