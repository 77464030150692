import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));
const FullPageLoader = ({ open }) => {
	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={open}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};

FullPageLoader.propTypes = {
	open: PropTypes.bool,
};

FullPageLoader.defaultProps = {
	open: false,
};

export default FullPageLoader;
