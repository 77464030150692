import Button, { ButtonProps } from "@mui/material/Button";
import { FaSpinner } from "react-icons/fa";
import customStyle from "./customStyle.module.css";

export const Buttons = ({
	variant,
	text,
	radius,
	styling,
	handleOnClick,
	isLoading,
	disabled,
	...props
}) => {
	return (
		<>
			<Button
				variant={variant}
				onClick={handleOnClick}
				sx={{ borderRadius: radius ? "21px" : null }}
				style={styling}
				{...props}
				disabled={disabled}
			>
				{text}
				{isLoading == true ? (
					<FaSpinner className={customStyle.spinnerAnimation} />
				) : null}
			</Button>
		</>
	);
};

export default Buttons;
