import { makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	customTooltip: {
		backgroundColor: "gray",
		// color: "black",
		fontSize: "16px",
		boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
	},
}));

export default function CustomTooltip(props: any) {
	const classes = useStyles();

	return (
		<Tooltip
			followCursor={true}
			{...props}
			classes={{
				tooltip: classes.customTooltip,
			}}
		/>
	);
}
