import styled from "@emotion/styled";
import { DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@mui/material";
import { includes } from "lodash";
import { FC, useMemo } from "react";
import { Description as DescriptionIcon } from "@material-ui/icons";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

type PreviewModalProps = {
	isOpen: boolean;
	file: any;
	onClose: () => void;
	isEdit: boolean;
};

const NoPreview = () => {
	return (
		<NoPreviewContainer>
			<IconContainer>
				<DescriptionIcon />
			</IconContainer>
			<NoPreviewText>This file has no preview.</NoPreviewText>
		</NoPreviewContainer>
	);
};

/**
 * A modal component that displays the file being previewed in the VoidRequestModal component
 * @param {PreviewModalProps} props
 * @component
 */
const PreviewModal: FC<PreviewModalProps> = ({
	isEdit = false,
	isOpen,
	file,
	onClose,
}) => {
	if (!isEdit) {
		//if void creation
		//access nest level
		file = file?.file;
	}
	const fileType = useMemo(() => {
		switch (file?.type) {
			case ".docx":
				return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
			case ".pdf":
				return "application/pdf";
			case ".jpeg":
				return "image/jpeg";
			case ".jpg":
				return "image/jpg";
			case ".png":
				return "image/png";
			default:
				return file?.type;
		}
	}, [file]);

	const isImage = useMemo(() => {
		return includes(["image/jpeg", "image/jpg", "image/png"], fileType);
	}, [file]);

	const isPdf = useMemo(() => {
		return includes(["application/pdf"], fileType);
	}, [file]);

	const [numPages, setNumPages] = useState(null);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	return (
		<Dialog maxWidth="sm" open={isOpen} onClose={onClose}>
			<DialogTitle style={{ padding: "16px 10px" }}>
				<DialogTitleWrapper>
					<FileName>{file?.name || file?.file_name}</FileName>
					<IconButton onClick={() => onClose()} aria-label="close">
						<CloseIcon />
					</IconButton>
				</DialogTitleWrapper>
			</DialogTitle>
			<DialogContent
				style={{
					paddingBottom: "5px",
					paddingLeft: "10px",
					paddingRight: "10px",
				}}
			>
				{isImage ? (
					<PreviewImage
						src={
							file?.url || URL?.createObjectURL(file?.url || file)
						}
						alt="file-image"
					/>
				) : isPdf ? (
					<div>
						<Document
							file={
								file?.url ||
								URL?.createObjectURL(file?.url || file)
							}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							{Array.from(new Array(numPages), (el, index) => (
								<Page
									key={index + 1}
									pageNumber={index + 1}
									width={500}
								/>
							))}
						</Document>
					</div>
				) : (
					<NoPreview />
				)}
			</DialogContent>
		</Dialog>
	);
};

export default PreviewModal;

const PreviewImage = styled.img`
	width: 100%;
	height: auto;
	object-fit: cover;
`;

const FileName = styled.span`
	font-size: 14.5px;
	text-overflow: ellipsis;
	width: 490px;
	font-family: Poppins-Medium;
	overflow: hidden;
	white-space: nowrap;
	font-weight: bold;
`;

const NoPreviewContainer = styled.div`
	width: 100%;
	height: 60vh;
	background-color: #dadce0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
`;

const DialogTitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		height: 55px;
		width: 50px;
		color: #8b9094;
	}
`;

const NoPreviewText = styled.span`
	font-size: 12.5px;
	font-family: Poppins-Regular;
`;
