import { useState } from "react";
import { connect } from "react-redux";
import {
	styled,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import FormattedTextField from "../Input/FormattedTextField.js";
import textStyles from "../textStyles.module.css";

const StyledAccordion = styled((props) => (
	<Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	margin: "5px",
	padding: "0px",
}));

const StyledAccordionSummary = styled((props) => (
	<AccordionSummary {...props} />
))(({ theme }) => ({
	backgroundColor: "#EBEBEB",
	margin: "0px",
	padding: "10px",
}));

const StyledAccordionDetails = styled((props) => (
	<AccordionDetails {...props} />
))(({ theme }) => ({
	padding: 0,
}));

const DynamicAccordion = ({
	accordionSummary,
	defaultExpanded,
	children,
	className,
	...props
}) => {
	const [expanded, setExpanded] = useState(false);

	return (
		<>
			<StyledAccordion
				onChange={() => setExpanded(!expanded)}
				defaultExpanded
			>
				<StyledAccordionSummary
					expandIcon={
						expanded ? (
							<Remove sx={{ fontSize: "14px", color: "black" }} />
						) : (
							<Add sx={{ fontSize: "14px", color: "black" }} />
						)
					}
				>
					<FormattedTextField
						className={textStyles.textStyles6001214WavehausSemi}
					>
						{accordionSummary}
					</FormattedTextField>
				</StyledAccordionSummary>
				<StyledAccordionDetails>{children}</StyledAccordionDetails>
			</StyledAccordion>
		</>
	);
};

export default connect()(DynamicAccordion);
