import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import loadingStyles from "./Loading.module.css";

export default function LoadingBar({ loading, styleProps, ...props }) {
	return (
		<>
			{loading ? (
				<Box
					className={loadingStyles.loadingBarBg}
					sx={{ display: "flex", ...styleProps }}
				>
					<CircularProgress {...props} />
				</Box>
			) : (
				<></>
			)}
		</>
	);
}
