import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Theme,
	Dialog,
	withStyles,
	DialogContent as MuiDialogContent,
	createStyles,
	Button,
} from "@material-ui/core";
import { ReminderUtil } from "../../utils/checkSession";
import { hideReminderModal, showEndSession } from "../../redux/modules/timeout";
import TimesUpSVG from "../../assets/icons/img-timesup.svg";
import styles from "./Reminder.module.css";

const DialogWithStyle = withStyles({
	root: {
		zIndex: 1100,
	},
})(Dialog);

const _styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	});

const GreenColorButton = withStyles(() => ({
	root: {
		color: "#FFFFFF",
		backgroundColor: "#2FAC45",
		"&:hover": {
			backgroundColor: "#138906",
		},
	},
}))(Button);

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
		paddingTop: 0,
		paddingBottom: 0,
	},
}))(MuiDialogContent);

const Reminder = () => {
	const { isOpen, isUserHasTime } = useSelector((state: any) => ({
		isOpen: state.timeout.showReminderModal,
		isUserHasTime: state.timeout.isUserHasTime,
	}));
	const dispatch = useDispatch();

	const handleCloseModal = () => {
		ReminderUtil.set(true);
		dispatch(hideReminderModal());
	};

	const handleEndSession = () => {
		ReminderUtil.set(true);
		dispatch(showEndSession());
	};

	return (
		<DialogWithStyle disableBackdropClick open={isOpen} maxWidth="lg">
			<DialogContent className={styles.modalContent}>
				<img
					src={TimesUpSVG}
					alt="Reminder"
					className={styles.reminderImg}
				/>
				{isUserHasTime ? (
					<>
						<span className={styles.title}>Reminder!</span>
						<span className={styles.body}>
							Your operating schedule will end in{" "}
							<b>5 minutes.</b>
							<br />
							Kindly finish your current transaction and proceed
							<br />
							to end the session.
						</span>
					</>
				) : (
					<>
						<span className={styles.title}>Time's up!</span>
						<span className={styles.body}>
							Your operating schedule just ended.
							<br />
							Kindly end session now.
						</span>
					</>
				)}
				<div className={styles.actionContainer}>
					{isUserHasTime ? (
						<>
							<Button
								variant="outlined"
								onClick={handleCloseModal}
							>
								Cancel
							</Button>
							<GreenColorButton onClick={handleEndSession}>
								Continue
							</GreenColorButton>
						</>
					) : (
						<GreenColorButton onClick={handleEndSession}>
							End Session
						</GreenColorButton>
					)}
				</div>
			</DialogContent>
		</DialogWithStyle>
	);
};

export default Reminder;
