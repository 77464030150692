import React from "react";
import errorSvg from "../../assets/icons/ic-error.svg";
import styles from "./Alert.module.css";

const Alert: React.FC<{}> = () => {
	return (
		<div className={styles.alertContainer}>
			<img src={errorSvg} alt="Error Icon" />
			<span>
				You have insufficient funds in your wallet. Kindly replenish
				your wallet to continue processing payments.
			</span>
		</div>
	);
};

export default Alert;
