import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { styled, Stack, Button, Box } from "@mui/material";
import textStyles from "../textStyles.module.css";
import { Formik } from "formik";
import { DatePickerForm } from "../DatePicker/FormattedDatePicker.js";
import InputField from "../Input/InputField.js";
import SelectField from "../Input/SelectField.js";
import FormattedTextField from "../Input/FormattedTextField.js";
import moment from "moment";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { setFile } from "../../../redux/modules/kycRegistration";
import { useSelector, useDispatch } from "react-redux";

const StyledStack = styled(Stack)(({ theme }) => ({
	display: "flex",
	flexFlow: "wrap",
	justifyContent: "space-between",
	padding: "5px 0 5px 0",
	alignItems: "center",
}));

const StyledAttachmentButton = styled(Button)(({ theme }) => ({
	height: "100%",
	flex: "1 0 19%",
	maxWidth: "19%",
	margin: "5px 0 5px 0",
	padding: "16px",
}));

const StyledBox = styled(Box)(({ theme }) => ({
	flex: "1 0 19%",
	maxWidth: "19%",
	margin: "5px 0 5px 0",
}));

const stylingInputFileEmpty = {
	backgroundColor: "#8A8A8A ",
	borderColor: "red ",
	color: "#FFF",
};

const stylingInputFile = {
	height: "100%",
	flex: "1 0 19%",
	maxWidth: "19%",
	margin: "5px 0 5px 0",
	padding: "16px",
};

const stylingInputFileRequired = {
	backgroundColor: "#f44336 ",
	color: "#FFF",
};

const IdentificationDetails = (props) => {
	let dispatch = useDispatch();
	let tomorrow = new Date();
	tomorrow.setDate(new Date().getDate() + 1);

	const { setIdentificationCIFields, submitHover } = props;

	const [inputFields, setInputFields] = useState({
		idType: {
			type: "Select",
			placeholder: "Select ID Type",
			name: "idType",
			fieldValue: "",
			onChange: (val, inputConfiguration) =>
				inputOnChange(
					val.target.name,
					val.target.value,
					inputConfiguration
				),
			values: [{}],
			required: true,
		},
		idNumber: {
			type: "Input",
			placeholder: "ID Number",
			name: "idNumber",
			fieldValue: "",
			onChange: (val) => inputOnChange(val.target.name, val.target.value),
			required: false,
			disabled: true,
		},
		idExpirationDate: {
			type: "Date",
			placeholder: "Expiration Date",
			name: "idExpirationDate",
			fieldValue: "",
			onChange: (val) =>
				inputOnChange(
					"idExpirationDate",
					moment(val).format("DD/MM/YYYY")
				),
			initialValues: { idExpirationDate: null },
			required: false,
			disabled: true,
		},
		attachFrontId: {
			type: "Attachment",
			placeholder: "FRONT ID",
			name: "attachFrontId",
			fieldValue: null,
			onChange: (val) => {
				const file = val.target.files[0];
				dispatch(setFile(file));
				inputOnChange(val.target.name, file.name);
			},
			required: true,
			dbname: "idPictures",
			idNumber: 0,
		},
		attachBackId: {
			type: "Attachment",
			placeholder: "BACK ID",
			name: "attachBackId",
			fieldValue: null,
			onChange: (val) => {
				const file = val.target.files[0];
				inputOnChange(val.target.name, file.name);
			},
			required: true,
			dbname: "idPictures",
			idNumber: 1,
		},
	});

	useEffect(() => {
		setIdentificationCIFields(inputFields);
	}, [inputFields]);

	const inputOnChange = (name, values, inputConfiguration) => {
		if (name == "idType") {
			setInputFields((prevProps) => ({
				...prevProps,
				["idNumber"]: {
					...prevProps["idNumber"],
					disabled: !inputConfiguration[values - 1].idNumberEnabled,
					required: inputConfiguration[values - 1].idNumberEnabled,
					fieldValue: "",
				},
				["idExpirationDate"]: {
					...prevProps["idExpirationDate"],
					disabled:
						!inputConfiguration[values - 1].idExpirationDateEnabled,
					required:
						inputConfiguration[values - 1].idExpirationDateEnabled,
					fieldValue: "",
					initialValues: { idExpirationDate: null },
				},
				[name]: { ...prevProps[name], fieldValue: values },
			}));
		} else {
			setInputFields((prevProps) => ({
				...prevProps,
				[name]: { ...prevProps[name], fieldValue: values },
			}));
		}
	};

	useEffect(() => {
		let tempDataIdType = {};
		Object.values(props.idTypesSelectValues).map((val) => {
			tempDataIdType = dropdownValuesSupply(
				tempDataIdType,
				"idType",
				val
			);
		});
	}, [props.idTypesSelectValues]);

	const dropdownValuesSupply = (tempData, objectKey, val) => {
		let selectVals = tempData ? tempData.values : {};
		if (selectVals) {
			tempData = {
				...inputFields[objectKey],
				values: [
					...selectVals,
					{
						id: val.id,
						name: val.name,
						idNumberEnabled: val.idNumberEnabled,
						idExpirationDateEnabled: val.idExpirationDateEnabled,
					},
				],
			};
		} else {
			tempData = {
				...inputFields[objectKey],
				values: [
					{
						id: val.id,
						name: val.name,
						idNumberEnabled: val.idNumberEnabled,
						idExpirationDateEnabled: val.idExpirationDateEnabled,
					},
				],
			};
		}
		setInputFields((prevProps) => ({
			...prevProps,
			[objectKey]: tempData,
		}));
		return tempData;
	};
	return (
		<>
			<StyledStack>
				{Object.values(inputFields).map((fields, x) => {
					if (fields.type == "Input") {
						return (
							<StyledBox key={x}>
								<InputField
									className={
										textStyles.textStylesNormal1218Poppins
									}
									label={fields.placeholder}
									sx={{
										fontSize: 12,
										fontFamily: "Poppins",
									}}
									inputProps={{
										style: {
											fontSize: 12,
											fontFamily: "Poppins",
										},
									}}
									name={fields.name}
									value={fields.fieldValue}
									onChange={(val) => fields.onChange(val)}
									key={x}
									required={fields.required}
									error={
										submitHover
											? fields.required
												? fields.fieldValue
													? false
													: true
												: false
											: false
									}
									disabled={fields.disabled}
								/>
							</StyledBox>
						);
					} else if (fields.type == "Select") {
						return (
							<StyledBox key={x}>
								<SelectField
									className={
										textStyles.textStylesNormal1218Poppins
									}
									label={fields.placeholder}
									values={fields.values}
									value={fields.fieldValue}
									variant="outlined"
									name={fields.name}
									onChange={(val) =>
										fields.onChange(val, fields.values)
									}
									key={x}
									required={fields.required}
									error={
										submitHover
											? fields.required
												? fields.fieldValue
													? false
													: true
												: false
											: false
									}
								/>
							</StyledBox>
						);
					} else if (fields.type == "Date") {
						return (
							<StyledBox key={x}>
								<Formik
									initialValues={fields.initialValues}
									key={x}
								>
									<DatePickerForm
										label={fields.placeholder}
										name={fields.name}
										required={fields.required}
										handleChange={(val) =>
											fields.onChange(val._d)
										}
										key={x}
										fieldValue={fields.fieldValue}
										error={
											submitHover
												? fields.required
													? fields.fieldValue
														? false
														: true
													: false
												: false
										}
										disabled={fields.disabled}
										minDate={tomorrow}
									/>
								</Formik>
							</StyledBox>
						);
					} else if (fields.type == "Attachment") {
						return (
							<StyledAttachmentButton
								variant="contained"
								component="label"
								key={x}
								style={
									submitHover
										? fields.required
											? fields.fieldValue
												? stylingInputFile
												: stylingInputFileRequired
											: stylingInputFileEmpty
										: fields.fieldValue
										? stylingInputFile
										: stylingInputFileEmpty
								}
							>
								<FormattedTextField
									className={
										textStyles.textStylesNormal1218Poppins
									}
								>
									{fields.fieldValue
										? fields.placeholder
										: "ATTACH " + fields.placeholder + " *"}
								</FormattedTextField>
								<input
									type="file"
									hidden
									name={fields.name}
									onChange={(val) => fields.onChange(val)}
									key={x}
									accept="image/png, image/jpg, image/jpeg"
								/>
							</StyledAttachmentButton>
						);
					}
				})}
			</StyledStack>
		</>
	);
};

export default connect(
	(state) => ({
		idTypesSelectValues: state.kycBayad.idTypesSelectValues,
	}),
	{ setFile }
)(IdentificationDetails);
