import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	transactionType: "",
	nationalitiesSelectValues: {},
	idTypesSelectValues: {},
	provincesSelectValues: {},
	permanentCitiesSelectValues: [],
	presentCitiesSelectValues: [],
	permanentZipSelectValues: [],
	presentZipSelectValues: [],
	sofSelectValues: {},
	nofSelectValues: {},
	selectedPermanentProvince: null,
	selectedPresentProvince: null,
	selectedPermanentCity: null,
	selectedPresentCity: null,
};

const kycBayad = createSlice({
	name: "kycBayad",
	initialState,
	reducers: {
		setSelectedPermanentProvince: (state, { payload }) => {
			state.selectedPermanentProvince = payload;
		},
		setSelectedPresentProvince: (state, { payload }) => {
			state.selectedPresentProvince = payload;
		},
		setSelectedPermanentCity: (state, { payload }) => {
			state.selectedPermanentCity = payload;
		},
		setSelectedPresentCity: (state, { payload }) => {
			state.selectedPresentCity = payload;
		},
		setTransactionType: (state, { payload }) => {
			state.transactionType = payload;
		},
		loading: (state, payload) => {
			state.loading = true;
		},
		error: (state, payload) => {
			state.loading = false;
			state.isError = true;
			state.error = payload;
		},
		fulfilledNationalitiesSelectValues: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.nationalitiesSelectValues = payload.result.data.nationality;
		},
		fulfilledIdTypesSelectValues: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.idTypesSelectValues = payload.result.data.idTypes;
		},
		fulfilledProvincesSelectValues: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.provincesSelectValues = payload.result.data.provinces;
		},
		fulfilledPermanentCitiesSelectValues: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.permanentCitiesSelectValues = payload.result.data.cities;
		},
		fulfilledPresentCitiesSelectValues: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.presentCitiesSelectValues = payload.result.data.cities;
		},
		fulfilledPermanentZipSelectValues: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.permanentZipSelectValues = payload.result.data.zipCodes;
		},
		fulfilledPresentZipSelectValues: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.presentZipSelectValues = payload.result.data.zipCodes;
		},
		fulfilledSofSelectValues: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.sofSelectValues = payload.result.data.sourceOfFunds;
		},
		fulfilledNofSelectValues: (state, payload) => {
			state.loading = false;
			state.isSuccess = true;
			state.nofSelectValues = payload.result.data.natureOfWork;
		},
	},
	extraReducers: {},
});

const getNationalitiesValuesAPI = async (client, state) => {
	return client.get("/v2/remittance/kyc/nationalities");
};

function getNationalitiesValues() {
	return {
		types: [
			loading.type,
			fulfilledNationalitiesSelectValues.type,
			error.type,
		],
		promise: getNationalitiesValuesAPI,
	};
}

const getIdTypesValuesAPI = async (client, state) => {
	return client.get("/v2/remittance/kyc/ids");
};

function getIdTypesValues() {
	return {
		types: [loading.type, fulfilledIdTypesSelectValues.type, error.type],
		promise: getIdTypesValuesAPI,
	};
}

const getProvincesValuesAPI = async (client, state) => {
	return client.get("/v2/remittance/kyc/provinces");
};

function getProvincesValues() {
	return {
		types: [loading.type, fulfilledProvincesSelectValues.type, error.type],
		promise: getProvincesValuesAPI,
	};
}

const getPermanentCitiesValuesAPI = async (client, state) => {
	return await client.get("/v2/remittance/kyc/cities", {
		params: { province_id: state.kycBayad.selectedPermanentProvince },
	});
};

const getPresentCitiesValuesAPI = async (client, state) => {
	return await client.get("/v2/remittance/kyc/cities", {
		params: { province_id: state.kycBayad.selectedPresentProvince },
	});
};

function getCitiesValues(addressType) {
	if (addressType == "permanentAddress") {
		return {
			types: [
				loading.type,
				fulfilledPermanentCitiesSelectValues.type,
				error.type,
			],
			promise: getPermanentCitiesValuesAPI,
		};
	} else if (addressType == "presentAddress") {
		return {
			types: [
				loading.type,
				fulfilledPresentCitiesSelectValues.type,
				error.type,
			],
			promise: getPresentCitiesValuesAPI,
		};
	}
}

const getPermanentZipValuesAPI = async (client, state) => {
	return await client.get("/v2/remittance/kyc/zip-codes", {
		params: {
			province_id: state.kycBayad.selectedPermanentProvince,
			city_id: state.kycBayad.selectedPermanentCity,
		},
	});
};

const getPresentZipValuesAPI = async (client, state) => {
	return await client.get("/v2/remittance/kyc/zip-codes", {
		params: {
			province_id: state.kycBayad.selectedPresentProvince,
			city_id: state.kycBayad.selectedPresentCity,
		},
	});
};

function getZipValues(addressType) {
	if (addressType == "permanentAddress") {
		return {
			types: [
				loading.type,
				fulfilledPermanentZipSelectValues.type,
				error.type,
			],
			promise: getPermanentZipValuesAPI,
		};
	} else if (addressType == "presentAddress") {
		return {
			types: [
				loading.type,
				fulfilledPresentZipSelectValues.type,
				error.type,
			],
			promise: getPresentZipValuesAPI,
		};
	}
}

const getSofValuesAPI = async (client, state) => {
	return client.get("/v2/remittance/kyc/source-of-funds");
};

function getSofValues() {
	return {
		types: [loading.type, fulfilledSofSelectValues.type, error.type],
		promise: getSofValuesAPI,
	};
}

const getNofValuesAPI = async (client, state) => {
	return client.get("/v2/remittance/kyc/nature-of-work");
};

function getNofValues() {
	return {
		types: [loading.type, fulfilledNofSelectValues.type, error.type],
		promise: getNofValuesAPI,
	};
}

const {
	setTransactionType,
	setSelectedPermanentProvince,
	setSelectedPresentProvince,
	selectedPermanentProvince,
	selectedPresentProvince,
	setSelectedPermanentCity,
	setSelectedPresentCity,
	loading,
	error,
	fulfilledNationalitiesSelectValues,
	fulfilledIdTypesSelectValues,
	fulfilledProvincesSelectValues,
	fulfilledPermanentCitiesSelectValues,
	fulfilledPresentCitiesSelectValues,
	fulfilledPermanentZipSelectValues,
	fulfilledPresentZipSelectValues,
	fulfilledSofSelectValues,
	fulfilledNofSelectValues,
} = kycBayad.actions;

export default kycBayad.reducer;

export {
	loading,
	setSelectedPermanentProvince,
	setSelectedPresentProvince,
	setSelectedPermanentCity,
	setSelectedPresentCity,
	setTransactionType,
	getNationalitiesValues,
	getIdTypesValues,
	getProvincesValues,
	getCitiesValues,
	getZipValues,
	getSofValues,
	getNofValues,
};
